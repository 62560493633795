import { Moment } from 'moment';

import { TaxiStore, TaxiStoreType } from './store';

import { MainAnalytic } from '../../utils/analytics';

import { AVAILABLE_CLASSES } from '../../constants/taxi';
import { PATTERN } from '../../constants/dateFormats';

import { AnalyticTaxiClass } from '../../types/taxi';
import { VoucherStoreType } from './types';

class Taxi {
  api: any;
  xhr: any;
  store: TaxiStoreType;

  constructor(api: any) {
    this.api = api.taxi;
    this.xhr = null;
    this.store = TaxiStore;
  }

  setVoucherClasses = (isApiV2: boolean) => this.store.setVoucherClasses(isApiV2);

  setDate = (value: Moment | null) => this.store.setDate(value);

  setDateTo = (value: Moment | null) => this.store.setDateTo(value);

  setAmount = (value: number | string) => this.store.setAmount(value);

  setVAT = (value: number) => this.store.setVAT(value);

  setCount = (value: number | string) => this.store.setCount(value);

  setAvailableClasses = (value: number) => {
    this.store.setAvailableClasses(value);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_TAXI_CLASS_CHOSEN(
        AVAILABLE_CLASSES.find(i => i.value === value)?.analyticValue as AnalyticTaxiClass,
      ),
    );
  };

  setLoading = (value: boolean) => this.store.setLoading(value);

  getVouchers = () => {
    this.getActiveVouchers();
    this.getCreatedVouchers();
    this.getTextWarning();
  };

  getActiveVouchers = () => {
    this.store.setLoadingActiveVouchers(true);

    this.api.getActiveVouchers()
      .then((res: VoucherStoreType[]) => {
        this.store.setActiveVouchers(res);
        this.store.setLoadingActiveVouchers(false);
      })
      .catch(() => {
        this.store.setLoadingActiveVouchers(false);
      });
  };

  getCreatedVouchers = () => {
    this.store.setLoadingCreatedVouchers(true);

    this.api.getCreatedVouchers()
      .then((res: VoucherStoreType[]) => {
        this.store.setCreatedVouchers(res);
        this.store.setLoadingCreatedVouchers(false);
      })
      .catch(() => {
        this.store.setLoadingCreatedVouchers(false);
      });
  };

  getTextWarning = async () => {
    const data = await this.api.getTextWarning();

    this.store.setTextTemplateWarning(data?.content?.text || '');
  };

  resetVoucher = (isApiV2: boolean) => {
    this.store.resetVoucher(isApiV2);
  };

  addToCart = (TripId: string) => {
    const {
      date,
      dateTo,
      amount,
      vat,
      count,
      availableClasses,
    } = this.store.voucher;

    const StartDate = date?.format(PATTERN.YEARMONTHDAY);
    const EndDate = dateTo?.format(PATTERN.YEARMONTHDAY);

    return this.api.addToCart({
      Amount: Number(amount) + Number(vat),
      Count: count,
      TripId: TripId || null,
      StartDate,
      EndDate,
      Classes: availableClasses,
    });
  };
}

export default Taxi;
