import React from 'react';
import { Button } from 'new-ui';

import { IMiceButtons } from './types';

import styles from './styles/index.module.css';

const MiceButtons = ({
  labelSecondary,
  labelTextual,
  onClickSecondary,
  disabledSecondary = false,
  onClickTextual,
}: IMiceButtons) => (
  <div className={ styles.wrapper_buttons }>
    <Button
      type='secondary'
      onClick={ onClickSecondary }
      disabled={ disabledSecondary }
    >
      { labelSecondary }
    </Button>
    <Button
      type='textual'
      className={ styles.second }
      onClick={ onClickTextual }
    >
      { labelTextual }
    </Button>
  </div>
);

export default MiceButtons;
