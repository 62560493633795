import { Moment } from 'moment';
import { getText } from '../../../../i18n';

import Api from '../../api';
import debounce from '../../api/debounce';

import { lpad } from '../../utils/pad';
import { Amplitude, MainAnalytic, MixPanel } from '../../utils/analytics';
import { formatDate, momentObject } from '../../utils/formatDate';

import { PATTERN } from '../../constants/dateFormats';

import { TrainStore } from './store/train';
import { TrainSearchStore } from './store/search';
import { TrainTicketsStore } from './store/tickets';
import { tripCacheStore } from '../order/stores/tripCache';
import { TrainSavedTicketStore } from './store/savedTicket';
import { TrainSavedTicketsStore } from './store/savedTicketsWithTransfer';

import { prepareCarStats } from './analytics';

import { ACTION_TYPES, TRACK_TYPES } from '../../constants/mixpanel';
import { CARTYPE } from '../../constants/train';
import { TRAVELPOLICYFILTER } from '../../constants/travelPolicy';

import { getTrainNameByNumber, modifySearchResponse } from '../../utils/train';
import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withInternalMethod } from '../utils/dectrators';

import { getSapsanType, isSapsan } from './corporateTrains/sapsan';
import { isStrij } from './corporateTrains/strij';
import { isNevskij } from './corporateTrains/nevskij';
import { isLastochka } from './corporateTrains/lastochka';

import {
  IFromTo,
  ITrainHistoryItem,
  IInternalSearchResult,
  IInternalSearchTrain,
  ISearchResult,
  ITrainAutocompleteItem,
  ITypeValue,
  IVariantWithTransfer,
  ICarWithoutSchemaRequest,
  IInternalCarDetailClientData,
  IInternalTCarTariffClientData,
  ICarriageResponse,
  IInternalCarriageResponse,
  ICarPlacesDetails,
  IInternalTrainDetailsClientData, ISavedTicket, TimeFilterType, IPlacesItem, ICarDescriptionParams,
} from '../../types/trains';
import { ICompany } from '../workspace/types';
import { ITag } from '../../types/shared';
import { TravelApprovalType } from '../travelApproval/consts';
import { IPlace } from '../../../page/TrainResult/types';

const DEBOUNCE_TIME = 200;

const COMPARTMENT = {
  ONE_COMPARTMENT: 0,
  COMPARTMENT_PART_CAR: 1,
  ONE_SECTION: '2',
};

const DEFAULT_SEARCH = {
  Id: null,
  Trains: [],
  TrainsWithTransfer: [],
};

const LABELS = {
  FILTER_TRAVEL_POLICY_NOT_APPLIED: getText('components:filterTravelPolicy.notApplied'),
};

const COMPARTMENT_VALUE = [
  { label: getText('services:trains.compartmentValue.default'), value: null },
  { label: getText('services:trains.compartmentValue.coupePart'), value: COMPARTMENT.COMPARTMENT_PART_CAR },
  { label: getText('services:trains.compartmentValue.platzPart'), value: COMPARTMENT.ONE_SECTION },
];

const getRoutesDirectionsAndDate = (
  from: IFromTo,
  to: IFromTo,
  date: Moment | null,
  dateBack: Moment | null,
  isBack: boolean,
) => {
  if (isBack) {
    return {
      fromRoute:
      to,
      toRoute:
      from,
      departureDate:
      dateBack,
    };
  }

  return {
    fromRoute: from,
    toRoute: to,
    departureDate: date,
  };
};

const getRoutesDirections = (from: IFromTo, to: IFromTo) =>
  ({ fromRoute: to, toRoute: from });

class Trains {
  xhrAutocomplete: any;
  xhr: any | null;
  searchStore: typeof TrainSearchStore;
  ticketsStore: typeof TrainTicketsStore;
  trainStore: typeof TrainStore;
  savedTicketStore: typeof TrainSavedTicketStore;
  savedTicketsStore: typeof TrainSavedTicketsStore;
  api: Api['train'];
  netStore = new NetworkStatesStore<'addToCartStatus'>();
  debounceAutocomplete: ReturnType<typeof debounce>;
  autocompleteLoadingTimer: ReturnType<typeof setTimeout>;

  constructor(api: any) {
    this.api = api.train;
    this.xhr = null;
    this.searchStore = TrainSearchStore;
    this.ticketsStore = TrainTicketsStore;
    this.trainStore = TrainStore;
    this.savedTicketStore = TrainSavedTicketStore;
    this.savedTicketsStore = TrainSavedTicketsStore;

    this.debounceAutocomplete = debounce(this.api.autocomplete, DEBOUNCE_TIME);
  }

  saveCarsWithoutSchemaStats = (data: {
    car: ICarPlacesDetails;
    companies: ICompany[];
    train: IInternalTrainDetailsClientData;
  }, add = false) => {
    const params: ICarWithoutSchemaRequest = prepareCarStats(data, add);

    this.api.saveCarsWithoutSchemaStats(params);
  };

  updateCarsDescription = () => {
    const { train } = this.trainStore;

    const promises: Promise<IInternalCarriageResponse>[] = [];
    train?.Cars.forEach(({ Tariffs }) => {
      Tariffs.forEach(({
        Number,
        Carrier,
        ClassService,
        AddSigns,
        Id,
      }) => {
        promises.push(
          this.getCarDescription({
            Number,
            Carrier,
            ClassService,
            AddSigns,
          }, train).then(carDescription => ({
            ...carDescription,
            carNumber: Number,
            id: Id,
          })),
        );
      });
    });

    Promise.all(promises).then((descriptions) => {
      const carsWithTariffs = train?.Cars.map((car) => {
        const tariffWithDescription = car.Tariffs.map((tariff) => {
          const tariffDescription = descriptions.find(descriptionItem =>
            descriptionItem.carNumber === car.Number && descriptionItem.id === tariff.Id);

          return { ...tariff, tariffDescription }; // TODO очень странная логика =( tariffDescription может быть undefined
        });

        return { ...car, Tariffs: tariffWithDescription };
      });

      this.trainStore.setTrainInfo({
        ...train as IInternalTrainDetailsClientData,
        // @ts-ignore
        Cars: [...carsWithTariffs],
      });
    });
  };

  getCarPlacesDetails = async (
    trainId: string,
    searchId: string,
    number: string,
    carId: string,
    companies: ICompany[],
    trainNumber: string,
    carService: string,
    carType: string,
    carDetails: string,
    currentLng: string,
  ) => {
    this.trainStore.setLoadingCarDetails(true);

    const {
      CarType,
      CarNumber,
      FirmName,
      PlacesPrices,
      PlacesByCompartmentPrices = [],
    } = await this.api.getCarPlacesDetails(trainId, searchId, number, carId);

    const params = {
      CarNumber: number,
      CarClass: carService,
      CarType: carType,
      TwoFloor: !!carDetails,
    };

    const {
      type,
      carImage,
      carJSON: carJSONString,
      direction,
    } = await this.api.getCarPlacesSchema(
      trainNumber,
      currentLng,
      params,
    );

    const carJSON = carJSONString ? JSON.parse(carJSONString) : null;

    const placeNumbers: string[] = [];
    PlacesPrices.forEach(({ Places }) => Places.forEach((place) => placeNumbers.push(place)));

    let car: ICarPlacesDetails = {
      Places: placeNumbers,
      Type: CarType,
      Number: CarNumber,
      FirmName,
      PlacesPrices,
      TrainNumber: trainNumber,
      trainType: type,
      carJSON,
      carImage,
      direction,
      PlacesByCompartmentPrices,
    };

    const { train } = this.trainStore;

    const numberCarId = parseInt(carId, 10);

    train?.Cars.some(({ Tariffs }) => {
      const selectedTariff = Tariffs.find(({ Id }) => Id === numberCarId);

      if (selectedTariff) {
        car = {
          ...selectedTariff,
          ...car,
        };

        return true;
      }

      return false;
    });

    const { Description } = await this.getCarDescription({
      Number: car.Number,
      Carrier: '',
      ClassService: '',
      AddSigns: '',
    }, train as IInternalTrainDetailsClientData);
    car.description = Description;

    if (!car.trainType) {
      this.saveCarsWithoutSchemaStats({
        car,
        companies,
        train: train as IInternalTrainDetailsClientData,
      },
      false,
      );
    }

    return this.trainStore.setPlaceDetails(car);
  };

  getTrainById = (trainId: string, searchId: string) => {
    this.trainStore.setLoading(true);

    return this.api.getTrainById(trainId, searchId)
      .then(({ SearchRequest, Train }) => {
        const { date } = this.searchStore;
        const { savedTickets } = this.savedTicketsStore;
        const savedTicket = this.getSavedTicket();

        const { StationFrom, StationTo } = SearchRequest;

        const updatedSearchRequest = savedTicket || savedTickets.length ? {
          ...SearchRequest,
          DepartureDate: date,
        } : SearchRequest;

        // @ts-ignore
        this.searchStore.setHistoryInstance(updatedSearchRequest, searchId);

        const updatedCars: IInternalCarDetailClientData[] = Train.Cars.map((car) => {
          const updatedTariffs: IInternalTCarTariffClientData[] = car.Tariffs.map(tariff => ({
            ...tariff,
            Number: car.Number,
          }));

          return {
            ...car,
            Tariffs: updatedTariffs,
          };
        });

        this.trainStore.setTrainInfo({
          ...Train,
          Cars: updatedCars,
          StationCodeFrom: StationFrom.Code,
          StationCodeTo: StationTo.Code,
        });
        this.trainStore.setLoading(false);
      });
  };

  getCarDescription = ({
    Number,
    Carrier,
    ClassService,
    AddSigns,
  }: ICarDescriptionParams,
  train: IInternalTrainDetailsClientData): Promise<ICarriageResponse> => {
    const trainType = getTrainNameByNumber(train) || null;

    return this.api.getCarDescription({
      CarNumber: `${Number}`,
      CompanyName: `${Carrier}`,
      ServiceClass: `${ClassService}`,
      TrainNumber: `${train.Number}`,
      TrainType: trainType,
      AddSigns,
    });
  };

  getHistory = () => this.api.getSearchHistory().then(this.setHistory);

  getCompartmentValue = () => COMPARTMENT_VALUE;

  getSapsanType = (type: string) => getSapsanType(type);

  setHistory = (list: ITrainHistoryItem[]) => this.searchStore.setHistoryList(list);

  setWarningTemplate = () => {
    this.api.getWarningTemplate().then((res) => {
      this.searchStore.setWarningTemplate(res);
    });
  };

  checkSeatsPlace = (car: ICarPlacesDetails, place: number) => {
    let result = lpad(place, 3);

    for (let i = 0; i < car.Places.length; i++) {
      if (car.Places[i].indexOf(result) !== -1) {
        result = car.Places[i];
        break;
      }
    }

    return result;
  };

  deleteTag = (tag: ITag) => this.ticketsStore.deleteFilterTag(tag);

  setNewSearch = () => {
    this.searchStore.setNewSearch();
    this.ticketsStore.setNewSearch();
    this.savedTicketStore.clearStore();
    this.savedTicketsStore.clearStore();
  };

  setImmediateSearch = (value: boolean) => {
    if (value) {
      this.ticketsStore.setNewSearch();
    }

    return this.searchStore.setImmediateSearch(value);
  };

  setFlipFromTo = () => {
    this.searchStore.setFlipFromTo();

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.CHANGEDIRECTION);
  };

  autocomplete = (
    query: string,
    storeCB: (query: string, items: ITrainAutocompleteItem[], loading: boolean) => void,
    fnLoading: () => void,
  ) => {
    fnLoading();

    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    if (this.autocompleteLoadingTimer) clearTimeout(this.autocompleteLoadingTimer);

    this.xhrAutocomplete = this.debounceAutocomplete(query.trim());

    this.autocompleteLoadingTimer = setTimeout(() => storeCB(query, [], true), 1000);

    return this.xhrAutocomplete.then((res: ITrainAutocompleteItem[]) => {
      clearTimeout(this.autocompleteLoadingTimer);
      storeCB(query, res, false);
    });
  };

  autocompleteFrom = (query: string) => this.autocomplete(
    query,
    this.searchStore.setFromSuggests,
    () => this.searchStore.setFromSuggestsLoading(true),
  );

  autocompleteTo = (query: string) => this.autocomplete(
    query,
    this.searchStore.setToSuggests,
    () => this.searchStore.setToSuggestsLoading(true),
  );

  setSchemeLoading = (value: boolean) => this.searchStore.setSchemeLoading(value);

  setSearchFromApproveRequest = async (travelApproval: TravelApprovalType) => {
    const { Destinations } = travelApproval;
    this.setSchemeLoading(true);

    try {
      const res = await this.api.autocomplete(travelApproval.Destinations[0].Name);

      const updateTravelApproval = {
        ...travelApproval,
        // @ts-ignore
        Destinations: Destinations[0]?.Name.trim() ? res[0] : { Name: '', Id: null },
      };
      this.searchStore.setSearchFromApprovalRequest(updateTravelApproval);
    } catch (e) {
      this.searchStore.setSearchFromApprovalRequest(null);
    } finally {
      this.setSchemeLoading(false);
      this.ticketsStore.setNewSearch();
      this.savedTicketStore.clearStore();
      this.savedTicketsStore.clearStore();
    }
  };

  saveAnalyticsAfterSearch = () => {
    const { subMenu } = this.ticketsStore;
    const { from, to, travellers } = this.searchStore;

    Amplitude.pushEvent(Amplitude.TYPE.TRAIN.SEARCH);

    const label = `${from.label} - ${to.label}`;
    const opts = {
      label,
      value: travellers,
    };

    if (subMenu) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.UPDATESEARCHONEWAY, opts);
    } else {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHONEWAY, opts);
    }
  };

  saveAnalyticsBeforeSearch = (
    origin: string,
    destination: string,
    startDate: string | null,
    people: number,
  ) => MixPanel.track(ACTION_TYPES.SEARCH, {
    type: TRACK_TYPES.TRAIN,
    origin,
    destination,
    startDate,
    people,
  });

  saveAnalyticsAfterSearchError = () => {
    const { from, to, travellers } = this.searchStore;

    return MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHNORESULT, {
      label: `${from.label} - ${to.label}`,
      value: travellers,
    });
  };

  getSavedArriveDate = (trains: IInternalSearchTrain[]): Moment | IInternalSearchTrain[] => {
    const savedTicket = this.getSavedTicket();
    const { savedTickets } = this.savedTicketsStore;

    if (!savedTicket && !savedTickets.length) return trains;

    return savedTickets.length ?
    // @ts-ignore
      momentObject(savedTickets[savedTickets.length - 1].train.ArrivalDate) :
      momentObject(savedTicket?.train.ArrivalDate);
  };

  filterTrains = (trains: IInternalSearchTrain[]) => trains
  // @ts-ignore
    .filter(({ DepartureDate }) => this.getSavedArriveDate(trains).isBefore(DepartureDate));

  filterTrainWithTransfer = (trainsWithTransfer: IVariantWithTransfer[][]) =>
    trainsWithTransfer.filter((items) =>
      items.every((item) =>
      // @ts-ignore
        this.getSavedArriveDate(trainsWithTransfer).isBefore(item.Trains[0].DepartureDate)));

  search = (settings: any) => {
    const {
      from,
      to,
      date,
      dateBack,
      travellers,
      // @ts-ignore
      fromHistory, // TODO нет в сторе!!!
      immediate,
      firstSearch,
    } = this.searchStore;
    const savedTicket = this.getSavedTicket();
    const { isChoosingBack } = this.savedTicketsStore;

    const isBack = !!dateBack && (!!savedTicket || isChoosingBack);

    this.setIsBackTrainChosing(isBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);
    const params = {
      // @ts-ignore
      StationFrom: fromRoute.selected.Code,
      // @ts-ignore
      StationTo: toRoute.selected.Code,
      DepartureDate: departureDate?.format(PATTERN.YEARMONTHDAY) || null,
      Travellers: Number(travellers),
      FromHistory: fromHistory,
    };

    this.saveAnalyticsBeforeSearch(
      params.StationFrom,
      params.StationTo,
      params.DepartureDate,
      travellers,
    );

    return this.api.search(params).then((res: ISearchResult) => {
      this.saveAnalyticsAfterSearch();

      if (immediate) {
        this.setImmediateSearch(false);
      }

      if (firstSearch) {
        this.setFirstSearch(false);
      }

      const searchRes: IInternalSearchResult = modifySearchResponse(res);

      const filteredTrains = isBack && searchRes.Trains
        ? this.filterTrains(searchRes.Trains)
        : searchRes.Trains;
      const filteredTrainsWithTransfer = isBack && searchRes.TrainsWithTransfer
        ? this.filterTrainWithTransfer(searchRes.TrainsWithTransfer)
        : searchRes.TrainsWithTransfer;

      return this.ticketsStore.setTicketsList({
        search: {
          ...searchRes,
          Trains: filteredTrains,
          TrainsWithTransfer: filteredTrainsWithTransfer,
        },
        settings,
      });
    }).catch(() => {
      this.saveAnalyticsAfterSearchError();

      return this.ticketsStore.setTicketsList({
        // @ts-ignore
        search: DEFAULT_SEARCH,
        settings,
      });
    });
  };

  searchTransfers = (settings: any) => {
    const {
      from,
      to,
      date,
      dateBack,
      travellers,
      // @ts-ignore
      fromHistory, // TODO нет в сторе
    } = this.searchStore;
    const { tickets } = this.ticketsStore;
    const savedTicket = this.getSavedTicket();
    const { isChoosingBack } = this.savedTicketsStore;

    const isBack = !!dateBack && (!!savedTicket || isChoosingBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);
    const params = {
      // @ts-ignore
      StationFrom: fromRoute.selected.Code,
      // @ts-ignore
      StationTo: toRoute.selected.Code,
      DepartureDate: departureDate?.format(PATTERN.YEARMONTHDAY),
      Travellers: Number(travellers),
      FromHistory: fromHistory,
    };

    return this.api.searchTransfers(params)
      .then((res) => {
        const filteredTrainsWithTransfer = isBack && res.TrainsWithTransfer
          ? this.filterTrainWithTransfer(res.TrainsWithTransfer)
          : res.TrainsWithTransfer;

        return this.ticketsStore.setTicketsList({
          search: {
            ...res,
            Trains: tickets,
            TrainsWithTransfer: filteredTrainsWithTransfer,
          },
          settings,
        });
      })
      .catch(() => this.ticketsStore.setTicketsList({
        // @ts-ignore
        search: DEFAULT_SEARCH,
        settings,
      }));
  };

  getSearchObjectByHistoryItem = (item: ITrainHistoryItem) => ({
    StationFrom: item.StationFrom.Code,
    StationTo: item.StationTo.Code,
    StationToCity: item.StationTo.City,
    StationFromCity: item.StationFrom.City,
    DepartureDate: item.DepartureDate,
    Travellers: item.Travellers,
    FromHistory: true,
  });

  searchByHistoryItem = (params: any, settings: any) => {
    const { StationFromCity, StationToCity, StationTo, StationFrom, DepartureDate, Travellers, FromHistory } = params;

    const mixPanelData = {
      type: TRACK_TYPES.TRAIN,
      origin: StationFromCity,
      destination: StationToCity,
      startDate: DepartureDate,
      people: Travellers,
    };

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHONEWAYFROMHISTORY, {
      label: `${StationFromCity} - ${StationToCity}`,
      value: Travellers,
    });

    const changedParams = {
      ...params,
      DepartureDate: formatDate(DepartureDate, PATTERN.YEARMONTHDAY),
      FromHistory: FromHistory === 'true',
      Travellers: Number(Travellers),
    };

    return this.api.search(changedParams).then((res) => {
      MixPanel.track(ACTION_TYPES.SEARCH, mixPanelData);

      const searchState = {
        StationFrom: {
          City: StationFromCity,
          Code: StationFrom,
        },
        StationTo: {
          City: StationToCity,
          Code: StationTo,
        },
        Travellers: Number(Travellers),
        DepartureDate,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          // @ts-ignore
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      });
    });
  };

  searchByParams = (params: Record<string, string>, settings: any) => {
    const { sp_code, sp_name, ep_code, ep_name, date, t_count, number } = params;

    const searchParams = {
      StationFrom: sp_code,
      StationTo: ep_code,
      DepartureDate: date,
      Travellers: Number(t_count),
    };

    return this.api.search(searchParams).then((res) => {
      const searchState: ITrainHistoryItem = {
        StationFrom: {
          City: sp_name,
          Code: sp_code,
        },
        StationTo: {
          City: ep_name,
          Code: ep_code,
        },
        Travellers: Number(t_count),
        DepartureDate: date,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
        filters: {
          number,
        },
        favorite: {
          // @ts-ignore
          number,
          date,
        },
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          // @ts-ignore
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        favorite: {
          // @ts-ignore
          number,
          date,
        },
        settings,
      });
    });
  };

  searchFromRequest = (requestItem: any, settings: any) => {
    const { SearchOptions } = requestItem;
    const { DepartureStation, ArrivalStation } = SearchOptions;
    const departureDate = formatDate(SearchOptions.DepartureDate, PATTERN.YEARMONTHDAY);

    const searchParams = {
      StationFrom: DepartureStation.Code,
      StationTo: ArrivalStation.Code,
      DepartureDate: departureDate,
      Travellers: requestItem.EmployeesNames.length,
      FromHistory: false,
    };

    return this.api.search(searchParams).then((res) => {
      const searchState = {
        StationFrom: {
          City: DepartureStation.Name,
          Code: DepartureStation.Code,
        },
        StationTo: {
          City: ArrivalStation.Name,
          Code: ArrivalStation.Code,
        },
        Travellers: requestItem.EmployeesNames.length,
        DepartureDate: departureDate,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          // @ts-ignore
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      });
    });
  };

  saveAnalyticsOnAddToCart = ({
    parentCar: { TypeShow, ClassService } }: any,
  totalPrice: number,
  ) => {
    const label = `${TypeShow} (${ClassService})`;

    const analyticsData = {
      label,
      value: totalPrice || null,
    };

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTOCART, analyticsData);
  };

  saveAnalyticsOnAddToNote = ({
    parentCar: { TypeShow, ClassService },
  }: any,
  totalPrice: number,
  ) => {
    const label = `${TypeShow} (${ClassService})`;

    const analyticsData = {
      label,
      value: totalPrice || null,
    };

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.TRAIN_SEARCH_TO_NOTE);
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTONOTE, analyticsData);
  };

  @bound
  @withInternalMethod(o => o.netStore.withLoaderFlow('addToCartStatus'))
  async addToCart(
    train: IInternalTrainDetailsClientData,
    car: any,
    places: any[],
    totalPrice: number,
    requestItemId: any,
  ) {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down, SpecialTariff, CompartmentNumber } = car;
    const placeNumber: string[] = [];
    const requests: Promise<void>[] = [];

    const TripId = tripCacheStore.tripId;

    const req = ({ place, many }: IPlacesItem) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToCart({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        RequestItemId: requestItemId,
        Many: many,
        Place: place,
        IsAlternative: car.IsAlternative,
        TripId,
        SpecialTariff,
        CompartmentNumber,
      });

      requests.push(promise);

      return promise;
    };

    const [firstPlace, ...otherPlaces] = places;

    // Разруливаем гонку на фронте, почему бы нет :chompy:
    const firstResponse = req(firstPlace);
    await firstResponse;

    // @ts-ignore
    otherPlaces.forEach((place) => req(place));

    const responses = [firstResponse, ...await Promise.all(requests)];

    this.saveAnalyticsOnAddToCart(car, totalPrice);
    Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTOCART, responses);

    return responses;
  }

  // временное решение, все это для гонки, потом уедет на бэк
  addToCartTwo = async (
    train: IInternalTrainDetailsClientData,
    car: any,
    places: string[],
    totalPrice: number,
    requestItemId: any,
  ) => {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down } = car;
    const placeNumber: string[] = [];
    const requests: Promise<void>[] = [];

    const req = ({ place, many }: any) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToCart({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        RequestItemId: requestItemId,
        Many: many,
        Place: place,
        IsAlternative: car.IsAlternative,
      });

      requests.push(promise);

      return promise;
    };
    // @ts-ignore
    places.forEach((place) => req(place));

    return Promise.all(requests)
      .then((res) => {
        this.saveAnalyticsOnAddToCart(car, totalPrice);
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTOCART, res);

        return res;
      });
  };

  // addToSmthWithTransfer = async (fn, requestItemId) => {
  //   const { savedTickets } = this.savedTicketsStore;
  //
  //   const list = savedTickets.reduce((acc, { places, car, train }) => {
  //     const { TrainId, SearchId } = train;
  //     const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down } = car;
  //
  //     const data = {
  //       CarNumber,
  //       ClassService,
  //       Bedding,
  //       OneCompartment,
  //       Up,
  //       Down,
  //       TrainId,
  //       SearchId,
  //       CarId: car.parentCar.Id,
  //       RequestItemId: requestItemId,
  //       IsAlternative: car.IsAlternative
  //     };
  //
  //     const result = places.map(({ many, place }) => ({
  //       ...data,
  //       Many: many,
  //       Place: place,
  //     }));
  //
  //     return [...acc, ...result];
  //   }, []);
  //
  //   await fn(list);
  // }
  //
  // addToCartWithTransfer = requestItemId =>
  //   this.addToSmthWithTransfer(this.api.addToCartWithTransfer, requestItemId);

  addToNote = async (
    train: any,
    car: any,
    places: IPlace[],
    totalPrice: number,
    TripId: any,
  ) => {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down, SpecialTariff, CompartmentNumber } = car;
    const placeNumber: Promise<any>[] = [];
    const requests: Promise<void>[] = [];

    const req = ({ place, many }: any) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToNote({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        Many: many,
        Place: place,
        TripId,
        IsAlternative: car.IsAlternative,
        SpecialTariff,
        CompartmentNumber,
      });

      requests.push(promise);

      return promise;
    };

    // Разруливаем гонку на фронте, почему бы нет :chompy:
    const firstReq = req(places[0]);
    await firstReq;

    [...places.slice(1)].forEach(place => req(place));

    return Promise.all(requests)
      .then((res) => {
        this.saveAnalyticsOnAddToNote(car, totalPrice);
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTONOTE, res);

        return res;
      });
  };

  addToFavorite = (ticket: any) => {
    const params = {
      ArrivalDate: ticket.ArrivalDate,
      ArrivalDateLocal: ticket.ArrivalDateLocal,
      DepartureDate: ticket.DepartureDate,
      DepartureDateLocal: ticket.DepartureDateLocal,
      ProviderName: ticket.ProviderName,
      StationFrom: ticket.StationFrom,
      StationTo: ticket.StationTo,
      CodeStationFrom: ticket.StationCodeFrom,
      CodeStationTo: ticket.StationCodeTo,
      TrainId: ticket.TrainId,
      TrainName: ticket.TrainName,
      TrainNumber: ticket.TrainNumber,
      TrainNumberLocal: ticket.TrainNumberLocal,
      TravelTime: ticket.TravelTime,
    };

    return this.api.addToFavorite(params);
  };

  getAvailability = (payload: any) => this.api.getTicketAvailability(payload);

  hasChooseUpDownSeats = (car: ICarPlacesDetails): boolean => car.Type === CARTYPE.RESERVEDSEATS
      || car.Type === CARTYPE.COMPARTMENT
      || car.Type === CARTYPE.SUITE;

  hasChooseWindowSeats = (car: ICarPlacesDetails): boolean => car.Type === CARTYPE.SEDENTARY;

  hasReservedSeats = (car: ICarPlacesDetails): boolean => car.Type === CARTYPE.RESERVEDSEATS;

  hasCompartment = (car: ICarPlacesDetails): boolean => car.Type === CARTYPE.COMPARTMENT;

  hasNotChooseSeats = (car: ICarPlacesDetails): boolean => car.Type === CARTYPE.COMMON;

  hasTwoFloors = (train: IInternalTrainDetailsClientData, car: ICarPlacesDetails) => {
    if (this.isCorporateTrain(train)) {
      return false;
    }

    return car.Places.filter((item) => parseInt(item, 10) > 80).length > 0;
  };

  isLastochka = (train: IInternalTrainDetailsClientData) => isLastochka(train);

  isSapsan = (train: IInternalTrainDetailsClientData) => isSapsan(train);

  isStrij = (train: IInternalTrainDetailsClientData) => isStrij(train);

  isNevskij = (train: IInternalTrainDetailsClientData) => isNevskij(train);

  isCorporateTrain = (train: IInternalTrainDetailsClientData) =>
    this.isLastochka(train)
    || this.isSapsan(train)
    || this.isStrij(train)
    || this.isNevskij(train);

  resetFilters = () => this.ticketsStore.resetFilters();

  mapStateToSearchObject = () => {
    const { from, to, date, dateBack, travellers } = this.searchStore;
    const savedTicket = this.getSavedTicket();

    const isBack = !!dateBack && !!savedTicket;

    this.setIsBackTrainChosing(isBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);

    return this.getSearchObject(
      {
        // @ts-ignore
        CodeStationFrom: fromRoute.selected.Code,
        // @ts-ignore
        StationFrom: fromRoute.selected.Name || fromRoute.selected.City,
        // @ts-ignore
        CodeStationTo: toRoute.selected.Code,
        // @ts-ignore
        StationTo: toRoute.selected.Name || toRoute.selected.City,
      },
      {
        date: departureDate,
        travellers,
      });
  };

  mapStateToSearchObjectFromWithTransfer = () => {
    const { from, to, date, travellers } = this.searchStore;
    const { fromRoute, toRoute } = getRoutesDirections(from, to);

    this.setFromAndTo(fromRoute, toRoute);

    return this.getSearchObject(
      {
        // @ts-ignore
        CodeStationFrom: fromRoute.selected.Code,
        // @ts-ignore
        StationFrom: fromRoute.selected.Name || fromRoute.selected.City,
        // @ts-ignore
        CodeStationTo: toRoute.selected.Code,
        // @ts-ignore
        StationTo: toRoute.selected.Name || toRoute.selected.City,
      },
      {
        date,
        travellers,
      });
  };

  getSearchObject = (item: any, { date, travellers }: any) => {
    const {
      CodeStationFrom,
      StationFrom,
      CodeStationTo,
      StationTo,
      TrainName,
      TrainNumber: number,
    } = item;

    return ({
      date: date.format(PATTERN.YEARMONTHDAY),
      sp_code: CodeStationFrom,
      sp_name: StationFrom,
      ep_code: CodeStationTo,
      ep_name: StationTo,
      name: TrainName,
      number,
      t_count: travellers,
    });
  };

  changeFavoriteStatus = (id: number, favoriteId: string) => this.ticketsStore.setFavorite(id, favoriteId);

  getSavedTicket = () => this.savedTicketStore.savedTicket;

  setSavedTicket = (value: ISavedTicket | null) => this.savedTicketStore.setSavedTicket(value);

  setIsBackTrainChosing = (value: boolean) => this.savedTicketStore.setIsChoosingBackTrain(value);

  setDate = (value: Moment | null) => this.searchStore.setDate(value);

  setDateBack = (value: Moment | null) => this.searchStore.setDateBack(value);

  setTravellers = (value: number) => this.searchStore.setTravellers(value);

  setFromSelected = (value: ITrainAutocompleteItem) => this.searchStore.setFromSelected(value);

  setToSelected = (value: ITrainAutocompleteItem) => this.searchStore.setToSelected(value);

  setFromAndTo = (from: IFromTo, to: IFromTo) => this.searchStore.setFromAndTo(from, to);

  setFirstSearch = (value: boolean) => this.searchStore.setFirstSearch(value);

  setPriceFilter = (values: number[]) => {
    const cacheFilterTags = this.ticketsStore.tags;

    this.ticketsStore.setPriceFilter(values);

    const sliderOldTag = cacheFilterTags.find(item => item.key === 'price');

    if (!sliderOldTag) {
      const sliderNewTag = this.ticketsStore.tags.find(item => item.key === 'price');

      if (sliderNewTag) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSLIDER, {
          label: sliderNewTag.name,
        });
      }
    }
  };

  setTypeFilter = (value: ITypeValue) => {
    this.ticketsStore.setTypeFilter(value);

    if (value.value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERCHECKBOX, {
        label: value.type,
      });
    }
  };

  setFavoriteFilter = (value: boolean) => this.ticketsStore.setFavoriteFilter(value);

  setTrainNumberFilter = (value: string) => this.ticketsStore.setTrainNumberFilter(value);

  setIsSapsanFilter = (value: boolean) => this.ticketsStore.setIsSapsanFilter(value);

  setTimeFilter = (type: TimeFilterType, values: any[]) => {
    const cacheFilterTags = this.ticketsStore.tags;
    this.ticketsStore.setTimeFilter(type, values);

    const sliderOldTag = cacheFilterTags.find(item => item.key === type);

    if (!sliderOldTag) {
      const sliderNewTag = this.ticketsStore.tags.find(item => item.key === type);

      if (sliderNewTag) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSLIDER, {
          label: sliderNewTag.name,
        });
      }
    }
  };

  setTravelPolicyFilter = (value: any) => {
    this.ticketsStore.setTravelPolicyFilter(value);

    // @ts-ignore
    const { travelPolicyAllList } = this.ticketsStore.travelPolicyAllList; // TODO это не работает

    if (travelPolicyAllList) {
      // @ts-ignore
      const selectedTp = travelPolicyAllList.find((item) => item.Id === value);

      if (selectedTp) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSWITCH, {
          label: selectedTp.Name,
        });
      }

      if (value === TRAVELPOLICYFILTER.NOTAPPLIED) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSWITCH, {
          label: LABELS.FILTER_TRAVEL_POLICY_NOT_APPLIED,
        });
      }
    }
  };

  setTransfersFilter = (value: any) => {
    this.ticketsStore.setTransfersFilter(value);

    if (value.value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERCHECKBOX, {
        label: value.transfers,
      });
    }
  };

  setSortBy = (value: any) => {
    this.ticketsStore.setSortBy(value);

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SORTING, {
      label: MainAnalytic.LABELS.TRAINS[value],
    });
  };

  setTrainFavorite = (value: any) => this.trainStore.setTrainFavorite(value);

  setCacheTickets = (tickets: never[]) => this.savedTicketsStore.setCacheTickets(tickets);

  setSavedTickets = (tickets: never) => this.savedTicketsStore.setSavedTickets(tickets, !!this.searchStore.dateBack);

  setSavedTicketsWithoutTransfer = (tickets: never) => this.savedTicketsStore.setSavedTicketsWithoutTransfer(tickets);

  setFromSavedTicket = () => {
    const { savedTicket } = this.savedTicketStore;
    const { TrainId } = this.trainStore.train as IInternalTrainDetailsClientData;

    const item = {
      ...savedTicket,
      TrainId,
    };

    // @ts-ignore
    return this.savedTicketsStore.setFromSavedTicket(item, !!this.searchStore.dateBack);
  };

  clearAfterGoBack = () => this.savedTicketsStore.clearAfterGoBack();

  clearSavedTicketsWithTransfer = () => this.savedTicketsStore.clearStore();

  removeFavoritesStatus = (item: IInternalSearchResult) =>
    item.Trains.forEach(({ TrainId }) => this.ticketsStore.setFavoritesWithTransfer(TrainId, ''));

  addFavorites = (item: any) => {
    const requests: any[] = [];
    // @ts-ignore
    const list = item.Trains.filter(({ FavoriteId }) => !FavoriteId);

    // @ts-ignore
    list.forEach((train) => requests.push(this.addToFavorite(train)
      .then(res => ({ TrainId: train.TrainId, FavoriteId: res }))
      .catch(() => null),
    ));

    return Promise.all(requests);
  };

  // addFavoritesWithTransfer = async (item) => {
  //   const list = item.Trains.reduce((acc, {
  //     ArrivalDate,
  //     ArrivalDateLocal,
  //     DepartureDate,
  //     DepartureDateLocal,
  //     FavoriteId,
  //     ProviderName,
  //     StationFrom,
  //     StationTo,
  //     StationCodeFrom,
  //     StationCodeTo,
  //     TrainId,
  //     TrainName,
  //     TrainNumber,
  //     TrainNumberLocal,
  //     TravelTime,
  //   }) => {
  //     if (!FavoriteId) {
  //       const params = {
  //         ArrivalDate,
  //         ArrivalDateLocal,
  //         DepartureDate,
  //         DepartureDateLocal,
  //         ProviderName,
  //         StationFrom,
  //         StationTo,
  //         CodeStationFrom: StationCodeFrom,
  //         CodeStationTo: StationCodeTo,
  //         TrainId,
  //         TrainName,
  //         TrainNumber,
  //         TrainNumberLocal,
  //         TravelTime,
  //       };
  //
  //       return [...acc, params];
  //     }
  //
  //     return acc;
  //   }, []);
  //
  //   return this.api.addFavoritesWithTransfer(list)
  //     .then(() => {
  //
  //     })
  //     .catch();
  // };

  addFavoritesStatus = (items: any[]) =>
    items.forEach(({ TrainId, FavoriteId }) => this.ticketsStore.setFavoritesWithTransfer(TrainId, FavoriteId));
}

export default Trains;
