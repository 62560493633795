import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, MultiSelect } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { FIELDS_FILTERS } from '../../../bi/constants/expenseReport';

import styles from '../styles/filters.module.css';
import { FiltersProps } from '../../../bi/services/expenseReports/types';

const LABELS = {
  EMPLOYEES: getText('expenseReports:filters.employees'),
  LAST_NAME_EMPLOYEE: getText('expenseReports:filters.lastNameOfTheEmployee'),
  COMPANIES: getText('expenseReports:filters.companies'),
  STATUSES: getText('expenseReports:filters.statuses'),
  PLACEHOLDER_COMPANIES: getText('expenseReports:filters.companiesPlaceholders'),
  PLACEHOLDER_STATUSES: getText('expenseReports:filters.statusesPlaceholders'),
  RESET_ALL_FILTERS: getText('expenseReports:filters.resetFilters'),
  MINE_CHECKBOX: getText('expenseReports:filters.mineCheckbox'),
};

const Filters: React.FC<FiltersProps> = observer(({ onChange, onResetFilters, getDraftExpenseReports }) => {
  const {
    expenseReportsStore: {
      filters: {
        employees,
        companies,
        statuses,
        mineCheckbox,
      },
      isShowMineCheckboxFilter,
      isShowCompaniesFilter,
    },
  } = useStores([MOBX_STORES.EXPENSE_REPORTS_STORE]);

  useEffect(() => {
    if (!getDraftExpenseReports()) {
      statuses.list = statuses.list.filter((status: any) => status.value !== 8);
    }
  }, []);

  const renderCompanies = () => {
    if (isShowCompaniesFilter) {
      return null;
    }

    return (
      <div className={ styles.filter }>
        <MultiSelect
          search
          placeholder={ LABELS.COMPANIES }
          searchPlaceholder={ LABELS.PLACEHOLDER_COMPANIES }
          values={ companies.selected }
          list={ companies.list }
          onChange={ values => onChange(FIELDS_FILTERS.COMPANIES, values) }
        />
      </div>
    );
  };

  const renderMineCheckbox = () => {
    if (!isShowMineCheckboxFilter) {
      return null;
    }

    return (
      <div className={ styles.filter }>
        <Checkbox
          value={ mineCheckbox.checked }
          onChange={ value => onChange(FIELDS_FILTERS.MINE, value) }
        >
          { LABELS.MINE_CHECKBOX }
        </Checkbox>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.reset }>
        <Button
          type='textual'
          onClick={ onResetFilters }
        >
          { LABELS.RESET_ALL_FILTERS }
        </Button>
      </div>
      <div className={ styles.filters }>
        <div className={ styles.filter }>
          <MultiSelect
            search
            withLabel
            placeholder={ LABELS.EMPLOYEES }
            searchPlaceholder={ LABELS.LAST_NAME_EMPLOYEE }
            list={ employees.list }
            values={ employees.selected }
            onChange={ values => onChange(FIELDS_FILTERS.EMPLOYEES, values) }
          />
        </div>
        { renderCompanies() }
        <div className={ styles.filter }>
          <MultiSelect
            search
            placeholder={ LABELS.STATUSES }
            searchPlaceholder={ LABELS.PLACEHOLDER_STATUSES }
            values={ statuses.selected }
            list={ statuses.list }
            onChange={ values => onChange(FIELDS_FILTERS.STATUS, values) }
          />
        </div>
        { renderMineCheckbox() }
      </div>
    </div>
  );
});

export { Filters };
