import React, { Component } from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Text, Tooltip, ItemPanel } from 'new-ui';

import { getText } from '../../../../../i18n';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import TrainService from '../../../../bi/services/trains';
import { TTrainStore } from '../../../../bi/services/trains/store/train';
import { TTrainSearchStore } from '../../../../bi/services/trains/store/search';
import {
  ICarModificatorClientData,
  IInternalCarDetailClientData,
  IInternalTCarTariffClientData, IInternalTrainDetailsClientData,
} from '../../../../bi/types/trains';
import AppService from '../../../../bi/services/app';

import { NameErrorsTravelPolicy } from '../../../../components/NameErrorsTravelPolicy';

import MoneyFormat from '../../../../bi/utils/money';
import { momentObject } from '../../../../bi/utils/formatDate';
import { parseSearchString } from '../../../../bi/utils/convertSearchParams';

import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../../../bi/constants/rights';
import { TRAINCARRIERS } from '../../../../bi/constants/trainCarriers';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { AccountTravelPolicy } from '../../../../bi/types/travelPolicy';
import { WorkspaceService } from '../../../../bi/types/workspace';
import { UserSessionService } from '../../../../bi/types/userSession';

import styles from './index.module.css';

const LABELS = {
  TEXT_PLACES_FOR_DISABILITIES: getText('trains:car.disabilities.placesOnlyDisabilities'),
  TEXT_BYING_PLACES_FOR_DISABILITIES: getText('trains:car.disabilities.onlyRzd'),
  EREGISTRATION_IS_NOT_AVAILABLE: getText('trains:results.eRegWarning'),
  SEATS: {
    UP: getText('trains:car.placePositions.up'),
    DOWN: getText('trains:car.placePositions.down'),
    DEFAULT: getText('trains:car.placePositions.default'),
  },
  CHOOSE_SEAT: getText('trains:car.chooseSeat'),
  RUB: getText('trains:car.rub'),
  CAR: getText('trains:car.car'),
  TRAVEL_POLICY_WARNING: getText('trains:car.travelPolicyWarning'),
  CAR_END_STATION: getText('trains:car.carEndStation'),
  CARRIER: getText('trains:car.carrier'),
  NON_REFUNDABLE: getText('trains:car.nonRefundable'),
};

interface ICarItemProps {
  item: IInternalCarDetailClientData,
  trainId: string,
  trainService: TrainService,
  userSessionService: UserSessionService,
  workspaceService: WorkspaceService,
  appService: AppService,
  history: History,
  onSelectCar: (label: string) => void,
  index: number,
  stores: {
    trainSearchStore: TTrainSearchStore,
    trainStore: TTrainStore,
  },
}

@withStores([MOBX_STORES.TRAIN_SEARCH, MOBX_STORES.TRAIN])
@observer
class CarItem extends Component<ICarItemProps> {
  static defaultProps = {
    onSelectCar: () => {},
    index: 0,
  };

  applyTravelPolicy = (
    { TravelPolicy }: IInternalTCarTariffClientData,
    unavailableTravelPolicy: boolean,
    accountTravelPolicy: AccountTravelPolicy) => {
    const hasTP = TravelPolicy && TravelPolicy.Apply;

    if (hasTP && unavailableTravelPolicy && !!accountTravelPolicy) {
      // @ts-ignore
      return !!TravelPolicy.Errors[accountTravelPolicy.Id];
    }

    return false;
  };

  renderTravelPolicyWarning = () => {
    const { item: { Tariffs }, workspaceService, userSessionService } = this.props;

    const travelPolicyList = userSessionService.get().travelPolicies;
    const accountTravelPolicy = workspaceService.travelPolicy;
    const { BuyTripAccount, BuyTripPersonal } = workspaceService.rights;

    const unavailableTravelPolicy =
      BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable
      && BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;

    const buyTripPersonal = unavailableTravelPolicy && !!accountTravelPolicy;

    const mergeTravelPoliciesErrors = (
      firstTravelPolicyErrors: Record<string, any>,
      secondTravelPolicyErrors: Record<string, any>,
    ) => Object.keys({ ...firstTravelPolicyErrors, ...secondTravelPolicyErrors })
      .reduce((acc, key) => {
        acc[key] = firstTravelPolicyErrors[key];

        if (secondTravelPolicyErrors[key]) {
          acc[key] = secondTravelPolicyErrors[key];
        }

        return acc;
      }, {} as Record<string, any>);

    const applyTP = Tariffs
      .some(tariff => this.applyTravelPolicy(tariff, unavailableTravelPolicy, accountTravelPolicy));

    let trainItem = Tariffs[0];

    if (applyTP && Tariffs.length > 1) {
      trainItem = {
        ...trainItem,
        TravelPolicy: {
          ...Tariffs[0].TravelPolicy,
          // @ts-ignore
          Errors: mergeTravelPoliciesErrors(Tariffs[0].TravelPolicy.Errors, Tariffs[1].TravelPolicy.Errors),
        },
      };
    }

    return (
      <NameErrorsTravelPolicy
        oneTravelPolicy
        applyTP={ applyTP }
        item={ trainItem }
        travelPolicyList={ travelPolicyList }
        selectedTravelPolicy={ buyTripPersonal ? accountTravelPolicy.Id : '' }
      />
    );
  };

  renderHeader = (nonRefundable: boolean[]) => {
    const { item: { Number } } = this.props;

    const carRefundable = nonRefundable.includes(true) && (
      <Text
        type='NORMAL_14'
        color='gray'
        className={ styles.refundable }
      >
        { LABELS.NON_REFUNDABLE }
      </Text>
    );

    return (
      <div className={ styles.header }>
        <div className={ styles.main }>
          <Text
            type='NORMAL_18'
          >
            {LABELS.CAR} { Number }
          </Text>
          { carRefundable }
        </div>
        { this.renderTravelPolicyWarning() }
      </div>
    );
  };

  renderCarDescription = ({ HaveInvalidPlaces, tariffDescription }: IInternalTCarTariffClientData) => {
    if (HaveInvalidPlaces) return LABELS.TEXT_PLACES_FOR_DISABILITIES;

    if (!tariffDescription) return null;

    const { Description, Services } = tariffDescription;

    return (
      <>
        <Text
          type='SEMIBOLD_14'
          color='gray'
          className={ styles.title }
        >
          { Description }
        </Text>
        {
          Services && Services.map(service => (
            <Text
              className={ styles.include }
              key={ service }
              color='gray'
              type='NORMAL_14'
            >
              { service }
            </Text>
          ))
        }
      </>
    );
  };

  renderCarrierTooltip = (carrier: string) => {
    const findedName = Object.values(TRAINCARRIERS).find(({ SHORTNAME }) => SHORTNAME === carrier);

    if (!findedName) {
      return (
        <Text
          className={ styles.carrier }
          type='NORMAL_16'
        >
          { carrier }
        </Text>
      );
    }

    const { NAME } = findedName;

    return (
      <div className={ styles.carrier }>
        <Tooltip
          show={ !!NAME }
          renderContent={ () => (
            <div className={ styles.tooltip }>
              <Text
                color='white'
                type='NORMAL_14_130'
              >
                { LABELS.CARRIER }
              </Text>
              <Text
                color='white'
                type='NORMAL_14_130'
              >
                { NAME }
              </Text>
            </div>
          ) }
        >
          <Text
            type='NORMAL_16'
          >
            { carrier }
          </Text>
        </Tooltip>
      </div>
    );
  };

  renderModificatorDescription = (modificator: ICarModificatorClientData | null) => {
    const isEmptyModificator = !modificator || !modificator.Arrival || !modificator.Arrival.ArrivalDate;

    if (isEmptyModificator) {
      return null;
    }

    const arrivalDate = momentObject(modificator.Arrival.ArrivalDate).format(PATTERN.DAY_OF_MONTH_WITH_YEAR_AND_TIME);

    return (
      <Text
        className={ styles.modificator }
        color='red'
        type='NORMAL_14'
      >
        { LABELS.CAR_END_STATION } { arrivalDate }.
      </Text>
    );
  };

  renderERegistrationWarning = () => {
    const { item: { Tariffs } } = this.props;
    const ERegistration = Tariffs.length > 0 ? Tariffs[0].ERegistration : true;

    return !ERegistration && (
      <Text
        className={ styles.warning }
        color='red'
        type='NORMAL_14'
      >
        { LABELS.EREGISTRATION_IS_NOT_AVAILABLE }
      </Text>
    );
  };

  renderSeatsItem = (label: string, count: number, isQaAttr = false) => {
    const qaAttrSeat = isQaAttr ? QA_ATTRIBUTES.train.result.item.seatDefault : '';

    return (
      <div className={ styles.seat }>
        <Text
          className={ styles.name }
          type='NORMAL_14'
          color='gray'
          qaAttr={ qaAttrSeat }
        >
          { label }
        </Text>
        <Text
          type='NORMAL_14'
          color='gray'
        >
          { count }
        </Text>
      </div>
    );
  };

  renderPriceHtml = (
    firstTotal: number,
    secondTotal: number,
    qaAttr = '',
    firstTotalAgentFee: number,
    secondTotalAgentFee: number,
  ) => {
    const { agentMode } = this.props.appService.get();
    const currencyHtml = (
      <Text
        className={ styles.currency }
      >
        { LABELS.RUB }
      </Text>
    );

    const preparedPriceSecond: number = !agentMode && secondTotal ? secondTotal + secondTotalAgentFee : secondTotal;

    const secondPriceHtml = !!secondTotal && (
      <div className={ styles.cost }>
        <Text
          type='bold_20'
        >
          &nbsp;{`– ${MoneyFormat.money(preparedPriceSecond)}`}
        </Text>
        { currencyHtml }
      </div>
    );

    const preparedPriceFirst: number = agentMode ? firstTotal : firstTotal + firstTotalAgentFee;

    return (
      <div className={ styles.cost } data-qa={ qaAttr }>
        <Text
          type='bold_20'
        >
          { MoneyFormat.money(preparedPriceFirst) }
        </Text>
        { !secondTotal && currencyHtml }
        { secondPriceHtml }
      </div>
    );
  };

  renderCarTariff = (tariff: IInternalTCarTariffClientData) => {
    const {
      item: {
        Number,
      },
      trainId,
      trainService,
      stores: { trainSearchStore: { searchId }, trainStore: { train } },
      onSelectCar,
      history,
      index,
    } = this.props;

    const {
      ClassService,
      Id,
      TypeShow,
      Seats: {
        Down,
        Up,
        Undef,
      },
      HaveInvalidPlaces,
      Tariff: {
        Total: firstTotal,
        AgentFee: firstTotalAgentFee,
      },
      Tariff2: {
        Total: secondTotal,
        AgentFee: secondTotalAgentFee,
      },
      Modificator,
      Carrier,
    } = tariff;

    const isSapsan = trainService.isSapsan(train as IInternalTrainDetailsClientData);
    const sapsanType = trainService.getSapsanType(ClassService);
    const typeAndClass = isSapsan
      ? `${sapsanType} ${ClassService || ''}`
      : `${TypeShow} ${ClassService || ''}`;

    const searchParams = parseSearchString(history.location.search);
    const tripId = searchParams?.tripId ? `?tripId=${searchParams.tripId}` : '';
    const linkUrl = `/search/train/${searchId}/${trainId}/car/${Number}/${Id}/${ClassService || ''}${tripId}`;

    const downSeatsHtml = !!parseInt(String(Down), 10) && this.renderSeatsItem(LABELS.SEATS.DOWN, Down as number);
    const upSeatsHtml = !!parseInt(String(Up), 10) && this.renderSeatsItem(LABELS.SEATS.UP, Up);
    const undefSeatsHtml = !!parseInt(String(Undef), 10) && this.renderSeatsItem(LABELS.SEATS.DEFAULT, Undef as number, true);

    const qaAttrPrice = index === 0 ? QA_ATTRIBUTES.train.result.item.price : '';
    const qaAttrChooseSeat = index === 0 ? QA_ATTRIBUTES.train.result.item.choosePlace : '';

    const actionButtonHtml = (
      <div className={ styles.action }>
        <Tooltip
          show={ !!HaveInvalidPlaces }
          renderContent={ () => (
            <Text
              type='NORMAL_14_130'
              color='white'
            >
              { LABELS.TEXT_BYING_PLACES_FOR_DISABILITIES }
            </Text>
          ) }
        >
          <Link
            to={ linkUrl }
            onClick={ () => onSelectCar(typeAndClass) }
          >
            <Button
              type='primary'
              qaAttr={ qaAttrChooseSeat }
            >
              { LABELS.CHOOSE_SEAT }
            </Button>
          </Link>
        </Tooltip>
      </div>
    );

    return (
      <div className={ styles.info } key={ Id }>
        { this.renderCarrierTooltip(Carrier) }
        <div className={ styles.description }>
          <div className={ styles.details }>
            <div className={ styles.car }>
              <Text type='NORMAL_16'>
                { typeAndClass }
              </Text>
              <div className={ styles.includes }>
                { this.renderCarDescription(tariff) }
              </div>
              { this.renderModificatorDescription(Modificator) }
            </div>
          </div>
          <div className={ styles.seats }>
            { downSeatsHtml }
            { upSeatsHtml }
            { undefSeatsHtml }
          </div>
        </div>
        <div className={ styles.tariff }>
          { this.renderPriceHtml(firstTotal, secondTotal, qaAttrPrice, firstTotalAgentFee, secondTotalAgentFee) }
          { actionButtonHtml }
        </div>
      </div>
    );
  };

  render() {
    const { item: { Tariffs } } = this.props;

    const carTariffsHtml = Tariffs.map(tariff => this.renderCarTariff(tariff));
    const nonRefundable = Tariffs.map(tariff => tariff.NonRefundable);

    return (
      <ItemPanel
        className={ styles.item }
        renderHeader={ () => this.renderHeader(nonRefundable) }
      >
        <div className={ styles.body }>
          { carTariffsHtml }
          { this.renderERegistrationWarning() }
        </div>
      </ItemPanel>
    );
  }
}

export { CarItem };
