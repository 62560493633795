import { checkRuPassport, checkUaWithDomesticPassport } from '../../../utils/documents';

import COUNTRIES from '../../../constants/countries';
import PASSPORTS from '../../../constants/passport';
import DOCUMENTSFIELDS from '../../../constants/documentsFields';

import { IDocumentUser, IPassport, IPassportView, TInvalid } from '../types';

const DOCUMENT_FIELDS_TO_VALIDATE = {
  DomesticPassport: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.LASTNAME,
    DOCUMENTSFIELDS.FIRSTNAME,
    DOCUMENTSFIELDS.PATRONYMIC,
  ],
  BirthCertificate: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.PATRONYMIC,
  ],
  ForeignPassport: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.DUEDATE,
    DOCUMENTSFIELDS.PATRONYMIC,
  ],
  ForeignPassportUA: [
    DOCUMENTSFIELDS.NUMBER,
    DOCUMENTSFIELDS.SURNAME,
    DOCUMENTSFIELDS.NAME,
    DOCUMENTSFIELDS.PATRONYMIC,
  ],
  TravelPolicy: [
    DOCUMENTSFIELDS.TRAVEL_POLICY,
  ],
};

const validateIsNotEmpty = (field: string, doc: IPassport) => (
  field === DOCUMENTSFIELDS.DUEDATE ? !!doc[field] : !!doc[field] && !!doc[field].trim().length
);

const mapDocumentToRequiredFields = (
  { Type, NoPatronymic }: { Type: string, NoPatronymic: boolean },
  code: string,
  isChangeAirTripPage?: boolean,
) => {
  let requiredFields;

  if (checkUaWithDomesticPassport(code, Type)) {
    requiredFields = DOCUMENT_FIELDS_TO_VALIDATE.ForeignPassportUA;
  } else if (code !== COUNTRIES.RU.SHORTNAME || Type === PASSPORTS.FOREIGN_PASSPORT.TYPE) {
    requiredFields = DOCUMENT_FIELDS_TO_VALIDATE.ForeignPassport;
  } else if (Type === PASSPORTS.BIRTH_CERTIFICATE.TYPE) {
    requiredFields = DOCUMENT_FIELDS_TO_VALIDATE.BirthCertificate;
  } else {
    requiredFields = DOCUMENT_FIELDS_TO_VALIDATE.DomesticPassport;
  }

  // УБРАТЬ условие NoPatronymic === undefined, когда появится бэк в этой задаче
  if (NoPatronymic || isChangeAirTripPage) {
    requiredFields = requiredFields.filter(field => field !== DOCUMENTSFIELDS.PATRONYMIC);
  }

  return requiredFields;
};

const validateDocumentFields = (
  document?: IDocumentUser | IPassport,
  code?: string,
  isChangeAirTripPage?: boolean,
) => {
  if (!document || !code) return false;

  const documentFields = mapDocumentToRequiredFields((document as IDocumentUser), code, isChangeAirTripPage);

  const isNotEmpty = documentFields.some(field => validateIsNotEmpty(field, (document as IPassport)));

  return isNotEmpty ? documentFields.every(field => validateIsNotEmpty(field, (document as IPassport))) : true;
};

const getInvalidDocumentsTypes = (
  code: string,
  documents: IDocumentUser[],
  documentsTypes: IPassportView | null,
  mayHavePassport?: boolean,
  isChangeAirTripPage?: boolean,
) => {
  if (!documentsTypes) return [];

  const { ForeignPassport, BirthCertificate, DomesticPassport } = documentsTypes;

  return documents.reduce((acc: TInvalid[], document: IDocumentUser) => {
    const { Type } = document;

    const isRuPassport = checkRuPassport(code, Type);

    if (mayHavePassport && Type === BirthCertificate) {
      return acc;
    }

    if (!mayHavePassport && Type === DomesticPassport) {
      return acc;
    }

    if (
      isRuPassport
      || Type === ForeignPassport
      || code !== COUNTRIES.RU.SHORTNAME
      || Type === BirthCertificate
    ) {
      const isValid = validateDocumentFields(document, code, isChangeAirTripPage);
      const requiredFields = mapDocumentToRequiredFields(document, code, isChangeAirTripPage);
      const emptyFields = requiredFields.filter(field => !validateIsNotEmpty(field, (document as unknown as IPassport)));

      return isValid ? acc : [
        ...acc,
        {
          type: Type,
          errors: {
            someFieldsEmpty: true,
            emptyFields,
          },
        },
      ];
    }

    return acc;
  }, []);
};

export { getInvalidDocumentsTypes as default, validateDocumentFields };
