import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Text,
  Select,
  Input,
  Button,
  List,
  Paginate,
  PageLoader,
  SidePanel,
  NoResults,
} from 'new-ui';
import { getText } from '../../../../../i18n';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import scrollToTop from '../../../utils/scrollToTop';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { Employee } from './components/Employee';
import { Alphabet } from '../../../../components/Alphabet';
import { AllFilters } from '../../../../components/Filters';
import { TagsFilters } from '../../../../components/Filters/components/tags';
import { ButtonFilters } from '../../../../components/Filters/components/buttonFiltes';

import ROUTES from '../../../../bi/constants/routes';
import STATUS, { FILTER_KEYS } from '../../../../bi/constants/employee';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IEmployee } from '../../../../bi/services/settings/types';
import { IEmployesSettingsPage, IEmployesSettingsPageState } from './types';

import styles from './index.module.css';

const ERRORS_MESSAGES = {
  ALL_EMPTY: getText('settings:employees.allEmpty'),
  ALL_EMPTY_FILTERS: getText('settings:employees.allEmptyFilters'),
  ALL_USER_EMPTY: getText('settings:employees.allUserEmpty'),
  ARCHIVE_EMPTY: getText('settings:employees.archiveEmpty'),
  ARCHIVE_USER_EMPTY: getText('settings:employees.archiveUserEmpty'),
  EMPLOYEE_EMPTY: getText('settings:employees.employeeEmpty'),
  USER_EMPTY: getText('settings:employees.userEmpty'),
  ACTIVE_EMPTY: getText('settings:employees.activeEmpty'),
  ACTIVE_USER_EMPTY: getText('settings:employees.activeUserEmpty'),
  EMPLOYEES_EMPTY: getText('settings:employees.employeesEmpty'),
  USERS_EMPTY: getText('settings:employees.usersEmpty'),
};

const ALPHABET_SHOW_CONDITION = 7;

const PATH_EMPLOYEE: string = 'employee';

const NO_DEPARTMENT = {
  label: getText('settings:employees.noDepartment'),
  value: 'NoDepartment',
};

const ARCHIVED = {
  label: getText('settings:employees.archived'),
  value: STATUS.ARCHIVE,
};

const LABELS = {
  SEARCH_INPUT: getText('settings:employees.searchInput'),
  SEARCH_INPUT_SMARTAGENT: getText('settings:employees.searchInputSA'),
  ADD_EMPLOYEE: getText('settings:employees.addEmployee'),
  ADD_USER: getText('settings:employees.addUsers'),
  EMPTY: getText('settings:employees.empty'),
  EMPTY_USERS: getText('settings:employees.emptyUsers'),
  COUNT_OF_EMPLOYEE: (count: number) => getText('settings:employees.countOfEmployees', { count }),
  COUNT_OF_USERS: (count: number) => getText('settings:employees.countOfUsers', { count }),
  ALL_ORGANIZATIONS: getText('settings:employees.allOrganizations'),
  NOT_FOUND: getText('settings:employees.notFound'),
  ALL_USER: getText('settings:employees.allUsers'),
  ALL_EMPLOYEE: getText('settings:employees.allEmployees'),
  DOWNLOAD: getText('settings:employees.download'),
};

// @ts-ignore
@withStores([MOBX_STORES.SETTINGS_STORE, MOBX_STORES.FILTERS_STORE])
@observer
class Employees extends Component<IEmployesSettingsPage, IEmployesSettingsPageState> {
  usersOrEmployeeText = isSmartAgent ? LABELS.ALL_USER : LABELS.ALL_EMPLOYEE;

  ACTIVE_STATUSES = [
    {
      label: this.usersOrEmployeeText,
      value: STATUS.ACCESS.ALL,
    },
    {
      label: getText('settings:employees.withAccess'),
      value: STATUS.ACCESS.USER,
    },
    {
      label: getText('settings:employees.withoutAccess'),
      value: STATUS.ACCESS.ACTIVE,
    },
  ];

  constructor(props: IEmployesSettingsPage) {
    super(props);

    const companiesItems = [{
      value: 0,
      label: LABELS.ALL_ORGANIZATIONS,
    }].concat(props.workspaceService.get().Companies.map(({ CompanyId, ShortCompanyName, CompanyName }) => ({
      value: CompanyId,
      label: ShortCompanyName || CompanyName,
    })));
    this.state = {
      companies: this.props.workspaceService.get().Companies,
      documentType: this.props.userSessionService.get().enums.documents,
      companiesItems,
      initComponent: true,
      isShowFilters: false,
    };
  }

  componentDidMount() {
    const {
      tripTagsService,
      settingsProtocol,
      departmentsService,
      settingsService,
      stores: {
        filtersStore: {
          filterValues,
        },
      },
    } = this.props;

    settingsService.setEmployeeFlag(true);
    settingsProtocol.getEmployeesPaginationListNew(!isSmartAgent, filterValues);
    tripTagsService.loadAccountTripTags();
    departmentsService.loadList();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EMPLOYEE_OPEN);

    this.setState({ ...this.state, initComponent: false });
  }

  componentWillUnmount() {
    const {
      settingsService: {
        resetTags,
      },
    } = this.props;

    const pathArray: string[] = window.location.pathname.split('/');

    if (pathArray[1] !== PATH_EMPLOYEE) {
      resetTags();
    }
  }

  update = () => this.setState({ documentType: this.props.userSessionService.get().enums.documents });

  prepareFilterItems = () => {
    const { departmentsService: { store: { list: { value } } } } = this.props;
    const items = [...this.ACTIVE_STATUSES, ARCHIVED];

    if (!isSmartAgent && value.length) {
      items.push(NO_DEPARTMENT);
    }

    return items;
  };

  handleFilterEmployees = (field: string, value: string | number) => {
    this.props.settingsProtocol.getFilterEmployees(field, value);

    if (field === FILTER_KEYS.COMPANY_ID && value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EMPLOYEES_ORG_SELECT);
    }

    if (field === FILTER_KEYS.SELECT && value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EMPLOYEES_STATUS_SELECT);
    }
  };

  handleFocusSearchEmployee = () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EMPLOYEES_SEARCH_PRESSED);

  redirectToEmployee = (id: number | null) => this.props.history.push({ pathname: id ? `${ROUTES.EMPLOYEE}${id}` : ROUTES.EMPLOYEE });

  getEmptyFilter = (statusSearch: string, status: string) => {
    const {
      stores: {
        settingsStore: {
          employees,
          employeesIsFiltered,
        },
        filtersStore: {
          isFiltered,
          filterValues: {
            SearchString,
          },
        },
      },
    } = this.props;

    const haveSearchAndEmployees = statusSearch && status === STATUS.ACCESS.ALL;

    if (isSmartAgent) {
      if (haveSearchAndEmployees) {
        return ERRORS_MESSAGES.USERS_EMPTY;
      }

      switch (status) {
        case STATUS.ACCESS.ALL: {
          return ERRORS_MESSAGES.ALL_USER_EMPTY;
        }
        case STATUS.ARCHIVE: {
          return ERRORS_MESSAGES.ARCHIVE_USER_EMPTY;
        }
        case STATUS.ACCESS.USER: {
          return ERRORS_MESSAGES.USER_EMPTY;
        }
        case STATUS.ACCESS.ACTIVE: {
          return ERRORS_MESSAGES.ACTIVE_USER_EMPTY;
        }
      }
    }

    if (haveSearchAndEmployees) {
      return ERRORS_MESSAGES.EMPLOYEES_EMPTY;
    }

    switch (status) {
      case STATUS.ACCESS.ALL: {
        const message = employees.length || isFiltered || SearchString || employeesIsFiltered
          ? ERRORS_MESSAGES.ALL_EMPTY_FILTERS
          : ERRORS_MESSAGES.ALL_EMPTY;

        return message;
      }
      case STATUS.ARCHIVE: {
        return ERRORS_MESSAGES.ARCHIVE_EMPTY;
      }
      case STATUS.ACCESS.USER: {
        return ERRORS_MESSAGES.EMPLOYEE_EMPTY;
      }
      case STATUS.ACCESS.ACTIVE: {
        return ERRORS_MESSAGES.ACTIVE_EMPTY;
      }
      default: {
        return '';
      }
    }
  };

  handleEditEmployee = ({ Id }: { Id: number }) => {
    this.redirectToEmployee(Id);
  };

  handleAddEmployee = () => {
    this.props.employeeService.setEmployee(null, this.state.documentType, this.props.featureFlagsTP);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EMPLOYEES_ADD_BUTTON_PRESSED);
    this.redirectToEmployee(null);
  };

  handlePageChange = (page: number) => {
    if (!isSmartAgent) {
      this.props.settingsService.editPage(page);
    } else {
      this.props.settingsProtocol.changePageEmployees(page);
    }

    scrollToTop();
  };

  showFilters = (value: boolean) => {
    const {
      settingsService: {
        getFiltersPayload,
        setErrorResponse,
      },
    } = this.props;

    if (value) {
      setErrorResponse(false);
      getFiltersPayload();
    }

    this.setState({ isShowFilters: value });
  };

  renderSearchInput = () => {
    const {
      stores: {
        filtersStore: {
          filterValues: {
            SearchString,
          },
        },
        settingsStore: {
          filter: {
            search,
          },
        },
      },
      settingsService: {
        editSearchString,
      },
    } = this.props;

    const onChangeInput = (value: string) => {
      if (!isSmartAgent) {
        return editSearchString(value);
      }

      return this.handleFilterEmployees(FILTER_KEYS.SEARCH, value);
    };

    const valueString = !isSmartAgent ? SearchString || '' : search;

    const placeholder = isSmartAgent ? LABELS.SEARCH_INPUT_SMARTAGENT : LABELS.SEARCH_INPUT;

    return (
      <Input
        qaAttr={ QA_ATTRIBUTES.settings.search }
        theme='light-shadow'
        className={ styles.search }
        isCleansing
        useTimeout={ false }
        value={ valueString }
        placeholder={ placeholder }
        onChange={ value => onChangeInput(value) }
        onFocus={ this.handleFocusSearchEmployee }
      />
    );
  };

  renderCompaniesSelect = () => {
    const { stores: { settingsStore: { filter: { companyId } } } } = this.props;
    const { companiesItems } = this.state;

    return (
      <div className={ styles.companies }>
        <Select
          qaAttr={ QA_ATTRIBUTES.settings.companies }
          items={ companiesItems }
          value={ companyId }
          excludeValue
          theme='default-large'
          onChange={ value => this.handleFilterEmployees(FILTER_KEYS.COMPANY_ID, value) }
        />
      </div>
    );
  };

  renderFilterSelect = () => {
    const { stores: { settingsStore: { filter: { select } } } } = this.props;

    return (
      <Select
        qaAttr={ QA_ATTRIBUTES.settings.employees }
        value={ select }
        className={ styles.filter }
        items={ this.prepareFilterItems() }
        excludeValue
        theme='default-large'
        onChange={ value => this.handleFilterEmployees(FILTER_KEYS.SELECT, String(value)) }
      />
    );
  };

  renderEmployeesContent = (employees: IEmployee[]) => {
    const { tripTagsService: { store: { tripTags } } } = this.props;
    const { companies } = this.state;
    const hasTripTags = !!tripTags.length;

    return (
      <List
        renderItem={ employee => (
          <Employee
            employee={ employee }
            companies={ companies }
            anchorPrefix='letter'
            hasTripTags={ hasTripTags }
          />
        ) }
        items={ employees }
        onClickItem={ this.handleEditEmployee }
        qaAttrFirstEl={ QA_ATTRIBUTES.employee.first }
      />
    );
  };

  renderEmptyFilteredEmployees = () => {
    const {
      stores: {
        settingsStore: {
          filter: {
            select,
            search,
          },
        },
        filtersStore: {
          waitingResponseEmployee,
          filterValues: {
            SearchString,
          },
        },
      },
    } = this.props;

    const selectSearch = !isSmartAgent ? SearchString : search;

    if (waitingResponseEmployee && !isSmartAgent) {
      return (
        <div
          className={ styles.wrapper_circle }
        >
          <PageLoader/>
        </div>
      );
    }

    return (
      <Text>
        { this.getEmptyFilter(selectSearch as string, select) }
      </Text>
    );
  };

  renderFiltersButton = (counter: number) => {
    const {
      workspaceService: {
        canEditEmployee,
      },
    } = this.props;
    const { isShowFilters } = this.state;

    if (isSmartAgent || !canEditEmployee) {
      return null;
    }

    const handleShowFilters = (value: boolean) => {
      this.showFilters(value);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.FILTERS_OPENED);
    };

    return (
      <ButtonFilters
        counter={ counter }
        onClick={ () => handleShowFilters(!isShowFilters) }
      />
    );
  };

  renderTagsFilters = () => {
    const {
      stores: {
        filtersStore: {
          filterSelector,
          filterCounter,
          filterTagsSearch: {
            Sex,
            Document,
            Company: TagsCompany,
            Departments,
            Status,
            Tags,
            BonusCards,
            Projects,
            TravelPolicies,
            Codes,
          },
        },
      },
      settingsService: {
        deleteTag,
        resetTags,
      },
    } = this.props;

    if (isSmartAgent) {
      return null;
    }

    return (
      <TagsFilters
        deleteTag={ deleteTag }
        sex={ Sex }
        document={ Document }
        company={ TagsCompany }
        status={ Status }
        tags={ Tags }
        bonusCards={ BonusCards }
        projects={ Projects }
        departments={ Departments }
        travelPolicies={ TravelPolicies }
        codes={ Codes }
        filterSelector={ filterSelector }
        counter={ filterCounter }
        resetTags={ resetTags }
      />
    );
  };

  renderAllFilters = () => {
    const {
      stores: {
        filtersStore,
        filtersStore: {
          valueSelected: {
            Sex: selectedSex,
            Document: selectedDocument,
            BonusCards: selectedBonusCards,
            Status: selectedStatus,
            Company: selectedCompany,
            Tags: selectedTags,
            Projects: selectedProjects,
            Departments: selectedDepartments,
            TravelPolicies: selectedTravelPolicies,
            Codes: selectedCodes,
          },
        },
      },
      settingsService: {
        setFilters,
        resetSelectedState,
        calculateFilterCounter,
        applyTags,
      },
    } = this.props;

    return (
      <AllFilters
        filtersStore={ filtersStore }
        selectedSex={ selectedSex }
        selectedDocument={ selectedDocument }
        selectedBonusCards={ selectedBonusCards }
        selectedStatus={ selectedStatus }
        selectedCompany={ selectedCompany }
        selectedTags={ selectedTags }
        selectedProjects={ selectedProjects }
        selectedDepartments={ selectedDepartments }
        selectedTravelPolicies={ selectedTravelPolicies }
        selectedCodes={ selectedCodes }
        setFilters={ setFilters }
        resetSelectedState={ resetSelectedState }
        calculateFilterCounter={ calculateFilterCounter }
        showFilters={ this.showFilters }
        applyTags={ applyTags }
      />
    );
  };

  renderFilters = () => {
    const { isShowFilters } = this.state;
    const {
      stores: {
        filtersStore: {
          errorResponse,
          loadingResponse,
        },
      },
    } = this.props;

    if (isSmartAgent) {
      return null;
    }

    const renderErrorLoading = errorResponse
      ? <NoResults label={ LABELS.NOT_FOUND } />
      : <PageLoader/>;

    const renderFilters = loadingResponse ? renderErrorLoading : this.renderAllFilters();

    return (
      <SidePanel
        show={ isShowFilters }
        onClose={ () => this.showFilters(!isShowFilters) }
      >
        { renderFilters }
      </SidePanel>
    );
  };

  renderInputSingleCompany = (isMulti: boolean) => {
    if (!isSmartAgent || isMulti) {
      return null;
    }

    return (
      <>
        { this.renderFilterSelect() }
        { this.renderAddEmployeeButton() }
      </>
    );
  };

  renderInputMultyCompany = (isMulti: boolean) => {
    if (!isSmartAgent || !isMulti) {
      return null;
    }

    return (
      <div className={ `${styles.row} ${styles['secondary-row']}` }>
        { this.renderCompaniesSelect() }
        { this.renderFilterSelect() }
        { this.renderAddEmployeeButton() }
      </div>
    );
  };

  renderHeaderEmployees = () => {
    const { stores: { filtersStore: { filterCounter } } } = this.props;
    const { companies } = this.state;

    const isMultiCompany = companies.length > 1;

    const renderAddEmployeeButtonNewFilters = () => !isSmartAgent && this.renderAddEmployeeButton();

    return (
      <>
        { this.renderFilters() }
        <div className={ styles.row }>
          { this.renderFiltersButton(filterCounter) }
          { this.renderSearchInput() }
          { this.renderInputSingleCompany(isMultiCompany) }
          { renderAddEmployeeButtonNewFilters() }
        </div>
        { this.renderInputMultyCompany(isMultiCompany) }
      </>
    );
  };

  renderEmployees = () => {
    const {
      stores: {
        settingsStore: {
          paginate: {
            currentPage,
            itemsPerPage,
            total,
          },
          employees,
          employeesCount,
        },
        filtersStore: {
          filterValues: {
            Page,
            Step,
          },
        },
      },
    } = this.props;

    const pagesCount = Math.ceil(total / itemsPerPage);

    const activeLetters = employees.map((employee: IEmployee) => employee.Surname[0]);
    const showAlphabet = activeLetters.length ? activeLetters.some((item) => item !== activeLetters[0]) : false;

    const pagePaginate = !isSmartAgent ? Page : currentPage;
    const stepPaginate = !isSmartAgent ? Step : itemsPerPage;

    const countOf = isSmartAgent ? LABELS.COUNT_OF_USERS(employeesCount) : LABELS.COUNT_OF_EMPLOYEE(employeesCount);

    const employeesCountHtml = !!employeesCount && (
      <Text
        type='bold_20'
        className={ styles.count }
      >
        { countOf }
      </Text>
    );

    const employeesHtml = employeesCount
      ? this.renderEmployeesContent(employees)
      : this.renderEmptyFilteredEmployees();

    return (
      <>
        { this.renderHeaderEmployees() }
        <div className={ styles.header_employees }>
          { employeesCountHtml }
        </div>
        <div
          className={ styles.tags }
        >
          { this.renderTagsFilters() }
        </div>
        <div className={ styles.employees }>
          { employees && employees.length > ALPHABET_SHOW_CONDITION && showAlphabet && (
            <Alphabet
              className={ styles.alphabet }
              anchorPrefix='#letter'
              activeLetters={ activeLetters }
              pagesCount={ pagesCount }
              currentPage={ Number(pagePaginate) }
              onChangePage={ this.handlePageChange }
            />
          ) }
          { employeesHtml }
        </div>
        <div className={ styles.paginate }>
          <Paginate
            page={ Number(pagePaginate) }
            itemsPerPage={ Number(stepPaginate) }
            total={ total }
            onChange={ this.handlePageChange }
          />
        </div>
      </>
    );
  };

  renderEmptyEmployees = () => {
    const {
      stores: {
        settingsStore: {
          employeesCount,
        },
        filtersStore: {
          waitingResponseEmployee,
        },
      },
    } = this.props;

    if (waitingResponseEmployee && !employeesCount) {
      return (
        <div>
          { this.renderHeaderEmployees() }
          <div
            className={ styles.loader }
          >
            <PageLoader/>
          </div>
        </div>
      );
    }

    const label = isSmartAgent ? LABELS.EMPTY_USERS : LABELS.EMPTY;

    return (
      <div className={ styles.empty }>
        <div className={ styles.message }>
          <Text className={ styles.text } type='NORMAL_16_130'>
            { label}
          </Text>
          { this.renderAddEmployeeButton() }
        </div>
      </div>
    );
  };

  renderAddEmployeeButton = () => {
    const { workspaceService: { canEditEmployee } } = this.props;

    if (!canEditEmployee) {
      return null;
    }

    const labelButton = isSmartAgent ? LABELS.ADD_USER : LABELS.ADD_EMPLOYEE;

    return (
      <Button
        className={ styles.button }
        onClick={ this.handleAddEmployee }
        qaAttr={ QA_ATTRIBUTES.settings.addEmployee }
      >
        { labelButton }
      </Button>
    );
  };

  renderLoading = () => <PageLoader />;

  render() {
    const {
      stores: {
        settingsStore: {
          employees,
          employeesIsFiltered,
        },
        filtersStore: {
          isFiltered,
          filterValues: {
            SearchString,
          },
        },
      },
    } = this.props;

    const isRenderEmployee = !!employees.length || isFiltered || !!SearchString || employeesIsFiltered;

    if (this.state.initComponent) return this.renderLoading();

    return isRenderEmployee ? this.renderEmployees() : this.renderEmptyEmployees();
  }
}

export { Employees };
