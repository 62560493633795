import React from 'react';
import { Text, Tooltip, Price as PriceComponent } from 'new-ui';
import { getText } from '../../../../../i18n';

import SYMBOLS from '../../../../bi/constants/symbols';

import { TrainRouteDetails } from '../TrainRouteDetails';

import { ClassDetails, RouteDetails, TrainItemRoutesProps } from '../types';

import styles from './index.module.css';

const TITLE = getText('trains:results.routeDetails.changeDuration');

const minPrice = (list: ClassDetails[]) => list.reduce((acc, { Price }) =>
  Math.min(acc, Price), list[0].Price);

const TrainItemRoutes = ({
  routes,
  currentRoute,
  currentRoute: { Id: currentRouteId, Classes: currentClasses },
  onClick,
}: TrainItemRoutesProps) => {
  const routeHtml = (route: RouteDetails) => {
    const { Id, Classes, Trains } = route;
    const isCurrentRoute = currentRouteId === Id;
    const color = isCurrentRoute ? 'default' : 'accent';

    const minCurrentPrice = minPrice(currentClasses);
    const min = minPrice(Classes);
    const price = isCurrentRoute ? minCurrentPrice : min - minCurrentPrice;

    const symbolHtml = !isCurrentRoute && min - minCurrentPrice > 0 && (
      <Text
        type='NORMAL_14'
        color={ color }
      >
        { SYMBOLS.PLUS.value }&thinsp;
      </Text>
    );

    const names = Trains.reduce<string[]>((acc, { StationFrom }, index) => {
      if (index !== 0) {
        return [...acc, StationFrom];
      }

      return acc;
    }, []).join(', ');

    const wrapperStyles = [styles.route];

    if (isCurrentRoute) {
      wrapperStyles.push(styles.current);
    }

    return (
      <Tooltip
        show={ !isCurrentRoute }
        key={ `train_item_route_${Id}` }
        position='bottom'
        className={ wrapperStyles.join(' ') }
        renderContent={ () => (
          <TrainRouteDetails
            route={ route }
            currentRoute={ currentRoute }
          />
        ) }
      >
        <div className={ styles.item } onClick={ () => onClick(route) }>
          <Text
            type='NORMAL_14'
            className={ styles.name }
          >
            { names.toLocaleLowerCase() }
          </Text>
          <div className={ styles.price }>
            { symbolHtml }
            <PriceComponent
              marginSmall
              type='NORMAL_14'
              typeCurrency='NORMAL_14'
              color={ color }
              value={ price }
            />
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <Text
        type='SEMIBOLD_14'
      >
        { TITLE }
      </Text>
      <div className={ styles.routes }>
        { routes.map(routeHtml) }
      </div>
    </div>
  );
};

export { TrainItemRoutes };
