import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import { History } from 'history';
import { Text, LinkButton } from 'new-ui';

import { getText } from '../../../../../i18n';

import Airline from '../../../../bi/services/airline';

import { ChangeAirTripSearchMenu } from '../../../../components/ChangeAirTripSearchMenu';

import { formatDate } from '../../../../bi/utils/formatDate';
import { conditionFlightClass } from '../../../../bi/utils/changeAirTrip';

import ROUTES from '../../../../bi/constants/routes';
import { DATEFORMATS } from '../../../../bi/constants/dateFormats';
import { PATH } from '../../../../bi/constants/changeAirTrip';
import { FIELDS_SEARCH_MENU } from '../../../../bi/constants/airline';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import {
  AirlineJsonData,
  AirRouteInfo,
  AirSearchRoute,
} from '../../../../bi/types/airline';
import { RouteSearchInterface } from '../../../../bi/utils/changeAirTrip/types';
import { WorkspaceService } from '../../../../bi/types/workspace';
import { UserSessionService } from '../../../../bi/types/userSession';

import styles from './styles/index.module.css';

interface IChangeAirTripHeaderResultProps {
  airlineService: Airline,
  workspaceService: WorkspaceService,
  userSessionService: UserSessionService,
  routesInfo?: AirRouteInfo[];
  routesSearch: AirSearchRoute[] | RouteSearchInterface[];
  history: History;
  tripId: string;
  ticketId: string;
  path: string;
  ticketData?: AirlineJsonData | null;
  setLoadingSearch: Dispatch<SetStateAction<boolean>>;
}

const LABELS = {
  DEPARTURE_DATE: getText('components:changeAirTrip.headerResult.departureDate'),
  CHANGE: getText('components:changeAirTrip.headerResult.change'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        header: {
          infoTicket,
          buttonChangeTicket,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

const DELAY = 250;

const ChangeAirTripHeaderResult = ({
  airlineService,
  workspaceService,
  userSessionService,
  routesInfo = [],
  routesSearch,
  history,
  tripId,
  ticketId,
  path,
  ticketData = null,
  setLoadingSearch,
}: IChangeAirTripHeaderResultProps) => {
  const [visibleSearchMenu, setVisibleSearchMenu] = useState(false);

  const wrapperStylesRef = useRef<HTMLDivElement>(null);
  const useRoutesInfoRef = useRef(!!routesInfo?.length);

  const handleChange = () => {
    if (wrapperStylesRef.current) {
      wrapperStylesRef.current.className += ` ${styles.wrapper_animation}`;
    }

    setTimeout(() => setVisibleSearchMenu(true), DELAY);
  };

  const handleAddFlightClass = () => {
    const resultFlightClass = conditionFlightClass(ticketData?.Class as string);

    airlineService.setSearchValue(FIELDS_SEARCH_MENU.FLIGHT_CLASS, resultFlightClass);
  };

  const handleMoveToResult = (searchId: string) => {
    setLoadingSearch(false);

    if (path === PATH.DATE || path === PATH.DATE_RESULT) {
      return history.push({
        pathname: ROUTES.CHANGE_AIR_TRIP.DATE_RESULT(tripId, ticketId, searchId),
      });
    }

    return history.push({
      pathname: ROUTES.CHANGE_AIR_TRIP.DIRECTION_RESULT(tripId, ticketId, searchId),
    });
  };

  const handleSearch = async () => {
    setLoadingSearch(true);
    handleAddFlightClass();

    const rightsBuyTrip = workspaceService.rights;
    const accountTravelPolicy = workspaceService.travelPolicy;
    const travelPolicyAllList = userSessionService.get().travelPolicies;
    const airlineCode = ticketData?.Routes[0].Segments[0].MarketingAirline.Code;
    const fareName = ticketData?.Fare?.Name;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    const searchId = await airlineService.searchAirTripChange(
      airlineCode as string,
      settings,
      fareName as string,
    );

    if (searchId) {
      airlineService.setDefaultSortValue();
      handleMoveToResult(searchId);
    }
  };

  const renderRoutesInfo = () => {
    if (useRoutesInfoRef.current) {
      return (
        routesInfo.map((route, index) => (
          <div data-qa={ infoTicket } className={ styles.route_info } key={ `route_info_${index}` }>
            <Text>
              {`${route.from} (${route.departureAirport.ID}) – ${route.to} (${route.arrivalAirport.ID})`}
            </Text>
            <div className={ styles.date }>
              <Text className={ styles.name } color='gray'>
                {`${LABELS.DEPARTURE_DATE}`}
              </Text>
              <Text>
                {`${formatDate(route.departureTimeSource, DATEFORMATS.DATE)}`}
              </Text>
            </div>
          </div>
        ))
      );
    }

    return routesSearch.map((route, index) => (
      <div data-qa={ infoTicket } className={ styles.route_info } key={ `route_info_${index}` }>
        <Text>
          {`${route.from.label} – ${route.to.label}`}
        </Text>
        <div className={ styles.date }>
          <Text className={ styles.name } color='gray'>
            {`${LABELS.DEPARTURE_DATE}`}
          </Text>
          <Text>
            {`${formatDate(route.date, DATEFORMATS.DATE)}`}
          </Text>
        </div>
      </div>
    ));
  };

  const renderContentInfo = () => (
    <div className={ styles.wrapper } ref={ wrapperStylesRef }>
      <div className={ styles.data }>
        { renderRoutesInfo() }
      </div>
      <LinkButton qaAttr={ buttonChangeTicket } onClick={ handleChange }>
        { LABELS.CHANGE }
      </LinkButton>
    </div>
  );

  const renderContent = () => {
    if (visibleSearchMenu) {
      return (
        <div className={ styles.wrapper_search }>
          <ChangeAirTripSearchMenu
            airlineService={ airlineService }
            path={ path }
            history={ history }
            ticketData={ ticketData }
            routesInfo={ routesInfo }
            routesSearch={ routesSearch as RouteSearchInterface[] }
            useRoutesInfo={ useRoutesInfoRef.current }
            onSearch={ handleSearch }
          />
        </div>
      );
    }

    return renderContentInfo();
  };

  return renderContent();
};

export { ChangeAirTripHeaderResult };
