import Api from '../../api';

import { airlineBaggageStore, IAirlineBaggageStore } from './store';

import { omit } from '../../utils/common';
import { createUnselectedBaggageWarning } from './utils/createUnselectedBaggageWarning';
import { IUnderageEmployeesItem } from '../../utils/employees';

import { AirRoute, ISelectedBaggageItem } from '../../types/airline';
import { IBaggage, IBaggageItemButton, ISelectedBaggage } from '../../types/airlineAdditionalServicesBaggage';

class AirlineBaggageService {
  api: Api['airlineBaggage'];
  store: IAirlineBaggageStore;

  constructor(api: Api) {
    this.api = api.airlineBaggage;
    this.store = airlineBaggageStore;
  }

  getBaggage = async (providerName: string, offerId: string, ticketId: number) => {
    try {
      const baggage = await this.api.getBaggageOffers(providerName, offerId);
      this.store.setBaggageOffers(baggage, ticketId);
    } catch (error) {
      this.store.setBaggageOffers(null, ticketId);
      this.store.setBaggageOffersGetError(String(ticketId));
      await this.deleteAllBaggages(ticketId);

      throw error;
    }
  };

  setEmployeeList = (employeeList: IUnderageEmployeesItem[], currentItemId: number) => {
    this.store.setEmployeeList({ ...this.store.employeeList, [currentItemId]: employeeList });
  };

  updateSelectedBaggage = ({
    ticketId,
    employeeId,
    routeId,
    price,
    routes,
    providerDescription,
    isInitial,
  }: ISelectedBaggageItem) => {
    const baggageButtonKey = `${ticketId}_${employeeId}_${routeId}`;
    const baggageDescription = this.store.baggageAddedToCart[baggageButtonKey]?.description;
    const updatedPrice = baggageDescription !== providerDescription
      ? price - (this.store.baggageAddedToCart[baggageButtonKey]?.price || 0)
      : 0;

    this.setAdditionalPrice({
      ...this.store.additionalPrice,
      [baggageButtonKey]: price,
    });

    if (isInitial) {
      this.store.setBaggageAddedToCart({
        ...this.store.baggageAddedToCart,
        [baggageButtonKey]: { price, description: providerDescription },
      });
    }

    this.store.setRoutes({
      ...this.store.routes,
      [baggageButtonKey]: {
        price: updatedPrice,
        description: providerDescription,
      },
    });

    const baggage = {
      TravellerId: employeeId,
      routeId,
      ProviderDescription: providerDescription,
    };

    this.updateBaggageIds(baggage, ticketId);
    this.updateTotalBaggageCost(ticketId);
    this.setSelectedBaggage(ticketId, routes);
  };

  updateBaggageIds = (baggage: IBaggageItemButton, ticketId: number) => {
    this.store.setBaggageIds(baggage, ticketId);
  };

  deleteBaggageOffer = (ticketId: number) => {
    this.store.selectedBaggage = { ...omit(this.store.selectedBaggage, [`${ticketId}`]) };
    this.store.baggageOffers = { ...omit(this.store.baggageOffers, [`${ticketId}`]) };
  };

  deleteBaggageOffers = () => {
    this.store.selectedBaggage = {} as Record<string, ISelectedBaggage>;
    this.store.baggageOffers = {} as Record<string, IBaggage>;
  };

  deleteAllBaggages = async (ticketId: number) => {
    await this.api.deleteAllBaggages(ticketId);
  };

  getBaggagesForSave = (ticketId: number, ID: string | number) => this.store.baggageIds[ticketId]
    .filter(({ routeId }) => routeId === ID)
    .map(({ TravellerId, ProviderDescription }) => ({ TravellerId, ProviderDescription }))
    .filter(Boolean);

  getBaggageRoutesForSave = (routes: AirRoute[], ticketId: number) => routes?.map(({ ID, Segments }) => {
    const firstSegment = Segments[0];
    const lastSegment = Segments[Segments.length - 1];

    const Arrival = lastSegment.ArrivalCityCode;
    const Departure = firstSegment.DepartureCityCode;

    return ({
      Arrival,
      Departure,
      Baggages: this.getBaggagesForSave(ticketId, ID),
    });
  });

  setSelectedBaggage = (ticketId: number, routes: AirRoute[]) => {
    if (!this.store.baggageOffers) {
      return;
    }

    const selectedBaggage = {
      ...omit(this.store.baggageOffers[ticketId], ['Error']),
      BaggageRoutes: this.getBaggageRoutesForSave(routes, ticketId),
    };

    this.store.setSelectedBaggage(selectedBaggage, ticketId);

    const warnings = createUnselectedBaggageWarning(
      this.store.selectedBaggage,
      this.store.employeeList[ticketId],
      routes,
    );

    this.store.setUnselectedBaggageWarning(warnings[ticketId] || [], ticketId);
  };

  setRoutes = (route: Record<string, Record<string, number | string>>) => {
    this.store.setRoutes(route);
  };

  setAdditionalPrice = (price: Record<string, number>) => {
    this.store.setAdditionalPrice(price);
  };

  clearRoutesById = (id: number, isForAll: boolean) => {
    this.store.routes = {
      ...Object.keys(this.store.routes).reduce((routes, key) => {
        const resultRoutes = { ...routes };

        const requiredId = isForAll
          ? key.startsWith(String(id))
          : key.includes(String(id));

        if (!requiredId) {
          resultRoutes[key] = this.store.routes[key];
        }

        return resultRoutes;
      }, {} as Record<string, Record<string, number | string>>),
    };
  };

  clearBaggage = (ticketId: number) => {
    const savedPriceItemId = this.store.baggageOffers?.[ticketId]?.SavedPriceItemId || '';

    const initialSelectedBaggage = {
      SavedPriceItemId: savedPriceItemId,
      BaggageRoutes: [],
    };

    this.clearRoutesById(ticketId, true);
    this.store.clearBaggageIds(ticketId);
    this.store.clearUnselectedBaggageWarning(ticketId);
    this.store.setSelectedBaggage(initialSelectedBaggage, ticketId);
    this.store.setUnselectedBaggageWarning([], ticketId);
    this.store.setTotalBaggageCost(0, ticketId);
  };

  setTotalBaggageCost = (totalBaggageCost: number, ticketId: number) => {
    this.store.setTotalBaggageCost(totalBaggageCost, ticketId);
  };

  updateTotalBaggageCost = (ticketId: number) => {
    const newTotal = Object.keys(this.store.routes)
      .filter(key => key.startsWith(String(ticketId)))
      .reduce((sum, key) => sum + Number(this.store.routes[key].price || 0), 0);

    this.setTotalBaggageCost(newTotal, ticketId);
  };

  addBaggage = async () => {
    const requests = Object.entries(this.store.selectedBaggage).map(([ticketId, body]) =>
      this.api.addBaggageToCart(Number(ticketId), body),
    );

    await Promise.all(requests);
  };

  deleteBaggageForEmployee = async (travellerId: number, ticketId: number, routes: AirRoute[]) => {
    const {
      selectedBaggage,
      employeeList,
      clearBaggageIdsForEmployee,
      setUnselectedBaggageWarning,
    } = this.store;

    if (selectedBaggage[ticketId]) {
      selectedBaggage[ticketId].BaggageRoutes = selectedBaggage[ticketId].BaggageRoutes
        .map(route => ({ ...route,
          Baggages: route.Baggages.map(baggage => {
            if (baggage && baggage.TravellerId === travellerId) {
              return { ...baggage, ProviderDescription: '' };
            }

            return baggage;
          }),
        }),
        );
    }

    this.clearRoutesById(travellerId, false);
    clearBaggageIdsForEmployee(ticketId, travellerId);

    const warnings = createUnselectedBaggageWarning(
      selectedBaggage,
      employeeList[ticketId],
      routes,
    )[ticketId];

    setUnselectedBaggageWarning(warnings, ticketId);
    await this.addBaggage();
  };
}

export default AirlineBaggageService;
