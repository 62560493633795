import React, { FC } from 'react';
import { Checkbox, Tooltip, Text, Icon } from 'new-ui';

import { getPropertyValues } from '../../../../bi/utils/train';

import { IEntireCompartment } from '../../../../bi/types/trains';

import styles from '../../index.module.css';

const EntireCompartment: FC<IEntireCompartment> = ({
  selectedPlace,
  entireCompartment,
  initialSelectedPlace,
  differentTrains,
  placesByCompartmentPrices,
  setEntireCompartment,
}) => {
  const selectedNumber = selectedPlace && selectedPlace.length > 0
    ? selectedPlace[0].number
    : null;

  const foundItems = placesByCompartmentPrices.find(section => (
    section && section.BuyFull && section.SpecialTariff
  ));

  const foundItemPlace = placesByCompartmentPrices.some(item =>
    item && item.BuyFull && item.PlacesByCompartment.Places.some(place =>
      parseInt(place, 10) === selectedNumber),
  );

  const showCheck = selectedPlace.length && foundItems
    ? !!foundItemPlace
    : false;

  const propertyValues = getPropertyValues(initialSelectedPlace, differentTrains, foundItems);

  if (!(showCheck && propertyValues && propertyValues.checkboxText && propertyValues.tooltipText)) return null;

  return (
    <Checkbox
      className={ styles.checkbox }
      value={ entireCompartment }
      onChange={ value => setEntireCompartment(value) }
    >
      <Tooltip
        className={ styles.tip }
        renderContent={ () => (
          <div className={ styles.tip_content }>
            <Text
              type='NORMAL_14_130'
              color='white'
            >
              {propertyValues.tooltipText}
            </Text>
          </div>) }
      >
        <div className={ styles.label }>
          <Text
            type='NORMAL_16'
          >
            {propertyValues.checkboxText}
          </Text>
          <Icon
            type='question'
            className={ styles.icon }
          />
        </div>
      </Tooltip>
    </Checkbox>
  );
};

export { EntireCompartment };
