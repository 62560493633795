import React, { Component } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { SearchPanel, StyledWrapper, Text, SearchMenuItemTypes } from 'new-ui';

import { observer } from 'mobx-react';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { momentObject } from '../../bi/utils/formatDate';
import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';
import getAccountId from '../../bi/utils/getAccountId';

import {
  SmartdeskProps,
  SmartdeskState,
  TodosPayload,
  TripIndexProps,
  TripsInCalendarProps,
} from '../../bi/types/smartdesk';
import { SmartdeskStoreType } from '../../bi/services/smartdesk/store';

import FORMATS from '../../bi/constants/formats';
import { PAYMENT } from '../../bi/constants/payment';
import ROUTES from '../../bi/constants/routes';
import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { UNIQUE_FORMED_TP } from '../../bi/constants/travelPolicy';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import { DEFAULTMENUTYPE } from '../../bi/constants/app';
import { withoutMentionSmartway } from '../../bi/constants/companiesRules';
import ToDoChangeEvents from './consts';

import Footer from '../../components/Footer';
import { WarningBlock } from '../../components/WarningBlock';
import SmartdeskPanel from '../../components/SmartdeskPanel';
import RecentTrips from './components/RecentTrips';
import { LinkTgAccount } from './components/LinkTgAccount';

import { ISendProps } from '../../bi/types/notification';

import logo from '../../../svg/logos/smartway.svg';

import styles from './styles/index.module.css';

const LABELS = {
  START_SEARCH: getText('smartdesk:startSearch'),
  CUSTOM_START_SEARCH: getText('smartdesk:customStartSearch'),
  NOTIFICATIONS: {
    sentOnEmailNotification: (email: string) =>
      getText('smartdesk:sentOnEmailNotification', { email }),
    taskAddSuccess: getText('smartdesk:notifications.taskAddSuccess'),
    taskEditSuccess: getText(
      'smartdesk:notifications.taskEditSuccess',
    ),
    taskRemoveSuccess: getText(
      'smartdesk:notifications.taskRemoveSuccess',
    ),
    allTaskRemoveSuccess: getText('smartdesk:notifications.allTaskRemoveSuccess'),
  },
  TEXT_LOADER: getText('smartdesk:requestList'),
  CUSTOM_BANNER_START: getText('smartdesk:customBannerStart'),
  CUSTOM_BANNER_END: getText('smartdesk:customBannerEnd'),
  CUSTOM_BANNER_BURSERIVCE_START: getText('smartdesk:customBannerBurService.start'),
  CUSTOM_BANNER_BURSERIVCE_LINK: getText('smartdesk:customBannerBurService.link'),
  CUSTOM_BANNER_BURSERIVCE_END: getText('smartdesk:customBannerBurService.end'),
  WARNING_CONTUR: getText('smartdesk:conturWarningSA'),
  WARNING_INTERCOM: getText('smartdesk:intercomWarning'),
};

const ANALYTIC_PANEL_SERVICE_TYPE = {
  [DEFAULTMENUTYPE.SEARCHAIRLINE]: ANALYTIC_SERVICE_TYPES.AIR,
  [DEFAULTMENUTYPE.SEARCHTRAIN]: ANALYTIC_SERVICE_TYPES.TRAIN,
  [DEFAULTMENUTYPE.SEARCHHOTEL]: ANALYTIC_SERVICE_TYPES.HOTEL,
  [DEFAULTMENUTYPE.SEARCHTRANSFER]: ANALYTIC_SERVICE_TYPES.TRANSFER,
  [DEFAULTMENUTYPE.SEARCHAEROEXPRESS]: ANALYTIC_SERVICE_TYPES.AERO_EXPRESS,
  [DEFAULTMENUTYPE.SEARCH_TAXI]: ANALYTIC_SERVICE_TYPES.TAXI,
};

const updateListWithNewEmployees = (list: IList[], index: number, newEmployees: string[]) => {
  const existingEmployees = new Set(list[index].Employees);

  newEmployees.forEach(e => existingEmployees.add(e));

  const listCopy = [...list];
  listCopy[index].Employees = Array.from(existingEmployees);

  return listCopy;
};

interface IList {
  CheckInDate: string,
  Employees: string[],
  Id: number,
  Name: string,
}

// @ts-ignore
@withStores([
  MOBX_STORES.CLOUD_PAYMENTS,
  MOBX_STORES.SMARTDESK,
  MOBX_STORES.UI_SETTINGS,
  MOBX_STORES.TRAVEL_APPROVALS,
  MOBX_STORES.POPUPS_STORE,
])
@observer
class Smartdesk extends Component<SmartdeskProps, SmartdeskState> {
  searchItems = this.props.uiSettingsProtocol.preparedSearchItems;
  isTravelApproval = this.props.approvalRequestProtocol.approveRequestExists;
  isExternalLink = this.props.featureFlagsService.getExternalLink();
  getShowElementForSmartagent = this.props.featureFlagsService.getShowElementForSmartagent();
  isDispayInsurance = this.props.featureFlagsService.getDisplayInsurance();
  store: SmartdeskStoreType;

  constructor(props: SmartdeskProps) {
    super(props);

    this.state = {
      calendar: {
        // @ts-ignore
        date: momentObject(),
        trips: {},
      },
      todo: [],
      invoice: null,
      companyFunds: props.appService.get().header.CompanyFunds,
      agentMode: props.appService.get().agentMode,
      accountId: getAccountId(),
    };
  }

  componentDidMount() {
    const {
      workspaceService: {
        canViewReports,
      },
      appService,
      popupsService,
      history,
      travelPolicyService,
      stores: {
        popupsStore: { popupsState, showTravelPopup, showSmartagentPopup },
      },
    } = this.props;

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.SMARTDESC.MAIN_SCREEN_OPENED);

    // @ts-ignore
    this.unsubscribeCompanyFunds = appService.subscribe(
      ({ header: { CompanyFunds } }: any) =>
        this.setState({ companyFunds: CompanyFunds }),
    );

    if (!isSmartAgent) {
      this.getTodo();

      if (canViewReports) {
        this.getLastInvoice();
      }
    }

    if (!popupsState.travel_policy_ready_made_popup && showTravelPopup && !isSmartAgent) {
      const redirectToUnique = () => {
        travelPolicyService.setFormedTP(UNIQUE_FORMED_TP.OLD);

        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.FORMED_TP_OPENED, { formedTP: UNIQUE_FORMED_TP.OLD });
        history.push(ROUTES.TRAVEL_POLICY_UNIQUE);
      };

      popupsService.addTPPopupSecond(redirectToUnique);
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_TP_POPUP);
    }

    if (!popupsState.smartagent_popup && isSmartAgent && showSmartagentPopup) {
      popupsService.addSmartagentPopup();
    }

    this.getCalendarTrips(this.state.calendar.date);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.MAIN_SMARTDESK_OPENED);
  }

  componentDidUpdate() {
    // @ts-ignore
    window.onbeforeunload = this.props.stores.cloudPaymentsStore
      .cantLeave
      ? e => {
        e.preventDefault();

        return '';
      }
      : undefined;
  }

  componentWillUnmount() {
    const { travelApprovalService, popupsService } = this.props;

    // @ts-ignore
    this.unsubscribeCompanyFunds();
    // @ts-ignore
    window.onbeforeunload = undefined;
    travelApprovalService.setResetTravelApprovalsStore();
    popupsService.setShowTravelPopup(false);
  }

  initialCalendar = () => {
    const {
      stores: {
        smartdeskStore: { tripsInCalendar },
      },
    } = this.props;

    const renderEmployees = (item: string[]) => {
      const additionalEmployees = item.length > 1 && (<span> +{ item.length - 1 }</span>);

      return (
        <Text
          type='NORMAL_14'
          className={ styles.employees }
        >
          { item[0] }
          { additionalEmployees }
        </Text>
      );
    };

    const renderTooltip = (item: IList[], trip: IList) => {
      const renderItem = (
        item.map(({ Id, Name, Employees }: IList, ind: number) => (
          <Link
            to={ `${ROUTES.TRIP.MAIN}/${Id}` }
            onClick={ this.handleClickToCalendarTrip }
            className={ styles.trip }
            key={ `day_${trip.Id}_${ind}` }
          >
            <Text
              type='SEMIBOLD_14'
              className={ styles.name }
            >
              { Name }
            </Text>
            { renderEmployees(Employees) }
          </Link>
        ))
      );

      return (
        <div className={ styles['tooltip-container'] }>
          { renderItem }
        </div>
      );
    };

    this.setState({
      calendar: {
        ...this.state.calendar,
        trips: tripsInCalendar.reduce((r: any, trip: TripsInCalendarProps) => {
          const key = momentObject(trip.CheckInDate).format(
            DATEFORMATS.DATE,
          );

          if (r[key]) {
            const sameTripIndex = r[key].list.findIndex(
              ({ Id }: TripIndexProps) => Id === trip.Id,
            );

            const newList = (sameTripIndex !== -1 && trip.Employees.length !== 0)
              ? updateListWithNewEmployees(r[key].list, sameTripIndex, trip.Employees)
              : [...r[key].list, trip];

            return {
              ...r,
              [key]: {
                ...r[key],
                list: newList,
              },
            };
          }

          return {
            ...r,
            [key]: {
              list: [trip],
              renderContent: (list: IList[]) => renderTooltip(list, trip),
            },
          };
        }, {}),
      },
    });
  };

  calcBalance = () => {
    const { companyFunds } = this.state;

    return companyFunds.reduce((val: number, current: { Debt: number }) => {
      const debt = current.Debt > 0 ? current.Debt : 0;

      return val + debt;
    }, 0);
  };

  getLastInvoice = () => {
    const { companyId, reportService } = this.props;

    reportService
      .getLastInvoice(companyId)
      .then((invoice: any) => this.setState({ invoice }));
  };

  getCalendarTrips = (month : Moment) => {
    const startDate = month.clone().startOf('month');
    const endDate = month.clone().endOf('month');

    this.props.smartdeskService.getTripsInCalendar({ startDate, endDate }).then(this.initialCalendar);
  };

  getTodo = () => {
    this.props.toDoService.get().then((result: []) => {
      this.setState({
        todo: result,
      });
    });
  };

  handleSendToAmplitude = (type: SearchMenuItemTypes) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_SEARCH_PRESSED(ANALYTIC_PANEL_SERVICE_TYPE[type]),
    );
  };

  sendNotification = (message: string, type: ISendProps['type'] = 'success') =>
    this.props.notificationService.send({ message, type });

  handleOpenTravelChat = () => {
    const { chatService, productAnalyticsProtocol } = this.props;
    chatService.show();
    productAnalyticsProtocol.clickIntercom();
  };

  handleOnTripClick = (tripId: number) => {
    this.props.history.push(`/trip/${tripId}`);
    MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.GOTOTRIPS, { label: MainAnalytic.LABELS.SMARTDESC.CURRENTTRIPS });
  };

  handleClickToCalendarTrip = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.GOTOTRIPS, { label: MainAnalytic.LABELS.SMARTDESC.CALENDAR });
  };

  handleChangeCalendarMonth = (value: Moment) => {
    this.setState(
      {
        calendar: {
          date: value,
          trips: {},
        },
      },
      () => this.getCalendarTrips(value),
    );
  };

  handleChangeTodo = ({ type, payload }: TodosPayload) => {
    const { toDoService } = this.props;

    const {
      taskAddSuccess,
      taskEditSuccess,
      taskRemoveSuccess,
      allTaskRemoveSuccess,
    } = LABELS.NOTIFICATIONS;

    let promise = null;
    switch (type) {
      case ToDoChangeEvents.ADD: {
        const { Text: T, isModal } = payload;
        promise = toDoService
          .saveOrUpdate({
            Id: 0,
            Text: T,
          })
          .then(() => {
            MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.ADDTASK, {
              label: isModal
                ? MainAnalytic.LABELS.SMARTDESC.ALLTASK
                : MainAnalytic.LABELS.SMARTDESC.MAINDISPLAY,
            });
            this.sendNotification(taskAddSuccess);
          });
        break;
      }
      case ToDoChangeEvents.EDIT: {
        promise = toDoService.saveOrUpdate(payload).then(() => {
          MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, payload.Status
            ? MainAnalytic.ACTIONS.SMARTDESC.COMPLETEDTODO
            : MainAnalytic.ACTIONS.SMARTDESC.RETURNTODO);
          this.sendNotification(taskEditSuccess);
        });
        break;
      }
      case ToDoChangeEvents.DELETE: {
        promise = toDoService.remove(payload as unknown as number).then(() => {
          MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.DELETETODO);
          this.sendNotification(taskRemoveSuccess);
        });
        break;
      }
      case ToDoChangeEvents.DELETEALL: {
        promise = toDoService.removeAll().then(() => {
          MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.DELETEALLTODO);
          this.sendNotification(allTaskRemoveSuccess);
        });
        break;
      }
      case ToDoChangeEvents.MODALOPENED: {
        MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.OPENALLTODO, { value: this.state.todo.length });
        break;
      }
    }

    if (promise) {
      promise.then(this.getTodo);
    }
  };

  handleDownloadInvoice = (invoiceNumber: string) => {
    const { reportService, companyId } = this.props;
    const { invoice } = this.state;

    if (
      !!invoice &&
      !!invoice.Package &&
      !!invoice.Package.DocumentId &&
      !!invoice.Package.PackageId
    ) {
      const { PackageId, DocumentId } = invoice.Package;
      reportService.downloadDocument(
        companyId,
        PackageId,
        DocumentId,
        FORMATS.PDF,
      );
    } else {
      reportService.downloadInvoice(companyId, invoiceNumber);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_DOWNLOAD_INVOICE_PRESSED);
  };

  handleSendInvoice = (email: string) => {
    const { invoice } = this.state;
    const { reportService, companyId } = this.props;

    let packageId = null;
    let documentId = null;

    if (
      invoice &&
      invoice.Package &&
      invoice.Package.PackageId &&
      invoice.Package.DocumentId
    ) {
      packageId = invoice.Package.PackageId;
      documentId = invoice.Package.DocumentId;
    }

    reportService
      .sendInvoice({
        companyId,
        invoiceNumber: invoice.Number,
        email,
        format: FORMATS.PDF,
        packageId,
        documentId,
      })
      .then(() =>
        this.sendNotification(
          LABELS.NOTIFICATIONS.sentOnEmailNotification(email),
        ),
      );

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_SEND_INVOICE_PRESSED);
  };

  renderHeroTitle = () => {
    const {
      stores: {
        uiSettingsStore: {
          settings: { customHeroTitle },
        },
      },
    } = this.props;
    const { CUSTOM_START_SEARCH, START_SEARCH } = LABELS;

    const titleText = customHeroTitle
      ? CUSTOM_START_SEARCH
      : START_SEARCH;
    const titleImg = customHeroTitle && (
      <img
        src={ logo }
        className={ styles.logo }
        alt='Smartway'
      />
    );

    return (
      <Text
        qaAttr={ QA_ATTRIBUTES.smartdesk.startSearch }
        type='bold_32'
      >
        { titleText }
        { titleImg }
      </Text>
    );
  };

  renderCustomBannerBurService = () => {
    if (this.state.accountId !== 34358) {
      return null;
    }

    return (
      <StyledWrapper className={ styles.custom_banner }>
        <Text type='NORMAL_16' >
          { LABELS.CUSTOM_BANNER_BURSERIVCE_START }
          <a href={ ROUTES.INTRODUCTORY_BRIEFING_BURSERVICE } target='_blank' rel='noreferrer'>
            { LABELS.CUSTOM_BANNER_BURSERIVCE_LINK }
          </a>
          { LABELS.CUSTOM_BANNER_BURSERIVCE_END }
        </Text>
      </StyledWrapper>
    );
  };

  renderCustomBanner = () => {
    if (!this.isExternalLink) {
      return null;
    }

    return (
      <StyledWrapper className={ styles.custom_banner }>
        <Text type='NORMAL_16' >
          { LABELS.CUSTOM_BANNER_START }
          <a href={ ROUTES.CUSTOM_BUSSINES_TRIPS_OGMK } target='_blank' rel='noreferrer'>
            { LABELS.CUSTOM_BANNER_END }
          </a>
        </Text>
      </StyledWrapper>
    );
  };

  renderPanel = () => {
    const clasess = [styles.menu];

    if (isSmartAgent) {
      clasess.push(styles.flex_menu);
    }

    const searchTheme = isSmartAgent && 'rectangle';

    const renderHeroTitleWorkplace = !isSmartAgent && this.renderHeroTitle();
    const renderCustomBannerWorkplace = !isSmartAgent && this.renderCustomBanner();

    return (
      <>
        <div className={ styles.location }>
          { renderHeroTitleWorkplace }
          { renderCustomBannerWorkplace }
        </div>
        <div className={ clasess.join(' ') }>
          <SearchPanel
            alternativeDesign={ isSmartAgent }
            theme={ searchTheme }
            searchMenuItems={ this.searchItems }
            qaAttr={ QA_ATTRIBUTES.smartdesk.searchPanel }
            onClick={ this.handleSendToAmplitude }
          />
        </div>
      </>
    );
  };

  renderTrips = () => {
    const { agentMode } = this.state;
    const {
      accountSettingsService: {
        getNoMice,
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
      aggregationId,
    } = this.props;

    if (isSmartAgent) return null;

    const showEventSticker = !isSmartAgent && !aggregationId && !getNoMice();

    return (
      <div className={ styles.trips }>
        <RecentTrips
          onTripClick={ this.handleOnTripClick }
          showEventSticker={ showEventSticker }
          isDispayInsurance={ this.isDispayInsurance }
          isWhiteLabel={ isWhiteLabel() }
          agentMode={ agentMode }
        />
      </div>
    );
  };

  render() {
    const {
      workspaceService,
      featureFlagsService,
      paymentService,
      stores: {
        cloudPaymentsStore: { cantLeave },
        uiSettingsStore: {
          settings: {
            showPaymentButton,
            showLastInvoice,
            copyrightName,
            phone,
            email: companyEmail,
            companyName: mainCompanyName,
          },
        },
      },
    } = this.props;
    const { calendar, todo, invoice, companyFunds } = this.state;
    const { canViewReports } = workspaceService;
    const balance = this.calcBalance();

    const {
      Companies: companies,
      CompanyId: companyId,
      Email: email,
      IsDemo,
      CompanyName: companyName,
      CompanyInn: companyInn,
    } = workspaceService.get();

    const preparedCompanies = companies.filter(({ IsShowReporting }) => IsShowReporting);

    const companiesItems = preparedCompanies
      ? preparedCompanies.map(
        ({ ShortCompanyName, CompanyId, CompanyName }: { ShortCompanyName: string, CompanyId: number, CompanyName: string }) => ({
          label: ShortCompanyName || CompanyName,
          value: CompanyId,
        }),
      )
      : [];

    const banAccountView = companyFunds
      .some((el: any) => (el.MaxVisibleBookingAmount !== null));

    const showInvoicePay = canViewReports || !banAccountView;

    const isContur = mainCompanyName === withoutMentionSmartway[0];

    const renderHeroTitleSA = isSmartAgent && this.renderHeroTitle();
    const renderCustomBannerSA = isSmartAgent && this.renderCustomBanner();
    const renderCustomBannerBurService = this.renderCustomBannerBurService();
    const renderPanelWorkPlace = !isSmartAgent && this.renderPanel();
    const renderPanelSmartagent = isSmartAgent && !this.getShowElementForSmartagent && this.renderPanel();
    const renderLinkTlg = !isSmartAgent ? <LinkTgAccount /> : null;

    const text = <>
      { LABELS.WARNING_CONTUR }
      <Text type='bold_16'><a href='https://kontur.ru/lp/travel' target='_blank' rel='noreferrer'>kontur.ru</a></Text>
    </>;

    const renderConturBlockSA = isContur && <WarningBlock text={ text } type='SEMIBOLD_16' color='default' />;

    return (
      <div className={ styles.wrap }>
        <Prompt when={ cantLeave } message={ PAYMENT.EXITMESSAGE } />
        { renderConturBlockSA }
        { renderHeroTitleSA }
        { renderCustomBannerBurService }
        { renderCustomBannerSA }
        { renderPanelWorkPlace }
        <div className={ styles.panel }>
          <SmartdeskPanel
            showInvoicePay={ showInvoicePay }
            calendar={ calendar }
            todo={ todo }
            invoice={ invoice }
            showPaymentButton={ showPaymentButton }
            showLastInvoice={ showLastInvoice }
            showInvoice={ canViewReports }
            balance={ balance }
            onChangeMonth={ this.handleChangeCalendarMonth }
            onChangeTodo={ this.handleChangeTodo }
            onDownloadInvoice={ this.handleDownloadInvoice }
            onSendInvoice={ this.handleSendInvoice }
            company={ {
              companies: companiesItems,
              companyId,
              email,
              companyName,
              companyInn,
              allCompanies: companies,
            } }
            isDemo={ IsDemo }
            paymentService={ paymentService }
            featureFlagsService={ featureFlagsService }
          >
            { renderPanelSmartagent }
          </SmartdeskPanel>
        </div>
        { this.renderTrips() }
        { renderLinkTlg }
        <Footer
          copyrightName={ copyrightName }
          email={ companyEmail }
          phone={ phone }
          wiki={ ROUTES.SMARTWAY_WIKI }
          companyName={ mainCompanyName }
          handleOpenTravelChat={ this.handleOpenTravelChat }
        />
      </div>
    );
  }
}

export default Smartdesk;
