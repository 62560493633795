import React from 'react';
import { Checkbox, Text } from 'new-ui';

const isIncludes = (arr: string[], value: string) => arr.includes(value);

interface IMiceCheckbox {
  onChange: (item: string) => void,
  values: string[],
  label: string,
  item: string
}

const MiceCheckbox = ({
  onChange,
  values,
  label,
  item,
}: IMiceCheckbox) => (
  <Checkbox
    value={ isIncludes(values, item) }
    onChange={ () => onChange(item) }
  >
    <Text type='NORMAL_14'>
      { label }
    </Text>
  </Checkbox>
);

export { MiceCheckbox };
