import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, LinkButton, RadioButton, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { ConditionalDialog } from '../../../../components/ApproveSchemeCommon/components/conditionalOfApprovers/ConditionalDialog';
import { ApproverComponents } from '../../../../components/ApproveSchemeCommon/components/approvers/Approvers';

import { getApproversAndRolesLength } from '../../../../bi/utils/approval';
import { isSmartAgent } from '../../../../bi/utils/env';

import {
  APPROVAL_SCHEME_STEP_FIELDS,
  APPROVAL_SCHEME_TYPES,
  APPROVAL_TYPES,
} from '../../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IEmployees, IRoles } from '../../../../bi/services/approvalSchemes/types';

import styles from './styles/index.module.css';

const LABELS = {
  DROP_CART: getText('approvalScheme:step.dropCart'),
  STEP_COUNT: (step: number) => getText('approvalScheme:step.stepCount', { step }),
  STEP_COUNT_CONTINUE: getText('approvalScheme:step.stepCountContinue'),
  REMOVE_STEP: getText('approvalScheme:step.removeStep'),
};

interface IApprovalPurchaseStep {
  ind: number,
  value: {
    Roles: number[],
    Approvers: string[],
    Type: number,
    ConditionOfApproval: number,
    Id?: number
  },
  draggableItem: {
    id: number | null,
    type: string,
  },
  employees: IEmployees,
  roles: IRoles,
  onChange(p: any): void,
  onRemove(): void,
  renderEmployee: any,
  onClickAddApprover(): void,
  onDragRemovableApprover(p: any): void,
  onDragEndRemovableApprover(): void,
}
const ApprovalPurchaseStep = observer(({
  ind,
  value,
  draggableItem,
  employees,
  roles,
  onChange,
  onRemove,
  renderEmployee,
  onClickAddApprover,
  onDragRemovableApprover,
  onDragEndRemovableApprover,
}: IApprovalPurchaseStep) => {
  const [canBeDropped, setCanBeDropped] = useState(false);
  const [dialog, setDialog] = useState({
    cb: () => {},
    show: false,
  });

  const { Roles, Approvers, Type } = value;
  const { id: draggableItemId, type: draggableItemType } = draggableItem;

  const handleOnChange = (payload: any) => onChange({ ...value, ...payload });

  const handleOnDrop = () => {
    setCanBeDropped(false);

    // @ts-ignore
    if (!draggableItemType || value[draggableItemType].includes(draggableItemId)) {
      return null;
    }

    // @ts-ignore
    const payload = { [draggableItemType]: Array.from(new Set(value[draggableItemType]).add(draggableItemId)) };

    if (getApproversAndRolesLength(Roles, Approvers) === 1) {
      return setDialog({
        show: true,
        cb: () => {
          setDialog({
            cb(): void {},
            show: false,
          });
          setCanBeDropped(false);

          return payload;
        },
      });
    }

    return handleOnChange(payload);
  };

  const handleDialogButtonClick = (val: any) => {
    const { cb } = dialog;

    // @ts-ignore
    handleOnChange({ [APPROVAL_SCHEME_STEP_FIELDS.CONDITION_OF_APPROVAL]: val, ...cb() });
  };

  const renderDroppable = () => (
    <div
      className={ styles['droppable-panel'] }
      onDragLeave={ () => setCanBeDropped(false) }
      onDragOver={ e => e.preventDefault() }
      onDrop={ handleOnDrop }
    >
      <Text type='normal_20'>
        { LABELS.DROP_CART }
      </Text>
    </div>
  );

  const renderRemoveButton = () => {
    if (ind === 1) {
      return null;
    }

    return (
      <LinkButton
        theme='blue-without-border'
        onClick={ onRemove }
      >
        { LABELS.REMOVE_STEP }
      </LinkButton>
    );
  };

  const qaAttrStep = `${QA_ATTRIBUTES.approvalSchemes.trips.moveToStep}-${ind}`;

  const renderItemScheme = (key: string) => {
    const qaAttrTypeScheme = APPROVAL_SCHEME_TYPES[key].value === 1 ? QA_ATTRIBUTES.approvalSchemes.trips.typeDisruptedTp : '';

    return (
      <RadioButton
        key={ key }
        className={ styles.radio }
        checked={ Type === APPROVAL_SCHEME_TYPES[key].value }
        alternativeDesign={ isSmartAgent }
        onChange={ () => handleOnChange({ [APPROVAL_SCHEME_STEP_FIELDS.TYPE]: APPROVAL_SCHEME_TYPES[key].value }) }
        qaAttr={ qaAttrTypeScheme }
      >
        { APPROVAL_SCHEME_TYPES[key].label }
      </RadioButton>
    );
  };

  const renderCommon = () => {
    const typeContent = (
      <div className={ styles['radio-buttons'] }>
        { Object.keys(APPROVAL_SCHEME_TYPES).map(renderItemScheme) }
      </div>
    );

    return (
      <div className={ styles.common }>
        <div className={ styles.header }>
          <Text type='NORMAL_18'>
            { LABELS.STEP_COUNT(ind) }{ LABELS.STEP_COUNT_CONTINUE }
          </Text>
          { renderRemoveButton() }
        </div>
        { typeContent }
        <ApproverComponents
          field={ APPROVAL_TYPES.TRIP }
          employees={ employees }
          roles={ roles }
          value={ value }
          renderEmployee={ renderEmployee }
          onChange={ onChange }
          onClickAddApprover={ onClickAddApprover }
          onDragRemovableApprover={ onDragRemovableApprover }
          onDragEndRemovableApprover={ onDragEndRemovableApprover }
          qaAttr={ qaAttrStep }
        />
      </div>
    );
  };

  const canBeDroppedWithId = canBeDropped && draggableItemId !== null;

  const wrapperClassNames = [styles.wrapper];

  if (canBeDroppedWithId) {
    wrapperClassNames.push(styles.droppable);
  }

  if (draggableItemId !== null) {
    wrapperClassNames.push(styles.highlighted);
  }

  const droppablePanel = canBeDroppedWithId && renderDroppable();

  const arrowContent = ind !== 1 && (
    <div className={ styles.arrow }>
      <Icon
        className={ styles.icon }
        color='gray'
        type='arrowsBack'
      />
    </div>
  );

  const onDragProps = canBeDroppedWithId ? {} : {
    onDragEnter: () => setCanBeDropped(true),
    onDragOver: (e: any) => e.preventDefault(),
    onDrop: handleOnDrop,
  };

  return (
    <div>
      { arrowContent }
      <div
        className={ wrapperClassNames.join(' ') }
        { ...onDragProps }
      >
        { renderCommon() }
        { droppablePanel }
        <ConditionalDialog
          show={ dialog.show }
          onClick={ handleDialogButtonClick }
        />
      </div>
    </div>
  );
});

export { ApprovalPurchaseStep };
