import { action, makeObservable, observable } from 'mobx';
import { currentLng, getText } from '../../../../../i18n';

import { checkDifferences, preparedRightsToBackend, preparedRightsToFrontend } from '../../../utils/rights';
import { isSmartAgent } from '../../../utils/env';
import { getEmployeeFullName, getEmployeeFullNameWithSimplicity } from '../../../utils/employees';

import {
  CREATERIGHTS,
  DELETERIGHTS,
  EDIT_BUY_WITH_PERSONAL_FUNDS,
  EDITRIGHTS,
  EMPTYRIGHTS,
} from '../../../constants/rights';

import {
  AcquiringLevel,
  AppoversTypeLigth,
  IAllApproveUsers,
  IApprovalShemas,
  IApprovalShemasERA,
  IDefaultRigths,
  IEmployees,
  IList,
  IListExpense,
  INested,
  ITpRightLight,
  IUsers,
  IUsersFull,
  UsersType,
} from '../types';

const DEFAULTDOCUMENTS = [
  {
    label: getText('services:employee.store.rights.defaultDocuments.byTrips'),
    value: 'personalTrips',
  },
  {
    label: getText('services:employee.store.rights.defaultDocuments.byAllTrips'),
    value: 'accountTrips',
  },
];

const ERRORTEXT = {
  TITLE: getText('services:employee.store.rights.emailError.title'),
  TITLE_FOR_SA: getText('services:employee.store.rights.emailError.title_SA'),
  ERROR: getText('services:employee.store.rights.emailError.text'),
};

const DEAFAULTRIGHTS = ({
  loadingButton: false,
  manually: false,
  copy: false,
  edit: false,
  close: false,
  buyTripPersonal: true,
  disableBuyTripPersonal: false,
  buyTripAccount: false,
  approve: false,
  finance: false,
  approveExpenseReports: false,
  showSecondStep: true,
  buyUnlimited: true,
  buyAfterApprove: false,
  buyTravelPolicy: false,
  buyApprovalScheme: false,
  createERUnlimited: true,
  createERAfterApprove: false,
  createERApprovalScheme: false,
  editEmployee: false,
  editSelf: true,
  viewTrips: false,
  viewExpenseReports: false,
  viewSelfTrips: true,
  editRights: false,
  disableEditRights: true,
  editTravelPolicies: false,
  showEditTravelPolicy: false,
  canGetVouchers: true,
  canGetInvoices: false,
  viewMiceEvents: false,
  sendDocuments: false,
  approvers: [],
  approversForExpenseReports: [],
  documents: DEFAULTDOCUMENTS[0].value,
  showSelectDocuments: true,
  copyUser: '',
  Acquiring: AcquiringLevel.Undefined,
  buyWithPersonalFunds: 'Unavailable',
  buyInsurance: false,
});

const DEFAULTUSERS = {
  all: [],
  default: [],
  withRights: [],
};

const DEFAULTERRORMSG = {
  title: '',
  error: '',
};

const DEFAULTTPRIGHTS = {
  Id: '',
  PolicyId: '',
  UserId: '',
};

const mapperApprovers = (list: IList[], email: string) => list
  .filter(({ Email }) => Email !== email)
  .map(({ LastName, FirstName, MiddleName, Email, Name }) => {
    const nameOrEmail = Name || Email;

    let label = nameOrEmail;
    let name = nameOrEmail;

    if (LastName && LastName.length && FirstName && FirstName.length) {
      label = `${LastName} ${FirstName} ${MiddleName.length ? `${MiddleName}` : ''}`;
      name = `${LastName} ${FirstName[0]}.${MiddleName.length ? `${MiddleName[0]}.` : ''}`;
    }

    return ({
      value: Email,
      label,
      name,
    });
  });

const mapperApproversWithId = (list: IList[], email: string) => (
  list
    .filter(({ Email }) => Email !== email)
    .map(({ LastName, FirstName, MiddleName, Email, Name, UserId }) => {
      const nameOrEmail = Name || Email;

      let label = nameOrEmail;
      let name = nameOrEmail;

      if (LastName && LastName.length && FirstName && FirstName.length) {
        label = `${LastName} ${FirstName} ${MiddleName.length ? `${MiddleName}` : ''}`;
        name = `${LastName} ${FirstName[0]}.${MiddleName.length ? `${MiddleName[0]}.` : ''}`;
      }

      return ({
        value: UserId,
        label,
        name,
      });
    })
);

const buyWithPersonalFundsValue = (buyTripAccount: boolean | undefined, buyWithPersonalFunds: boolean | string) => {
  let mayBuyPersonal = buyWithPersonalFunds;

  if (typeof buyWithPersonalFunds === 'string') {
    mayBuyPersonal = buyWithPersonalFunds !== EDIT_BUY_WITH_PERSONAL_FUNDS.Unavailable && buyWithPersonalFunds !== EDIT_BUY_WITH_PERSONAL_FUNDS.Undefined;
  }

  if (buyTripAccount && mayBuyPersonal) {
    return EDIT_BUY_WITH_PERSONAL_FUNDS.All;
  }

  if (!buyTripAccount && mayBuyPersonal) {
    return EDIT_BUY_WITH_PERSONAL_FUNDS.Personal;
  }

  return EDIT_BUY_WITH_PERSONAL_FUNDS.Unavailable;
};

class RightsStore {
  constructor() {
    makeObservable(this);
  }

  @observable rights: IDefaultRigths = DEAFAULTRIGHTS;
  @observable newRights: boolean = false;
  @observable canLogin: boolean = false;
  @observable viewRights: any = null;
  @observable sourceRights: any = null;
  @observable users: IUsersFull = DEFAULTUSERS;
  @observable allApproveUsers: IAllApproveUsers[] = [];
  @observable allApproveUsersWithId: UsersType[] = [];
  @observable viewApprovers: string[] = [];
  @observable viewExpenseReportApprovers: string[] = [];
  @observable approversListLoading: boolean = false;
  @observable sourceApprovers: string[] = [];
  @observable documents: INested[] = DEFAULTDOCUMENTS;
  @observable login: string = '';
  @observable chosenTP: string = '';
  @observable sourceChosenTP: string = '';
  @observable chosenApprovalScheme: number | null = null;
  @observable tpRightsByCopyUser: ITpRightLight = DEFAULTTPRIGHTS;
  @observable errorMsg = DEFAULTERRORMSG;
  @observable accountLng: string = currentLng;
  @observable approveERUsers: UsersType[] = [];
  @observable chosenApprovalERScheme: number | null = null;
  @observable viewRightsLoading: boolean = false;

  approvalSchemes: IApprovalShemas[] = [];
  approvalExpenseReportsSchemes: IApprovalShemasERA[] = [];

  @action
  setLogin = (value: string) => {
    this.login = value;
  };

  @action
  setViewRightsLoading = (isLoading: boolean) => {
    this.viewRightsLoading = isLoading;
  };

  @action
  setViewRights = (rights: any) => {
    if (rights) {
      this.sourceRights = rights;
      this.newRights = false;

      this.updateCanLogin(true);
      this.setLogin(rights.Login);

      this.viewRights = {
        ...DEAFAULTRIGHTS,
        ...preparedRightsToFrontend(rights),
        edit: true,
      };

      return;
    }

    this.newRights = true;
    this.viewRights = null;
    this.sourceRights = null;
  };

  @action
  setRights = (approvalSchemes: IApprovalShemas[] = [], approvalExpenseReportsSchemes: IApprovalShemasERA[] = []) => {
    this.approvalSchemes = approvalSchemes;
    this.approvalExpenseReportsSchemes = approvalExpenseReportsSchemes;

    if (this.newRights && !this.viewRights) {
      const users: IUsers[] = this.users.withRights || this.users.default;

      this.rights = {
        ...DEAFAULTRIGHTS,
        manually: true,
        copyUser: users.length ? users[0].value : '',
        showEditTravelPolicy: true,
      };

      if (approvalSchemes.length) {
        this.rights = {
          ...this.rights,
          buyUnlimited: false,
          buyApprovalScheme: true,
        };

        this.updateApprovalScheme(approvalSchemes[0].Id);
      }

      if (approvalExpenseReportsSchemes.length) {
        this.rights = {
          ...this.rights,
          createERUnlimited: false,
          createERApprovalScheme: true,
        };

        this.updateApprovalERScheme(approvalExpenseReportsSchemes[0].Id);
      }

      return;
    }

    this.allApproveUsers = this.allApproveUsers.filter(({ value }: { value: string }) => value !== this.login);
    this.approveERUsers = this.approveERUsers.filter(({ value }: { value: string }) => value !== this.login);

    this.rights = {
      ...this.viewRights,
      manually: false,
      edit: true,
      approvers: this.viewApprovers,
      approversForExpenseReports: this.viewExpenseReportApprovers,
    };
  };

  @action
  setCopyRights = (rights: any) => {
    this.rights = {
      ...DEAFAULTRIGHTS,
      ...preparedRightsToFrontend(rights),
    };
  };

  @action
  setUsers = (list: IEmployees[]) => {
    this.users.all = list.map(({ Surname, Name, Patronymic, Id, ApprovalSchemeId, ApprovalExpenseReportSchemeId }) => ({
      label: `${Surname} ${Name} ${Patronymic?.length ? `${Patronymic}` : ''}`,
      value: `${Id}`,
      name: `${Surname} ${Name[0]}.${Patronymic?.length ? `${Patronymic[0]}.` : ''}`,
      approvalSchemeId: ApprovalSchemeId,
      approvalExpenseReportSchemeId: ApprovalExpenseReportSchemeId,
    }));

    const allList = this.users.all;

    this.users.default = allList.sort((first: IUsers, second: IUsers) => Number(second.value) - Number(first.value)).slice(0, 3);

    const listRights = list.filter(({ Rights }) => Rights !== null);

    this.users.withRights = listRights.map(({
      Surname,
      Name,
      Patronymic,
      Id,
      ApprovalSchemeId,
      ApprovalExpenseReportSchemeId,
    }) => ({
      label: getEmployeeFullName({ Surname, Name, Patronymic }),
      value: `${Id}`,
      name: getEmployeeFullNameWithSimplicity({ Surname, Name, Patronymic }, true),
      approvalSchemeId: ApprovalSchemeId,
      approvalExpenseReportSchemeId: ApprovalExpenseReportSchemeId,
    }));
  };

  @action
  setAllApprovers = (list: IList[]) => {
    if (!list) {
      return;
    }

    this.allApproveUsers = mapperApprovers(list, this.login);
    this.allApproveUsersWithId = mapperApproversWithId(list, this.login);
  };

  @action
  updateApprovalERScheme = (value: number) => {
    this.chosenApprovalERScheme = value;
  };

  @action
  changeAcquiringLevel = (value: boolean) => {
    let newValue = AcquiringLevel.Undefined;

    if (value) {
      if (this.rights.buyTripPersonal) newValue = AcquiringLevel.Personal;

      if (this.rights.buyTripAccount) newValue = AcquiringLevel.All;
    }

    this.rights.Acquiring = newValue;
  };

  @action
  setApproversForExpenseReports = (list: IList[] & IListExpense[]) => {
    if (!list) return;

    this.approveERUsers = mapperApprovers(list, this.login);
  };

  @action
  setApproversLoading = (value: boolean) => {
    this.approversListLoading = value;
  };

  @action
  setApprovers = (list: IList[]) => {
    this.viewApprovers = list.length ? list.map((el) => el.UserId) : [];
    this.sourceApprovers = this.viewApprovers;
  };

  @action
  setSourceChosenTp = (value: string) => {
    this.sourceChosenTP = value || '';
  };

  @action
  changeCopyAndManually = (value: boolean) => {
    this.rights.manually = value;
    this.rights.copy = !value;
  };

  @action
  changeCloseAndEdit = (value: boolean) => {
    this.rights.close = value;
    this.rights.edit = !value;
  };

  @action
  changeAccountLng = (value: string) => {
    this.accountLng = value;
  };

  @action
  updateTpRightsByCopyUser = (tpRights: ITpRightLight) => {
    if (!tpRights) {
      this.tpRightsByCopyUser = DEFAULTTPRIGHTS;

      return;
    }

    this.tpRightsByCopyUser = { ...tpRights };
  };

  updateSecondStep = () => {
    if (!this.rights.buyTripPersonal && !this.rights.buyTripAccount) {
      this.rights.showSecondStep = false;
      this.rights.buyUnlimited = false;
      this.rights.buyAfterApprove = false;
      this.rights.buyTravelPolicy = false;

      this.rights.canGetVouchers = false;
      this.rights.showSelectDocuments = false;
      this.rights.documents = DEFAULTDOCUMENTS[1].value;

      return;
    }

    this.rights.showSecondStep = true;
    this.rights.buyUnlimited = true;
    this.rights.buyAfterApprove = false;
    this.rights.buyTravelPolicy = false;

    if (this.approvalSchemes.length) {
      this.rights.buyUnlimited = false;
      this.rights.buyApprovalScheme = true;
    }

    this.rights.canGetVouchers = true;
    this.rights.showSelectDocuments = true;
  };

  updateShowEditTravelPolicy = () => {
    this.rights.showEditTravelPolicy = this.rights.buyTripAccount || !this.rights.buyTripPersonal || !this.rights.buyTravelPolicy;
    this.rights.editTravelPolicies = this.rights.showEditTravelPolicy ? this.rights.editTravelPolicies : false;
  };

  @action
  changeBuyTripPersonal = (value: boolean) => {
    this.rights.buyTripPersonal = value;

    if (value) {
      this.rights.editTravelPolicies = false;
      this.rights.showSelectDocuments = true;

      if (this.rights.Acquiring === AcquiringLevel.All) this.rights.Acquiring = AcquiringLevel.Personal;
    } else {
      this.rights.Acquiring = AcquiringLevel.Undefined;
    }

    this.rights.viewSelfTrips = value || this.rights.buyTripAccount;

    this.updateSecondStep();
    this.updateShowEditTravelPolicy();

    if (!this.newRights) {
      return;
    }

    this.rights.canGetVouchers = value || this.rights.canGetVouchers;
  };

  @action
  changeBuyTripAccount = (value: boolean) => {
    this.rights.buyTripAccount = value;
    this.rights.disableBuyTripPersonal = value;
    this.rights.buyWithPersonalFunds = buyWithPersonalFundsValue(value, this.rights.buyWithPersonalFunds);

    if (value) {
      this.rights.buyTripPersonal = true;
      this.rights.editTravelPolicies = false;
      this.rights.showSelectDocuments = true;

      if (this.rights.Acquiring === AcquiringLevel.Personal) this.rights.Acquiring = AcquiringLevel.All;
    } else if (this.rights.Acquiring === AcquiringLevel.All) this.rights.Acquiring = AcquiringLevel.Personal;

    this.rights.viewSelfTrips = value || this.rights.buyTripPersonal;

    this.updateSecondStep();
    this.updateShowEditTravelPolicy();

    if (!this.newRights) {
      return;
    }

    this.rights.canGetVouchers = value || this.rights.canGetVouchers;
    this.rights.editEmployee = value;
    this.rights.disableEditRights = !value;

    this.rights.editRights = value ? this.rights.editRights : false;
  };

  @action
  changeApprove = (value: boolean) => {
    this.rights.approve = value;
  };

  @action
  changeApproveExpenseReports = (value: boolean) => {
    this.rights.approveExpenseReports = value;
  };

  @action
  changeViewExpenseReports = (value: boolean) => {
    this.rights.viewExpenseReports = value;
  };

  @action
  changeFinance = (value: boolean) => {
    this.rights.finance = value;
  };

  @action
  setBuyRightToDefault = () => {
    this.rights.buyUnlimited = false;
    this.rights.buyAfterApprove = false;
    this.rights.buyTravelPolicy = false;
    this.rights.buyApprovalScheme = false;
  };

  @action
  setCreateERRightToDefault = () => {
    this.rights.createERUnlimited = false;
    this.rights.createERAfterApprove = false;
    this.rights.createERApprovalScheme = false;
  };

  @action
  changeBuyUnlimited = (value: boolean) => {
    this.setBuyRightToDefault();
    this.rights.buyUnlimited = value;

    this.updateShowEditTravelPolicy();
  };

  @action
  changeBuyAfterApprove = (value: boolean) => {
    this.setBuyRightToDefault();
    this.rights.buyAfterApprove = value;

    this.updateShowEditTravelPolicy();
  };

  @action
  changeBuyApprovalScheme = (value: boolean) => {
    this.setBuyRightToDefault();
    this.rights.buyApprovalScheme = value;

    this.updateShowEditTravelPolicy();
  };

  @action
  changeBuyTravelPolicy = (value: boolean) => {
    this.setBuyRightToDefault();
    this.rights.buyTravelPolicy = value;

    this.updateShowEditTravelPolicy();
  };

  @action
  changeCreateAfterApprove = (value: boolean) => {
    this.setCreateERRightToDefault();
    this.rights.createERAfterApprove = value;
  };

  @action
  changeCreateApprovalScheme = (value: boolean) => {
    this.setCreateERRightToDefault();
    this.rights.createERApprovalScheme = value;
  };

  @action
  changeCreateERUnlimited = (value: boolean) => {
    this.setCreateERRightToDefault();
    this.rights.createERUnlimited = value;
  };

  @action
  changeEditEmployee = (value: boolean) => {
    this.rights.editEmployee = value;
    this.rights.disableEditRights = !value;

    this.rights.editRights = value ? this.rights.editRights : false;
  };

  @action
  changeViewTrips = (value: boolean) => {
    this.rights.viewTrips = value;
    this.rights.viewSelfTrips = value || this.rights.viewSelfTrips;
  };

  @action
  changeEditRights = (value: boolean) => {
    this.rights.editRights = value;
  };

  @action
  changeEditTravelPolicy = (value: boolean) => {
    this.rights.editTravelPolicies = value;
  };

  @action
  changeCanGetVouchers = (value: boolean) => {
    this.rights.canGetVouchers = value;
  };

  @action
  changeViewMiceEvents = (value: boolean) => {
    this.rights.viewMiceEvents = value;
  };

  @action
  changeCanGetInvoices = (value: boolean) => {
    this.rights.canGetInvoices = value;
  };

  @action
  changeCanGetDocuments = (value: boolean) => {
    this.rights.sendDocuments = value;
  };

  @action
  changeBuyInsurance = (value: boolean) => {
    this.rights.buyInsurance = value;
  };

  @action
  changeLoadingButton = () => {
    this.rights.loadingButton = !this.rights.loadingButton;
  };

  @action
  updateCopyUser = (id: string) => {
    this.rights.copyUser = id;
  };

  @action
  updateApproversForUser = (list: string[]) => {
    this.rights.approvers = list;
  };

  @action
  updateApproversERForUser = (list: string[]) => {
    this.rights.approversForExpenseReports = list;
    this.viewExpenseReportApprovers = list;
  };

  @action
  setERApprovers = (list: AppoversTypeLigth[]) => {
    this.viewExpenseReportApprovers = list.length ? list.map(({ Email }) => Email) : [];
  };

  @action
  updateViewApprovers = (list: string[]) => {
    this.viewApprovers = list;
  };

  @action
  updateDocuments = (value: string) => {
    this.rights.documents = value;
  };

  @action
  updateViewRights = () => {
    this.viewRights = { ...this.rights };
    this.viewApprovers = this.rights.approvers;
    this.newRights = false;
    this.updateCanLogin(true);
  };

  @action
  updateCanLogin = (value: boolean) => {
    this.canLogin = value;
  };

  @action
  updateErrorMsg = (value: boolean | string) => {
    if (!value) {
      this.errorMsg = DEFAULTERRORMSG;

      return;
    }

    this.errorMsg.title = isSmartAgent ? ERRORTEXT.TITLE_FOR_SA : ERRORTEXT.TITLE;
    this.errorMsg.error = ERRORTEXT.ERROR;
  };

  @action
  updateChosenTP = (value: string) => {
    this.chosenTP = value;
  };

  @action
  updateApprovalScheme = (value: string | any) => {
    this.chosenApprovalScheme = value;
  };

  @action
  clearRights = () => {
    if (this.viewRights) {
      this.rights = { ...this.viewRights };

      return;
    }

    this.rights = { ...DEAFAULTRIGHTS };
  };

  @action
  clearStore = () => {
    this.viewRights = null;
    this.rights = DEAFAULTRIGHTS;
    this.newRights = false;
    this.canLogin = false;
    this.sourceRights = null;
    this.users = DEFAULTUSERS;
    this.allApproveUsers = [];
    this.sourceApprovers = [];
    this.viewApprovers = [];
    this.viewExpenseReportApprovers = [];
    this.documents = DEFAULTDOCUMENTS;
    this.login = '';
    this.chosenTP = '';
    this.sourceChosenTP = '';
    this.errorMsg = DEFAULTERRORMSG;
    this.tpRightsByCopyUser = DEFAULTTPRIGHTS;
    this.approveERUsers = [];
    this.rights.approversForExpenseReports = [];
  };

  @action
  closeAccess = () => {
    this.viewRights = null;
    this.updateCanLogin(false);
    this.setLogin('');
    this.newRights = true;
    this.viewApprovers = [];
  };

  @action
  preparedApproversToSave = () => {
    const approvers = this.viewApprovers;

    return approvers.length ? this.allApproveUsers.filter((user: UsersType) => approvers.includes(user.value)).map(({ value }: { value: string }) => value) : [];
  };

  @action
  preparedApproversToSaveId = () => {
    const approvers = this.viewApprovers;

    return approvers.length ? this.allApproveUsersWithId.filter((user: UsersType) => approvers.includes(user.value)).map(({ value }) => value) : [];
  };

  @action
  preparedApproversExpenseReportsToSave = () => {
    const approvers = this.viewExpenseReportApprovers;

    return approvers.length ? this.approveERUsers.filter((user: UsersType) => approvers.includes(user.value)).map(({ value }: { value: string }) => value) : [];
  };

  @action
  preparedRightsToSave = () => {
    if (!this.sourceRights) {
      if (!this.viewRights) {
        return EMPTYRIGHTS;
      }

      return {
        ...CREATERIGHTS,
        ...preparedRightsToBackend(this.viewRights),
        Login: this.login,
      };
    }

    if (!this.viewRights) {
      return {
        ...this.sourceRights,
        ...DELETERIGHTS,
      };
    }

    const {
      AccountId,
      EmployeeId,
      UserId,
    } = this.sourceRights;

    return {
      ...EDITRIGHTS,
      AccountId,
      EmployeeId,
      UserId,
      ...preparedRightsToBackend(this.viewRights),
      Login: this.login,
    };
  };

  @action
  checkDifferencesRights = (chosenTP: string) => checkDifferences(
    this.sourceRights,
    this.viewRights,
    this.sourceApprovers,
    this.viewApprovers,
    this.sourceChosenTP,
    chosenTP,
  );
}

export default RightsStore;
