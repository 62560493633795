import React from 'react';
import { Select, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';

import styles from './index.module.css';

type ClassRuleProps = {
  value: string,
  isSapsan: boolean,
  isUnique: boolean | undefined,
  isTaxi: boolean,
  type: string,
  onChange: (value: string) => void,
};

const DICTIONARIES = {
  [SERVICETYPE.AIR]: () => [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM, label: getText('settings:travelPolicy.rules.class.types.economy') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM_OR_BUSINESS, label: getText('settings:travelPolicy.rules.class.types.economyOrBiz') },
  ],
  [SERVICETYPE.TAXI]: (_isSapsan: boolean, isApiV2: boolean) => (isApiV2 ? [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.LITE, label: getText('settings:travelPolicy.rules.class.taxi.lite') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.STANDART, label: getText('settings:travelPolicy.rules.class.taxi.standart') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.OPTIMUM, label: getText('settings:travelPolicy.rules.class.taxi.optimum') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.BUSINESS, label: getText('settings:travelPolicy.rules.class.taxi.business') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.CHILDREN, label: getText('settings:travelPolicy.rules.class.taxi.children') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.MINIVAN, label: getText('settings:travelPolicy.rules.class.taxi.minivan') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.CRUISE, label: getText('settings:travelPolicy.rules.class.taxi.cruise') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.PREMIER, label: getText('settings:travelPolicy.rules.class.taxi.premier') },
  ] : [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.ECONOMY, label: getText('settings:travelPolicy.rules.class.taxi.economy') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.COMFORT, label: getText('settings:travelPolicy.rules.class.taxi.comfort') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.COMFORT_PLUS, label: getText('settings:travelPolicy.rules.class.taxi.comfortPlus') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.BUSINESS, label: getText('settings:travelPolicy.rules.class.taxi.business') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.CHILDREN, label: getText('settings:travelPolicy.rules.class.taxi.children') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.MINIVAN, label: getText('settings:travelPolicy.rules.class.taxi.minivan') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.CRUISE, label: getText('settings:travelPolicy.rules.class.taxi.cruise') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.PREMIER, label: getText('settings:travelPolicy.rules.class.taxi.premier') },
  ]),
  [SERVICETYPE.TRANSFER]: () => [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.STANDARD, label: getText('settings:travelPolicy.rules.class.transfer.standard') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.TOLL_ROAD_STANDARD, label: getText('settings:travelPolicy.rules.class.transfer.tollRoadStandard') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.COMFORT, label: getText('settings:travelPolicy.rules.class.transfer.comfort') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.TOLL_ROAD_COMFORT, label: getText('settings:travelPolicy.rules.class.transfer.tollRoadComfort') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.BUSINESS_LITE, label: getText('settings:travelPolicy.rules.class.transfer.businessLite') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.BUSINESS, label: getText('settings:travelPolicy.rules.class.transfer.business') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.TOLL_ROAD_BUSINESS, label: getText('settings:travelPolicy.rules.class.transfer.tollRoadBusiness') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.MINIVAN, label: getText('settings:travelPolicy.rules.class.transfer.minivan') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.MINIVAN_VIP, label: getText('settings:travelPolicy.rules.class.transfer.minivanVip') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.MINIBUS, label: getText('settings:travelPolicy.rules.class.transfer.minibus') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.MINIBUS_VIP, label: getText('settings:travelPolicy.rules.class.transfer.minibusVip') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TRANSFER_CLASSES.EXECUTIVE, label: getText('settings:travelPolicy.rules.class.transfer.executive') },
  ],
  [SERVICETYPE.TRAIN]: (isSapsan: boolean) => (isSapsan ? [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM, label: getText('settings:travelPolicy.rules.class.types.economy') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM_OR_BUSINESS, label: getText('settings:travelPolicy.rules.class.types.economyOrBiz') },
  ] : [
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM, label: getText('settings:travelPolicy.rules.class.types.coupe') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM_OR_BUSINESS, label: getText('settings:travelPolicy.rules.class.types.superVip') },
    { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.RESERVED_SEATS, label: getText('settings:travelPolicy.rules.class.types.reservedSeats') },
  ]),
};

const LABELS = {
  SAPSAN: getText('settings:travelPolicy.rules.class.sapsan'),
  CHEAPER: getText('settings:travelPolicy.rules.class.cheaper'),
  ONLY: getText('settings:travelPolicy.rules.class.only'),
};

const ClassRule = ({
  isUnique,
  value,
  onChange,
  isSapsan,
  isTaxi,
  type,
}: ClassRuleProps) => {
  const { featureFlagsService: { getNewTaxiClasses } } = useServices(['FeatureFlags']);

  const prepareDescription = () => (isSapsan ? LABELS.SAPSAN : LABELS.CHEAPER);

  const prepareDescriptionTaxi = () => (isTaxi ? LABELS.CHEAPER : null);

  const dictionary = DICTIONARIES[type](isSapsan, getNewTaxiClasses());
  const description = type === SERVICETYPE.TRAIN && prepareDescription();
  const taxiDescription = (type === SERVICETYPE.TAXI || type === SERVICETYPE.TRANSFER) && prepareDescriptionTaxi();

  return (
    <div className={ styles.wrap } data-qa={ QA_ATTRIBUTES.settings.travelPolicy.addRules.rules.classRule }>
      <Text>{ LABELS.ONLY }</Text>
      <Select
        disabled={ !!isUnique }
        theme='open-blue'
        className={ styles.select }
        items={ dictionary }
        onChange={ onChange }
        value={ value }
      />
      <Text>{ description }</Text>
      <Text>{ taxiDescription }</Text>
    </div>
  );
};

export { ClassRule };
