import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Select, Datepicker, Text, BackLink } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { UseStoresInterface, withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import Report from '../../bi/services/report';
import Notification from '../../bi/services/notification';
import Workspace from '../../bi/services/workspace';
import Payment from '../../bi/services/payment';

import OperationsTable from '../../components/OperationsTable';

import { isSmartAgent } from '../../bi/utils/env';

import { PAYMENT } from '../../bi/constants/payment';

import { IMatch } from '../../bi/types/shared';
import { IOperationsList, IOperationsTotal } from '../../bi/types/report';
import { ICompany } from '../../bi/services/workspace/types';

import styles from './styles/transactions.module.css';

const LABELS = {
  TITLE: getText('reports:transactions.title'),
  BACK_LINK: getText('reports:transactions.back'),
};

interface IStores extends Pick<UseStoresInterface, 'cloudPaymentsStore'> {}

interface ITransactionReportProps {
  match: IMatch,
  reportService: Report,
  notificationService: Notification,
  workspaceService: Workspace,
  paymentService: Payment,
  stores: IStores,
}

interface ITransactionReportState {
  loading: boolean,
  companiesItems: ICompany[],
  currentCompany: number,
  total: IOperationsTotal | null,
  items: IOperationsList[],
  more: boolean,
  startDate: Moment,
  endDate: Moment,
  maxDate: Moment,
  showFinanceDetails: boolean,
  companyName: string,
  companyEmail: string,
  companyInn: string,
  companyId: number,
  companies: ICompany[],
  isDemo: boolean,
  multi: boolean,
}

@withStores([MOBX_STORES.CLOUD_PAYMENTS])
@observer
class TransactionReport extends Component<ITransactionReportProps, ITransactionReportState> {
  unsubscribeFn: () => void;

  constructor(props: ITransactionReportProps) {
    super(props);

    const {
      items,
      total,
      more,
      startDate,
      endDate,
      maxDate,
    } = props.reportService.getOperations();
    const { Multi,
      Companies,
      CompanyName,
      Email,
      CompanyInn,
      CompanyId,
      IsDemo,
    } = props.workspaceService.get();

    const companiesItems = Multi
      ? Companies.filter(({ IsShowReporting }) => IsShowReporting).map(value => ({
        label: value.ShortCompanyName || value.CompanyName,
        value: value.CompanyId,
      }))
      : [];

    const currentCompany = parseInt(props.match.params.companyId, 10);
    const showFinanceDetails = this.showFinanceDetails(Companies, currentCompany);

    this.state = {
      loading: true,
      // @ts-ignore
      companiesItems,
      currentCompany,
      total,
      items,
      more,
      startDate,
      endDate,
      maxDate,
      showFinanceDetails,
      companyName: CompanyName,
      companyEmail: Email,
      companyInn: CompanyInn,
      companyId: CompanyId,
      companies: Companies,
      isDemo: IsDemo,
      multi: Multi,
    };
  }

  componentDidMount() {
    const { reportService } = this.props;

    this.unsubscribeFn = reportService.subscribeOperations(this.updateOperations);

    const { startDate, endDate, currentCompany } = this.state;

    reportService.loadTotalOperationsByDate(currentCompany, startDate, endDate);
  }

  componentDidUpdate() {
    // @ts-ignore
    window.onbeforeunload = this.props.stores.cloudPaymentsStore.cantLeave ? (e) => {
      e.preventDefault();

      return '';
    } : undefined;
  }

  componentWillUnmount() {
    if (this.unsubscribeFn) this.unsubscribeFn();

    // @ts-ignore
    window.onbeforeunload = undefined;
  }

  updateOperations = ({ loading, items, total, more }: ITransactionReportState) => {
    this.setState({
      loading,
      items,
      total,
      more,
    });
  };

  loadOperations = () => {
    const { startDate, endDate, currentCompany } = this.state;

    this.props.reportService.loadTotalOperationsByDate(currentCompany, startDate, endDate);
  };

  handleLoadOperations = () => {
    this.setState({
      loading: true,
    }, this.loadOperations);
  };

  handleChangeDate = (field: string, value: Moment | string | null) => {
    // @ts-ignore
    const currentValue = this.state[field];

    if (currentValue && !currentValue.isSame(value)) {
      // @ts-ignore
      this.setState({ [field]: value }, this.handleLoadOperations);
    }
  };

  handleChangeCompanyId = (currentCompany: number) => this.setState({ currentCompany }, this.handleLoadOperations);

  showFinanceDetails = (companies: ICompany[], companyId: number): boolean => {
    const company = companies.find(item => item.CompanyId === companyId);

    return !!company && !!company.ShowFinanceDetails;
  };

  handleChangeShowFinanceDetails = (companyId: number) => {
    const { companies } = this.state;

    this.setState({
      showFinanceDetails: this.showFinanceDetails(companies, companyId),
    });
  };

  handleChangeCompany = (value: number) => {
    this.handleChangeCompanyId(value);
    this.handleChangeShowFinanceDetails(value);
  };

  renderCompanySelect() {
    const { companiesItems, currentCompany } = this.state;

    if (!companiesItems?.length) {
      return null;
    }

    return (
      <div className={ styles.select_wrap }>
        <Select
          className={ styles.select }
            // @ts-ignore
          items={ companiesItems }
          value={ currentCompany }
          onChange={ this.handleChangeCompany }
          theme='default-large'
          excludeValue
          smooth
        />
      </div>
    );
  }

  renderDatePickers = () => {
    const { startDate, endDate, maxDate } = this.state;

    return (
      <div className={ styles.calendars_wrap }>
        <Datepicker
          value={ startDate }
          onChange={ (value) => this.handleChangeDate('startDate', value) }
          max={ maxDate }
          inputTheme='open'
          inputClassName={ styles.input_wrap }
          isDuration
          durationDates={ [startDate, endDate] }
        />
        &mdash;
        <Datepicker
          value={ endDate }
          onChange={ (value) => this.handleChangeDate('endDate', value) }
          min={ startDate }
          max={ maxDate }
          inputTheme='open'
          inputClassName={ styles.input_wrap }
          isDuration
          durationDates={ [startDate, endDate] }
        />
      </div>
    );
  };

  render() {
    const { reportService, notificationService, paymentService, stores: { cloudPaymentsStore: { cantLeave } } } = this.props;
    const {
      items,
      total,
      loading,
      companiesItems,
      currentCompany,
      showFinanceDetails,
      companyEmail,
      companyName,
      companyInn,
      isDemo,
      companies,
      multi,
    } = this.state;

    const companiesHtml = this.renderCompanySelect();
    const datePickersHtml = this.renderDatePickers();

    return (
      <div className={ styles.wrap }>
        <Prompt
          when={ cantLeave }
          message={ PAYMENT.EXITMESSAGE }
        />
        <BackLink
          link={ '/reports/main' }
          text={ LABELS.BACK_LINK }
          alternativeDesign={ isSmartAgent }
        />
        <div className={ styles.main }>
          <Text type='bold_32' className={ styles.title }>{LABELS.TITLE}</Text>
          { datePickersHtml }
          { companiesHtml }
        </div>
        <OperationsTable
          multi={ multi }
          items={ items }
          total={ total }
          loading={ loading }
          currentCompany={ currentCompany }
          reportService={ reportService }
          paymentService={ paymentService }
          notRenderDetails
          sendNotification={ opts => notificationService.send(opts) }
          showFinanceDetails={ showFinanceDetails }
          companyEmail={ companyEmail }
          companyName={ companyName }
          companyInn={ companyInn }
            // @ts-ignore
          companiesItems={ companiesItems }
          isDemo={ isDemo }
            // @ts-ignore
          allCompanies={ companies }
        />
      </div>
    );
  }
}

export default TransactionReport;
