const OPERATIONSACTION = {
  STARTLOADOPERATION: 'operations.start',
  LOADCOMPLETE: 'operations.loaded',
  LOADRANGEOPERATIONSCOMPLETE: 'operations.range.loaded',
  LOADBALANCE: 'balance',
  INVOICE_REQUISITES: 'invoiceRequisites',
  LOADBALANCEDATARANGE: 'balanceByDateRange',
  GET_ALL_INVOICE: 'get.all.invoice',
};

const UPDACTION = {
  STARTLOADUPD: 'upd.start',
  LOADCOMPLETE: 'upd.loaded',
  GET_TOTAL_COUNT_DOCUMENT_PACKAGES: 'get.total.count.document.packages',
  GET_DOCUMENT_PACKAGES_BY_PAGE: 'get.document.package.by.page',
};

export { OPERATIONSACTION, UPDACTION };
export default OPERATIONSACTION;
