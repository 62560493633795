import React, { Dispatch, SetStateAction } from 'react';
import { History } from 'history';
import { Icon, Text, Button, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { AirItemApprove } from './components/AirItemApprove';

import { createFromToAirTicketChange } from '../../../../bi/utils/changeAirTrip';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import ROUTES from '../../../../bi/constants/routes';
import { AirlineCacheItem } from '../../../../bi/types/airline';
import { ChatService } from '../../../../bi/types/chat';
import { OrderItem } from '../../../../bi/types/order';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES } from '../../../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:changeAirTrip.confirmationChange.title'),
  INFO_TRAVEL_ASSISTANT: getText('components:changeAirTrip.confirmationChange.infoTravelAssistant'),
  SEND_REQUEST: getText('components:changeAirTrip.sendRequest'),
  DEMO_TOOLTIP: getText('components:changeAirTrip.demo'),
  BACK: getText('components:changeAirTrip.back'),
  NEW_DATA: getText('components:changeAirTrip.changeDocument.newData'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        approve: {
          sendRequest,
          back,
          icon,
          title,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

interface IChangeAirTripApproveResultProps {
  history: History;
  chatService: ChatService;
  newTicket?: AirlineCacheItem | null;
  fareId: string;
  tripItem?: OrderItem | null;
  tripId: string;
  isDemo: boolean;
  onShowApproveChange: Dispatch<SetStateAction<boolean>>;
}

const ChangeAirTripApproveResult = ({
  history,
  chatService,
  newTicket = null,
  fareId,
  tripItem = null,
  tripId,
  onShowApproveChange,
  isDemo,
}: IChangeAirTripApproveResultProps) => {
  const itemData = JSON.parse(tripItem?.ActualVersion.JsonData || '');
  const newFare = newTicket?.Fares.find(fare => fare.Id === fareId);

  const createMessage = () => {
    const fromTo = createFromToAirTicketChange(
      newTicket as AirlineCacheItem,
      newFare?.Name as string,
    );
    const message = `
      \n---------------
      ${LABELS.NEW_DATA}:${fromTo}
    `;

    return message;
  };

  const handleEditTripItem = () => {
    if (tripItem) {
      chatService.requestOnChangeByOrder(tripItem, createMessage());
      chatService.sendTypeForAirline(tripItem.Id);
    }
  };

  const handleSendRequest = () => {
    handleEditTripItem();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_CONFIRM_PRESSED(Number(tripId), ANALYTIC_SERVICE_TYPES.AIR),
    );

    history.push(ROUTES.CHANGE_AIR_TRIP.TRIP(tripId));
  };

  return (
    <div className={ styles.wrapper }>
      <Text qaAttr={ title } type='bold_32'>
        { LABELS.TITLE }
      </Text>
      <AirItemApprove itemData={ itemData } />
      <div className={ styles.arrow_wrapper }>
        <Icon
          qaAttr={ icon }
          className={ styles.arrow }
          type='arrowDownApproveStage'
          color='black'
        />
      </div>
      <AirItemApprove
        isNewTicket
        itemData={ newTicket }
        metaNewTicket={ { ...itemData, Fare: newFare } }
      />
      <Text className={ styles.info }>
        { LABELS.INFO_TRAVEL_ASSISTANT }
      </Text>
      <div className={ styles.action }>
        <Tooltip
          show={ isDemo }
          renderContent={ () => (
            <Text
              className={ styles.tooltip_text }
              color='white'
              type='NORMAL_14_130'
            >
              { LABELS.DEMO_TOOLTIP }
            </Text>
          ) }
        >
          <Button
            qaAttr={ sendRequest }
            className={ styles.button }
            type='secondary'
            disabled={ isDemo }
            onClick={ handleSendRequest }
          >
            { LABELS.SEND_REQUEST }
          </Button>
        </Tooltip>
        <Button
          qaAttr={ back }
          className={ styles.button }
          type='textual'
          onClick={ () => onShowApproveChange(false) }
        >
          { LABELS.BACK }
        </Button>
      </div>
    </div>
  );
};

export { ChangeAirTripApproveResult };
