import parseJsonString from './parseJsonString';

import { SERVICETYPE } from '../constants/serviceType';

import { IItem } from '../types/cart';
import { AdditionaService, AirRoute, IServiceAdditionalInfo } from '../types/airline';

interface ISelectedBaggage {
  TravellerId: number,
  Service: IServiceAdditionalInfo,
}

interface TSelectedBaggage { [ID: string]: AdditionaService[] }

export const getSelectedBaggageCart = (item: IItem) => {
  const { ServiceType, Data, Id: cartId } = item;
  const isNotAirlineService = ServiceType !== SERVICETYPE.AIR;

  if (isNotAirlineService) {
    return [];
  }

  const itemData = parseJsonString(Data);
  const { Routes = [] } = itemData;
  const selectedBaggageInCart = Routes
    .reduce((acc: TSelectedBaggage, { ID, Segments = [] }: AirRoute) => {
      acc[ID] = Segments
        .map(({ AdditionalServices = [] }) => AdditionalServices).flat();

      return acc;
    }, {});

  return Object.keys(selectedBaggageInCart).flat().map((routeId) => {
    const route = selectedBaggageInCart[routeId];

    if (!route) {
      return [];
    }

    return route.map(({ TravellerId: employeeId, Service }: ISelectedBaggage) => ({
      ticketId: cartId,
      employeeId,
      routeId,
      price: Service?.Price,
      routes: Routes,
      providerDescription: Service?.ProviderDescription,
      isInitial: true,
    }));
  });
};
