import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, IconButton, Text, ItemPanel } from 'new-ui';

import { getText } from '../../../../../i18n';

import { getTrainNameByNumber, getNamesOfTransfer, isDepartureChanged } from '../../../../bi/utils/train';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { formatDate, isSameDate, isSameDay } from '../../../../bi/utils/formatDate';
import { applyTrainTPTrainWithTransfer, commonTPTrainWithTransfer } from '../../../../bi/utils/travelPolicy';
import { isSmartAgent } from '../../../../bi/utils/env';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import CLASSNAMESFORTESTS from '../../../../bi/constants/classnamesfortests';
import ANIMATION from '../../../../bi/constants/animation';
import FAVORITESACTION from '../../../../bi/constants/favorites';
import { DATEFORMATS, PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { LinkAction } from '../../../../components/LinkAction';
import { FavoriteAction } from '../../../../components/FavoriteAction';
import { NameErrorsTravelPolicy } from '../../../../components/NameErrorsTravelPolicy';

import { TrainSearchItem } from '../TrainSearchItem';
import { TrainItemRoutes } from '../TrainItemRoutes';
import { TrainItemDetails } from '../TrainItemDetails';
import { FreePlacesDetails } from '../FreePlacesDetails';

import {
  ICurrentTicket,
  RouteDetails,
  Train,
  ITrainTicketWithTransferProps,
  TrainTicketState,
} from '../types';

import { IInternalSearchTrain } from '../../../../bi/types/trains';

import styles from './ticketWithTransfer.module.css';

const LABELS = {
  TRAIN_WITH_NUMBER: getText('trains:ticket.train'),
  CHOOSE_CAR: getText('trains:ticket.chooseCar'),
  DATE: (mscDate: string, format: string) => getText('trains:ticket.date', { mscDate, format }),
  INFO_STATION: getText('trains:results.details.railwayStationChanging'),
  ATTENTION: getText('trains:results.details.attention'),
};

const isFavorite = (currentTicket: ICurrentTicket) => currentTicket.Trains.every(({ FavoriteId }) => !!FavoriteId);

@withStores([MOBX_STORES.TRAIN_SEARCH, MOBX_STORES.TRAIN_TICKETS])
@observer
class TrainTicketWithTransfer extends Component<ITrainTicketWithTransferProps, TrainTicketState> {
  static defaultProps = {
    onCopyLinkTrip: () => {},
    onGetLinkTrip: () => {},
    onAddToFavorite: () => {},
    onSelectTrain: () => {},
  };

  state = {
    animationClass: '',
    showDetails: false,
    currentTicket: this.props.tickets[0],
  };

  componentDidUpdate(prevProps: ITrainTicketWithTransferProps) {
    if (prevProps.tickets[0].TripDuration !== this.props.tickets[0].TripDuration) {
      this.setState({
        showDetails: false,
        currentTicket: this.props.tickets[0],
      });
    }
  }

  getQAAttrs = () => {
    const { ind } = this.props;

    const {
      first,
      buttonCart,
      time,
      duration,
      station,
      date,
      numberTransfers,
      price,
    } = QA_ATTRIBUTES.search.train.transfer.item;

    const isFirstEl = ind === 0;
    const firstElItem = isFirstEl ? first : '';
    const firstElButton = isFirstEl ? buttonCart : '';
    const firstElTimeFrom = isFirstEl ? time.from : '';
    const firstElTimeTo = isFirstEl ? time.to : '';
    const firstElDuration = isFirstEl ? duration : '';
    const firstElStationFrom = isFirstEl ? station.from : '';
    const firstElStationTo = isFirstEl ? station.to : '';
    const firstElDateFrom = isFirstEl ? date.from : '';
    const firstElDateTo = isFirstEl ? date.to : '';
    const firstElTransfer = isFirstEl ? numberTransfers : '';
    const firstElPrice = isFirstEl ? price : '';

    return {
      firstElItem,
      firstElButton,
      firstElTimeFrom,
      firstElTimeTo,
      firstElDuration,
      firstElStationFrom,
      firstElStationTo,
      firstElDateFrom,
      firstElDateTo,
      firstElTransfer,
      firstElPrice,
    };
  };

  toggleShowDetails = () => this.setState({ showDetails: !this.state.showDetails });

  handleAnimationEnd = () => this.setState({ animationClass: '' });

  handleItemRouteClick = (item: RouteDetails) => this.setState({ currentTicket: item });

  handleClick = () => {
    const {
      idRequestItem,
      history,
      onSaveCacheTickets,
      stores: { trainTicketsStore: { searchId } },
    } = this.props;
    const { currentTicket: { Trains } } = this.state;

    onSaveCacheTickets(Trains);

    const { TrainId } = Trains[0];
    const linkUrl = `/search/train/${searchId}/${TrainId}`;

    if (idRequestItem > 0) {
      return history.push({ pathname: linkUrl, search: 'is_request=true' });
    }

    return history.push({ pathname: linkUrl });
  };

  handleChangeFavorite = (action: string) => {
    const { onAddToFavorite, onChangeFavorite } = this.props;
    const { currentTicket, currentTicket: { Trains } } = this.state;

    if (action === FAVORITESACTION.ADD) {
      const values = Trains.map(({ TrainNumber }: Train) => parseInt(TrainNumber, 10)).join(', ');

      this.setState({
        animationClass: ANIMATION.PULSE,
      });

      if (onAddToFavorite) {
        onAddToFavorite(MainAnalytic.LABELS.TRAINS.SEARCHRESULTS, values);
      }
    }

    onChangeFavorite(currentTicket, action);
  };

  renderHeader = () => {
    const {
      travelPolicyList,
      onGetLink,
      onCopyLinkTrip,
      onGetLinkTrip,
      ind,
      stores: { trainTicketsStore: { searchId, filters: { selectedTravelPolicy } } },
    } = this.props;
    const { currentTicket, currentTicket: { Trains } } = this.state;

    const names = Trains.map((item: IInternalSearchTrain) => {
      const { TrainNumberLocal, TrainNumber, TrainName } = item;
      const trainNameIdentifier = getTrainNameByNumber(item);

      const trainName = TrainName || trainNameIdentifier || '';
      const trainNumber = TrainNumberLocal || TrainNumber;

      return `${trainNumber} ${trainName ? `(${trainName})` : ''}`;
    });

    const fullTrainNumber = `${LABELS.TRAIN_WITH_NUMBER} ${names.join(' + ')}`;

    const qaAttrFirstElTrainNumber = ind === 0 ? QA_ATTRIBUTES.search.train.transfer.item.number : '';

    const favoriteHtml = !isSmartAgent && (
      <FavoriteAction
        className={ `${styles.link} ${CLASSNAMESFORTESTS.TRAINS.TOFAVORITE}` }
        id={ isFavorite(currentTicket) }
        onClick={ this.handleChangeFavorite }
      />
    );

    return (
      <div className={ styles.header }>
        <Text type='NORMAL_18' qaAttr={ qaAttrFirstElTrainNumber }>
          { fullTrainNumber }
        </Text>
        <div className={ styles.actions }>
          <NameErrorsTravelPolicy
            oneTravelPolicy
            hiddenNames
            item={ commonTPTrainWithTransfer(Trains) }
            applyTP={ applyTrainTPTrainWithTransfer(Trains, selectedTravelPolicy) }
            travelPolicyList={ travelPolicyList }
            selectedTravelPolicy={ selectedTravelPolicy }
          />
          <LinkAction
            item={ currentTicket }
            className={ `${styles.link} ${CLASSNAMESFORTESTS.TRAINS.GETLINK}` }
            generateLink={ (item) => onGetLink(item, searchId.toString()) }
            onCopyToClipboard={ onCopyLinkTrip }
            onClick={ onGetLinkTrip }
          />
          { favoriteHtml }
        </div>
      </div>
    );
  };

  renderTransfers = () => {
    const { currentTicket: { Trains } } = this.state;

    const newList = [...Trains];
    newList.splice(0, 1);

    const html = ({ TrainId, StationFrom, DepartureDateLocal, DepartureDate }: IInternalSearchTrain, ind: number) => {
      const localTime = `${formatDate(DepartureDateLocal, PATTERN.DAY_OF_MONTH)}, ${formatDate(DepartureDateLocal, DATEFORMATS.TIME)}`;
      const mscDate = isSameDay(DepartureDateLocal, DepartureDate) ? `${formatDate(DepartureDate, PATTERN.DAY_OF_MONTH)}, ` : '';
      const mscTime = !isSameDate(DepartureDateLocal, DepartureDate) ?
        LABELS.DATE(mscDate, formatDate(DepartureDate, DATEFORMATS.TIME)) :
        '';

      return (
        <Text key={ `transfer_${TrainId}_${ind}` } type='NORMAL_14_120' className={ styles.item_transfer }>
          <span className={ styles.semi_bold }>{getNamesOfTransfer(newList.length, ind)}:</span>
          <span className={ styles.station }> {StationFrom.toLocaleLowerCase()}</span>, {localTime}{mscTime}
        </Text>
      );
    };

    const renderWarningTransfer = () => {
      const displayWarningTransfer = Trains.some((item: IInternalSearchTrain, index: number) => isDepartureChanged(index, item, Trains));

      if (!displayWarningTransfer) {
        return null;
      }

      return (
        <Text
          className={ styles.warning_transfer }
          color='red'
          type='SEMIBOLD_14'
        >
          {LABELS.ATTENTION} {LABELS.INFO_STATION}
        </Text>
      );
    };

    return (
      <div className={ styles.transfers }>
        { newList.map(html) }
        { renderWarningTransfer() }
      </div>
    );
  };

  render() {
    const {
      ind,
      tickets,
      stores: {
        trainTicketsStore: {
          filters: {
            selectedTravelPolicy,
          },
        },
        trainSearchStore: {
          travellers,
        },
      },
      appService,
    } = this.props;
    const {
      currentTicket,
      currentTicket: {
        TripDuration,
        Classes,
        Trains,
      },
      animationClass,
      showDetails,
    } = this.state;

    const typeIcon = showDetails ? 'arrowsUpRound' : 'arrowsDownRound';

    const detailsHtml = showDetails &&
      <TrainItemDetails
        appService={ appService }
        details={ Trains }
        travellers={ travellers }
      />;
    const transfersHtml = !showDetails && tickets.length === 1 && this.renderTransfers();

    const anotherTransfers = tickets.length > 1 && (
      <TrainItemRoutes
        routes={ tickets }
        currentRoute={ currentTicket }
        onClick={ this.handleItemRouteClick }
      />
    );

    const firstTicket = Trains[0];
    const lastTicket = Trains[Trains.length - 1];
    const transfersTime = Trains.reduce((acc: number, { ChangeDuration }:IInternalSearchTrain) => acc + ChangeDuration, 0);
    const countTransfers = Trains.length - 1;
    const { agentMode } = appService.get();

    const {
      firstElItem,
      firstElPrice,
      firstElButton,
    } = this.getQAAttrs();

    const commonTicket = {
      DepartureDate: firstTicket.DepartureDate,
      DepartureDateLocal: firstTicket.DepartureDateLocal,
      ArrivalDate: lastTicket.ArrivalDate,
      ArrivalDateLocal: lastTicket.ArrivalDateLocal,
      StationFrom: firstTicket.StationFrom,
      StationTo: lastTicket.StationTo,
      TravelTime: TripDuration + transfersTime,
      TransfersTime: transfersTime,
      CountTransfers: countTransfers,
    };

    return (
      <ItemPanel
        key={ `train_${ind}` }
        index={ ind }
        animationClass={ animationClass }
        className={ CLASSNAMESFORTESTS.TRAINS.ITEM }
        onAnimationEnd={ this.handleAnimationEnd }
        renderHeader={ this.renderHeader }
        favorite={ isFavorite(currentTicket) }
        warning={ applyTrainTPTrainWithTransfer(Trains, selectedTravelPolicy) }
        qaAttr={ firstElItem }
      >
        <div className={ styles.wrapper_item }>
          <div className={ styles.item }>
            <div className={ styles.wrapper_info }>
              <div className={ styles.trains }>
                <TrainSearchItem
                  isTicketWithTransfer
                  ticket={ commonTicket }
                  qaAttr={ this.getQAAttrs() }
                >
                  <FreePlacesDetails
                    classes={ Classes }
                    travellers={ travellers }
                    qaAttrPrice={ firstElPrice }
                    agentMode={ agentMode }
                  />
                </TrainSearchItem>
                { detailsHtml }
              </div>
            </div>
            { transfersHtml }
            { anotherTransfers }
            <div className={ styles.arrow }>
              <IconButton
                className={ styles.icon }
                iconType={ typeIcon }
                onClick={ this.toggleShowDetails }
              />
            </div>
          </div>
          <div className={ styles.actions }>
            <div className={ styles.button }>
              <Button onClick={ this.handleClick } qaAttr={ firstElButton }>
                { LABELS.CHOOSE_CAR }
              </Button>
            </div>
          </div>
        </div>
      </ItemPanel>
    );
  }
}

export { TrainTicketWithTransfer };
