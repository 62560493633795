import { groupBy } from '../../../../../bi/utils/groupBy';
import { getRouteTitleAdditionalBaggage } from '../../../../../bi/utils/getRouteTitleAdditionalBaggage';

import { ADDITIONAL_SERVICES } from '../../../../../bi/constants/airline';

import { AirRoute } from '../../../../../bi/types/airline';

interface IGetPaidBaggageInfoProps {
  itemData: { Routes: AirRoute[] };
  labels: string;
}

interface IPaidBaggageInfo { [key: string]: number }

/** Утилита для сбора данных для отрисовки выбранных багажей */
const getPaidBaggageInfo = ({
  itemData,
  labels,
}: IGetPaidBaggageInfoProps) => {
  const routesBaggagePrice: IPaidBaggageInfo = itemData.Routes.reduce((acc, route) => {
    let totalPriceForRoute = 0;

    route.Segments.forEach((segment) => {
      const baggageServices = (segment.AdditionalServices || [])
        .filter(service => service.Type === ADDITIONAL_SERVICES.Baggage);

      if (baggageServices.length > 0) {
        baggageServices.forEach(service => {
          totalPriceForRoute += service.Service.Price;
        });
      }
    });

    if (!totalPriceForRoute) {
      return acc;
    }

    /**  Общая стоимость багажа для всех сегментов маршрута */
    return ({
      ...acc,
      [route.ID]: totalPriceForRoute,
    });
  }, {});

  /** Получаем первый и последний сегменты из каждого маршрута для составления названия роута */
  const routeSegments = itemData.Routes.map(route => ({
    routeId: route.ID,
    firstSegment: route.Segments[0],
    lastSegment: route.Segments[route.Segments.length - 1],
  }));

  /** Извлекаем дополнительные услуги только для последнего сегмента с ценами на багаж */
  const paidBaggage = routeSegments
    .flatMap(({ lastSegment, routeId }) => lastSegment.AdditionalServices.map(service => ({ ...service, routeId })))
    .filter(service => service.Type === ADDITIONAL_SERVICES.Baggage);

  if (!paidBaggage.length) return null;

  const paidBaggageBySegments = groupBy(paidBaggage, ({ SegmentId }) => String(SegmentId));

  return Object.keys(paidBaggageBySegments).map((key, index) => {
    const currentSegment = routeSegments
      ?.map(({ lastSegment }) => lastSegment)
      ?.find(({ ID }) => ID === Number(key));

    const id = paidBaggageBySegments[key][0].routeId;

    const currentRoute = routeSegments?.find(({ lastSegment }) => lastSegment.ID === Number(key));

    const routeTitle = currentRoute
    && getRouteTitleAdditionalBaggage([currentRoute.firstSegment, currentRoute.lastSegment], false);

    return ({
      keyInd: `${currentSegment?.ID}_${index}`,
      serviceLabel: labels,
      routeTitle,
      customAnalytics: null,
      services: paidBaggageBySegments[key],
      segmentPrice: routesBaggagePrice[id] || 0,
    });
  });
};

export { getPaidBaggageInfo };
