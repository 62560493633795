import CONFIG from '../../../../config';

import { api } from '../../apiV2';

import { widget } from './widget';

import { PaymentMethodsStore, CloudPaymentStoreType } from './store';

import { calculationAmount } from '../../utils/cloudPayments';
import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withIM } from '../utils/dectrators';

import {
  NOT_NUMBERS,
  ONLY_NUMBERS_OR_EMPTY,
  FOUR_GROUPS_FOUR_NUMBERS,
  GROUP_NOT_NUMBERS,
} from '../../constants/regExp';

import {
  ERRORS_CARD_FORM,
  PAYMENT_INFO,
  PAYMENT_METHODS,
  PaymentMethodType,
} from '../../types/paymentMethod';

class CloudPaymentService {
  store: CloudPaymentStoreType;
  api: typeof api.employeePersonalPayment;

  networkStatesStore = new NetworkStatesStore<'setPaymentType'>();

  constructor() {
    this.store = PaymentMethodsStore;
    this.api = api.employeePersonalPayment;
  }

  get currentPaymentMethod() {
    return this.store.paymentMethod;
  }

  get isPersonalPaymentMethod() {
    return this.store.paymentMethod !== PAYMENT_METHODS.ORGANIZATION_ACCOUNT;
  }

  getAccountId = () => this.store.accountId;

  setAccountId = (accountId: string) => {
    this.store.setAccountId(accountId);
  };

  clearStore = () => {
    this.store.clearStore();
  };

  @bound
  @withIM((o) => o.networkStatesStore.withLoaderFlow('setPaymentType'))
  async setPaymentMethod(cartId: number, paymentMethod: PaymentMethodType) {
    const data = await this.api.setPaymentMethod(cartId, paymentMethod);

    this.store.setPaymentMethod(data);
  }

  openPaymentWidget = (paymentInfo: Omit<PAYMENT_INFO, 'accountId'>, paymentType?: string) => {
    widget({ ...paymentInfo, accountId: this.getAccountId() }, paymentType);
  };

  onChangeCardNumber = (value: string) => {
    let cardNumberWithSpaces = value;

    const cardValue = cardNumberWithSpaces
      .replace(NOT_NUMBERS, '')
      .match(FOUR_GROUPS_FOUR_NUMBERS);

    if (cardValue) {
      cardNumberWithSpaces = !cardValue[2]
        ? cardValue[1]
        : `${cardValue[1]}-${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ''
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
    }

    const numbers = cardNumberWithSpaces.replace(GROUP_NOT_NUMBERS, ' ');

    this.store.setCardNumber(numbers);
  };

  onChangeExpDate = (value: string) => {
    let expDate = value.replace(NOT_NUMBERS, '');

    if (expDate.length >= 3) {
      expDate = `${expDate.substring(0, 2)}/${expDate.substring(2, 4)}`;
    }

    if (expDate.length >= 1 && (parseInt(expDate[0], 10) > 1 || parseInt(expDate[1], 10) > 2)) {
      expDate = expDate[0] === '1' ? '1' : '';
      expDate = expDate[1] > '2' ? '' : expDate[1];
    }

    this.store.setExpDate(expDate);
  };

  onChangeCvv = (value: string) => {
    const cvv = value.replace(NOT_NUMBERS, '');

    if (value.length < 4) {
      this.store.setCvv(cvv);
    }
  };

  onChangeEmail = (value: string) => {
    this.store.setEmail(value);
  };

  onChangeAmountOfDeposit = (value: string) => {
    const { transactionFee } = calculationAmount(value, true);

    if (value?.length) {
      // @ts-ignore
      this.store.setCardFormErrors({ ...this.store.cardFormErrors, emptyAmount: '' });
    }

    if (ONLY_NUMBERS_OR_EMPTY.test(value)) {
      this.store.setAmountOfDeposit(value);
      this.store.setCommission(String(transactionFee));
    }
  };

  validatePaymentForm = () => {
    const {
      cardForm: { cvv, cardNumber, expDate },
      amountOfDeposit,
      setCardFormErrors,
    } = this.store;

    if (!amountOfDeposit?.length) {
      setCardFormErrors({ ...this.store.cardFormErrors, emptyAmount: 'EmptyAmount' });
    }

    return cvv?.length && cardNumber?.length && expDate?.length && amountOfDeposit?.length;
  };

  createPaymentCryptogram = async () => {
    this.store.setIsLoading(true);

    const { cvv, cardNumber, expDate } = this.store.cardForm;
    const splitDate = expDate.split('/');
    const expDateMonth = splitDate[0];
    const expDateYear = splitDate[1] || '';

    // @ts-ignore
    const checkout = new cp.Checkout({
      publicId: CONFIG.CLOUDPAYMENTS.API_KEY,
    });

    try {
      const result = await checkout.createPaymentCryptogram({
        cvv,
        cardNumber,
        expDateMonth,
        expDateYear,
      });

      this.store.setCryptogram(result);
      this.store.setCardFormErrors({});

      return result;
    } catch (e) {
      this.store.setCardFormErrors(e as ERRORS_CARD_FORM);

      return e;
    } finally {
      this.store.setIsLoading(false);
    }
  };

  payViaApi = async () => {
    const { email, cryptogram, amountOfDeposit, commission } = this.store;

    try {
      const result = await this.api.payViaAPI({
        CardCryptogramPacket: cryptogram,
        Amount: Number(amountOfDeposit),
        Commission: Number(commission),
        Email: email,
      });

      this.clearStore();

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default CloudPaymentService;
