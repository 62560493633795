import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Collapsible, DotLoading, Text } from 'new-ui';

import { useStores } from '../../../../../bi/context';
import { useServices } from '../../../../../bi/context/services';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { getText } from '../../../../../../i18n';

import { AirlineAdditionalServicesBaggageRow } from './components';

import MoneyFormat from '../../../../../bi/utils/money';
import parseJSON from '../../../../../bi/utils/parseJsonString';
import { IUnderageEmployeesItem } from '../../../../../bi/utils/employees';

import { AirRoute, AirSegment, ISelectedBaggageItem } from '../../../../../bi/types/airline';
import { ICartItemInfo } from '../../../../../bi/types/airlineAdditionalServices';

import styles from '../styles.module.css';

interface IAirlineAdditionalServicesProps {
  everyEmployeeChoosen: boolean;
  noEmployeesSelected : boolean;
  currentItem: ICartItemInfo;
  routes: AirRoute[];
  getSelectedBaggage: () => ISelectedBaggageItem[][];
  additionalServicesPrice: number;
}

const LABELS = {
  ADDITIONAL_BAGGAGE: getText('cart:airlineAdditionalService.baggage.additionalBaggage'),
  RESET_SELECTED_BAGGAGE: getText('cart:airlineAdditionalService.baggage.resetSelectedBaggage'),
  SELECTION_BAGGAGE: getText('cart:airlineAdditionalService.baggage.selectionBaggage'),
  WARNING_SEGMENT: getText('cart:airlineAdditionalService.baggage.warningSegments'),
  BAGGAGE_GET_ERROR: getText('cart:airlineAdditionalService.baggage.baggageGetErrorWarning'),
  SELECT_PASSENGERS: getText('cart:airlineAdditionalService.seats.selectPassengers'),
};

const AirlineAdditionalServicesBaggage = observer(({
  everyEmployeeChoosen,
  noEmployeesSelected,
  currentItem,
  routes,
  getSelectedBaggage,
  additionalServicesPrice,
}: IAirlineAdditionalServicesProps) => {
  const {
    airlineBaggageService: {
      getBaggage,
      setEmployeeList,
      clearBaggage,
      deleteAllBaggages,
    },
    cartService: { load },
  } = useServices(['AirlineBaggage', 'Cart']);

  const {
    airlineBaggageStore: {
      baggageOffers,
      employeeList,
      baggageOffersGetError,
      unselectedBaggageWarning,
    },
  } = useStores([MOBX_STORES.AIRLINE_BAGGAGE]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetBaggageSelection, setResetBaggageSelection] = useState(false);

  const baggageRoutes = baggageOffers?.[currentItem.Id]?.BaggageRoutes;

  const isChangedEmployeeListLength = employeeList[currentItem.Id]?.length !== currentItem.Employees.length;

  /** Ключ для перерендера коллапса при изменении списка сотрудников, получении багажа и вывода предупреждений */
  const collapsibleKey =
  `${employeeList[currentItem.Id]?.length}-${baggageRoutes?.length}-${isLoading}-${unselectedBaggageWarning[currentItem.Id]?.length}`;

  const getBaggageOffers = async () => {
    const { ProviderName: providerName, Metadata: { FareId } } = parseJSON(currentItem.Data);
    const offerId = parseJSON(FareId).OfferId;
    setIsLoading(true);

    try {
      await getBaggage(providerName, offerId, currentItem.Id);
    } catch (error) {
      await load();
    }

    setIsLoading(false);
  };

  /** Обновление списка сотрудников */
  useEffect(() => {
    if (isChangedEmployeeListLength) {
      if (isOpen) {
        setIsOpen(true);
      }

      setEmployeeList(currentItem.Employees, currentItem.Id);
    }
  }, [currentItem]);

  const getRouteCode = (segments: AirSegment[]) => {
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    return {
      departure: firstSegment.DepartureCityCode,
      arrival: lastSegment.ArrivalCityCode,
    };
  };

  /** Проверка, что все роуты маршрута имеют соответствующие предложения багажа */
  const isAllBaggageRoutesValid = routes.every((route) => {
    const { departure, arrival } = getRouteCode(route.Segments);

    const baggageRoute = baggageRoutes?.find(
      (routeBaggage) =>
        routeBaggage.Departure === departure &&
        routeBaggage.Arrival === arrival,
    );

    return Boolean(baggageRoute);
  });

  const deleteAllTicketBaggage = async () => {
    clearBaggage(currentItem.Id);
    setResetBaggageSelection(true);
    await deleteAllBaggages(currentItem.Id);
    await load();
  };

  const handleResetComplete = () => {
    setResetBaggageSelection(false);
  };

  const toggleCollapse = async () => {
    setIsOpen((prev) => !prev);

    if (!isOpen) {
      setIsLoading(true);
      await getBaggageOffers();
      setIsLoading(false);
    }
  };

  const renderLoading = () => (
    <div className={ styles.warning_wrapper }>
      <DotLoading />
    </div>
  );

  const renderCollapsibleHeader = () => (
    <Text type='SEMIBOLD_16'>
      { LABELS.SELECTION_BAGGAGE }
    </Text>
  );

  const renderWarningMessage = (message: string) => (
    <div className={ styles.warning_wrapper }>
      <Text type='SEMIBOLD_16' color='red'>
        { message }
      </Text>
    </div>
  );

  const renderAirlineAdditionalServicesBaggageRows = () => employeeList?.[currentItem.Id]?.map(
    (employeeInfo: IUnderageEmployeesItem, index: number) => (
      <AirlineAdditionalServicesBaggageRow
        key={ employeeInfo?.Employee.Id }
        number={ index + 1 }
        ticketId={ currentItem.Id }
        routes={ routes }
        employee={ employeeInfo?.Employee }
        everyEmployeeChoosen={ everyEmployeeChoosen }
        resetBaggageSelection={ resetBaggageSelection }
        onResetComplete={ handleResetComplete }
        baggageRoutes={ baggageRoutes }
        getSelectedBaggage={ getSelectedBaggage }
      />
    ),
  );

  const renderWarningSegments = () => {
    const allSegments = routes.reduce<string[]>((acc, route: AirRoute) => {
      if (route.Segments.length < 2 || !employeeList[currentItem.Id]?.length) return acc;

      const segmentsString = route.Segments
        .map(({ DepartureCity, ArrivalCity }: AirSegment) => `${DepartureCity} - ${ArrivalCity}`)
        .join(', ');

      acc.push(`${segmentsString}`);

      return acc;
    }, []);

    if (!allSegments.length) return null;

    return (
      <div className={ styles.warning_segment }>
        <Text type='SEMIBOLD_16' color='red'>
          {LABELS.WARNING_SEGMENT} {allSegments.join(', ')}.
        </Text>
      </div>
    );
  };

  const renderWarningsList = (warnings: string[]) => warnings.map((warning, index) => (
    <Text key={ index } type='SEMIBOLD_16' color='red'>
      {warning}
    </Text>
  ));

  const renderWarningNotAllBaggageSelected = () => {
    const warnings = unselectedBaggageWarning[currentItem.Id];

    if (!warnings?.length) return null;

    return (
      <div className={ clsx(styles.warning_segment, styles.warning_not_all_baggage) }>
        { renderWarningsList(warnings) }
      </div>
    );
  };

  const renderTotalBaggageCost = () => (
    <Text type='bold_18_130' className={ styles.additional_price }>
      { LABELS.ADDITIONAL_BAGGAGE }: + { MoneyFormat.money(additionalServicesPrice, true) }
    </Text>
  );

  const renderAdditionalServicesBaggage = () => {
    if (isOpen && isLoading) return renderLoading();

    if (noEmployeesSelected) return renderWarningMessage(LABELS.SELECT_PASSENGERS);

    if (baggageOffersGetError[currentItem.Id]
      || !baggageRoutes?.length
      || !isAllBaggageRoutesValid
    ) return renderWarningMessage(LABELS.BAGGAGE_GET_ERROR);

    return (
      <>
        <div className={ clsx(styles.wrapper_places, styles.paddings) }>
          { renderAirlineAdditionalServicesBaggageRows() }
          { renderWarningSegments() }
          { renderWarningNotAllBaggageSelected() }
        </div>
        <div className={ styles.amount }>
          { renderTotalBaggageCost() }
          <Button
            className={ styles.delete_places }
            type='primary-outline'
            onClick={ deleteAllTicketBaggage }
          >
            { LABELS.RESET_SELECTED_BAGGAGE }
          </Button>
        </div>
      </>
    );
  };

  return (
    <Collapsible
      open={ isOpen }
      key={ collapsibleKey }
      title={ renderCollapsibleHeader() }
      headerWrapperClassname={ styles.header_collapsible_wrapper }
      onHeaderClick={ toggleCollapse }
    >
      { renderAdditionalServicesBaggage() }
    </Collapsible>
  );
});

export { AirlineAdditionalServicesBaggage };
