import React from 'react';
import { observer } from 'mobx-react';

import { Checkbox, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { isSmartAgent } from '../../../../../bi/utils/env';

import styles from '../index.module.css';

const LABELS = {
  EMPLOYEE_MAY: (empl: string) => getText('settings:employees.employee.steps.rights.steps.admin.employeeMay', { empl }),
  EDIT_PERSONAL_DATA: getText('settings:employees.employee.steps.rights.steps.admin.editPersonalData'),
  EDIT_PERSONAL_DATA_FOR_AGENTS: getText('settings:employees.employee.steps.rights.steps.admin.editPersonalDataForAgents'),
  GIVE_ACCESS: getText('settings:employees.employee.steps.rights.steps.admin.giveAccess'),
  GIVE_ACCESS_FOR_AGENTS: getText('settings:employees.employee.steps.rights.steps.admin.giveAccessForAgent'),
  VIEW_MICE: getText('settings:employees.employee.steps.rights.steps.admin.viewMice'),
  ANOTHER_TRIPS: getText('settings:employees.employee.steps.rights.steps.admin.anotherTrips'),
  CREATE_AND_EDIT_TP: getText('settings:employees.employee.steps.rights.steps.admin.createAndEditTp'),
  VIEW_EXPENSE_REPORTS: getText('settings:employees.employee.steps.rights.steps.admin.viewExpenseReports'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface IAdminStep {
  employee: string,
  active: string,
  header: string,
  showExpenseReportApprove: boolean,
  rights: {
    showSecondStep: boolean,
    editTravelPolicies: boolean,
    showEditTravelPolicy: boolean,
    editEmployee: boolean,
    editRights: boolean,
    disableEditRights: boolean,
    viewTrips: boolean,
    viewMiceEvents: boolean,
    viewExpenseReports: boolean,
  },
  onChangeStep(value: string): void,
  onChangeRights(value: string, checkValue: boolean): void,
}

const AdminStep = observer(({
  employee,
  active,
  header,
  showExpenseReportApprove,
  rights: {
    showSecondStep,
    editTravelPolicies,
    showEditTravelPolicy,
    editEmployee,
    editRights,
    disableEditRights,
    viewTrips,
    viewMiceEvents,
    viewExpenseReports,
  },
  onChangeStep,
  onChangeRights,
}: IAdminStep) => {
  const commonPrevStep = showSecondStep ? STEPSRIGHTS.LIMIT : STEPSRIGHTS.MAIN;
  const prevStep = isSmartAgent ? STEPSRIGHTS.MAIN : commonPrevStep;
  const editPersonalData = isSmartAgent ? LABELS.EDIT_PERSONAL_DATA_FOR_AGENTS : LABELS.EDIT_PERSONAL_DATA;
  const giveAccess = isSmartAgent ? LABELS.GIVE_ACCESS_FOR_AGENTS : LABELS.GIVE_ACCESS;

  const renderAnotherTrips = () => (
    <Checkbox
      value={ viewTrips }
      onChange={ value => onChangeRights(FIELDRIGHTS.VIEWTRIPS, value) }
      className={ styles.item }
      qaAttr={ modals.admin.anotherTrips }
    >
      { LABELS.ANOTHER_TRIPS }
    </Checkbox>
  );

  const renderMice = () => !isSmartAgent && (
    <Checkbox
      value={ viewMiceEvents }
      onChange={ value => onChangeRights(FIELDRIGHTS.VIEW_MICE_EVENTS, value) }
      className={ styles.item }
      qaAttr={ modals.admin.viewMice }
    >
      { LABELS.VIEW_MICE }
    </Checkbox>
  );

  const renderExpenseReport = () => !isSmartAgent && showExpenseReportApprove && (
    <Checkbox
      value={ viewExpenseReports }
      disabled={ !viewTrips }
      onChange={ value => onChangeRights(FIELDRIGHTS.VIEW_EXPENSE_REPORTS, value) }
      className={ styles.item }
      qaAttr={ modals.admin.viewExpenseReports }
    >
      { LABELS.VIEW_EXPENSE_REPORTS }
    </Checkbox>
  );

  const renderEditTravelPolicy = () => !isSmartAgent && showEditTravelPolicy && (
    <Checkbox
      value={ editTravelPolicies }
      onChange={ value => onChangeRights(FIELDRIGHTS.EDITTRAVELPOLICIES, value) }
      className={ styles.item }
      qaAttr={ modals.admin.tp }
    >
      { LABELS.CREATE_AND_EDIT_TP }
    </Checkbox>
  );

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ () => onChangeStep(STEPSRIGHTS.NOTIFICATION) }
      onClickCancel={ () => onChangeStep(prevStep) }
      qaAttrHeader={ modals.admin.title }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.admin.buttons.cancel }
    >
      <Text className={ styles.subtitle }>
        { LABELS.EMPLOYEE_MAY(employee) }
      </Text>
      <Checkbox
        value={ editEmployee }
        onChange={ value => onChangeRights(FIELDRIGHTS.EDITEMPLOYEE, value) }
        className={ styles.item }
        qaAttr={ modals.admin.personalData }
      >
        { editPersonalData }
      </Checkbox>
      <Checkbox
        value={ editRights }
        disabled={ disableEditRights }
        onChange={ value => onChangeRights(FIELDRIGHTS.EDITRIGHTS, value) }
        className={ styles.item }
        qaAttr={ modals.admin.giveAccess }
      >
        { giveAccess }
      </Checkbox>
      { renderAnotherTrips() }
      { renderEditTravelPolicy() }
      { renderExpenseReport() }
      { renderMice() }
    </RightsDialogWrap>
  );
});

export { AdminStep };
