import React, { Component, createRef, RefObject } from 'react';
import * as queryString from 'query-string';
import { Button, Text, PageLoader, NoResults } from 'new-ui';

import { RouteComponentProps } from 'react-router-dom';
import { getText } from '../../../i18n';

import CONFIG from '../../../config';

import ACTION from '../../bi/services/hotels/action';
import { SEARCH_MENU_PAGE, SEARCH_MENU_TYPES } from '../../bi/services/hotels/constants/searchMenuTypes';

import { preloadOrigUrlAndUrl } from '../../bi/utils/images';
import { getBooleanFromStringBoolean, getNumbersFromString } from '../../bi/utils/strings';
import MainAnalytic from '../../bi/utils/analytics/main';
import textAbbreviation from '../../bi/utils/textAbbreviation';
import MoneyFormat from '../../bi/utils/money';
import { prepareCustomCheckinCheckoutTime } from '../../bi/utils/hotel';
import { getHotelStatic } from '../../bi/utils/hotels';

import FAVORITESACTION from '../../bi/constants/favorites';
import ROUTES from '../../bi/constants/routes';
import { SELECTTRAVELLERS } from '../../bi/constants/hotelsSearch';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { FILTER_FIELD_ONLINE, UPSELL_EXPERIMENTS } from '../../bi/constants/hotel';
import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import { POPUP_NAME } from '../../bi/constants/popups';

import { YandexMaps } from '../../components/YandexMap';
import { MapDialog } from '../../components/YandexMap/components/MapDialog';
import { ImageGallery } from '../../components/ImageGallery/ImageGallery';
import { HotelNameBlock } from '../../components/HotelNameBlock';
import { ShowOnMapButton } from '../../components/ShowOnMapButton';
import { Rating } from '../../components/Rating';
import { HotelSearchMenu } from '../../components/Menu/HotelSearchMenu';
import { Review } from '../../components/Review';
import { FormHotelSearchDialog } from '../../components/FormHotelSearchDialog';
import { StyledWrapperBookMark } from '../../components/StyledWrapperBookMark';
import { ImportantInformation } from './components/importantInformation';
import ContextAdvertising from '../../components/ContextAdvertising';

import PageHotel from '../../../images/mice/context/pageHotel.webp';

import { Hotel } from './components/Hotel';

import {
  IHotelStore,
  IHotel,
  PrepareRate,
  HotelImage,
  QueryParse,
  ToNoteHotel,
} from '../../bi/services/hotels/types';
import HotelsService from '../../bi/services/hotels';
import EmployeeService from '../../bi/services/employee';
import ChatService from '../../bi/services/chat';
import NotificationService from '../../bi/services/notification';
import FavoriteService from '../../bi/services/favorites';
import WorkspaceService from '../../bi/services/workspace';
import RequestsService from '../../bi/services/requests';
import FeatureFlagsService from '../../bi/services/featureFlags';
import SidePanelService from '../../bi/services/sidePanel';
import UserSessionService from '../../bi/services/userSession';
import AccountSettings from '../../bi/services/accountSettings';
import PopupsService from '../../bi/services/popups';
import AppService from '../../bi/services/app';

import { isSmartAgent } from '../../bi/utils/env';

import smarthotelBigSvg from './styles/images/smarthotelBig.svg';
import styles from './styles/hotel_result.module.css';

const HEADER_PART = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const SYMBOL_LENGTH = {
  DESCRIPTION: 600,
};

const LABELS = {
  MORE_ABOUT_HOTEL: getText('hotels:hotelResult.moreAboutOfHotel'),
  SEARCH: getText('hotels:hotelResult.loader'),
  NO_REVIEWS: getText('hotels:hotelResult.noReviews'),
  REVIEWS: getText('hotels:hotelResult.reviews'),
  RECOMMENDED: getText('hotels:hotelResult.recommended'),
  GUARANTEED: getText('hotels:hotelResult.guaranteed'),
  DISTANCE_TO_CENTER: (city: string, distance: string) => getText('hotels:hotelResult.distanceToCenter', { city, distance }),
  ADD_TO_CART_SUCCESS: (name: string, city: string, checkinDate: string, checkoutDate: string) =>
    getText('hotels:hotelResult.notifications.toCart.success', { name, city, checkinDate, checkoutDate }),
  ADD_TO_CART_ERROR: (name: string, city: string, checkinDate: string, checkoutDate: string) =>
    getText('hotels:hotelResult.notifications.toCart.success', { name, city, checkinDate, checkoutDate }),
  ADD_TO_NOTE: (name: string, city: string, checkinDate: string, checkoutDate: string) =>
    getText('hotels:hotelResult.notifications.toNote', { name, city, checkinDate, checkoutDate }),
  REMOVE_FROM_FAVORITE: (name: string) =>
    getText('hotels:hotelResult.notifications.toFavorite.remove', { name }),
  ADD_TO_FAVORITE: (name: string) => getText('hotels:hotelResult.notifications.toFavorite.add', { name }),
  CHANGE_GUARANTEE_PRICE: getText('hotels:hotelResult.notifications.changeGuaranteePrice'),
  ADD_ITEM: (trip: string) => getText('hotels:hotelResult.addItem', { trip }),
};

interface HotelResultPageProps {
  match: RouteComponentProps['match'],
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history'],
  hotelsService: HotelsService,
  employeeService: EmployeeService,
  chatService: ChatService,
  notificationService: NotificationService,
  favoriteService: FavoriteService,
  workspaceService: WorkspaceService,
  requestsService: RequestsService,
  featureFlagsService: FeatureFlagsService,
  sidePanelService: SidePanelService,
  userSessionService: UserSessionService,
  accountSettingsService: AccountSettings,
  popupsService: PopupsService,
  appService: AppService,
  aggregationId: number,
}

interface HotelResultPageState {
  loading: boolean,
  hotel: IHotel | null,
  loadingRate: boolean,
  openedMap: boolean,
  shouldShowReviews: boolean,
  loadedImgs: HotelImage[]
  allImageLoadFailed: boolean,
  showFormHotelSearchDialog: boolean,
  loadingRequest: boolean,
  selectOfflineRoom: any | null,
  isFiltersHotelsInMicroservice: boolean,
  upsellFlags: string[],
  isShownContext: boolean,
}

class HotelResultPage extends Component<HotelResultPageProps, HotelResultPageState> {
  idRequest = 0;
  idRequestItem = 0;
  amenitiesRef: HTMLDivElement | null = null;
  roomsRef: HTMLDivElement | null = null;
  openDatePickerTo: RefObject<HTMLElement> = createRef();
  openDatePickerFrom: RefObject<HTMLElement> = createRef();
  buttonsRefs: RefObject<HTMLElement>[] = [createRef(), createRef()];
  prevQuery: string;
  hotelId: string;
  unsubscribeFn: () => void;
  imageGallery: ImageGallery | null;
  queryTripId: string | any;

  state: HotelResultPageState = {
    loading: false,
    hotel: null,
    loadingRate: false,
    openedMap: false,
    shouldShowReviews: false,
    loadedImgs: [],
    allImageLoadFailed: false,
    showFormHotelSearchDialog: false,
    loadingRequest: false,
    selectOfflineRoom: null,
    isFiltersHotelsInMicroservice: this.props.featureFlagsService.getFiltersHotelsInMicroservice(),
    upsellFlags: this.props.featureFlagsService.getUpsellFlags(),
    isShownContext: !this.props.popupsService.store.popupsState.mice_hotels_hotel_group_search,
  };

  componentDidMount() {
    // @ts-ignore
    const { hotelId } = this.props.match.params;
    const { request, requestItem } = this.props.requestsService.get();

    this.idRequest = request.Id;
    this.idRequestItem = requestItem.Id > 0
      ? requestItem.Id
      : Number(queryString.parse(this.props.location.search).RequestItemId);

    this.initHotelFilter();

    if (hotelId) {
      this.prevQuery = this.props.location.search;
      this.hotelId = hotelId;
      this.updateUrlQuery(hotelId);
    }

    this.queryTripId = queryString.parse(this.props.location.search)?.tripId;

    this.unsubscribeFn = this.props.hotelsService.subscribeHotel(this.updateState);
  }

  componentDidUpdate() {
    const { region: { selected: { Id } } } = this.props.hotelsService.getSearchState();

    if (this.prevQuery !== this.props.location.search && Number(this.hotelId) === Number(Id)) {
      // @ts-ignore
      this.updateUrlQuery(this.props.match.params.hotelId, this.props);
      this.prevQuery = this.props.location.search;

      if (this.roomsRef) {
        window.scroll({ top: this.roomsRef.offsetTop, left: 0, behavior: 'smooth' });
      }
    }
  }

  initHotelFilter = () => {
    const queryParse = queryString.parse(this.props.location.search);
    const {
      online,
      breakfast,
      hasCancellation,
    } = queryParse;

    const onlineExist = online ?? 'true';

    this.props.hotelsService.initHotelFilter({
      online: getBooleanFromStringBoolean(onlineExist as string),
      meal: breakfast ? getNumbersFromString([...breakfast]) : [],
      hasCancellation: getBooleanFromStringBoolean(hasCancellation as string),
    });
  };

  componentWillUnmount() {
    this.unsubscribeFn();
    this.props.hotelsService.clearStateHotel();
  }

  updateState = ({
    hotel,
    loadingHotel,
    loadingRate,
    showFormHotelSearchDialog,
    loadingRequest,
    selectOfflineRoom,
  }: IHotelStore) => this.setState({
    hotel,
    loading: loadingHotel,
    loadingRate,
    showFormHotelSearchDialog,
    loadingRequest,
    selectOfflineRoom,
  }, () => {
    if (this.state.shouldShowReviews && !this.state.loading && this.state.hotel) {
      this.showReviews();
      this.setState({ shouldShowReviews: false });
    }
  });

  updateUrlQuery = (hotelId: string, newProps?: HotelResultPageProps) => {
    const { hotel } = this.state;
    const props = newProps || this.props;
    const queryParse = queryString.parse(props.location.search);
    const {
      CheckinDate,
      CheckoutDate,
      GuestsCount,
      TravellersCount,
      AddToHistory = 'false',
      Label = '',
      FromHistory = 'false',
      CheckinTime = '',
      CheckoutTime = '',
      Reviews,
      RedirectSearchType = '',
    } = queryParse;

    const RegionId = Number(hotelId);

    const initSearchParams = {
      hotelId: Number(hotelId),
      CheckinDate,
      CheckoutDate,
      GuestsCount: Number(GuestsCount),
      TravellersCount: Number(TravellersCount),
      RegionId: Number(RegionId),
      AddToHistory: getBooleanFromStringBoolean(AddToHistory as string),
      Label,
      FromHistory: getBooleanFromStringBoolean(FromHistory as string),
      CheckinTime: CheckinTime ?? null,
      CheckoutTime: CheckoutTime ?? null,
      RedirectSearchType,
    };

    // @ts-ignore
    this.props.hotelsService.initSearch(initSearchParams);

    const { loadingHotel, showFormHotelSearchDialog, loadingRequest } = this.props.hotelsService.getHotelState();
    this.setState({
      loading: loadingHotel,
      shouldShowReviews: Reviews === 'true',
      showFormHotelSearchDialog,
      loadingRequest,
    }, () => (hotel && (hotel.Id === Number(hotelId))
      ? this.loadRates()
      : this.loadHotelWithRates({ ...queryParse, RegionId })),
    );
  };

  loadHotelWithRates = (queryParse: QueryParse) => {
    const { hotelsService } = this.props;
    const { isFiltersHotelsInMicroservice } = this.state;

    hotelsService.searchByHotelId(queryParse, isFiltersHotelsInMicroservice)
      .then(this.preloadAndFilterImages)
      .catch((err) => {
        if (err.status === 404) {
          hotelsService.setErrorHotelNotFound();
        } else {
          window.console.error(err);
        }
      });
  };

  loadRates = () => {
    const { hotelsService, notificationService, aggregationId } = this.props;
    const { isFiltersHotelsInMicroservice } = this.state;

    return hotelsService.loadHotelRates(isFiltersHotelsInMicroservice)
      .then(() => {
        this.preloadAndFilterImages();

        const { showIsPriceGuaranteeChange } = hotelsService.getHotelState();

        if (!aggregationId && !isSmartAgent && showIsPriceGuaranteeChange) {
          notificationService.send({
            message: LABELS.CHANGE_GUARANTEE_PRICE,
            // @ts-ignore
            level: 'success',
          });
        }
      })
      .catch((err: any) => {
        if (err.status === 404) {
          hotelsService.setErrorHotelNotFound();
        } else {
          window.console.error(err);
        }
      });
  };

  preloadAndFilterImages = async () => {
    const { hotelsService } = this.props;
    const { hotel } = this.state;
    const { filters: { online } } = hotelsService.getHotelState();

    if (!hotel?.Images?.length) {
      return;
    }

    const preloadedImages = await Promise.all(hotel.Images.map(preloadOrigUrlAndUrl));
    const filteredImages = preloadedImages.filter(Boolean);
    const allImageLoadFailed = !filteredImages.length;

    // @ts-ignore
    this.setState({ loadedImgs: filteredImages, allImageLoadFailed });

    if (hotel?.IsAvailableContract && online) {
      this.handleUpdateFilters(
        ACTION.UPDATEHOTELFILTER,
        { filterName: FILTER_FIELD_ONLINE, value: false },
      );
    }
  };

  setShowMap = (value: boolean) => {
    this.setState({ openedMap: value });

    if (value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SHOWDEHOTELFROMMAP);
    }
  };

  showReviews = () => {
    const { sidePanelService } = this.props;

    sidePanelService.setRenderFn(this.renderReviews);
    sidePanelService.setShow(true);
  };

  scrollToAmenities = () => {
    if (this.amenitiesRef) {
      window.scroll({
        top: this.amenitiesRef.offsetTop - 40,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  handleAddToCart = (hotel: IHotel, rate: PrepareRate) => {
    const { hotelsService, notificationService, history } = this.props;

    const promise = this.idRequestItem > 0
      ? hotelsService.addToCart(rate, this.idRequestItem)
      : hotelsService.addToCart(rate);

    const { checkin, checkout } = hotelsService.getSearchState();
    const checkinDate = checkin.format(DATEFORMATS.DATE);
    const checkoutDate = checkout.format(DATEFORMATS.DATE);

    promise.then(() => {
      notificationService.send({
        message: LABELS.ADD_TO_CART_SUCCESS(hotel.Name, hotel.City, checkinDate, checkoutDate),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.CART.MAIN),
        qaAttr: QA_ATTRIBUTES.hotels.current.room.buttonCartNotification,
      });
    })
      .catch(() => {
        notificationService.send({
          message: LABELS.ADD_TO_CART_ERROR(hotel.Name, hotel.City, checkinDate, checkoutDate),
          // @ts-ignore
          level: 'error',
        });
      });
  };

  handleAddToNote = (hotel: IHotel, rate: PrepareRate) => {
    const { hotelsService, notificationService, history } = this.props;
    hotelsService.addToNote(rate as ToNoteHotel).then(() => {
      const { checkin, checkout } = hotelsService.getSearchState();

      const checkinDate = checkin.format(DATEFORMATS.DATE);
      const checkoutDate = checkout.format(DATEFORMATS.DATE);

      notificationService.send({
        message: LABELS.ADD_TO_NOTE(hotel.Name, hotel.City, checkinDate, checkoutDate),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.NOTE.MAIN),
        qaAttr: rate.qaAttr as string,
      });
    });
  };

  handleFavoriteToggle = (action: string, headerPart: string) => {
    const { favoriteService, hotelsService, notificationService, history } = this.props;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_FAVORITES_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );

    // @ts-ignore
    const hotel: IHotel = this.state.hotel;

    if (action === FAVORITESACTION.REMOVE) {
      return favoriteService.removeItem(hotel.FavoriteId)
        .then(() => {
          notificationService.send({
            message: LABELS.REMOVE_FROM_FAVORITE(hotel.Name),
            // @ts-ignore
            level: 'success',
            qaAttr: QA_ATTRIBUTES.hotels.result.notificationRemoveFavorite,
          });

          hotelsService.changeFavoriteStatus(hotel.ClassificatorId, '');
          hotelsService.updateHotelFavorite(null);
        });
    }

    return hotelsService.addToFavorite(hotel)
      .then((favoriteId: string) => {
        notificationService.send({
          message: LABELS.ADD_TO_FAVORITE(hotel.Name),
          // @ts-ignore
          level: 'success',
          onClick: () => history.push(ROUTES.FAVORITES),
          qaAttr: QA_ATTRIBUTES.hotels.result.notificationFavorite,
        });

        const id = favoriteId.split('"').join('');

        hotelsService.changeFavoriteStatus(hotel.ClassificatorId, id, headerPart);
        hotelsService.updateHotelFavorite(id);
      });
  };

  handleSendRequest = () => {
    const { hotelsService, chatService, workspaceService } = this.props;
    const { hotel, selectOfflineRoom } = this.state;

    const {
      adult,
      bedType,
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      travellersCount,
      comment,
      arrayEmployees,
      employeesId,
    } = hotelsService.getSearchState();
    const { Email } = workspaceService.get();
    const { textError } = selectOfflineRoom;

    const tpErrorText = textError ? `${textError}\n` : '';

    const sendMessage = this.queryTripId ? `<br>${LABELS.ADD_ITEM(this.queryTripId)}</br>` : '';

    const commentary = `${tpErrorText}${comment}${sendMessage}`;

    // @ts-ignore
    const roomCount = SELECTTRAVELLERS[travellersCount]
      .find((item) => item.value === adult).roomCount;

    if (!chatService.hasDisabledUser(Email) && workspaceService.canUseIntercom) {
      return chatService.requestReservation({
        // @ts-ignore
        hotel,
        bedType,
        room: selectOfflineRoom,
        adult,
        checkin,
        checkout,
        customCheckin,
        customCheckout,
        travellersCount,
        roomCount,
        comment: commentary,
        arrayEmployees,
        employeesId,
      }).then(() => hotelsService.closeDialogAfterSend());
    }

    return Promise.reject();
  };

  handleGalleryLeft = () => {
    MainAnalytic.sendGalleryEvent(
      MainAnalytic.CATEGORY.HOTELS,
      MainAnalytic.ACTIONS.HOTELS.SWITCHPHOTO,
      MainAnalytic.GALLERY.LEFT,
    );
  };

  handleGalleryRight = () => {
    MainAnalytic.sendGalleryEvent(
      MainAnalytic.CATEGORY.HOTELS,
      MainAnalytic.ACTIONS.HOTELS.SWITCHPHOTO,
      MainAnalytic.GALLERY.RIGHT,
    );
  };

  goToRegionSearch = (searchObj: any) => this.props.history.push({
    pathname: ROUTES.SEARCH.HOTELS_REGION,
    search: queryString.stringify(searchObj),
  });

  updateSearchParams = () => {
    const { hotelsService } = this.props;
    const params = hotelsService.getParamForHotelDetail();
    const {
      online,
      breakfast,
      hasCancellation,
      RequestItemId,
      RedirectSearchId = null,
      RedirectSearchType = null,
    } = queryString.parse(this.props.location.search);

    const search = queryString.stringify({
      CheckinDate: params.checkin,
      CheckoutDate: params.checkout,
      GuestsCount: params.adult,
      TravellersCount: params.travellersCount,
      // @ts-ignore
      RoomCount: SELECTTRAVELLERS[params.travellersCount].find(item => item.value === params.adult).roomCount,
      RegionName: params.label,
      // @ts-ignore
      RegionId: params.Id,
      AddToHistory: false,
      ...prepareCustomCheckinCheckoutTime(params.customCheckin, params.customCheckout),
      RequestItemId,
      online,
      breakfast,
      hasCancellation,
      RedirectSearchId,
      RedirectSearchType,
    });

    if (params.travellersCount > 1) {
      MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.MULTIPLEROOMSSEARCH, {
        label: SELECTTRAVELLERS[params.travellersCount].find(({ value }) => value === params.adult)?.label || '',
      });
    }

    const { region: { selected: { Id } } } = hotelsService.getSearchState();

    this.props.history.replace({
      pathname: `${ROUTES.SEARCH.HOTEL_PAGE}/${Id}`,
      search,
      state: {
        noReloadingScroll: true,
      },
    });

    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.CHANGEDATES);
  };

  goToMice = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS.PRESSED);

    window.open(ROUTES.EVENT_CONTEXT, '_blank');
  };

  handleShownContext = (value: boolean) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS.CLOSED);

    this.setState({ isShownContext: value });
  };

  handleChangeForm = () => {
    const { hotelsService } = this.props;

    const { region: { selected: { Id } } } = hotelsService.getSearchState();

    if (Number(this.hotelId) !== Number(Id)) {
      return;
    }

    if (hotelsService.searchFormIsValid()) {
      this.updateSearchParams();
    }
  };

  handleDetail = () => {
    this.updateSearchParams();

    const { region: { selected: { Id } } } = this.props.hotelsService.getSearchState();

    this.hotelId = Id;
  };

  handleUpdateHotel = () => {
    const { hotelsService } = this.props;
    const isRegion = hotelsService.isRegion() || false;

    if (isRegion) {
      // @ts-ignore
      return this.goToRegionSearch(hotelsService.mapStateToSearchObject());
    }

    return this.handleDetail();
  };

  handleUpdateFilters = (field: string, value: { filterName: string, value: boolean | number[] }) => {
    this.props.hotelsService.setHotel(field, value);
  };

  handleOpenDatePickerFrom = (time: string) => {
    // @ts-ignore
    this.openDatePickerFrom.current(time);

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.DETAIL_EARLY_CHECKIN_BUTTON);
  };

  handleOpenDatePickerTo = (time: string) => {
    // @ts-ignore
    this.openDatePickerTo.current(time);

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.HOTELS.DETAIL_LATE_CHECKOUT_BUTTON);
  };

  renderGallery = () => {
    const { hotel, loadedImgs, allImageLoadFailed } = this.state;

    if (!hotel?.Images?.length || allImageLoadFailed) {
      return null;
    }

    const imgs = loadedImgs.length ? loadedImgs : hotel.Images;

    const imgsList = imgs.map(img => ({
      original: img.OrigUrl,
      thumbnail: img.Url,
    }));

    return (
      <ImageGallery
        autoPlay
        ref={ (i) => {
          this.imageGallery = i;
        } }
        items={ imgsList }
        slideInterval={ 4000 }
        onSlideLeft={ this.handleGalleryLeft }
        onSlideRight={ this.handleGalleryRight }
      />
    );
  };

  renderReviews = () => {
    const {
      hotel: {
        // @ts-ignore
        Reviews: {
          Reviews, Rating: rating,
        },
      },
    } = this.state;

    const reviewsContent = Reviews && Reviews.length
      // @ts-ignore
      ? Reviews.map((review, idx) => (<Review key={ idx } value={ review } />))
      : (
        <div className={ styles['no-reviews-wrapper'] }>
          <NoResults label={ LABELS.NO_REVIEWS } />
        </div>
      );

    return (
      <div className={ styles['reviews-wrapper'] }>
        <div className={ styles.header }>
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.current.reviewsTitle }
            type='bold_32'
            className={ styles.text }
          >
            { LABELS.REVIEWS }
          </Text>
          <Rating value={ rating } fullSize />
        </div>
        { reviewsContent }
      </div>
    );
  };

  renderMap = () => {
    const { appService } = this.props;
    const { openedMap, hotel, loadingRate } = this.state;
    const { agentMode } = appService.get();
    // @ts-ignore
    const { Latitude, Longitude, Reviews: { Rating: rating }, RoomGroups } = hotel;

    const rateToBeShowed = RoomGroups.length && RoomGroups[0].Rates[0];
    const hideMarkerPrice = !RoomGroups.length && !loadingRate;

    return (
      <MapDialog
        show={ openedMap }
        onChange={ this.setShowMap }
        qaAttrClose={ QA_ATTRIBUTES.hotels.current.closeMap }
      >
        <YandexMaps
          qaAttr={ QA_ATTRIBUTES.hotels.current.map }
          singleMarker
          center={ [Latitude, Longitude] }
          placeMarks={ [
            {
              Rating: rating,
              rate: rateToBeShowed,
              hideMarkerPrice,
              ...getHotelStatic(hotel as IHotel),
            },
          ] }
          APIKEY={ CONFIG.YANDEX_MAPS_API_KEY }
          onFormatMoney={ MoneyFormat.money }
          agentMode={ agentMode }
        />
      </MapDialog>
    );
  };

  renderSearchPanel() {
    const { hotelsService, featureFlagsService } = this.props;

    return (
      <HotelSearchMenu
        hotelsService={ hotelsService }
        type={ SEARCH_MENU_TYPES.SUB_MENU }
        typePage={ SEARCH_MENU_PAGE.HOTEL }
        onSearch={ this.handleUpdateHotel }
        onSearchSubMenu={ this.handleUpdateHotel }
        onChangeForm={ this.handleChangeForm }
        openDatePickerTo={ (fn: (mode: string) => void) => {
          // @ts-ignore
          this.openDatePickerTo.current = fn;
        } }
        openDatePickerFrom={ (fn: (mode: string) => void) => {
          // @ts-ignore
          this.openDatePickerFrom.current = fn;
        } }
        buttonsRefs={ this.buttonsRefs }
        searchOnTimeChanged
        featureFlagsService={ featureFlagsService }
      />
    );
  }

  renderFormHotelSearchDialog = () => {
    const { hotelsService, employeeService } = this.props;
    const { hotel, loadingRequest, showFormHotelSearchDialog, selectOfflineRoom } = this.state;

    return showFormHotelSearchDialog && (
      <FormHotelSearchDialog
        hotelsService={ hotelsService }
        employeeService={ employeeService }
        hotel={ hotel as IHotel }
        onSendRequest={ this.handleSendRequest }
        selectOfflineRoom={ selectOfflineRoom }
        loading={ loadingRequest }
        show
      />
    );
  };

  renderImportantInformation = () => {
    const { hotelsService: { importantInformation } } = this.props;

    if (!importantInformation) {
      return null;
    }

    return (
      <ImportantInformation
        informationInfo={ importantInformation }
      />
    );
  };

  renderHotelItems = (hotel: IHotel, isRecommended: boolean = false) => {
    const {
      hotelsService,
      chatService,
      workspaceService,
      userSessionService,
      sidePanelService,
      featureFlagsService,
      location,
      history,
      appService,
    } = this.props;

    return (
      <div className={ styles.rates }>
        <Hotel
          isRecommended={ isRecommended }
          chatService={ chatService }
          workspaceService={ workspaceService }
          userSessionService={ userSessionService }
          sidePanelService={ sidePanelService }
          hotelsService={ hotelsService }
          appService={ appService }
          featureFlagsService={ featureFlagsService }
          location={ location }
          history={ history }
          openDatePickerFrom={ this.handleOpenDatePickerFrom }
          openDatePickerTo={ this.handleOpenDatePickerTo }
          nightsCount={ hotelsService.getSearchDatesDiff() }
          hotel={ hotel }
          onAddToCart={ this.handleAddToCart }
          onAddToNote={ this.handleAddToNote }
          onUpdateFilters={ this.handleUpdateFilters }
          onRenderAmenities={ (ref: HTMLDivElement) => { this.amenitiesRef = ref; } }
          onRenderRooms={ (ref: HTMLDivElement) => { this.roomsRef = ref; } }
          buttonsRefs={ this.buttonsRefs }
        />
      </div>
    );
  };

  renderRecommendedHotel = (hotel: IHotel) => {
    const { upsellFlags } = this.state;
    const { location: { search } } = this.props;
    const { RedirectSearchId = null } = queryString.parse(search);

    if (!upsellFlags.includes(UPSELL_EXPERIMENTS.SECOND) || !RedirectSearchId) return null;

    return this.renderHotelItems(hotel, true);
  };

  renderContextAdvertising = () => {
    const {
      aggregationId,
      hotelsService,
      accountSettingsService: {
        getNoMice,
      },
      popupsService: {
        closePopupState,
        store: {
          popupsState: {
            mice_hotels_hotel_group_search,
          },
        },
      },
      featureFlagsService: {
        getHiddenContextAdjMice,
      },
    } = this.props;
    const { isShownContext } = this.state;

    const { travellersCount } = hotelsService.getSearchState();

    if (!isShownContext
      || travellersCount < 4
      || aggregationId
      || getNoMice()
      || isSmartAgent
      || !getHiddenContextAdjMice()
    ) {
      return null;
    }

    if (!mice_hotels_hotel_group_search) {
      closePopupState(POPUP_NAME.CONTEXT.HOTEL);
    }

    return (
      <ContextAdvertising
        classname={ styles.context }
        image={ PageHotel }
        onClick={ this.goToMice }
        onClose={ () => this.handleShownContext(false) }
        amplitude={ MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS.SHOWED }
        classnameBtn={ styles.wrapper_context }
      />
    );
  };

  renderPage() {
    const { aggregationId } = this.props;
    const { hotel } = this.state;

    if (!(hotel && hotel.Id)) return <NoResults />;

    const {
      IsSmartHotel,
      Description,
      Stars,
      Name,
      FavoriteId,
      Address,
      DistanceFromCenter,
      Reviews: {
        Rating: rating,
        Reviews,
      },
      RegionName,
      IsPriceGuaranteed,
    } = hotel;

    const smartIconHtml = IsSmartHotel && aggregationId && (
      <div className={ styles['header-smart'] }>
        <img
          className={ styles.icon }
          src={ smarthotelBigSvg }
          alt='Smarthotel'
        />
        <Text type='SEMIBOLD_14' className={ styles.text }>{ LABELS.RECOMMENDED }</Text>
      </div>
    );

    const descriptionStr = Description
      ? textAbbreviation(Description.substring(Description.indexOf('<p>')), SYMBOL_LENGTH.DESCRIPTION)
      : '';
    const priceGuaranteedLabel = IsPriceGuaranteed ? LABELS.GUARANTEED : '';
    const isWithoutPriceGuaranteed = !aggregationId && !isSmartAgent ? priceGuaranteedLabel : '';

    const onClickReviews = Reviews.length ? this.showReviews : () => {};

    return (
      <>
        { this.renderSearchPanel() }
        { this.renderContextAdvertising() }
        <div className={ styles.page }>
          <HotelNameBlock
            stars={ Stars }
            name={ Name }
            favoriteId={ FavoriteId }
            qaAttrText={ QA_ATTRIBUTES.hotels.current.title }
            qaAttrStars={ QA_ATTRIBUTES.hotels.current.stars }
            qaAttrFavorites={ QA_ATTRIBUTES.hotels.current.favorites }
            qaAttrReviews={ QA_ATTRIBUTES.hotels.current.reviews }
            onFavoriteToggle={ (action: string) => this.handleFavoriteToggle(action, HEADER_PART.TOP) }
            onClickReviews={ onClickReviews }
          />
          <StyledWrapperBookMark bookmarkText={ isWithoutPriceGuaranteed } className={ styles.static }>
            <div className={ styles.info }>
              <div>
                <div className={ styles.header }>
                  {smartIconHtml}
                  <Rating
                    fullSize
                    qaAttr={ QA_ATTRIBUTES.hotels.current.rating }
                    value={ rating }
                    onClick={ this.showReviews }
                  />
                </div>
                <div className={ styles.description }>
                  <div
                    className={ styles.text }
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: descriptionStr } }
                  />
                  <Button
                    qaAttr={ QA_ATTRIBUTES.hotels.current.more }
                    type='textual'
                    className={ styles['more-btn'] }
                    onClick={ this.scrollToAmenities }
                  >
                    { LABELS.MORE_ABOUT_HOTEL }
                  </Button>
                </div>
              </div>
              <div className={ styles['hotel-address'] }>
                <Text type='NORMAL_14' >{Address}</Text>
                <Text type='NORMAL_14' className={ styles.distance }>
                  { LABELS.DISTANCE_TO_CENTER(RegionName, DistanceFromCenter.toString()) }
                </Text>
                <ShowOnMapButton
                  style={ { marginTop: '12px' } }
                  qaAttr={ QA_ATTRIBUTES.hotels.current.showOnMap }
                  onClick={ () => this.setShowMap(true) }
                />
              </div>
            </div>
            <div className={ styles.gallery }>
              { this.renderGallery() }
            </div>
          </StyledWrapperBookMark>
          { this.renderRecommendedHotel(hotel) }
          { this.renderImportantInformation() }
          { this.renderHotelItems(hotel) }
          { this.renderMap() }
          { this.renderFormHotelSearchDialog() }
        </div>
      </>
    );
  }

  renderLoading = () => (
    <PageLoader qaAttr={ QA_ATTRIBUTES.hotels.result.loading } text={ LABELS.SEARCH } />
  );

  render() {
    const { loading } = this.state;

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        { this.renderPage() }
      </div>
    );
  }
}

export default HotelResultPage;
