import { Moment } from 'moment';

import { SmartdeskStore, SmartdeskStoreType } from './store';

import { PATTERN } from '../../constants/dateFormats';

import { IResponseAnalytics } from './types';

class Smartdesk {
  api: any;
  xhr: any;
  store: SmartdeskStoreType;

  constructor(api: any) {
    this.api = api.trips;
    this.xhr = null;
    this.store = SmartdeskStore;
  }

  getTripsInCalendar = (params: any) => {
    let lDate = params.startDate;
    let rDate = params.endDate;

    if (typeof lDate === 'object') {
      lDate = lDate.format(PATTERN.YEARMONTHDAY);
    }

    if (typeof rDate === 'object') {
      rDate = rDate.format(PATTERN.YEARMONTHDAY);
    }

    return this.api.getTripsInCalendar({ startDate: lDate, endDate: rDate })
      .then(this.store.setTripsInCalendar);
  };

  setLoading = (value: boolean) => this.store.setLoading(value);

  setSearchSmartdesk = (value: boolean): void => this.store.setSearchSmartdesk(value);

  getReservationNotepad = async () => {
    this.store.setIsNotepad(false);

    try {
      const res = await this.api.getReservationNotepad();

      this.store.getReservationNotepad(res);
      this.store.setIsNotepad(true);
    } catch (e) {
      this.store.setIsNotepad(false);
    }
  };

  setCompanies = async (value: number) => {
    await this.getTripsSmartagentPanel(String(value));

    this.store.selectCompany(value);

    this.store.selectIndexCompany(value);
  };

  getTripsSmartagentPanel = async (query: string = '') => {
    this.store.setIsTrips(false);

    try {
      const res = await this.api.getTripsSmartagentPanel(query);

      this.store.setTrips(res);
      this.store.setIsTrips(true);
    } catch (e) {
      this.store.setIsTrips(false);
    }
  };

  getAnalyticsSmartagentPanel = async ({ startDate, endDate, companyIds }: IResponseAnalytics) => {
    try {
      const res = await this.api.getAnalyticsSmartagentPanel({ startDate, endDate, companyIds });

      this.store.setAnalyticsSmartagent(res);
      this.store.setErrorAnalytics(false);
    } catch (e) {
      this.store.setErrorAnalytics(true);
    }
  };

  getCompanies = async () => {
    try {
      const res = await this.api.getCompanies();

      this.store.getCompanies(res);
      this.store.setErrorAnalytics(false);
    } catch (e) {
      this.store.setErrorAnalytics(true);
    }
  };

  changeSelectedCompanies = (value: []) => {
    this.store.changeSelectedCompanies(value);
  };

  changeStartDate = (value: Moment) => {
    this.store.changeStartDate(value);
  };

  changeEndDate = (value: Moment) => {
    this.store.changeEndDate(value);
  };

  selectCompany = (value: number) => {
    this.store.selectCompany(value);
  };
}

export default Smartdesk;
