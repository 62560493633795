import React, { useState, useRef } from 'react';
import { History } from 'history';
import { Moment } from 'moment';
import {
  Tag,
  Text,
  Button,
  Paginate,
  Dialog,
  LinkButton,
  NoResults,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import App from '../../../../bi/services/app';
import Airline from '../../../../bi/services/airline';
import FeatureFlags from '../../../../bi/services/featureFlags';

import { AirlineFilters } from '../../../AirlineResult/components/filters';
import { SortSelect } from '../../../../components/SortSelect';
import AirlineSearchItem from '../../../../components/AirlineSearchItem';
import { ChangeAirTripFormOther } from '../../../../components/ChangeAirTripFormOther';

import slideToTop from '../../../utils/scrollToTop';
import MoneyFormat from '../../../../bi/utils/money';
import { stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import parseUnix from '../../../../bi/utils/parseDateTime';
import { formatDate, formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';

import ROUTES from '../../../../bi/constants/routes';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import AIRFILTERTYPE from '../../../../bi/constants/tagsFilterType';
import { CHANGE_AIR_TRIP_FIELDS } from '../../../../bi/constants/airline';

import {
  AirlineCacheItem,
  AirlineResultData,
  AirlineSourceWithOriginalPrice,
  IFavoriteInfo,
} from '../../../../bi/types/airline';
import { ChatService } from '../../../../bi/types/chat';
import { ITravelPolicyListItem } from '../../../../bi/types/travelPolicy';
import { Filter } from '../../../AirlineResult/components/types';
import { OrderItem } from '../../../../bi/types/order';
import { UserSessionService } from '../../../../bi/types/userSession';
import { WorkspaceService } from '../../../../bi/types/workspace';

import styles from './styles/index.module.css';

const LABELS = {
  NO_MATCHING_TICKET: getText('components:changeAirTrip.noMatchingTicket'),
  NOT_FOUND: getText('air:result.notFound'),
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  CHANGE_PARAMS_OF_FILTERS: getText('air:result.changeParamsOfFilters'),
  RESET_FILTERS: getText('air:result.resetFilters'),
  FAVORITE_NOT_FOUND_MESSAGE: {
    ONLY_FROM: (number: string, date: string) =>
      getText('air:result.favoriteNotFoundMessages.onlyFrom', { number, date }),
    FROM_AND_BACK: (number: string, date: string) =>
      getText('air:result.favoriteNotFoundMessages.fromAndBack', { number, date }),
  },
  FLIGHT: getText('air:result.flight'),
  OF: getText('air:result.of'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        item: itemTest,
        itemWrapper: itemWrapperTest,
        sortSelect,
        openOtherRequest,
        dialogOtherRequest,
        filters: filtersTest,
        notFound,
        notFoundParamsSearch,
        notFoundParamsFilter,
        resetParamsFilter,
      },
    },
  },
} = QA_ATTRIBUTES;

interface IChangeAirTripResultProps {
  airlineService: Airline;
  workspaceService: WorkspaceService;
  featureFlagsService: FeatureFlags,
  userSessionService: UserSessionService;
  chatService: ChatService;
  appService: App;
  travelPolicyList: ITravelPolicyListItem[];
  history: History;
  tripItem?: OrderItem | null;
  dataInfo?: AirlineResultData | null;
  tripId: string;
  isComplex: boolean;
  onAddTicketChanged: (item: AirlineCacheItem, currentFareId: string) => void;
}

const ChangeAirTripResult = ({
  airlineService,
  workspaceService,
  featureFlagsService,
  userSessionService,
  chatService,
  appService,
  travelPolicyList,
  history,
  tripItem = null,
  dataInfo = null,
  tripId,
  isComplex,
  onAddTicketChanged,
}: IChangeAirTripResultProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const isFavoriteLastPathRef = useRef(false);

  const preparedLabelOfFavorite = (
    numbers: string[],
    { from, to }: { from: Moment; to: Moment },
    { from: airlineFrom, to: airlineTo }: { from: Moment; to: Moment },
  ) => {
    const { ONLY_FROM, FROM_AND_BACK } = LABELS.FAVORITE_NOT_FOUND_MESSAGE;

    const airlineNumbers = numbers
      .map((item, index) => (index === 0 ? `${airlineFrom} ${item}` : `${airlineTo} ${item}`))
      .join(', ');

    if (numbers.length === 1) {
      return ONLY_FROM(airlineNumbers, formatDate(from, PATTERN.DAY_OF_MONTH));
    }

    return FROM_AND_BACK(airlineNumbers, formatRangeDateWithSimplicity(from, to));
  };

  const handleUpdateFilters = (field: string, changes: any) => {
    if (field === AIRFILTERTYPE.FLIGHTSNUMBERS) {
      airlineService.setFlightsNumbersValue(changes);
    }

    airlineService.setResultValue(`${CHANGE_AIR_TRIP_FIELDS.SEARCH_FILTERS}.${field}`, changes);
  };

  const handleGetLinkAction = (item: AirlineSourceWithOriginalPrice) => {
    const url = ROUTES.SEARCH.AIR;
    const { travellers: travelers, routes, isDirect, flightClass } = airlineService.getSearch();
    const segments = item.Routes.map((route) => route.Segments[0]);

    const mapRoutes = item.Routes.map(({ Segments }) => {
      const { ArrivalAirport, DepartureAirport } = Segments[0];

      return {
        Arrival: { Code: ArrivalAirport.ID, Name: ArrivalAirport.Name },
        Departure: { Code: DepartureAirport.ID, Name: DepartureAirport.Name },
        Segments,
      };
    });

    const mappedItem = {
      Routes: mapRoutes,
      Class: flightClass,
      isDirect,
    };

    // @ts-ignore
    const searchParams = airlineService.getSearchObject(mappedItem, { dateFrom: routes[0].date, dateTo: routes[0].dateBack, travelers });
    const searchString = stringifySearchParams(searchParams);
    const link = window.location.origin + history.createHref({ pathname: url, search: searchString });
    let description = '';

    segments.forEach((segment) => {
      const departureInfo = `${segment.FlightNumber} ${segment.DepartureCity} ${segment.DepartureAirport.ID}`;
      const arrivalInfo = `${segment.ArrivalCity} ${segment.ArrivalAirport.ID}`;
      const departureTime = parseUnix(segment.DepartureTime).format(PATTERN.DAY_WITH_DIGITALMONTH);
      const arrivalTime = parseUnix(segment.ArrivalTime).format(PATTERN.DAY_WITH_DIGITALMONTH);

      description += `\n${LABELS.FLIGHT} ${departureInfo} - ${arrivalInfo}, ${departureTime} - ${arrivalTime}, `;
    });

    description += `${LABELS.OF} ${MoneyFormat.money(item.Price.TotalPrice, true)}`;

    return `${description} \n\n${link}`;
  };

  const handlePageChange = (page: number) => {
    airlineService.setResultValue(CHANGE_AIR_TRIP_FIELDS.SEARCH_PAGING, page);
    slideToTop();
  };

  const handleResetFilters = () => {
    isFavoriteLastPathRef.current = false;
    airlineService.resetFilters();
  };

  const renderDialog = () => {
    if (!showDialog) return null;

    return (
      <Dialog
        qaAttr={ dialogOtherRequest }
        show={ showDialog }
        showClosing
        onChange={ () => setShowDialog(false) }
      >
        <ChangeAirTripFormOther
          chatService={ chatService }
          history={ history }
          tripId={ tripId }
          tripItem={ tripItem }
          isDemo={ workspaceService.isDemo }
        />
      </Dialog>
    );
  };

  const renderLinkButton = () => {
    const { sources, items } = dataInfo as AirlineResultData;
    const styleButton = !sources.length || !items.length ? styles.change_in_no_results : styles.other_change;

    return (
      <LinkButton
        qaAttr={ openOtherRequest }
        className={ styleButton }
        theme='red'
        onClick={ () => setShowDialog(true) }
      >
        { LABELS.NO_MATCHING_TICKET }
      </LinkButton>
    );
  };

  const renderNoResults = () => (
    <div className={ styles.wrapper_no_results }>
      { renderLinkButton() }
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results }>
            <Text qaAttr={ notFound } type='bold_18'>
              { LABELS.NOT_FOUND }
            </Text>
            <Text qaAttr={ notFoundParamsSearch } className={ styles.subtext }>
              { LABELS.CHANGE_PARAMS_OF_SEARCH }
            </Text>
          </div>
        ) }
      />
      { renderDialog() }
    </div>
  );

  const renderFullPage = (html: JSX.Element) => {
    const {
      filters,
      routeInfo,
      isAnyFavorite,
      unavailableTravelPolicy,
    } = dataInfo as AirlineResultData;

    const airlinesDictionary = Object.assign({}, ...(userSessionService.get().enums.bonus?.Air ?? [])
      .map(({ Code }) => ({ [Code.toLowerCase()]: Code })));

    return (
      <div className={ styles.result }>
        <AirlineFilters
          airlineService={ airlineService }
          isComplex={ isComplex }
          filters={ filters as Filter }
          travelPolicyList={ travelPolicyList }
          routeInfo={ routeInfo }
          isAnyFavorite={ isAnyFavorite }
          unavailableTravelPolicy={ unavailableTravelPolicy }
          airlinesDictionary={ airlinesDictionary }
          updateFilters={ handleUpdateFilters }
          isChangeAirTrip
          onBlurAirlineNumber={ () => {} }
          qaAttrs={ filtersTest }
        />
        { html }
      </div>
    );
  };

  const renderFilterEmptyPanel = () => {
    const { favorite } = dataInfo as AirlineResultData;

    const { numbers, dates, airline } = favorite as IFavoriteInfo || {
      numbers: [],
      dates: { from: null, to: null },
      airline: { from: null, to: null },
    };

    const title = isFavoriteLastPathRef.current || favorite
      ? preparedLabelOfFavorite(numbers, dates, airline)
      : LABELS.NOT_FOUND;

    const subtext = isFavoriteLastPathRef.current
      ? LABELS.CHANGE_PARAMS_OF_SEARCH
      : LABELS.CHANGE_PARAMS_OF_FILTERS;

    return (
      <div className={ styles.wrapper_no_results }>
        { renderLinkButton() }
        <NoResults
          renderContent={ () => (
            <div className={ styles.no_results }>
              <Text qaAttr={ notFound } type='bold_18'>
                { title }
              </Text>
              <Text qaAttr={ notFoundParamsFilter } className={ styles.subtext }>
                { subtext }
              </Text>
              <Button
                qaAttr={ resetParamsFilter }
                type='primary-outline'
                onClick={ handleResetFilters }
              >
                { LABELS.RESET_FILTERS }
              </Button>
            </div>
          ) }
        />
        { renderDialog() }
      </div>
    );
  };

  const renderEmptyFilter = () => renderFullPage(renderFilterEmptyPanel());

  const renderList = () => {
    const {
      sortBy,
      items,
      tags,
      paging,
      travelPolicyAllList,
      filters,
    } = dataInfo as AirlineResultData;

    const sortItems = airlineService.sortChangeAirTrip;

    const { travellers } = airlineService.getSearch();

    const ticketHtml = items.map((ticket, index) => (
      <AirlineSearchItem
        index={ index }
        key={ ticket.Id }
        item={ ticket as AirlineSourceWithOriginalPrice }
        appService={ appService }
        workspaceService={ workspaceService }
        featureFlagsService={ featureFlagsService }
        preventDuplication={ [] }
        selectedTravelPolicy={ filters.selectedTravelPolicy }
        travelPolicyList={ travelPolicyAllList }
        isComplex={ isComplex }
        hideBookmarks
        travellers={ travellers }
        onUpdateCheckbox={ (value, route) => airlineService.setResultCheckbox(value, route) }
        onAddToCart={ onAddTicketChanged }
        onAddToNotepad={ () => {} }
        onAddToFavorite={ () => {} }
        onGetLink={ handleGetLinkAction }
        onCopyLink={ () => {} }
        isChangeAirTrip
        qaAttrItem={ itemTest }
        qaAttrItemWrapper={ itemWrapperTest }
      />
    ));

    const tagsHtml = !!tags.length && tags.map((tag) => {
      const { name, key, readOnly } = tag;

      return (
        <Tag
          label={ name }
          readOnly={ readOnly }
          onClick={ () => airlineService.deleteTags(tag) }
          key={ key }
          className={ styles.tag }
          qaAttr={ tag.qaAttr }
        />
      );
    });

    const html = (
      <div className={ styles.content }>
        <div className={ styles.header }>
          <div className={ styles.main }>
            <div className={ styles.select }>
              <SortSelect
                items={ sortItems }
                value={ sortBy }
                onChange={ (value) => airlineService.setResultValue(CHANGE_AIR_TRIP_FIELDS.SEARCH_SORT, value) }
                qaAttr={ sortSelect }
              />
            </div>
            <div className={ styles.tags } >
              { tagsHtml }
            </div>
          </div>
        </div>
        { renderLinkButton() }
        <div className={ styles.list }>
          <div className={ styles.items }>
            { ticketHtml }
          </div>
          <Paginate
            total={ paging.total }
            page={ paging.current }
            itemsPerPage={ paging.count }
            onChange={ handlePageChange }
          />
        </div>
        { renderDialog() }
      </div>
    );

    return renderFullPage(html);
  };

  if (!dataInfo) return null;

  const { sources, items } = dataInfo;

  if (!sources.length) return renderNoResults();

  if (!items.length) return renderEmptyFilter();

  return renderList();
};

export { ChangeAirTripResult };
