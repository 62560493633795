import ACTION from './action';
import Store from '../../../store';

export interface IChatStore {
  show: boolean;
  sendingMessageToAdmin: boolean;
  isRequesting: boolean;
  isCarrotQuest: boolean;
  isSmartChat: boolean;
}

const createNewState = (): IChatStore => ({
  show: false,
  sendingMessageToAdmin: false,
  isRequesting: false,
  isCarrotQuest: false,
  isSmartChat: false,
});

const reducer = (action: { [key: string]: any }, state: IChatStore) => {
  switch (action.type) {
    case ACTION.UPDATECHATSTATE: {
      return {
        ...state,
        show: action.payload === 'show',
      };
    }

    case ACTION.STARTSENDINGMESSAGETOADMIN: {
      return {
        ...state,
        sendingMessageToAdmin: true,
        isRequesting: true,
      };
    }

    case ACTION.ENDSENDINGMESSAGETOADMIN: {
      return {
        ...state,
        sendingMessageToAdmin: false,
        isRequesting: false,
      };
    }

    case ACTION.CHANGECHAT: {
      return {
        ...state,
        isCarrotQuest: action.payload.isCarrotQuest,
        isSmartChat: action.payload.isSmartChat,
      };
    }

    default: {
      return state;
    }
  }
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
