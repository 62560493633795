import React from 'react';

import { CONSTRUCTORS } from '../protocols';
import { servicesProtocolsContext } from './context';

export type ProtocolConstructorsList = typeof CONSTRUCTORS;
type ProtocolConstructorsKeys = keyof ProtocolConstructorsList;

export type UseProtocolReturnType<Names extends ProtocolConstructorsKeys> = {
  [i in Names as `${Uncapitalize<i & string>}Protocol`]: InstanceType<ProtocolConstructorsList[i]>
};

export type ProtocolReturnType<Names extends ProtocolConstructorsKeys = ProtocolConstructorsKeys> = {
  [i in Names]: InstanceType<ProtocolConstructorsList[i]>
};

export const useProtocols = <Names extends ProtocolConstructorsKeys>(
  names: Names[] = [],
) => {
  const ctx = React.useContext(servicesProtocolsContext);

  if (ctx === null) throw Error('Called outside ProtocolProvider.');

  return names.reduce((r, protocolClass) => {
    const service = ctx.getProtocol(protocolClass);

    const serviceName = `${protocolClass.charAt(0).toLowerCase() + protocolClass.slice(1)}Protocol`;

    return { ...r, [serviceName]: service };
  }, {}) as UseProtocolReturnType<Names>;
};
