import { MomentInput } from 'moment';
import { HTTPError } from 'superagent';

import { getText } from '../../../../i18n';

import Api from '../../api';

import OperationsStore from './oparationsStore';
import DocumentPackagesStore from './packagesStore';
import { RequestDialogStore } from './requestDialogStore';
import { approveReportStore } from './approveReportStore';

import { formatDate } from '../../utils/formatDate';
import { bound, withInternalMethod } from '../utils/dectrators';
import NetworkStatesStore from '../utils/network/networkStatesStore';

import { UPDACTION, OPERATIONSACTION } from './action';
import { COMMAREGEXP, WHITESPACES } from '../../constants/regExp';
import { PATTERN } from '../../constants/dateFormats';
import { ERROR_CODE } from '../../constants/app';
import FORMATS from '../../constants/formats';

import {
  IAllSourceInvoice,
  IInvoiceDebitData,
  IOperationsList,
  IOperationStore,
  IPackagesStore,
  ISourceOperationsList,
  ITotalOperations,
  SendInvoiceProps,
  IDownloadSpecifiedReport,
  PackagesItems,
} from '../../types/report';
import { DateType, DateWithoutMomentType, IMultiSelectWithMainList } from '../../types/shared';

const LABELS = {
  SUCCESSFUL_MESSAGE: getText('reports:reportAct.successfulMessage'),
  UNSUCCESSFUL_MESSAGE: getText('reports:reportAct.unsuccessfulMessage'),
  NO_DATA: getText('approve:errors.noData'),
  ERROR: getText('approve:errors.error'),
};

const INVOICEDIALOG = 'invoiceDialog';

const prepareAllInvoices = (allInvoices: IAllSourceInvoice[]) =>
  allInvoices.map((item) =>
    ({ label: item.InvoiceNum, value: JSON.stringify(item) }),
  );

class Report {
  api: Api['report'];

  ns = new NetworkStatesStore<'getReportApprove'>();
  loadDocumentPackageXhr: any = null;
  xhr: any = null;

  approveReportStore = approveReportStore;
  operationStore = OperationsStore();
  packagesStore = DocumentPackagesStore();
  requestDialogStore = RequestDialogStore;
  loadError: string | null | HTTPError = null;

  constructor(api: Api) {
    this.api = api.report;
  }

  get = (): IOperationStore => this.operationStore.getState(); // есть вероятность, что нигде не используется, т.к. есть дубль getOperations

  getOperations = (): IOperationStore => this.operationStore.getState();

  getPackages = (): IPackagesStore => this.packagesStore.getState();

  getInvoiceDialogSettings = () => JSON.parse(localStorage.getItem(INVOICEDIALOG) as string) || {};

  setLoadError = (error: string | null | HTTPError) => {
    this.loadError = error;
  };

  setInvoiceDialogSettings = (field: string, value: string): void => {
    const settings = this.getInvoiceDialogSettings() || {};

    settings[field] = value;

    localStorage.setItem(INVOICEDIALOG, JSON.stringify(settings));
  };

  loadOperations = async (companyId: number): Promise<void> => {
    this.operationStore.dispatch({
      type: OPERATIONSACTION.STARTLOADOPERATION,
    });

    try {
      const res = await this.api.lastInvoice(companyId);
      this.operationStore.dispatch({
        type: OPERATIONSACTION.LOADCOMPLETE,
        payload: res,
      });
    } catch {}
  };

  loadOperationsByDateRange = (startDate: DateType, endDate: DateType, companyId: number): void => {
    const date = {
      start: startDate,
      end: endDate,
    };

    if (typeof startDate !== 'string') {
      date.start = startDate.format('YYYY-MM-DD');
    }

    if (typeof endDate !== 'string') {
      date.end = endDate.format('YYYY-MM-DD');
    }

    return this.api.getOperationsByDateRange(date.start, date.end, companyId).then((res: ISourceOperationsList[]) => {
      this.operationStore.dispatch({
        type: OPERATIONSACTION.LOADRANGEOPERATIONSCOMPLETE,
        payload: res,
      });
    });
  };

  loadTotalOperations = (companyId: number): void =>
    this.api.getBalance(companyId).then((res: ITotalOperations) => // TODO: this.api.getBalance (используется два раза, (и нужны доп параметры, которые тут не пробрасываются))
      this.operationStore.dispatch({
        type: OPERATIONSACTION.LOADBALANCE,
        payload: res,
      }));

  getInvoiceRequisites = (companyId: number): void => this.api.getInvoiceRequisites(companyId).then((res: string) => {
    if (res) {
      this.operationStore.dispatch({
        type: OPERATIONSACTION.INVOICE_REQUISITES,
        payload: res,
      });
    }
  });

  loadTotalOperationsByDate = async (companyId: number, startDate: DateType, endDate: DateType): Promise<void> => {
    const date = {
      start: formatDate(startDate, PATTERN.YEARMONTHDAY),
      end: formatDate(endDate, PATTERN.YEARMONTHDAY),
    };

    try {
      const res = await this.api.getBalance(companyId, date.start, date.end);

      this.operationStore.dispatch({
        type: OPERATIONSACTION.LOADBALANCE,
        payload: res,
      });

      this.loadOperationsByDateRange(date.start, date.end, companyId);
    } catch (e) {
    }
  };

  loadDocumentPackages = (companyId: number): Promise<void> | null => {
    if (this.loadDocumentPackageXhr) {
      this.loadDocumentPackageXhr.abort();
    }

    this.loadDocumentPackageXhr = this.api.getDocumentPackages(companyId);
    this.loadDocumentPackageXhr.then((res: ISourceOperationsList[]) => {
      this.packagesStore.dispatch({
        type: UPDACTION.LOADCOMPLETE,
        payload: res,
      });
    });

    return this.loadDocumentPackageXhr;
  };

  getTotalCountDocumentPackages = async (companyId: number) => {
    try {
      const count: number = await this.api.getTotalCountDocumentPackages(companyId);
      this.packagesStore.dispatch({
        type: UPDACTION.GET_TOTAL_COUNT_DOCUMENT_PACKAGES,
        payload: count,
      });
    } catch (e) {
      this.packagesStore.dispatch({
        type: UPDACTION.GET_TOTAL_COUNT_DOCUMENT_PACKAGES,
        payload: 0,
      });
    }
  };

  getDocumentPackagesByPage = async (companyId: number, page: number) => {
    try {
      const data: PackagesItems[] = await this.api.getDocumentPackagesByPage(companyId, page);
      this.packagesStore.dispatch({
        type: UPDACTION.GET_DOCUMENT_PACKAGES_BY_PAGE,
        payload: data,
      });
    } catch (e) {
      this.packagesStore.dispatch({
        type: UPDACTION.GET_DOCUMENT_PACKAGES_BY_PAGE,
        payload: [],
      });
    }
  };

  getAllInvoices = async (companyId: number): Promise<void> => {
    this.operationStore.dispatch({
      type: OPERATIONSACTION.GET_ALL_INVOICE,
      payload: { loadingAllInvoices: true, allInvoices: [] },
    });

    try {
      const res: IAllSourceInvoice[] = await this.api.getAllInvoices(companyId);

      this.operationStore.dispatch({
        type: OPERATIONSACTION.GET_ALL_INVOICE,
        payload: { loadingAllInvoices: false, allInvoices: prepareAllInvoices(res) },
      });
    } catch (e) {
      this.operationStore.dispatch({
        type: OPERATIONSACTION.GET_ALL_INVOICE,
        payload: {
          loadingAllInvoices: false,
          allInvoices: [],
        },
      });
    }
  };

  getLastInvoice = (companyId: number): Promise<void> => this.api.getLastInvoice(companyId);

  getDeposit = (companyId: number, sum: string, format: string): void => {
    const sumWithDotWithoutSpace = sum.replace(COMMAREGEXP, '.').replace(WHITESPACES, '');

    return this.api.getDeposit(companyId, sumWithDotWithoutSpace, format);
  };

  sendInvoice = (params: SendInvoiceProps): Promise<void> => {
    const {
      email,
      format,
      companyId,
      invoiceNumber,
      packageId,
      documentId,
    } = params;

    if (packageId && documentId) {
      return this.api.sendInvoice({
        Email: email,
        Format: format,
        CompanyId: companyId,
        Package: {
          PackageId: packageId,
          DocumentId: documentId,
        },
      });
    }

    return this.api.sendInvoice({
      Email: email,
      Format: format,
      Number: invoiceNumber,
      CompanyId: companyId,
    });
  };

  downloadVoucher = (orderId: number): Promise<void> => this.api.downloadVoucher(orderId);

  downloadVoucherLocale = (orderId: number, locale: string): Promise<void> => this.api.downloadVoucherLocale(orderId, locale);

  downloadAllInvoiceByData = (companyId: number, packageId: number): void =>
    this.api.downloadAllInvoiceByData(companyId, packageId);

  downloadServiceForm = (tripItemId: number, serviceId: number | null): Promise<void> =>
    this.api.downloadServiceForm(tripItemId, serviceId);

  downloadTrip = (id: number): void => this.api.downloadTrip(id);

  downloadPaymentDetails = (
    currentCompany: number,
    startDate: DateWithoutMomentType,
    endDate: DateWithoutMomentType,
    periodType: string,
  ): void =>
    this.api.downloadPaymentDetails(currentCompany, startDate, endDate, periodType);

  downloadOriginalTrip = (id: number) => this.api.downloadOriginalTrip(id);

  downloadInvoice = (companyId: number | string, operation: IOperationsList | string, format: string = FORMATS.PDF): void => {
    let num = '';

    if (typeof operation === 'string') num = operation;
    else num = operation.InvoiceNum;

    if (!num.includes('/')) {
      return this.api.downloadInvoiceWithoutSlash(companyId, num, format);
    }

    return this.api.downloadInvoice(companyId, num, format);
  };

  downloadPenalty = (companyId: number, operationId: number, format: string = FORMATS.PDF): void =>
    this.api.downloadPenalty(companyId, operationId, format);

  downloadInvoiceDebt = (companyId: number, data: IInvoiceDebitData): void =>
    this.api.downloadInvoiceDebt(companyId, data);

  downloadInvoiceForDateRange = (
    companyId: number,
    startDate: DateType,
    endDate: DateType,
    format: string = FORMATS.PDF,
  ): void => {
    const startDateStr = typeof startDate !== 'string' ? startDate.format('YYYY-MM-DD') : startDate;
    const endDateStr = typeof endDate !== 'string' ? endDate.format('YYYY-MM-DD') : endDate;

    this.api.downloadInvoiceForDateRange(companyId, startDateStr, endDateStr, format);
  };

  downloadDocument = (companyId: number, packageId: number, documentId: number, format: string): void =>
    this.api.downloadDocument(companyId, packageId, documentId, format);

  downloadNote = (params: number[]): Promise<void> => this.api.downloadNote(params);

  downloadSpecifiedReportGsprom = (data: IDownloadSpecifiedReport): void =>
    this.api.downloadSpecifiedReportGsprom(data);

  downloadSpecifiedReportSevmash = (data: IDownloadSpecifiedReport): void =>
    this.api.downloadSpecifiedReportSevmash(data);

  subscribeOperations = (cb: any) => this.operationStore.subscribe(cb);

  subscribePackages = (cb: any) => this.packagesStore.subscribe(cb);

  setDownloadReportDialog = (show: boolean, loading: boolean): void =>
    this.approveReportStore.setDownloadDialog(show, loading);

  setErrorReportDialog = (show: boolean, msg: string): void => this.approveReportStore.setErrorDialog(show, msg);

  downloadReport = (startDate: MomentInput, endDate: MomentInput, companies: IMultiSelectWithMainList[], type: string): void => {
    const selectCompanies: number[] = companies.map(({ main }) => Number(main));
    const selectDepartments: number[] = companies
      .reduce((r: number[], { nested }) =>
        [...r, ...(nested || []).map(id => Number(id))], [],
      );

    const params = {
      Companies: selectCompanies,
      Departments: selectDepartments,
      StartDate: formatDate(startDate, PATTERN.YEARMONTHDAY),
      EndDate: formatDate(endDate, PATTERN.YEARMONTHDAY),
      MainSheet: type,
    };

    return this.api.downloadApproveReport(params);
  };

  downloadReportApprove = async (
    startDate: MomentInput,
    endDate: MomentInput,
    companies: IMultiSelectWithMainList[],
    type: string,
  ): Promise<void> => {
    const { downloadDialog: { show } } = this.approveReportStore;

    this.setDownloadReportDialog(show, true);

    try {
      this.downloadReport(startDate, endDate, companies, type);

      this.setDownloadReportDialog(false, false);

      return await Promise.resolve();
    } catch (err: any) {
      const msg = err.status === ERROR_CODE.NOT_FOUND ? LABELS.NO_DATA : LABELS.ERROR;

      this.setErrorReportDialog(true, msg);
      this.setDownloadReportDialog(false, false);

      return Promise.reject(err);
    }
  };

  downloadApproveReportFail = (err: HTTPError) => {
    const msg = err.status === ERROR_CODE.NOT_FOUND ? LABELS.NO_DATA : LABELS.ERROR;

    this.setErrorReportDialog(true, msg);
    this.setDownloadReportDialog(false, false);
  };

  @bound
  @withInternalMethod((o) =>
    o.ns.withLoaderFlow('getReportApprove', o.downloadApproveReportFail),
  )
  async downloadApproveReport(
    startDate: MomentInput, endDate: MomentInput, companies: IMultiSelectWithMainList[], type: string,
  ) {
    const show = this.approveReportStore.downloadDialog.show;

    this.setDownloadReportDialog(show, true);

    try {
      await this.downloadReport(startDate, endDate, companies, type);
      this.setDownloadReportDialog(false, false);
      this.setLoadError(null);
    } catch (error) {
      this.setLoadError(error as HTTPError);
      this.setDownloadReportDialog(true, false);
    }
  }

  requestAct = ({
    companyId,
    startDate,
    endDate,
    format,
    isDetailed,
    companiesIds,
    reportEmail,
  }: {
    companyId: number,
    startDate: string,
    endDate: string,
    format: string,
    isDetailed: number,
    companiesIds: number[],
    reportEmail: string,
  }): void => {
    const data = {
      startDate,
      endDate,
      isDetailed,
      companiesIds,
      reportEmail,
    };

    return this.api.requestAct(companyId, format, data);
  };

  setShowRequestDialog = (value: boolean): void => {
    this.requestDialogStore.setShowRequestDialog(value);
  };

  setShowRequestErrorDialog = (value: boolean): void => {
    this.requestDialogStore.setShowRequestErrorDialog(value);
  };

  sendRequestAct = async (currentCompany: number) => {
    const {
      date,
      dateTo,
      detailed,
      format,
      selectedCompanies,
      reportEmail,
    } = this.requestDialogStore;

    const companiesIds = selectedCompanies.length ? selectedCompanies : [currentCompany];

    const data = {
      startDate: formatDate(date, PATTERN.YEARMONTHDAY),
      endDate: formatDate(dateTo, PATTERN.YEARMONTHDAY),
      isDetailed: detailed,
      format,
      companiesIds,
      reportEmail,
    };

    this.requestDialogStore.setLoadingRequestDialog(true);

    try {
      await this.requestAct({
        companyId: currentCompany,
        ...data,
      });

      this.requestDialogStore.setLoadingRequestDialog(false);
      this.setShowRequestDialog(false);
    } catch (e) {
      this.requestDialogStore.setLoadingRequestDialog(false);
      this.setShowRequestDialog(false);
      this.setShowRequestErrorDialog(true);
    }
  };

  downloadReportAct = async (guid: string): Promise<void> => {
    if (guid) {
      try {
        this.requestDialogStore.setLoadingReportAct(true);
        await this.api.downloadReportAct(guid);

        this.requestDialogStore.setLoadingReportAct(false);
        this.requestDialogStore.setReportAct({
          Success: true,
          Message: LABELS.SUCCESSFUL_MESSAGE,
        });
      } catch (e) {
        this.requestDialogStore.setLoadingReportAct(false);
        this.requestDialogStore.setReportAct({
          Success: false,
          Message: LABELS.UNSUCCESSFUL_MESSAGE,
        });
      }
    }
  };

  downloadSelectedDocuments = (companyId: number, docsIds: number[], format: string): Promise<void> =>
    this.api.downloadSelectedDocuments(companyId, docsIds, format);
}

export default Report;
