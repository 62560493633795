import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { getAdvantagesOfTime, getAdvantagesOfPrice, getShortTypeText } from '../../../../bi/utils/train';
import { momentObject, formatDate } from '../../../../bi/utils/formatDate';

import { PATTERN, DATEFORMATS } from '../../../../bi/constants/dateFormats';

import { TrainRouteDetailsProps } from '../types';

import { TrainDetails } from '../../../../bi/types/trains';

import styles from './index.module.css';

const LABELS = {
  DIRECT_TIME: getText('trains:results.routeDetails.directTime'),
  LOCAL_DIRECT_TIME: getText('trains:results.routeDetails.localDirectTime'),
  CHANGE_DURATION: getText('trains:results.routeDetails.changeDuration'),
};

const TrainRouteDetails = ({ route, currentRoute }: TrainRouteDetailsProps) => {
  const countDuration = (list: TrainDetails[]) => list.reduce((acc, { ChangeDuration }) => acc + ChangeDuration, 0);

  const { isAdvantages, text } = getAdvantagesOfTime(countDuration(route.Trains), countDuration(currentRoute.Trains));
  const changeDuration = isAdvantages ? <b>{ text }</b> : text;

  const advantagesOfPrice = (first: number, second: number) => {
    const { isAdvantages: isAdvantagesPrice, text: textPrice } = getAdvantagesOfPrice(first, second);

    return isAdvantagesPrice ? <b>{ textPrice }</b> : textPrice;
  };

  const typesHtml = route.Classes.reduce<React.ReactNode[]>((acc, { Type, Price }, index) => {
    const currentPrice = currentRoute.Classes.find((item) => item.Type === Type);

    if (currentPrice) {
      const type = getShortTypeText(Type, false);
      const html = (
        <div className={ styles.text } key={ `ticket_route_details_${index}_${Type}` }>
          { type }: { advantagesOfPrice(Price, currentPrice.Price) }
        </div>
      );

      return [...acc, html];
    }

    return acc;
  }, []);

  const firstTransferDateLocal = momentObject(route.Trains[0].DepartureDateLocal);
  const firstTransferDate = momentObject(route.Trains[0].DepartureDate);
  const localDirectTime = `${formatDate(firstTransferDateLocal, PATTERN.DAY_OF_MONTH)}, ${formatDate(firstTransferDateLocal, DATEFORMATS.TIME)}`;

  return (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.wrapper }
    >
      <div className={ styles.text }>
        { LABELS.LOCAL_DIRECT_TIME } { localDirectTime }
      </div>
      <div className={ styles.text }>
        { LABELS.DIRECT_TIME } { formatDate(firstTransferDate, DATEFORMATS.TIME) }
      </div>
      <div className={ styles.text }>
        { LABELS.CHANGE_DURATION } { changeDuration }
      </div>
      { typesHtml }
    </Text>
  );
};

export { TrainRouteDetails };
