import React from 'react';

import { MultiSelect, MultiSelectValuesNested, Select, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useProtocols } from '../../../../bi/context/protocol';

import { ACQUIRING_LEVEL_TEXT, FIELDRIGHTS, SHORTFIELDRIGHTS } from '../../../../bi/constants/rights';

import { isSmartAgent } from '../../../../bi/utils/env';

import {
  IApprovalShemas,
  IApprovalShemasERA,
  IStringData,
  UsersType,
  UsersTypeLigth,
} from '../../../../bi/services/employee/types';

import styles from './index.module.css';

const LABELS = {
  BY_ANY: getText('settings:employees.employee.steps.rights.byAny'),
  FOR_ALL_EMPL: getText('settings:employees.employee.steps.rights.forAllEmployees'),
  FOR_MYSELF: getText('settings:employees.employee.steps.rights.forMyself'),
  CREATE_AO: getText('settings:employees.employee.steps.rights.createAO'),
};

type TRights = {
  id: string,
  label: string
  name?: string,
};

const DEFAULT_TP = [{
  value: '',
  label: '',
}];

interface IListRightsTexts {
  rights: TRights[],
  approveUsers: string[],
  allApproveUsers: IStringData[],
  travelPolicies?: UsersTypeLigth[],
  approvalSchemes: IApprovalShemasERA[],
  approveERUsers: UsersType[],
  approvalERSchemes: IApprovalShemas[],
  viewExpenseReportApprovers: string[],
  email: string,
  travelPolicy: UsersTypeLigth | null | undefined,
  approvalScheme?: number,
  chosenApprovalERScheme?: number,
  buyTravelPolicy: boolean,
  buyAfterApprove: boolean,
  buyTripAccount: boolean,
  buyWithPersonalFunds: string,
  createERApprovalScheme: boolean,
  createERAfterApprove: boolean,
  buyApprovalScheme?: boolean,
  canEdit?: boolean,
  onChangeTravelPolicy?(id: string | number): void,
  onChangeViewApprovers?(value: MultiSelectValuesNested[] | string[] | number[] | string): void,
  onChangeApprovalScheme?(value: string): void,
  onChangeApprovalERScheme?(value: number | boolean | string): void,
  onChangeApproveERUsers?(value: MultiSelectValuesNested[] | string[] | number[] | string): void,
}

const ListRightsTexts = ({
  rights,
  approveUsers,
  viewExpenseReportApprovers,
  allApproveUsers,
  travelPolicies = DEFAULT_TP,
  approvalSchemes,
  email,
  travelPolicy = null,
  approvalScheme = 0,
  approveERUsers,
  approvalERSchemes,
  chosenApprovalERScheme = 0,
  createERApprovalScheme,
  createERAfterApprove,
  buyTravelPolicy,
  buyWithPersonalFunds,
  buyAfterApprove,
  buyTripAccount,
  buyApprovalScheme,
  canEdit,
  onChangeTravelPolicy = () => {},
  onChangeViewApprovers = () => {},
  onChangeApprovalScheme = () => {},
  onChangeApprovalERScheme = () => {},
  onChangeApproveERUsers = () => {},
}: IListRightsTexts) => {
  const { personalPaymentProtocolProtocol } = useProtocols(['PersonalPaymentProtocol']);

  const renderTravelPolicy = () => {
    if (!travelPolicy) {
      return null;
    }

    const { value, label } = travelPolicy;

    if (!canEdit) {
      return <Text type='bold_16'>{ label }</Text>;
    }

    return (
      <Select
        theme='open-blue'
        className={ styles.select }
        items={ travelPolicies }
        value={ value }
        onChange={ onChangeTravelPolicy }
      />
    );
  };

  const renderApprovalScheme = (schemes: { Id: number, Name: string }[], scheme: number, onChangeScheme:(value: string) => void) => {
    if (isSmartAgent) return null;

    if (!canEdit) {
      const foundScheme = schemes.find(({ Id }: { Id: number }) => Id === scheme);

      if (foundScheme) {
        return (
          <Text type='bold_16'>
            { foundScheme.Name }
          </Text>
        );
      }
    }

    const mappedList = schemes.map(({ Id, Name }: { Id: number, Name: string }) => ({ label: Name, value: Id }));

    return (
      <Select
        theme='open-blue'
        className={ styles.select }
        items={ mappedList }
        onChange={ onChangeScheme }
        value={ scheme }
      />
    );
  };

  const renderByWithPersonalFunds = () => {
    const label = ACQUIRING_LEVEL_TEXT.find(({ value }) => value === buyWithPersonalFunds)?.label;

    return (
      <Text type='bold_16'>
        { label }
      </Text>
    );
  };

  const getApproveUsersLabel = (allUsers: IStringData[], users: string[]) => allUsers.reduce((result: string[], { value, name }) => {
    if (users.find((selectedUser) => selectedUser.toLowerCase() === value.toLowerCase())) {
      return [...result, name];
    }

    return result;
  }, []).join(', ');

  const renderApproveUsers = (users: string[], allUsers: IStringData[], onChangeApprovers: (value: MultiSelectValuesNested[] | string[] | number[] | string) => void) => {
    const label = users.length ? getApproveUsersLabel(allUsers, users) : LABELS.BY_ANY;

    if (!canEdit) {
      return !!label && <>(<Text type='bold_16'>{ label }</Text>)</>;
    }

    return (
      <MultiSelect
        search
        className={ styles.multiselect }
        theme='textual-blue'
        placeholder={ LABELS.BY_ANY }
        values={ users }
        list={ allUsers }
        onChange={ onChangeApprovers }
      />
    );
  };

  const renderContent = (id: string) => {
    if (id === SHORTFIELDRIGHTS.BUYTRIP) {
      if (buyTravelPolicy && travelPolicy && !buyTripAccount) {
        return renderTravelPolicy();
      }

      if (buyAfterApprove) {
        return renderApproveUsers(approveUsers, allApproveUsers, onChangeViewApprovers);
      }

      if (buyApprovalScheme) {
        return renderApprovalScheme(approvalSchemes, approvalScheme, onChangeApprovalScheme);
      }
    }

    if (id === SHORTFIELDRIGHTS.CREATE_EXPENSE_REPORTS) {
      if (createERAfterApprove) {
        return renderApproveUsers(viewExpenseReportApprovers, approveERUsers, onChangeApproveERUsers);
      }

      if (createERApprovalScheme) {
        return renderApprovalScheme(approvalERSchemes, chosenApprovalERScheme, onChangeApprovalERScheme);
      }
    }

    if (id === SHORTFIELDRIGHTS.DOCUMENTS || id === FIELDRIGHTS.SENDDOCUMENTS) {
      return <Text type='bold_16'>{ email }</Text>;
    }

    if (id === SHORTFIELDRIGHTS.BUY_WITH_PERSONAL_FUNDS && personalPaymentProtocolProtocol.canPayWithPersonalFunds) {
      return renderByWithPersonalFunds();
    }

    return null;
  };

  const content = rights.map(({ id, label }, index: number) => {
    const preLabel = buyTripAccount ? LABELS.FOR_ALL_EMPL : LABELS.FOR_MYSELF;
    const labelHtml = id === SHORTFIELDRIGHTS.CREATE_EXPENSE_REPORTS ?
      `${LABELS.CREATE_AO} ${preLabel} ${label}` : label;

    return (
      <Text
        className={ styles.text }
        type='NORMAL_16_140'
        key={ `available_rights_${id}_${index}` }
      >
        { labelHtml }
        &nbsp;
        { renderContent(id) }
      </Text>
    );
  });

  return (
    <div className={ styles.wrap }>
      { content }
    </div>
  );
};

export { ListRightsTexts };
