// @ts-nocheck
import { getText } from '../../../../i18n';

import ROUTES from '../../constants/routes';
import {
  APPROVE_EXPENSE_REPORTS,
  APPROVETRIPRIGHT,
  FINANCEDOCUMENTSRIGHT,
  VIEWTRIPSRIGHT,
} from '../../constants/rights';
import { SERVICETYPE, SERVICETYPESEARCH } from '../../constants/serviceType';
import { DEFAULTMENUTYPE, SEARCHOPTION } from '../../constants/app';
import { COMPANY_LOGOS, ACCOUNT_LOGOS, AGREGATORS, AGGREGATORS_ACCOUNT } from '../../constants/accounts';

import getAccountId from '../../utils/getAccountId';
import { isSmartAgent } from '../../utils/env';

import logoSmartway from '../../../../svg/logos/text-smartway-logo.svg';
import iconSmartway from '../../../../svg/logos/s-smartway-icon.svg';
import logoSmartagent from '../../../../svg/logos/smartagent-logo.svg';
import { IMenuItem } from '../../types/app';

const isAccountCreatedAfter5November2024 = (dateString: string) => {
  const date = new Date(dateString);

  if (!dateString || isNaN(date.getTime())) {
    return false;
  }

  return date > new Date('2024-11-05T00:00:00');
};

class UiSettingsProtocol {
  constructor(services) {
    Object.keys(services).forEach((s) => {
      this[s] = services[s];
    });
  }

  get isAggregator(): boolean {
    const { UiSettings: { store: { settings: { companyName } } }, Workspace: { companyId } } = this;
    const accountId = getAccountId();
    const aggregationId = this.getAggregationId();

    return (
      AGREGATORS.IDS.includes(companyId) ||
      AGREGATORS.IDS.includes(accountId) ||
      AGREGATORS.IDS.includes(aggregationId) ||
      AGREGATORS.NAMES.includes(companyName)
    );
  }

  get isAggregatorCKR(): boolean {
    const accountId = getAccountId();

    return AGGREGATORS_ACCOUNT.CKR === accountId;
  }

  get isAggregatorPPR(): boolean {
    const { UiSettings: { store: { settings: { companyName } } } } = this;

    const accountId = getAccountId();

    return AGGREGATORS_ACCOUNT.PPR === accountId || companyName === 'petrolcards';
  }

  get isViewAnalytics() {
    const canViewReports = this.Workspace.canViewReports;
    const isViewAllTrips = this.Workspace.rights.ViewTrips === VIEWTRIPSRIGHT.All;
    const { showReports, showTabReportsWithoutFinanceRights } = this.UiSettings.store.settings;

    return ((canViewReports && showReports) || showTabReportsWithoutFinanceRights) || isViewAllTrips;
  }

  changeUiSettings = async () => {
    const { AccountSettings, UiSettings } = this;
    const accountId = getAccountId();
    const flags = await AccountSettings.loadAccountSettings();

    return UiSettings.getUiSettings(accountId, flags);
  };

  get searchServiceTypeForMenu() {
    const { AccountSettings } = this;
    const searchArray = AccountSettings.getSearchServiceType();

    if (searchArray.length) {
      let list = searchArray;

      if (isSmartAgent) {
        list = searchArray.filter(item =>
          item !== SEARCHOPTION.SEARCH_CONFERENCE &&
          item !== SEARCHOPTION.SEARCH_GROUP &&
          item !== SEARCHOPTION.SEARCH_EVENT,
        );
      }

      if (!isSmartAgent && !AccountSettings.getBookingTaxi()) {
        return [...list, SERVICETYPE.TAXI];
      }

      return list;
    }

    return [];
  }

  get isSearchAeroexpress() {
    return (
      !this.searchServiceTypeForMenu.length ||
      this.searchServiceTypeForMenu.includes(SERVICETYPE.AEROEXPRESS)
    );
  }

  get preparedSearchItems() {
    const { App, FeatureFlags: { getPPRPromoSale }, Workspace } = this;

    const { RegistrationDate } = Workspace.get();

    if (isSmartAgent) {
      return App.menuSearchItemsSmartagent;
    }

    const appItems = this.isAggregatorPPR && (getPPRPromoSale() || isAccountCreatedAfter5November2024(RegistrationDate))
      ? App.menuSearchItemsPPR
      : App.menuSearchItemsWithoutAeroexpress;

    if (this.searchServiceTypeForMenu && this.searchServiceTypeForMenu.length) {
      return appItems
        .filter(({ type }) => this.searchServiceTypeForMenu.includes(SERVICETYPESEARCH[type]));
    }

    if (this.AccountSettings.getBookingTaxi()) {
      return App.menuSearchItemsWithoutTaxi;
    }

    return appItems;
  }

  preparedSearchItemsForMenu = (searchItems: IMenuItem[]): IMenuItem[] => {
    if (this.searchServiceTypeForMenu && this.searchServiceTypeForMenu.length) {
      return searchItems.filter(({ type }) => this.searchServiceTypeForMenu.includes(SERVICETYPESEARCH[type]));
    }

    let items = searchItems;

    if (this.AccountSettings.getBookingTaxi()) {
      items = items.filter(({ type }) => type !== DEFAULTMENUTYPE.SEARCH_TAXI);
    }

    if (isSmartAgent) {
      items = items.filter(({ type }) =>
        type !== DEFAULTMENUTYPE.SEARCH_CONFERENCE &&
        type !== DEFAULTMENUTYPE.SEARCH_GROUP &&
        type !== DEFAULTMENUTYPE.SEARCH_EVENT &&
        type !== DEFAULTMENUTYPE.SEARCH_TAXI,
      );
    }

    return items;
  };

  get isTripsDropdown() {
    const { DisableExpenseReport } = this.FeatureFlags.getFeatureFlags();
    const { getAnalytics, getSalary1C, getNoExpenseReport } = this.AccountSettings;
    const { ViewTrips, Finance } = this.Workspace.rights;
    const { showReports, showTabReportsWithoutFinanceRights } = this.UiSettings.store.settings;

    const notShowReport = (Finance !== FINANCEDOCUMENTSRIGHT.Available || !showReports) && !showTabReportsWithoutFinanceRights;
    const notShowExpenseReports = DisableExpenseReport || ViewTrips === VIEWTRIPSRIGHT.Undefined || getNoExpenseReport();

    return !isSmartAgent && getSalary1C() && !notShowExpenseReports && !notShowReport && !getAnalytics();
  }

  getAppMenuItemByRights = (rights) => {
    const { AccountSettings, App, FeatureFlags } = this;

    return App.menuItems.reduce((r, item) => {
      const newResult = [...r];

      const {
        Finance,
        Approve,
        ApproveExpenseReports,
        showReports,
        showTabReports,
        showTaxi,
        isViewAnalytics,
        showChartsAnalytics,
      } = rights;
      const { ShowExpenseReportApprove } = FeatureFlags.getFeatureFlags();
      const { getShowElementForSmartagent } = FeatureFlags;

      const { getBookingTaxi, getAnalytics, getSalary1C, getNoExpenseReport, getNoMice } = AccountSettings;

      const { Companies, CompanyName: currentCompanyName } = this.Workspace.get();

      const notShowReportingSetting = Companies.find(({ IsShowReporting }) => IsShowReporting);
      const notShowReportingSettingSA = Companies.find(({ IsShowReporting, CompanyName }) =>
        !IsShowReporting && CompanyName === currentCompanyName) || false;

      const notShowReport = (Finance !== FINANCEDOCUMENTSRIGHT.Available || !showReports) && !showTabReports;
      const notShowExpenseReports = !rights || getNoExpenseReport();
      const exitConditions = (notShowReport || !notShowReportingSetting) && notShowExpenseReports;
      const nestedItems = [];

      switch (item.type) {
        case DEFAULTMENUTYPE.SMARTDESC:

          break;
        case DEFAULTMENUTYPE.SEARCH: {
          if (isSmartAgent && getShowElementForSmartagent()) {
            return newResult;
          }

          break;
        }

        case DEFAULTMENUTYPE.REPORT:
          if (notShowReport || notShowReportingSettingSA || !isSmartAgent) {
            return newResult;
          }

          break;
        case DEFAULTMENUTYPE.CHARTS_ANALYTICS:
          if (isSmartAgent || !showChartsAnalytics || !isViewAnalytics || getAnalytics()) {
            return newResult;
          }

          newResult.push({
            title: getText('services:app.menuItems.chartsAnalytics'),
            url: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FIRST,
            type: DEFAULTMENUTYPE.CHARTS_ANALYTICS,
            needClickHandle: false,
          });

          return newResult;

        case DEFAULTMENUTYPE.WAITINGAPPROVE:
          if (isSmartAgent) return newResult;

          if (rights && Approve === APPROVETRIPRIGHT.Available) {
            nestedItems.push(...App.approveMenuTrips);
          }

          if (rights &&
            ShowExpenseReportApprove &&
            ApproveExpenseReports === APPROVE_EXPENSE_REPORTS.Available
          ) {
            nestedItems.push(...App.approveMenuExpenses);
          }

          if (rights &&
            Approve !== APPROVETRIPRIGHT.Available &&
            ApproveExpenseReports !== APPROVE_EXPENSE_REPORTS.Available
          ) {
            return newResult;
          }

          break;
        case DEFAULTMENUTYPE.EXPENSE_REPORTS:
          if (isSmartAgent || notShowExpenseReports) {
            return newResult;
          }

          break;
        case DEFAULTMENUTYPE.TAXI:
          if (isSmartAgent || getBookingTaxi() || !showTaxi) {
            return newResult;
          }

          break;
        case DEFAULTMENUTYPE.APPLICATION:
          if (isSmartAgent) {
            return newResult;
          }

          if (rights
            && FeatureFlags
            && getSalary1C()
            && !notShowExpenseReports
            && !notShowReport
            && !getAnalytics()
          ) {
            nestedItems.push(App.tripsMenuItems);
          }

          nestedItems.push(this.isAggregator ? App.applicationSchemeRequestAggregator : App.applicationSchemeRequest);

          if (!isSmartAgent
            && rights
            && FeatureFlags
            && getSalary1C()
            && (notShowExpenseReports || notShowReport || getAnalytics())) {
            nestedItems.push(App.requestsItem1c);
          }

          break;
        case DEFAULTMENUTYPE.TRAVELERS:
          if (!isSmartAgent) {
            return newResult;
          }

          break;

        case DEFAULTMENUTYPE.EVENT:
          if (isSmartAgent
            || (this.isAggregator && !this.isAggregatorPPR)
            || getNoMice()) {
            return newResult;
          }

          break;
        case DEFAULTMENUTYPE.ACCOUNTING:
          if (isSmartAgent || exitConditions) return newResult;

          if (!notShowReport && !!notShowReportingSetting) {
            nestedItems.push(App.accountingMenuItemReport);
          }

          if (!notShowExpenseReports) {
            nestedItems.push(App.accountingMenuItemExpenseReport);
          }

          break;
      }

      const newItem = { ...item, items: nestedItems };

      if (item.type === DEFAULTMENUTYPE.SEARCH && item.items && item.items.length) {
        newItem.items = this.preparedSearchItemsForMenu(item.items);
      }

      newResult.push(newItem);

      return newResult;
    }, []);
  };

  getSearchMenuForAddingTrip = (searchItems: IMenuItem[]) => this.preparedSearchItemsForMenu(searchItems);

  getLogo = () => {
    const { UiSettings: { store: { settings: { companyName } } }, Workspace: { companyId } } = this;

    let img = logoSmartway;
    const accountId = getAccountId();
    let currentIcon = null;

    if (Object.keys(COMPANY_LOGOS.BY_IDS).includes(String(companyId))) {
      img = COMPANY_LOGOS.BY_IDS[companyId];
    }

    if (Object.keys(ACCOUNT_LOGOS.BY_IDS).includes(String(accountId))) {
      img = ACCOUNT_LOGOS.BY_IDS[accountId];
    }

    if (Object.keys(COMPANY_LOGOS.BY_NAME).includes(companyName)) {
      img = COMPANY_LOGOS.BY_NAME[companyName];
    }

    if (!Object.keys(COMPANY_LOGOS.BY_NAME).includes(companyName)
      && !Object.keys(COMPANY_LOGOS.BY_IDS).includes(String(companyId))
      && !Object.keys(ACCOUNT_LOGOS.BY_IDS).includes(String(accountId))) {
      currentIcon = iconSmartway;
    }

    if (isSmartAgent) {
      currentIcon = null;
      img = logoSmartagent;
    }

    return {
      img,
      iconSmartway: currentIcon,
      name: companyName,
    };
  };

  sendQuality = () => {
    const { UiSettings: { store: { settings: { emailToQuality } } }, Quality: { send } } = this;

    if (emailToQuality.length) {
      return send(emailToQuality);
    }

    return send();
  };

  isWhiteLabel = () => this.UiSettings.store.isWhiteLabel;

  getAggregationId = () => this.UiSettings.store.aggregationId;
}

export { UiSettingsProtocol };
