import React from 'react';

import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { IDataApprovalStatusCard } from '../../../bi/types/approvalScheme';

import styles from './index.module.css';

interface IDeleteDialogProps {
  show: boolean,
  data: IDataApprovalStatusCard | null,
  isLoading: boolean,
  onCancel: () => void,
  onSubmit: (Id: number) => void,
}

const LABELS = {
  TITLE: getText('components:travelApproval.deleteRequestDialog.title'),
  CONFIRM: getText('components:travelApproval.deleteRequestDialog.confirm'),
  BUTTONS: {
    CONFIRM: getText('components:travelApproval.deleteRequestDialog.buttons.confirm'),
    CANCEL: getText('components:travelApproval.deleteRequestDialog.buttons.cancel'),
  },
  NOTIFICATION: getText('components:travelApproval.deleteRequestDialog.notification'),
};

const DeleteDialog = ({ show, onCancel, onSubmit, data, isLoading }: IDeleteDialogProps) => {
  if (!show || !data) return null;

  const {
    date,
    DestinationCity,
    employee,
    Id,
  } = data;

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ onCancel }
    >
      <div className={ styles.dialog_delete_request }>
        <Text type='bold_20'>
          { LABELS.TITLE }
        </Text>
        <Text className={ styles.confirm }>
          { LABELS.CONFIRM }
          &nbsp;
          <b>{DestinationCity}, {date}, {employee} ?</b>
        </Text>
        <div className={ styles.actions }>
          <Button
            type='secondary'
            onClick={ () => onSubmit(Id) }
            loading={ isLoading }
          >
            { LABELS.BUTTONS.CONFIRM }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onCancel }
          >
            { LABELS.BUTTONS.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { DeleteDialog };
