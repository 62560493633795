import React, { useState, useEffect } from 'react';
import { Text, Paginate } from 'new-ui';

import { getText } from '../../../../../i18n';

import { Table } from './table';

import { PackagesItems, ReportService } from '../../../../bi/types/report';
import { WorkspaceService } from '../../../../bi/types/workspace';

import styles from '../../styles/report-upd.module.css';

const LABELS = {
  TITLE: getText('reports:documents.title'),
};

const ITEMS_PER_PAGE = 5;
const INIT_PAGE = 1;

const requetsPage = (page: number) => page - 1;

interface DocumentPackagesProps {
  reportService: ReportService,
  workspaceService: WorkspaceService,
  currentCompany: number,
}

const renderCloseDocumentsText = (currentCompany = 0, workspaceService: WorkspaceService) => {
  const text = workspaceService.getPeriodSchemeDescription(currentCompany);

  return <Text type='NORMAL_16_140'>{ text }</Text>;
};

const DocumentPackages = ({
  reportService,
  reportService: {
    getPackages,
    subscribePackages,
    getTotalCountDocumentPackages,
    getDocumentPackagesByPage,
  },
  workspaceService,
  currentCompany = 0,
}: DocumentPackagesProps) => {
  const initItems = getPackages().items;
  const initCount = getPackages().count;

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(initItems);
  const [count, setCount] = useState(initCount);
  const [currentPage, setCurrentPage] = useState(INIT_PAGE);

  const updateDocuments = ({
    loading: updateLoading,
    items: updateItems,
    count: updateCount,
  }: { loading: boolean, items: PackagesItems[], count: number }): void => {
    setLoading(updateLoading);
    setItems(updateItems);
    setCount(updateCount);
  };

  useEffect((): void => {
    const unsubscribeFn = subscribePackages(updateDocuments);
    getTotalCountDocumentPackages(currentCompany);
    getDocumentPackagesByPage(currentCompany, requetsPage(INIT_PAGE));

    setCurrentPage(INIT_PAGE);
    setLoading(true);

    return unsubscribeFn;
  }, [currentCompany, reportService]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    getDocumentPackagesByPage(currentCompany, requetsPage(page));
  };

  return (
    <div className={ styles.wrap }>
      <Text type='bold_24'>{ LABELS.TITLE }</Text>
      { renderCloseDocumentsText(currentCompany, workspaceService) }
      <Table
        items={ items }
        loading={ loading }
        currentCompany={ currentCompany }
        reportService={ reportService }
      />
      <Paginate
        page={ currentPage }
        total={ count }
        itemsPerPage={ ITEMS_PER_PAGE }
        onChange={ handleChangePage }
      />
    </div>
  );
};

export { DocumentPackages };
