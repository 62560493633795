// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { Button, Tooltip, Dialog, Checkbox, Text, IconButton, PROPS } from 'new-ui';
import { getText } from '../../../../../i18n';

import { sendStatOnDeleteApproveCart } from '../../../../bi/services/cart/approveAnalytic';

import { BookChildDialog } from '../../../../components/BookChildDialog';

import { isBeforeDate } from '../../../../bi/utils/formatDate';
import { isCartHasAirChildren } from '../../../../bi/utils/cart';
import {
  parsedAllItems,
  getFlightRoute,
  getDatesTrip,
  getEmployeeList,
} from '../../../../bi/utils/airline';

import { CART_STATUS } from '../../../../bi/constants/cart';
import { CONSISTENTCARTMODES } from '../../../../bi/constants/approve';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const {
  BUTTON: { TYPES: { SECONDARY } },
  ICON: { COLORS: { BLUE }, TYPES: { TRASHCAN } },
  TEXT: {
    TYPES: { BOLD_20, NORMAL_14, NORMAL_14_130 },
    COLORS: { ACCENT, GRAY, GREEN, RED, WHITE },
  },
} = PROPS;

const LABELS = {
  BACK_TO_NOTE_RESERVED_TICKETS: getText('cart:approveStatuses.backToNoteReservedTickets'),
  CONFIRM_ACTION: getText('cart:approveStatuses.confirmAction'),
  DEMO_TOOLTIP: getText('cart:approveStatuses.demoTooltip'),
  SENT_TO_APPROVE: date => getText('cart:approveStatuses.sentToApprove', { date }),
  SENT_TO_APPROVE_OFFLINE: date => getText('cart:approveStatuses.sentToApproveOffline', { date }),
  APPROVE_REQUEST_FROM: getText('cart:approveStatuses.approveRequestFrom'),
  SENT: getText('cart:approveStatuses.sent'),
  TRIP_NOT_APPROVED: getText('cart:approveStatuses.tripNotApproved'),
  PAYMENT_TRIP_AFTER_APPROVAL: getText('cart:approveStatuses.paymentTripAfterApproval'),
  APPROVE_STATUS_TEXT: {
    APPROVED: getText('cart:approveStatuses.statuses.approved'),
    DECLINED: getText('cart:approveStatuses.statuses.declined'),
    WAITING_APPROVE: getText('cart:approveStatuses.statuses.waitingApprove'),
    FOUL: getText('cart:approveStatuses.statuses.foul'),
  },
  NAVIGATE_TO_CHECKOUT_LABEL: getText('cart:approveStatuses.navigateToCheckout'),
  SEND_TO_CHAT: getText('cart:approveStatuses.sendToChat'),
  SEND_REQUEST: getText('cart:approveStatuses.sendRequest'),
  UNDERAGE_TOOLTIP: getText('cart:approveStatuses.underageTooltip'),
  MESSAGE_WAS_SENT: getText('cart:approveStatuses.messageWasSentTooltip'),
  TRIP_INFO: (
    route: string,
    dates: string,
    name: string,
    pnr: string,
  ) => getText('note:dialog.tripInfo', { route, dates, name, pnr }),
  REMOVE_INFO: getText('note:dialog.removeInfo'),
};

const ApprovedCartStatusText = () => <Text color={ GREEN } type={ NORMAL_14 }>{LABELS.APPROVE_STATUS_TEXT.APPROVED}</Text>;
const DeclinedCartStatusText = () => <Text color={ RED } type={ NORMAL_14 }>{LABELS.APPROVE_STATUS_TEXT.DECLINED}</Text>;
const WaitingApproveCartStatusText = () => (
  <Text color={ GRAY } type={ NORMAL_14 }>{LABELS.APPROVE_STATUS_TEXT.WAITING_APPROVE}</Text>
);

const ApprovedCartPurchaseBtn = ({
  onClick,
  isOffline,
  alreadySentIds,
  id,
}) => {
  const label = isOffline ? LABELS.SEND_TO_CHAT : LABELS.NAVIGATE_TO_CHECKOUT_LABEL;
  const isAlreadySent = isOffline && alreadySentIds.includes(id);

  return (
    <Tooltip
      show={ isAlreadySent }
      renderContent={ () => (
        <div className={ styles['tooltip-content'] }>
          <Text color='white' type='NORMAL_14_130'>{ LABELS.MESSAGE_WAS_SENT }</Text>
        </div>
      ) }
    >
      <Button
        type={ SECONDARY }
        onClick={ onClick }
        qaAttr={ QA_ATTRIBUTES.cart.approval.purchaseButton }
        disabled={ isAlreadySent }
      >
        { label }
      </Button>
    </Tooltip>
  );
};

ApprovedCartPurchaseBtn.propTypes = {
  isOffline: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  alreadySentIds: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
};

const WaitingApproveCartPurchaseBtn = ({ isOffline }) => {
  if (isOffline) return null;

  return (
    <div className='sw-tooltip-wrapper'>
      <Tooltip renderContent={ () => (
        <div className={ styles['tooltip-content'] }>
          <Text type={ NORMAL_14_130 } color={ WHITE }>{LABELS.PAYMENT_TRIP_AFTER_APPROVAL}</Text>
        </div>
      ) }
      >
        <Button
          disabled
          onClick={ () => ({ }) }
        >
          { LABELS.NAVIGATE_TO_CHECKOUT_LABEL }
        </Button>
      </Tooltip>
    </div>
  );
};

const SendCartRequestBtn = ({ onClick }) => (
  <Tooltip renderContent={ () => (
    <div className={ styles['tooltip-content'] }>
      <Text type={ NORMAL_14_130 } color={ WHITE }>{ LABELS.UNDERAGE_TOOLTIP }</Text>
    </div>
  ) }
  >
    <Button
      type={ SECONDARY }
      onClick={ onClick }
    >
      { LABELS.SEND_REQUEST }
    </Button>
  </Tooltip>
);

SendCartRequestBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const DeclinedCartPurchaseBtn = () => (
  <Text type={ NORMAL_14 } color={ RED }>{ LABELS.TRIP_NOT_APPROVED }</Text>
);

export const returnApproveStatusText = (status) => {
  const approveStatusComponents = {
    [CART_STATUS.IN_PROGRESS]: WaitingApproveCartStatusText,
    [CART_STATUS.AUTHORISED]: ApprovedCartStatusText,
    [CART_STATUS.REJECTED]: DeclinedCartStatusText,
  };

  const ApproveStatusText = approveStatusComponents[status];

  return (<ApproveStatusText />);
};

export const ReturnPurchaseButton = ({
  type,
  item,
  onPurchase,
  haveFouls,
  hasUnderage,
  alreadySentIds,
}) => {
  const purchaseBtnComponents = {
    [CART_STATUS.IN_PROGRESS]: WaitingApproveCartPurchaseBtn,
    [CART_STATUS.AUTHORISED]: ApprovedCartPurchaseBtn,
    [CART_STATUS.REJECTED]: DeclinedCartPurchaseBtn,
  };

  const [show, setShow] = useState(false);

  const isUnderage = hasUnderage && type === CART_STATUS.AUTHORISED;

  const handleConfirm = (e) => {
    e.stopPropagation();
    const hasChildren = isCartHasAirChildren(item.sources);

    if (hasChildren) {
      setShow(true);

      return;
    }

    onPurchase(item, isUnderage, true);
  };

  const handleContinueConfirm = (e) => {
    e.stopPropagation();
    setShow(false);
    onPurchase(item, isUnderage, true);
  };

  const bookChildDialog = (
    <BookChildDialog
      show={ show }
      onClose={ () => setShow(false) }
      onContinue={ handleContinueConfirm }
    />
  );

  const PurchaseButton = isUnderage ? SendCartRequestBtn : purchaseBtnComponents[type];

  return (
    <>
      <PurchaseButton
        onClick={ handleConfirm }
        haveFouls={ haveFouls }
        isOffline={ item.isOffline }
        alreadySentIds={ alreadySentIds }
        id={ item.id }
      />
      {bookChildDialog}
    </>
  );
};

ReturnPurchaseButton.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onPurchase: PropTypes.func.isRequired,
  haveFouls: PropTypes.bool.isRequired,
  hasUnderage: PropTypes.bool.isRequired,
  alreadySentIds: PropTypes.array,
};

const deleteButtonLabels = {
  Approved: getText('cart:approveStatuses.deleteButtonLabels.approved'),
  Declined: getText('cart:approveStatuses.deleteButtonLabels.declined'),
  WaitingApprove: getText('cart:approveStatuses.deleteButtonLabels.waitingApprove'),
  ApproverMode: getText('cart:approveStatuses.deleteButtonLabels.approvedMode'),
};

export class ConsistentCartDeleteButton extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDemo: PropTypes.bool,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: CONSISTENTCARTMODES.VIEWER,
    isDemo: false,
  };

  state = {
    showDialog: false,
    airToNote: true,
    loading: false,
  };

  setModal = (value) => {
    this.setState({ showDialog: value });
  };

  handleDelete = (e) => {
    const { item, onDelete, mode, type } = this.props;
    const { airToNote } = this.state;

    this.setState({ loading: true });

    e.stopPropagation();
    sendStatOnDeleteApproveCart(mode, type);

    onDelete(item, airToNote);
  };

  handleChangeAirToNote = value => this.setState({ airToNote: value });

  renderItems = (list) => {
    const { airToNote } = this.state;

    if (airToNote || !list) return null;

    const listItemsHtml = list.map((source) => {
      const { id, isReserved, routes, allowedEmployees, PNR } = source;

      if (!isReserved) return null;

      return (
        <div key={ id }>
          <Text className={ styles.text }>
            { LABELS.TRIP_INFO(
              getFlightRoute(routes),
              getDatesTrip(routes),
              getEmployeeList(allowedEmployees),
              PNR,
            )}
          </Text>
        </div>
      );
    });

    return (
      <>
        { listItemsHtml }
        <Text className={ styles.text }>
          { LABELS.REMOVE_INFO }
        </Text>
      </>
    );
  };

  render() {
    const { type, mode, isDemo, item: { Items, sources, ServerTime, serverTime } } = this.props;
    const { showDialog, airToNote, loading } = this.state;

    const buttonLabel = mode === CONSISTENTCARTMODES.VIEWER
      ? deleteButtonLabels[type]
      : deleteButtonLabels.ApproverMode;

    const list = Items || sources;
    const sTime = ServerTime || serverTime;

    const actualListItem = parsedAllItems(list, true, false);

    const moveAirToNoteContent = list.some(({ ServiceType, IsReserved, BookDeadline }) =>
      ServiceType === SERVICETYPE.AIR && IsReserved && isBeforeDate(sTime, BookDeadline)) && (
      <div className={ styles['air-to-note'] }>
        <Checkbox onChange={ this.handleChangeAirToNote } value={ airToNote }>
          { LABELS.BACK_TO_NOTE_RESERVED_TICKETS }
        </Checkbox>
        <div className={ styles['item-info'] }>
          { this.renderItems(actualListItem) }
        </div>
      </div>
    );

    return (
      <>
        <Tooltip
          show={ isDemo }
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text color={ WHITE } type={ NORMAL_14_130 }>{ LABELS.DEMO_TOOLTIP }</Text>
            </div>
          ) }
        >
          <IconButton
            disabled={ isDemo }
            iconType={ TRASHCAN }
            iconColor={ BLUE }
            className={ styles.delete }
            onClick={ () => this.setModal(true) }
            qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.button }
          >
            <Text type={ NORMAL_14 }>
              { buttonLabel }
            </Text>
          </IconButton>
        </Tooltip>
        <Dialog
          show={ showDialog }
          onChange={ this.setModal }
          showClosing
        >
          <div className={ styles['dialog-content'] }>
            <Text
              type={ BOLD_20 }
              className={ styles.header }
              qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.dialog.header }
            >
              { LABELS.CONFIRM_ACTION }
            </Text>
            { moveAirToNoteContent }
            <div className={ styles.actions }>
              <Button
                onClick={ this.handleDelete }
                loading={ loading }
                qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.dialog.button }
              >
                { buttonLabel }
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export const ApproveStatusListItem = ({ email, name, date, resolution, showApproversCKR, isOffline }) => {
  const preparedEmail = () => {
    if (showApproversCKR) {
      return <Text type={ NORMAL_14 } color={ ACCENT } className={ styles.name }>{ email }</Text>;
    }

    if (email) {
      return (
        <Tooltip renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text color={ WHITE } type={ NORMAL_14_130 }>{ email }</Text>
          </div>
        ) }
        >
          <Text type={ NORMAL_14 } color={ ACCENT } className={ styles.name }>{ name }</Text>
        </Tooltip>
      );
    }

    return (
      <Text type={ NORMAL_14 } color={ ACCENT } className={ styles.name }>{ name }</Text>
    );
  };
  const labelSentToApprove = !isOffline ?
    LABELS.SENT_TO_APPROVE(date) :
    LABELS.SENT_TO_APPROVE_OFFLINE(date);

  return (
    <Text className={ styles['approve-list-item'] } type={ NORMAL_14 }>
      { labelSentToApprove }&nbsp;
      { preparedEmail() }
      &nbsp;
      —
      &nbsp;
      { returnApproveStatusText(resolution) }
    </Text>
  );
};

ApproveStatusListItem.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  showApproversCKR: PropTypes.bool.isRequired,
};
