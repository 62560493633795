import React, { Component } from 'react';
import { flushSync } from 'react-dom';
import { Moment } from 'moment';
import {
  Button,
  Paginate,
  Tag,
  Text,
  NoResults,
  StyledWrapper,
  Icon,
  Tooltip,
  HorizonalLine,
} from 'new-ui';

import { getText } from '../../../i18n';

import { MessageCPS } from './components/MessageCPS';
import { SortSelect } from '../../components/SortSelect';
import AirlineSearchItem from '../../components/AirlineSearchItem';
import AirlineSearchMenu from '../../components/Menu/AirlineSearchMenu';
import { StickyHidingPanel } from '../../components/StickyHiding';
import { AirlineFilters } from './components/filters';
import { AirlinesResultSkeleton } from './components/AirlinesResultSkeleton/index';
import ContextAdvertising from '../../components/ContextAdvertising';
import Avia from '../../../images/mice/context/avia.webp';

import parseUnix from '../../bi/utils/parseDateTime';
import { MainAnalytic } from '../../bi/utils/analytics';
import { parseSearchString, stringifySearchParams } from '../../bi/utils/convertSearchParams';
import { extractOriginalData, temperaturePriceWidth } from '../../bi/utils/airlineMultisearch';
import { defaultPattern, formatDate, formatRangeDateWithSimplicity, dateWithoutMoment } from '../../bi/utils/formatDate';
import slideToTop from '../utils/scrollToTop';
import MoneyFormat from '../../bi/utils/money';
import { isSmartAgent } from '../../bi/utils/env';

import FAVORITESACTION from '../../bi/constants/favorites';
import ROUTES from '../../bi/constants/routes';
import { PATTERN } from '../../bi/constants/dateFormats';
import { HISTORY_ACTIONS } from '../../bi/constants/history';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { AIRFILTERTYPE } from '../../bi/constants/tagsFilterType';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import { SORT_VALUE } from '../../bi/constants/airline';
import { POPUP_GROUP_TYPES, POPUP_NAME } from '../../bi/constants/popups';

import {
  AirlineFareWithNewPrice,
  AirlineSource,
  AirlineSourceWithOriginalPrice,
  AirRoute,
  AnalyticSortAir,
} from '../../bi/types/airline';
import { IAirlineResultPageProps, IAirlineResultPageState } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  SEARCH: getText('air:result.loader'),
  FAVORITE_NOT_FOUND_MESSAGE: {
    ONLY_FROM: (number: string, date: string) =>
      getText('air:result.favoriteNotFoundMessages.onlyFrom', { number, date }),
    FROM_AND_BACK: (number: string, date: string) =>
      getText('air:result.favoriteNotFoundMessages.fromAndBack', { number, date }),
  },
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  CHANGE_PARAMS_OF_FILTERS: getText('air:result.changeParamsOfFilters'),
  NOT_FOUND: getText('air:result.notFound'),
  RESET_FILTERS: getText('air:result.resetFilters'),
  FLIGHT: getText('air:result.flight'),
  OF: getText('air:result.of'),
  NOTIFICATIONS_CART: {
    SUCCESS: (locations: string, departureDate: string, price: string) =>
      getText('air:result.notifications.cart.success', { locations, departureDate, price }),
    FAIL: (locations: string, departureDate: string, price: string) =>
      getText('air:result.notifications.cart.fail', { locations, departureDate, price }),
  },
  NOTIFICATIONS_NOTE: {
    SUCCESS: (locations: string, departureDate: string, price: string) =>
      getText('air:result.notifications.note.success', { locations, departureDate, price }),
  },
  NOTIFICATIONS_FAVORITE: {
    REMOVE: getText('air:result.notifications.favorite.remove'),
    ADD: (locations: string, departureDate: string) =>
      getText('air:result.notifications.favorite.add', { locations, departureDate }),
  },
  TEMPERATURE_PRICE: {
    HEADER: getText('air:result.temperaturePrice.header'),
    TOOLTIP: getText('air:result.temperaturePrice.tooltip'),
    GREEN: getText('air:result.temperaturePrice.green'),
    YELLOW: getText('air:result.temperaturePrice.yellow'),
    RED: getText('air:result.temperaturePrice.red'),
    PRICES: {
      LOW: getText('air:result.temperaturePrice.prices.low'),
      MIDDLE: getText('air:result.temperaturePrice.prices.middle'),
      HIGH: getText('air:result.temperaturePrice.prices.high'),
    },
  },
};

const PANEL_ADJUSTMENT = {
  STICKY: 188,
  HIDDEN: 900,
};

const preparedLabelOfFavorite = (
  numbers: string[],
  { from, to }: { from: Moment; to: Moment },
  { from: airlineFrom, to: airlineTo }: { from: Moment; to: Moment },
) => {
  const { ONLY_FROM, FROM_AND_BACK } = LABELS.FAVORITE_NOT_FOUND_MESSAGE;

  const airlineNumbers = numbers
    .map((item, index: number) => (index === 0 ? `${airlineFrom} ${item}` : `${airlineTo} ${item}`))
    .join(', ');

  if (numbers.length === 1) {
    return ONLY_FROM(airlineNumbers, formatDate(from, PATTERN.DAY_OF_MONTH));
  }

  return FROM_AND_BACK(airlineNumbers, formatRangeDateWithSimplicity(from, to));
};

const AnalyticSortAirMatch = {
  [SORT_VALUE.PRICE_DOWN]: AnalyticSortAir.price,
  [SORT_VALUE.DISTANCE_DOWN]: AnalyticSortAir.flight_duration,
  [SORT_VALUE.TRANSFER_TIME_DOWN]: AnalyticSortAir.transplant_duration,
  [SORT_VALUE.DEPARTURE_TIME_DOWN]: AnalyticSortAir.departure_time,
  [SORT_VALUE.RECOMMENDED]: AnalyticSortAir.recommendations,
};

class AirlineResultPage extends Component<IAirlineResultPageProps, IAirlineResultPageState> {
  constructor(props: IAirlineResultPageProps) {
    super(props);

    const { enums } = this.props.userSessionService.get();

    const airlinesDictionary = enums?.bonus ?
      Object.assign({}, ...enums.bonus.Air.map(item => ({ [item.Code.toLowerCase()]: item.Code }))) :
      {};

    this.state = {
      loading: false,
      isVisibleSkeleton: true,
      ...this.props.airlineService.getResult(),
      ticketsInCart: [],
      airlinesDictionary,
      travelPolicyList: props.userSessionService.get().travelPolicies,
      rightsBuyTrip: props.workspaceService.rights,
      accountTravelPolicy: props.workspaceService.travelPolicy,
      travelPolicyAllList: props.userSessionService.get().travelPolicies,
      isShownContext: !props.popupsService.store.popupsState.mice_air_group_search,
    };
  }

  isComplex = this.props.airlineService.getSearch().isComplex;
  isFavoriteLastPath = false;
  idRequest = 0;
  idRequestItem = 0;

  unsubscribeFn: () => void;

  componentDidMount() {
    const {
      airlineService: {
        subscribeResult,
        searchByGuid,
      },
      requestsService,
      location: {
        search,
      },
      match: {
        params: {
          guid,
        },
      },
      history: {
        action,
      },
    } = this.props;
    const {
      sources,
      items,
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    } = this.state;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    const searchParams = parseSearchString(search);

    if (searchParams && searchParams.is_favorite) {
      this.isFavoriteLastPath = items.length === 0;
    }

    const { request, requestItem } = requestsService.get();

    this.idRequest = request.Id;
    this.idRequestItem = requestItem.Id;
    this.unsubscribeFn = subscribeResult(this.updateState);

    if (action === HISTORY_ACTIONS.POP && sources.length === 0) {
      this.setState({ loading: true });

      searchByGuid(guid, settings)
        .then(() => {
          this.setState({ loading: false });
        });
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SCREEN_OPENED(ANALYTIC_SERVICE_TYPES.AIR),
    );
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state: IAirlineResultPageState) => {
    this.setState({
      ...state,
    });
  };

  handleSearch = () => {
    const { popupsService } = this.props;
    const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList } = this.state;

    popupsService.closePopupsByType(POPUP_GROUP_TYPES.SEARCH_RESULT);

    flushSync(() => this.setState(prev => ({ ...prev, isVisibleSkeleton: false })));

    this.setState({
      loading: true,
      ticketsInCart: [],
      isVisibleSkeleton: true,
    }, () => {
      const settings = {
        rightsBuyTrip,
        accountTravelPolicy,
        travelPolicyAllList,
      };

      this.props.airlineService.search(settings).then((searchId: string) => {
        let search = '';

        if (this.idRequest > 0) {
          search = 'is_request=true';
        }

        this.props.history.push({ pathname: `/search/airline/${searchId}`, search });
      });
    });
  };

  handleShownContext = (value: boolean) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.AVIA.PRESSED);

    this.setState({ isShownContext: value });
  };

  goToMice = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.AVIA.CLOSED);

    window.open(ROUTES.EVENT_CONTEXT, '_blank');
  };

  handleSearchSubMenu = () => {
    this.handleSearch();

    const state = this.props.airlineService.airlineSearchStore.getState();
    let action = MainAnalytic.ACTIONS.AIRLINE.UPDATESEARCHONEWAY;

    if (state.routes.length === 1 && state.routes[0].dateBack) {
      action = MainAnalytic.ACTIONS.AIRLINE.UPDATESEARCHTHEREAGAIN;
    }

    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, action, {
      label: MainAnalytic.LABELS.AIRLINE[state.flightClass],
      value: state.travellers,
    });
  };

  handleSortSearchResult = (value: string) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_AIR_SORTING_CHOSEN(AnalyticSortAirMatch[value]),
    );

    return this.props.airlineService.setResultValue('sort', value);
  };

  showAeroexpressUpsell = (trip: AirlineSourceWithOriginalPrice) => {
    const { airlineService, popupsProtocol } = this.props;

    const { travellers, isComplex } = airlineService.getSearch();
    const searchParams = parseSearchString(location.search);

    popupsProtocol.offerAeroexpress(trip, { count: travellers, isComplex }, Number(searchParams?.tripId), this.idRequestItem);
  };

  handleAddToCart = (trip: AirlineSourceWithOriginalPrice, currentFareId: string, finalPrice?: number) => {
    const {
      airlineService,
      notificationService,
      uiSettingsProtocol,
      location,
      history,
    } = this.props;
    const searchParams = parseSearchString(location.search);
    const requestItemId = this.idRequestItem > 0 ? this.idRequestItem : null;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AIR),
    );

    const mappedItems = extractOriginalData(trip, currentFareId).map(({
      tripId,
      fareId,
    }) => ({
      tripId,
      tripIdForOrder: searchParams?.tripId || null,
      fareId: fareId || null,
      requestItemId,
      customFareId: trip.AvailablCustomFares ? currentFareId : null,
    }));
    const promise = airlineService.addToCart(mappedItems);

    const route = trip.Routes[0];
    const locations = `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`;
    const departureDate = parseUnix(route.Segments[0].DepartureTime).format(defaultPattern);

    const formattedPrice = MoneyFormat.money(finalPrice as number);

    promise.then(() => {
      notificationService.send({
        message: LABELS.NOTIFICATIONS_CART.SUCCESS(locations, departureDate, formattedPrice),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.CART.MAIN),
        qaAttr: QA_ATTRIBUTES.search.airline.buttonCartNotification,
      });

      this.handlePreventDuplication(trip.Id);
    }).catch(() => {
      notificationService.send({
        message: LABELS.NOTIFICATIONS_CART.FAIL(locations, departureDate, formattedPrice),
        // @ts-ignore
        level: 'error',
      });
    });

    if (uiSettingsProtocol.isSearchAeroexpress) {
      this.showAeroexpressUpsell(trip);
    }
  };

  handleAddToNotepad = (trip: AirlineSourceWithOriginalPrice, currentFareId: string) => {
    const { airlineService, notificationService, history } = this.props;
    const searchParams = parseSearchString(location.search);

    const mappedItems = extractOriginalData(trip, currentFareId as string).map(({
      fareId,
      tripId,
    }) => ({
      tripId,
      fareId: fareId || null,
      tripIdForOrder: searchParams && (searchParams?.tripId || null),
      customFareId: trip.AvailablCustomFares ? currentFareId : null,
    }),
    );

    const promise = airlineService.addToNote(mappedItems);

    promise.then(() => {
      const route = trip.Routes[0];
      const locations = `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`;
      const departureDate = parseUnix(route.Segments[0].DepartureTime).format(defaultPattern);
      const price = trip.Price.TotalPrice;

      const formattedPrice = MoneyFormat.money(price);

      notificationService.send({
        message: LABELS.NOTIFICATIONS_NOTE.SUCCESS(locations, departureDate, formattedPrice),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.NOTE.MAIN),
        qaAttr: QA_ATTRIBUTES.search.airline.item.notificationNote,
      });
    });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_NOTEBOOK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AIR),
    );
  };

  handleChangeFavorite = ({ Id, OriginalTrips }: { Id: number, OriginalTrips: AirlineSource[] }, action: string) => {
    const { airlineService, notificationService, favoriteService, history } = this.props;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_FAVORITES_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AIR),
    );

    if (action === FAVORITESACTION.REMOVE) {
      return Promise.all(OriginalTrips.map(trip => favoriteService.removeItem(trip.FavoriteId as string)
        .then(() => {
          notificationService.send({
            message: LABELS.NOTIFICATIONS_FAVORITE.REMOVE,
            // @ts-ignore
            level: 'success',
            qaAttr: QA_ATTRIBUTES.search.airline.item.notificationRemoveFavorite,
          });

          airlineService.changeFavoriteStatus(Id, trip.Id, '');
        })));
    }

    return Promise.all(OriginalTrips.map(trip => airlineService.addToFavorite(trip).then((favoriteId: string) => {
      const route = trip.Routes[0];

      const locations = `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`;
      const departureDate = parseUnix(route.Segments[0].DepartureTime).format(defaultPattern);

      notificationService.send({
        message: LABELS.NOTIFICATIONS_FAVORITE.ADD(locations, departureDate),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.FAVORITES),
        qaAttr: QA_ATTRIBUTES.search.airline.item.notificationFavorite,
      });

      airlineService.changeFavoriteStatus(Id, trip.Id, favoriteId);
    })));
  };

  handlePageChange = (page: number) => {
    this.props.airlineService.setResultValue('paging', page);
    slideToTop();
  };

  handleReturnToSearch = () => {
    this.props.history.push({ pathname: ROUTES.SEARCH.AIR, search: 'isChanging=true' });

    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.CHANGESEARCHCOMPLEXROUTE);
  };

  handleUpdateFilters = (field: string, changes: any) => {
    if (field === 'flightsNumbers') {
      this.props.airlineService.setFlightsNumbersValue(changes);
    }

    this.props.airlineService.setResultValue(`filters.${field}`, changes);
  };

  handlePreventDuplication = (id: number) => this.setState({
    ...this.state,
    ticketsInCart: [...this.state.ticketsInCart, id],
  });

  handleUpdateCheckbox = (value: boolean, route: AirRoute) => this.props.airlineService.setResultCheckbox(value, route);

  handleResetFilters = () => {
    this.isFavoriteLastPath = false;
    this.props.airlineService.resetFilters();
  };

  handleGetLinkAction = (item: AirlineSourceWithOriginalPrice) => {
    const url = ROUTES.SEARCH.AIR;
    const { travellers: travelers, routes, isDirect, flightClass } = this.props.airlineService.getSearch();
    const segments = item.Routes.map(route => route.Segments[0]);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_LINK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AIR),
    );

    const mappedItem = {
      Routes: item.Routes.map(route => (
        {
          Arrival: { Code: route.Segments[0].ArrivalAirport.ID, Name: route.Segments[0].ArrivalAirport.Name },
          Departure: { Code: route.Segments[0].DepartureAirport.ID, Name: route.Segments[0].DepartureAirport.Name },
          Segments: route.Segments,
          ID: route.ID,
          Duration: route.Duration,
          Date: route.Date,
          DirectCount: route.DirectCount,
          Selected: route.Selected,
        })),
      Class: flightClass,
      IsDirect: isDirect,
    };

    const searchParams = this.props.airlineService.getSearchObject(mappedItem, { dateFrom: routes[0].date, dateTo: routes[0].dateBack, travelers });
    const searchString = stringifySearchParams(searchParams);
    const link = window.location.origin + this.props.history.createHref({ pathname: url, search: searchString });
    let description = '';

    segments.forEach((segment) => {
      description += `\n${LABELS.FLIGHT} ${segment.FlightNumber} ${segment.DepartureCity} ${segment.DepartureAirport.ID} - ${segment.ArrivalCity} ${segment.ArrivalAirport.ID}, ${dateWithoutMoment(segment.DepartureTime, PATTERN.DAY_WITH_DIGITAL_MONTH_AND_TIME)} - ${dateWithoutMoment(segment.ArrivalTime, PATTERN.DAY_WITH_DIGITAL_MONTH_AND_TIME)}, `;
    });

    description += `${LABELS.OF} ${MoneyFormat.money(item.Price.TotalPrice, true)}`;

    return `${description} \n\n${link}`;
  };

  handleBlurAirlineName = (value?: string) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTEROTHER, { label: value });
  };

  handleClickToLinkButton = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.GETLINK);
  };

  handleOnCopyLink = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.COPYLINK);
  };

  handleShowDetails = () => {
    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.SHOWDETAILS);
  };

  handleChangeFare = (newFare: AirlineFareWithNewPrice, fares: AirlineFareWithNewPrice[]) => {
    const minPrice = Math.min(...fares.map(item => item.Price.TotalPrice));

    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FARESELECTION, {
      label: newFare.Name,
      value: newFare.Price.TotalPrice - minPrice,
    });
  };

  renderLoading = () => {
    const { isVisibleSkeleton } = this.state;

    if (!isVisibleSkeleton) {
      return null;
    }

    return (
      <div className={ styles.result } data-qa={ QA_ATTRIBUTES.search.airline.loadingAirlines }>
        <AirlinesResultSkeleton />
      </div>
    );
  };

  renderNoResults = () => (
    <div className={ styles.result }>
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results } data-qa={ QA_ATTRIBUTES.search.airline.noResult }>
            <Text type='bold_18'>
              { LABELS.NOT_FOUND }
            </Text>
            <Text className={ styles.subtext }>
              { LABELS.CHANGE_PARAMS_OF_SEARCH }
            </Text>
          </div>
        ) }
      />
    </div>
  );

  renderFilterEmptyPanel = () => {
    const { favorite } = this.state;
    const { numbers, dates, airline } = favorite || {
      numbers: [],
      dates: { from: null, to: null },
      airline: { from: null, to: null },
    };

    const title = this.isFavoriteLastPath
      ? preparedLabelOfFavorite(numbers, dates, airline)
      : LABELS.NOT_FOUND;

    const subtext = this.isFavoriteLastPath
      ? LABELS.CHANGE_PARAMS_OF_SEARCH
      : LABELS.CHANGE_PARAMS_OF_FILTERS;

    return (
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results }>
            <Text type='bold_18'>
              { title }
            </Text>
            <Text className={ styles.subtext }>
              { subtext }
            </Text>
            <Button
              type='primary-outline'
              onClick={ this.handleResetFilters }
            >
              { LABELS.RESET_FILTERS }
            </Button>
          </div>
        ) }
      />
    );
  };

  renderEmptyFilter = () => this.renderFullPage(this.renderFilterEmptyPanel());

  renderFullPage = (html: React.ReactNode) => {
    const { airlineService } = this.props;
    const {
      filters,
      routeInfo,
      isAnyFavorite,
      airlinesDictionary,
      travelPolicyList,
      unavailableTravelPolicy,
    } = this.state;

    const requestId = this.idRequest > 0 ? Number(this.idRequest) : null;

    return (
      <div className={ styles.result }>
        <AirlineFilters
          requestId={ requestId }
          airlineService={ airlineService }
          isComplex={ this.isComplex }
          filters={ filters }
          travelPolicyList={ travelPolicyList }
          routeInfo={ routeInfo }
          isAnyFavorite={ isAnyFavorite }
          unavailableTravelPolicy={ unavailableTravelPolicy }
          airlinesDictionary={ airlinesDictionary }
          updateFilters={ this.handleUpdateFilters }
          onBlurAirlineNumber={ this.handleBlurAirlineName }
          qaAttrs={ QA_ATTRIBUTES.search.airline.filters }
        />
        { html }
      </div>
    );
  };

  renderTemperatureSection = () => {
    const { temperatureScale } = this.state;

    if (!temperatureScale?.length) return null;

    const renderHeader = () => (
      <div className={ styles.header }>
        <Text type='NORMAL_16'>{ LABELS.TEMPERATURE_PRICE.HEADER }</Text>
        <Tooltip
          position='right'
          renderContent={ () => (
            <div className={ styles.tooltip }>
              <Text type='NORMAL_14_130' color='white'>{ LABELS.TEMPERATURE_PRICE.TOOLTIP }</Text>
              <ul className={ styles['list-text'] }>
                <li>{ LABELS.TEMPERATURE_PRICE.GREEN }</li>
                <li>{ LABELS.TEMPERATURE_PRICE.YELLOW }</li>
                <li>{ LABELS.TEMPERATURE_PRICE.RED }</li>
              </ul>
            </div>
          ) }
        >
          <Icon type='info' color='blue' />
        </Tooltip>
      </div>
    );

    const renderHorizontalLines = () => temperatureScale.map(({ MiddleStart, MiddleEnd, Count }, index) => {
      let lowPriceWidth = temperaturePriceWidth(MiddleStart.Index, Count);
      let middlePriceWidth = temperaturePriceWidth(MiddleEnd.Index, Count) - lowPriceWidth;
      let highPriceWidth = 100 - middlePriceWidth - lowPriceWidth;
      const minWidth = 13;

      if (!lowPriceWidth && !middlePriceWidth) {
        middlePriceWidth = 100;
        highPriceWidth = 0;
      }

      // Width not too small
      if (lowPriceWidth && lowPriceWidth < minWidth) {
        lowPriceWidth = minWidth;
      }

      // Width not too small
      if (highPriceWidth && highPriceWidth < minWidth) {
        highPriceWidth = minWidth;
      }

      return (
        <HorizonalLine
          key={ `${Count}_${index}` }
          wrapperClassName={ styles.horizontalLine }
          lines={ [
            {
              width: lowPriceWidth,
              color: 'green',
              iconType: 'rub',
              labelIcon: `${LABELS.TEMPERATURE_PRICE.PRICES.LOW}`,
            },
            {
              width: middlePriceWidth,
              color: 'yellow',
              iconType: 'rub',
              labelIcon: `${LABELS.TEMPERATURE_PRICE.PRICES.MIDDLE}`,
              texts: {
                start: `${MoneyFormat.money(MiddleStart.Price)} ₽`,
                end: `${MoneyFormat.money(MiddleEnd.Price)} ₽`,
              },
            },
            {
              width: highPriceWidth,
              color: 'red',
              iconType: 'rub',
              labelIcon: `${LABELS.TEMPERATURE_PRICE.PRICES.HIGH}`,
            },
          ] }
        />
      );
    });

    return (
      <StyledWrapper className={ styles['temparature-wrapper'] }>
        { renderHeader() }
        { renderHorizontalLines() }
      </StyledWrapper>
    );
  };

  renderContextAdvertising = () => {
    const {
      aggregationId,
      airlineService,
      accountSettingsService: {
        getNoMice,
      },
      popupsService: {
        closePopupState,
        store: {
          popupsState: {
            mice_air_group_search,
          },
        },
      },
      featureFlagsService: {
        getHiddenContextAdjMice,
      },
    } = this.props;
    const { isShownContext } = this.state;
    const { travellers } = airlineService.getSearch();

    if (travellers < 5
      || aggregationId
      || !isShownContext
      || getNoMice()
      || isSmartAgent
      || !getHiddenContextAdjMice()
    ) {
      return null;
    }

    if (!mice_air_group_search) {
      closePopupState(POPUP_NAME.CONTEXT.AVIA);
    }

    return (
      <ContextAdvertising
        image={ Avia }
        onClick={ this.goToMice }
        onClose={ () => this.handleShownContext(false) }
        amplitude={ MainAnalytic.ACTIONS.MICE.CONTEXT.AVIA.SHOWED }
        classnameBtn={ styles.wrapper_context }
      />
    );
  };

  renderList = () => {
    const { airlineService, requestsService, workspaceService, featureFlagsService, appService } = this.props;
    const {
      sortBy,
      items,
      tags,
      paging,
      ticketsInCart,
      travelPolicyList,
      filters: {
        selectedTravelPolicy,
        price: {
          from,
        },
      },
    } = this.state;

    const qaAttrTagAircompanyIncludes =
      tags.some(({ filter }) => filter === AIRFILTERTYPE.AIRLINES)
        ? QA_ATTRIBUTES.search.airline.aircompanyIncludes
        : '';

    const sortItems = airlineService.sort;

    const request = requestsService.get().request;
    const { isComplex, travellers } = airlineService.getSearch();

    const ticketHtml = items.map((ticket, index: number) => (
      <AirlineSearchItem
        index={ index }
        key={ ticket.Id }
        item={ ticket }
        workspaceService={ workspaceService }
        featureFlagsService={ featureFlagsService }
        appService={ appService }
        preventDuplication={ ticketsInCart }
        selectedTravelPolicy={ selectedTravelPolicy }
        priceFilter={ from }
        travelPolicyList={ travelPolicyList }
        isComplex={ isComplex }
        travellers={ travellers }
        onUpdateCheckbox={ (value: boolean, route: AirRoute) => this.handleUpdateCheckbox(value, route) }
        onAddToCart={ this.handleAddToCart }
        onAddToNotepad={ this.handleAddToNotepad }
        onAddToFavorite={ this.handleChangeFavorite }
        onGetLink={ this.handleGetLinkAction }
        onCopyLink={ this.handleOnCopyLink }
        onShowDetails={ this.handleShowDetails }
        onChangeFare={ this.handleChangeFare }
        onClickToLinkButton={ this.handleClickToLinkButton }
        qaAttrItem={ QA_ATTRIBUTES.search.airline.item }
        qaAttrItemWrapper={ QA_ATTRIBUTES.search.airline.itemWrapper }
        qaAttrButton={ QA_ATTRIBUTES.search.airline.buttonCart }
        qaAttrFavorite={ QA_ATTRIBUTES.search.airline.favorite }
      />
    ));

    const tagsHtml = !!tags.length && tags.map(tag => {
      const { name, key, readOnly } = tag;

      return (
        <Tag
          label={ name }
          readOnly={ readOnly }
          onClick={ () => airlineService.deleteTags(tag) }
          key={ key }
          className={ styles.tag }
          qaAttr={ tag.qaAttr }
        />
      );
    });

    const requestCommentHtml = this.idRequest > 0 && request.Comment ? (
      <div className={ styles.comment }>{ request.Comment }</div>
    ) : null;

    const html = (
      <div className={ styles.content }>
        <StickyHidingPanel
          adjustment={ PANEL_ADJUSTMENT.STICKY }
          hiddenAdjustment={ PANEL_ADJUSTMENT.HIDDEN }
          fixedClass={ styles['sticky-panel-fixed'] }
          tags={ tags }
        >
          <div className={ styles.header }>
            { requestCommentHtml }
            <div className={ styles.main }>
              <div className={ styles.select }>
                <SortSelect
                  items={ sortItems }
                  value={ sortBy }
                  onChange={ this.handleSortSearchResult }
                  qaAttr={ QA_ATTRIBUTES.search.airline.sortSelect }
                />
              </div>
              <div className={ styles.tags } data-qa={ qaAttrTagAircompanyIncludes }>
                { tagsHtml }
              </div>
            </div>
            { this.renderTemperatureSection() }
            <MessageCPS />
          </div>
        </StickyHidingPanel>
        <div>
          { this.renderContextAdvertising() }
        </div>
        <div className={ styles.list }>
          <div data-qa={ QA_ATTRIBUTES.search.airline.items } className={ styles.items }>
            { ticketHtml }
          </div>
          <Paginate
            total={ paging.total }
            page={ paging.current }
            itemsPerPage={ paging.count }
            onChange={ this.handlePageChange }
            qaAttrNext={ QA_ATTRIBUTES.search.airline.paginateNext }
          />
        </div>
      </div>
    );

    return this.renderFullPage(html);
  };

  renderResults = () => {
    const { sources, items } = this.state;

    if (!sources.length) return this.renderNoResults();

    if (!items.length) return this.renderEmptyFilter();

    return this.renderList();
  };

  render() {
    const { airlineService, requestsService, featureFlagsService } = this.props;
    const { loading } = this.state;

    const html = loading ? this.renderLoading() : this.renderResults();

    return (
      <div className={ styles.wrap }>
        <AirlineSearchMenu
          subMenu
          requestsService={ requestsService }
          airlineService={ airlineService }
          handleReturnToSearch={ this.handleReturnToSearch }
          onSearch={ this.handleSearch }
          onSearchSubMenu={ this.handleSearchSubMenu }
          qaAttrsSubMenu={ QA_ATTRIBUTES.search.airline.menu }
          featureFlagsService={ featureFlagsService }
        />
        { html }
      </div>
    );
  }
}

export default AirlineResultPage;
