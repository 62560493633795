import React, { FC, useEffect, useState, useRef } from 'react';
import { Text, NoResults } from 'new-ui';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';

import { getText } from '../../../i18n';

import { MOBX_STORES } from '../../bi/context/stores';
import { useStores } from '../../bi/context';

import MainAnalytic from '../../bi/utils/analytics/main';

import { HISTORY_ACTIONS } from '../../bi/constants/history';
import { ADDITIONAL_TYPES, PATH } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';

import { VipHallSearchMenu } from '../../components/Menu/VipHallSearchMenu';
import { VipHallResultPage } from './components/VipHallResultPage';
import { SearchLoading } from '../../components/SearchLoading';

import { VipHallResultProps } from '../../bi/types/vipHall';
import { IDetails } from '../../bi/types/airlineAdditionalServices';

import { SORT_FIELDS } from '../../bi/constants/vipHall';

import styles from './index.module.css';

const LABELS = {
  SEARCH: getText('vipHall:loader'),
  NOT_FOUND: getText('vipHall:item.notFound'),
  CHANGE_PARAMS: getText('vipHall:item.changeParams'),
};

const ANALYTICS_SORT_VALUES = {
  PRICE_UP: 'up',
  PRICE_DOWN: 'down',
  NOT: 'not',
};

const VipHallResult: FC<VipHallResultProps> = observer(({
  history,
  breadCrumbsService,
  orderService,
  vipHallService,
  match,
  match: { params: { guid, tripId, ticketId }, path },
}) => {
  const { vipHallSearchStore: { terminals } } = useStores([MOBX_STORES.VIP_SEARCH_STORE]);
  const { vipHallStore: { loading, items, mainInfo } } = useStores([MOBX_STORES.VIP_STORE]);

  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const selectedFilter = useRef<string>('');

  const { route, terminal, segment } = queryString.parse(location.search);

  const isVipHallPage = path === PATH.RESULT_VIP;

  useEffect(() => {
    if (terminals.length > 1 && !terminal) {
      const typeService = path === PATH.RESULT_VIP
        ? ADDITIONAL_TYPES.VIP_HALL
        : ADDITIONAL_TYPES.ESCORT;

      history.push(ROUTES.ADDITIONAL_SERVICES.TERMINAL(tripId, ticketId, typeService, route, segment));
    }

    if (history.action === HISTORY_ACTIONS.POP && items.length === 0) {
      vipHallService.searchByGuid(guid, terminal);
    }

    const { details } = orderService.get();

    setDetailsData(details);

    breadCrumbsService.setCurrentPath(path);

    return () => {
      if (terminal && !selectedFilter.current) {
        const analyticType = isVipHallPage
          ? MainAnalytic.ACTIONS.VIP_HALL.VIP_SORT_PRESSED
          : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_SORT_PRESSED;

        MainAnalytic.sendFirebase(analyticType, {
          sort: ANALYTICS_SORT_VALUES.NOT,
        });
      }

      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const tripItem = detailsData && detailsData.OrderItems.find((ticket: any) => ticket.Id === +ticketId);
  const ticketData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const handleResetFilters = () => vipHallService.resetFilters();

  const handleSortSearchResult = (opts: any) => {
    vipHallService.filterItems(opts);

    selectedFilter.current = opts;

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_SORT_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_SORT_PRESSED;

    MainAnalytic.sendFirebase(analyticType, {
      sort: opts === SORT_FIELDS.PRICE_UP
        ? ANALYTICS_SORT_VALUES.PRICE_UP
        : ANALYTICS_SORT_VALUES.PRICE_DOWN,
    });
  };

  const handleChangePage = (page: number) => {
    vipHallService.setPagingItems(page);
  };

  if (loading || !ticketData) {
    return (
      <div className={ styles.search_wrapper }>
        <SearchLoading text={ LABELS.SEARCH } />
      </div>
    );
  }

  if (!items.length) {
    return (
      <div className={ styles.wrapper_no_results }>
        <NoResults
          renderContent={ () => (
            <div className={ styles.no_results }>
              <Text type='bold_18'>
                { LABELS.NOT_FOUND }
              </Text>
              <Text className={ styles.subtext }>
                { LABELS.CHANGE_PARAMS }
              </Text>
            </div>
          ) }
        />
      </div>
    );
  }

  return (
    <>
      <VipHallSearchMenu
        ticketData={ ticketData }
        mainInfo={ mainInfo }
      />
      <div className={ styles.wrapper }>
        <VipHallResultPage
          match={ match }
          isVipHallPage={ isVipHallPage }
          onSortSearchResult={ handleSortSearchResult }
          onResetFilters={ handleResetFilters }
          onChangePage={ handleChangePage }
        />
      </div>
    </>
  );
});

export { VipHallResult };
