import { getText } from '../../../../../i18n';

import { createCityNameByCode } from './createCityNameByCode';
import { createEmployeeDictionaryFullName } from './createEmployeeDictionaryFullName';

import { AirRoute } from '../../../types/airline';
import { IBaggageRoute, ISelectedBaggage } from '../../../types/airlineAdditionalServicesBaggage';
import { IUnderageEmployeesItem } from '../../../utils/employees';

interface IBaggageRouteResult {
  routesWithBaggage: IBaggageRoute[];
  routesWithoutBaggage: IBaggageRoute[];
}

const LABELS = {
  NOT_ALL_BAGGAGE_SELECTED: (name: string, segments: string) =>
    getText('cart:airlineAdditionalService.baggage.notAllBaggageSelected', { name, segments }),
};

export const createUnselectedBaggageWarning = (
  selectedBaggage: Record<string, ISelectedBaggage>,
  employees: IUnderageEmployeesItem[],
  ticketRoutes: AirRoute[],
): Record<string, string[]> => {
  if (ticketRoutes?.length <= 1) {
    return {};
  }

  const warnings: Record<string, string[]> = {};
  const employeeDictionary = createEmployeeDictionaryFullName(employees);

  Object.keys(selectedBaggage).forEach(ticketId => {
    const baggageInfo = selectedBaggage[ticketId];
    const baggageRoutes = baggageInfo.BaggageRoutes;
    const ticketWarnings: string[] = [];

    const travellerIds = [
      ...new Set(baggageRoutes.flatMap(route => route.Baggages.map(baggage => baggage?.TravellerId))),
    ];

    travellerIds.forEach(travellerId => {
      const { routesWithBaggage, routesWithoutBaggage } = baggageRoutes.reduce(
        (acc, route) => {
          const hasBaggage = route.Baggages.some(
            baggage => baggage?.TravellerId === travellerId && baggage?.ProviderDescription,
          );

          return hasBaggage
            ? { ...acc, routesWithBaggage: [...acc.routesWithBaggage, route] }
            : { ...acc, routesWithoutBaggage: [...acc.routesWithoutBaggage, route] };
        },
        { routesWithBaggage: [], routesWithoutBaggage: [] } as IBaggageRouteResult,
      );

      if (routesWithoutBaggage.length && routesWithBaggage.length) {
        const missingRoutes = routesWithoutBaggage.reduce((acc, route) => {
          const departureCity = route.Departure && createCityNameByCode(route.Departure, ticketRoutes);
          const arrivalCity = route.Arrival && createCityNameByCode(route.Arrival, ticketRoutes);

          const routeString = `${departureCity} - ${arrivalCity}`;

          return acc ? `${acc}, ${routeString}` : routeString;
        }, '');

        const fullName = (travellerId && employeeDictionary[travellerId]) || '';
        const warningText = LABELS.NOT_ALL_BAGGAGE_SELECTED(fullName, missingRoutes);

        ticketWarnings.push(warningText);
      }
    });

    if (ticketWarnings.length) {
      warnings[ticketId] = ticketWarnings;
    }
  });

  return warnings;
};
