import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { UseServicesReturnType } from '../../../../bi/context/services';

import { SearchHistory } from '../../../../components/SearchHistory';
import { TrainsHistoryItem } from '../TrainsHistoryItem';

import { ITrainHistoryItem } from '../../../../bi/types/trains';

interface IServices extends UseServicesReturnType<'Trains'> {}

type TTrainHistoryTypeProps = IServices & {
  onClick: (item: ITrainHistoryItem) => void;
};

const TrainHistory = observer(({
  onClick,
  trainsService,
}: TTrainHistoryTypeProps) => {
  const {
    trainSearchStore,
  } = useStores([MOBX_STORES.TRAIN_SEARCH]);

  useEffect(() => {
    trainsService.getHistory();

    return () => {
      trainsService.setHistory([]);
    };
  }, []);

  const historyList = trainSearchStore?.historyList;

  if (!historyList || historyList.length === 0) {
    return null;
  }

  return (
    <SearchHistory
      items={ historyList }
      renderItem={ (item: ITrainHistoryItem) => <TrainsHistoryItem item={ item } /> }
      onClick={ onClick }
      qaAttrLabel={ QA_ATTRIBUTES.search.train.history.text }
      qaAttrWrapper={ QA_ATTRIBUTES.search.train.history.wrapper }
    />
  );
});

export { TrainHistory };
