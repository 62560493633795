import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Price, Text, TextColor } from 'new-ui';

import { useStores } from '../../../../../../../bi/context';
import { useServices } from '../../../../../../../bi/context/services';
import { MOBX_STORES } from '../../../../../../../bi/context/stores';

import { getText } from '../../../../../../../../i18n';

import OptionButton from '../../../../../../../components/OptionButton';

import { IEmployee } from '../../../../../../../bi/utils/employees';

import SYMBOLS from '../../../../../../../bi/constants/symbols';

import { AirRoute, AirSegment, ISelectedBaggageItem } from '../../../../../../../bi/types/airline';
import { IBaggageRoutes } from '../../../../../../../bi/types/airlineAdditionalServicesBaggage';

import styles from './styles.module.css';

interface IAirlineAdditionalServicesBaggageRowProps {
  baggageRoutes?: IBaggageRoutes[];
  employee: IEmployee;
  everyEmployeeChoosen: boolean;
  resetBaggageSelection: boolean;
  number: number;
  routes: AirRoute[];
  ticketId: number;
  onResetComplete: () => void;
  getSelectedBaggage: () => ISelectedBaggageItem[][];
}

const LABELS = {
  SELECT_PASSENGERS: getText('cart:airlineAdditionalService.seats.selectPassengers'),
  WITHOUT_ADD_BAGGAGE: getText('cart:airlineAdditionalService.baggage.withoutAddBaggage'),
};

const DEFAULT_ID = 'id';

const AirlineAdditionalServicesBaggageRow = observer(({
  employee,
  everyEmployeeChoosen,
  number,
  resetBaggageSelection,
  routes,
  ticketId,
  onResetComplete,
  baggageRoutes,
  getSelectedBaggage,
}: IAirlineAdditionalServicesBaggageRowProps) => {
  const {
    airlineBaggageStore: {
      selectedBaggage,
      routes: routesStore,
    },
  } = useStores([MOBX_STORES.AIRLINE_BAGGAGE]);

  const {
    airlineBaggageService: {
      updateSelectedBaggage,
      addBaggage,
    },
    cartService: { load },
  } = useServices(['AirlineBaggage', 'Cart']);

  const [isProcessingAddBaggage, setIsProcessingAddBaggage] = useState(false);

  const { Surname, Name, Patronymic, Id: employeeId } = employee;
  const nameEmployee = `${Surname} ${Name} ${Patronymic}`;

  const preparedBaggageOffers = baggageRoutes?.map(({ BaggageOffers }) => [{
    description: LABELS.WITHOUT_ADD_BAGGAGE,
    offerId: DEFAULT_ID,
    price: 0,
    providerDescription: '',
  },
  ...BaggageOffers?.map(({
    Description: description,
    OfferId: offerId,
    Price: price,
    ProviderDescription: providerDescription,
  }) => ({
    description,
    offerId,
    price,
    providerDescription,
  }))]);

  useEffect(() => {
    if (resetBaggageSelection) {
      onResetComplete();
    }
  }, [resetBaggageSelection, onResetComplete]);

  useEffect(() => {
    if (!selectedBaggage[ticketId]?.SavedPriceItemId) {
      getSelectedBaggage().forEach((newSelectedBaggage: ISelectedBaggageItem[]) => {
        if (!newSelectedBaggage.length) return;

        newSelectedBaggage.forEach((baggage: ISelectedBaggageItem) => {
          updateSelectedBaggage(baggage);
        });
      });
    }
  }, []);

  const handleBaggageChoose = (
    routeId: string,
    price: number,
    providerDescription: string,
  ) => {
    if (!everyEmployeeChoosen) return;

    updateSelectedBaggage({
      ticketId,
      employeeId,
      routeId,
      price,
      routes,
      providerDescription,
    });
  };

  const renderPrice = (price: number, selected: boolean) => {
    const color = selected ? 'default' : 'accent';

    return (
      <>
        <Text type='NORMAL_14' color={ color as TextColor }>
          { SYMBOLS.PLUS.value }&thinsp;
        </Text>
        <Price
          marginSmall
          type='NORMAL_14'
          typeCurrency='NORMAL_14'
          color={ color }
          value={ price }
        />
      </>
    );
  };

  const renderTooltip = () => (
    <Text className={ styles.tooltip } type='NORMAL_14_130' color='white'>
      { LABELS.SELECT_PASSENGERS }
    </Text>
  );

  const handleAddBaggage = async (
    routeId: string,
    price: number,
    providerDescription: string,
  ) => {
    if (isProcessingAddBaggage) return;

    try {
      setIsProcessingAddBaggage(true);
      handleBaggageChoose(routeId, price, providerDescription);
      await addBaggage();
      await load();
    } finally {
      setIsProcessingAddBaggage(false);
    }
  };

  const renderOptionBaggageButtons = (routeId: string, routeIndex: number) => {
    const baggageOffers = preparedBaggageOffers?.[routeIndex];
    const sortedBaggageOffers = baggageOffers?.sort((a, b) => a.price - b.price);

    return sortedBaggageOffers?.map(({ description, price, providerDescription }, index) => {
      const uniqueBaggageId = `${ticketId}_${employeeId}_${routeId}`;

      const isCurrent = routesStore?.[uniqueBaggageId]?.description === providerDescription
      || (!Object.keys(routesStore).includes(uniqueBaggageId) && providerDescription === '');

      return (
        <OptionButton
          key={ `${employeeId}_${index}` }
          position='top'
          title={ description }
          isCurrent={ isCurrent }
          disabled={ !everyEmployeeChoosen || isProcessingAddBaggage }
          isProcessing={ isProcessingAddBaggage }
          tooltipContent={ renderTooltip }
          subtitle={ renderPrice(price, isCurrent) }
          onOptionButtonClick={ () => handleAddBaggage(routeId, price, providerDescription) }
        />
      );
    });
  };

  const getRouteTitle = (segments: AirSegment[]) => {
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    return `${firstSegment.DepartureCity} — ${lastSegment.ArrivalCity}`;
  };

  const renderSegmentInfo = () => {
    const segmentInfo = routes.map((route: AirRoute, routeIndex: number) => (
      <div className={ styles.segment_info_wrapper } key={ route.ID }>
        <Text className={ styles.segment_info }>
          { getRouteTitle(route.Segments) }
        </Text>
        <div className={ styles.baggage_item }>
          { renderOptionBaggageButtons(route.ID as string, routeIndex) }
        </div>
      </div>
    ));

    const wrapperSegmentClassName = segmentInfo.length > 2
      ? `${styles.wrapper_segment} ${styles.has_more_than_two}`
      : styles.wrapper_segment;

    return (
      <div className={ wrapperSegmentClassName }>
        { segmentInfo }
      </div>
    );
  };

  return (
    <div className={ styles.wrapper_flight }>
      <div className={ styles.wrapper_baggage }>
        <Text>
          <span className={ styles.wrapper_name_employee }>{number}. {nameEmployee}</span>
        </Text>
        <div className={ styles.wrapper_segment }>
          { renderSegmentInfo() }
        </div>
      </div>
    </div>
  );
});

export { AirlineAdditionalServicesBaggageRow };
