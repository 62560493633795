import React, { memo, useEffect, useState } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import PASSPORTS from '../../../../../../bi/constants/passport';
import { SERVICETYPE } from '../../../../../../bi/constants/serviceType';

import { IEmployeeCartItem, ISourcesEmployees } from '../../../../../../bi/services/checkout/types';

import styles from './styles.module.css';

const LABELS = {
  CHECK_PATRONYMIC: getText('components:employeePassport.checkPatronymic'),
};

interface IProps {
  cartItem: IEmployeeCartItem[];
  employees: ISourcesEmployees[];
  serviceType: string;
}

const AbsencePatronymicInForeignPassport = memo(({ cartItem, employees, serviceType }: IProps) => {
  const [isShowError, setIsShowError] = useState(false);

  const checkPatronymicDocument = () => {
    if (!employees.length) {
      return setIsShowError(false);
    }

    return employees.forEach((employee, index) => {
      const currentDocument = employee.Employee.Documents.find((item) => cartItem[index].DocumentId === item?.Id);

      if (currentDocument?.Type === PASSPORTS.FOREIGN_PASSPORT.TYPE && !currentDocument.Patronymic) {
        setIsShowError(true);

        return;
      }

      setIsShowError(false);
    });
  };

  useEffect(() => {
    checkPatronymicDocument();
  }, [cartItem, employees]);

  if (!isShowError || serviceType !== SERVICETYPE.TRAIN) return null;

  return (
    <Text
      color='red'
      type='NORMAL_16'
      className={ styles.errorText }
    >
      { LABELS.CHECK_PATRONYMIC }
    </Text>
  );
});

export { AbsencePatronymicInForeignPassport };
