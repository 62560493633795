import {
  ICarPlacesDetails,
  ICarWithoutSchemaRequest,
  IInternalTrainDetailsClientData,
} from '../../types/trains';

export const prepareCarStats = (
  value: {
    car: ICarPlacesDetails;
    companies: any;
    train: IInternalTrainDetailsClientData;
  },
  add: boolean,
): ICarWithoutSchemaRequest => {
  const {
    car,
    companies,
    train: { Route, StationDepart, StationArrive },
  } = value;
  const { Number, TrainNumber } = car;

  const fullRoute = Route.join(' – ');
  const selectedRoute = `${StationDepart} – ${StationArrive}`;
  const accountId = companies[0].CompanyId;
  const date = new Date(Date.now()).toISOString();

  return {
    Date: date,
    TrainNumber,
    CarNumber: Number,
    FullRoute: fullRoute,
    SelectedRoute: selectedRoute,
    CompanyId: accountId,
    Event: add ? 1 : 0,
  };
};

export { prepareCarStats as default };
