import { getText } from '../../../../../i18n';

import { FIELD_CALCULATOR, fieldCalculator, LABELS_COMMENT } from '../../const';

import { ICalculatorCancelRes } from '../../../../bi/services/events/type';

const LABELS = {
  COMMENT_FOREIGN: getText('event:calculator.city.const.day'),
};

const generateCommentMice = (data: ICalculatorCancelRes) => {
  const comments: string[] = [];

  const addComment = (label: string, value: string) => {
    comments.push(`${label}: ${value}`);
  };

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case fieldCalculator.Direction:
          addComment(FIELD_CALCULATOR.DIRECTION, LABELS_COMMENT.DIRECTION[value]);
          break;
        case fieldCalculator.HotelStars:
          addComment(FIELD_CALCULATOR.HOTEL, LABELS_COMMENT.HOTEL[value]);
          break;
        case fieldCalculator.ConferenceHall:
          addComment(FIELD_CALCULATOR.CONFERENCE_HALL, LABELS_COMMENT.HALL[value]);
          break;
        case fieldCalculator.Transfer:
          addComment(FIELD_CALCULATOR.TRANSFER, LABELS_COMMENT.TRANSFER[value]);
          break;
        case fieldCalculator.Food:
          if (Array.isArray(value) && value.length > 0) {
            const foodComments = (value as string[]).map(item => LABELS_COMMENT.EATS[item]).join(', ');
            addComment(FIELD_CALCULATOR.FOOD, foodComments);
          }

          break;
        case fieldCalculator.MICEServices:
          if (Array.isArray(value) && value.length > 0) {
            const servicesComments = (value as string[]).map(item => LABELS_COMMENT.OTHER[item]).join(', ');
            addComment(FIELD_CALCULATOR.SERVICES, servicesComments);
          }

          break;
      }
    }
  });

  return comments.length > 0 ? `${comments.join(', \n')}.` : '';
};

const generateCommentMiceForeign = (value: string, day: number) => {
  if (!value) {
    return `${LABELS.COMMENT_FOREIGN} ${day}.`;
  }

  return `${value},\n${LABELS.COMMENT_FOREIGN} ${day}.`;
};

export {
  generateCommentMice,
  generateCommentMiceForeign,
};
