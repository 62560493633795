import { UUID } from 'node:crypto';
import * as qs from 'query-string';
import axios from 'axios';

import { getFile, downloadFileWithGuid } from './xhr';
import { axiosInstance } from './apiNext';

import CONFIG from '../../../config';
import { ROUTES_V2, Routes } from './routes';

import {
  Department,
  Employee,
  FiltersRes,
  Project,
  Tag,
  TripData,
  TripsReport,
  TripsReq,
  TripsRes,
  UserAnalyticValue,
} from '../types/trips';
import { QuizData, QuizUserChoises } from '../types/travelPolicyQuiz';
import { CommissionType, PaymentMethodType } from '../types/paymentMethod';
import { PaginationOptions } from '../services/employeePayment/types';
import { FactualApprovesLiteResponse } from '../services/approve/types';
import { EmployeeType } from '../services/employee/consts';

const api = {
  applicationScheme: {
    getSchemes: async () => (await axios.get(`${CONFIG.API_CLUSTER}/travel-approval/template-request`)).data,
    createScheme: async (schemeTemplate: any) => (axios.post(`${CONFIG.API_CLUSTER}/travel-approval/template-request/`, schemeTemplate)),
    updateScheme: async (schemeTemplate: any, schemeId: string) => (axios.put(`${CONFIG.API_CLUSTER}/travel-approval/template-request/${schemeId}`, schemeTemplate)),
    getSchemeById: async (schemeId: string) => (await axios.get(`${CONFIG.API_CLUSTER}/travel-approval/template-request/${schemeId}`)).data,
    deleteSchemeById: async (schemeId: string) => (axios.delete(`${CONFIG.API_CLUSTER}/travel-approval/template-request/${schemeId}`)),
  },
  approve: {
    getList: async () => (await axios.get<FactualApprovesLiteResponse[]>(
      Routes.cluster.travelApproval.approves.factual.waiting,
    )).data,
  },
  approvalScheme: {
    getTravelApprovalRequests: async (EmployeeIds: number[], Statuses: string[], page: number) => {
      const { data } = await axios.post(`${CONFIG.API_CLUSTER}/travel-approval/requests?page=${page}`, { EmployeeIds, Statuses });

      return data;
    },

    getTravelApprovalEmployees: async () => (await axios.get(`${CONFIG.API_CLUSTER}/travel-approval/requests/employees`))?.data,

    deleteTravelApprovalRequest: async (requestId: number) => (
      await axios.delete(`${CONFIG.API_CLUSTER}/travel-approval/request/${requestId}`)
    ).data,

    downloadAllDocuments: async (requestId: number) => {
      const { data } = await axios.get(`${CONFIG.API_CLUSTER}/travel-approval/request/${requestId}/all-documents`);
      await getFile(`${data}`);
    },

    addToArchive: async (requestId: number) => (axios.put(`${CONFIG.API_CLUSTER}/travel-approval/request/${requestId}/archive`)),

    sendApprovalRequestToEmail: async (requestId: number, email: string) => (axios.post(`${CONFIG.API_CLUSTER}/travel-approval/request/${requestId}/email/${email}/all-documents`)),
  },
  smartdesk: {
    getTrips: async (): Promise<TripData[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/trips/smartdesk`)).data,
  },
  trips: {
    getTrips: async (data: TripsReq): Promise<TripsRes> => (await axios.post(`${CONFIG.API_CLUSTER}/trip-service/wp/trips/info`, data)).data,
    getFilters: async (): Promise<FiltersRes> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters`)).data,
    autocompleteCities: async (query: string): Promise<string[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters/cities/${query}`)).data,
    autocompleteEmployees: async (query: string): Promise<Employee[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters/employees/${query}`)).data,
    autocompleteTags: async (query: string): Promise<Tag[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters/tags/${query}`)).data,
    autocompleteProjects: async (query: string): Promise<Project[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters/projects/${query}`)).data,
    autocompleteUserAnalytics: async (id: number, query: string): Promise<UserAnalyticValue[]> => (await axios.get(`${CONFIG.API_CLUSTER}/trip-service/wp/filters/analytics/${id}/${query}`)).data,
    getTripsReport: async (data: TripsReport) => downloadFileWithGuid(`${CONFIG.API_CLUSTER}/analytics-service/wp/trips/basic`, data),
    getTripsReportCKR: async (data: TripsReport) => downloadFileWithGuid(`${CONFIG.API_CLUSTER}/analytics-service/special/ckr`, data),
    getSLAReportCKR: async (data: TripsReport) => downloadFileWithGuid(`${CONFIG.API_CLUSTER}/analytics-service/special/ckr/employees`, data),
  },
  departments: {
    get: async (): Promise<Department[]> => (await axios.get(`${CONFIG.API_ROOT}/departments`)).data,
  },
  travelPolicyQuiz: {
    getQuestion: async (questionNumber: number): Promise<QuizData> => (await axios.get(`${CONFIG.API_CLUSTER}/travel-policies-service/questionnaire/question/${questionNumber}`)).data,
    setQuestionData: async (data: QuizUserChoises): Promise<any> => (await axios.post(`${CONFIG.API_CLUSTER}/travel-policies-service/questionnaire/question/`, data)).data,
  },
  employeePersonalPayment: {
    getEmployeePersonalTransactions: async (sortingData: PaginationOptions) => (await axios.post(`${CONFIG.API_CLUSTER}/${ROUTES_V2.employeePersonalPayment.service}/transaction`, sortingData)).data,
    getCommissionInfo: async (price: number, type: CommissionType): Promise<number> => (await axios.get(`${CONFIG.API_CLUSTER}/${ROUTES_V2.employeePersonalPayment.service}/commission/calculate?price=${price}&type=${type}`)).data,
    payViaAPI: async (payload: any) => (await axios.post(`${CONFIG.API_CLUSTER}/${ROUTES_V2.employeePersonalPayment.service}/payment/by-cryptogram`, payload)).data,
    getPersonalBalance: async (): Promise<number> => (await axios.get(`${CONFIG.API_CLUSTER}/${ROUTES_V2.employeePersonalPayment.service}/balance`)).data,
    setPaymentMethod: async (cartId: number, pt: PaymentMethodType) => (
      await axios.put<PaymentMethodType>(Routes.cluster.cartService.cartWp.cartId.paymentSource(cartId, pt))
    ).data,
  },
  employee: {
    autocomplete: async (query: string, abortSignal: AbortController['signal']) => ((
      await axiosInstance.post<{ Employees: EmployeeType[] }>(
        Routes.cluster.autocompleteEmployee.employees,
        { Page: 1, Step: 20, SearchString: query },
        { signal: abortSignal },
      )
    ).data),
  },
  /**
   * @deprecated Please add microservice in oas-utilities and use apiNext from sibling file.
   */
  signature: {
    downloadDoc: async (docId: UUID) => downloadFileWithGuid(
      Routes.cluster.documentAggregator.v1.documentId.download(docId), {},
    ),
    downloadDocPreview: async (docId: UUID) => (
      await axios.get<string[]>(Routes.cluster.documentAggregator.v1.documentId.preview(docId))
    ).data,
    sendToEmail: async (email: string, docId: UUID) => (
      axios.post(Routes.cluster.documentAggregator.v1.documentId.sendEmail(docId), { email })
    ),
    sendToGov: async (docIds: UUID[]) =>
      axios.post(Routes.cluster.documentAggregator.v1.signEnhanced, { docIds }),
    downloadReport: async (employeeId: number) => (
      downloadFileWithGuid(
        Routes.cluster.documentAggregator.documents.registry.download(
          qs.stringify({ employeeId }),
        ),
        {},
      )
    ),
  },
};

export { api };
