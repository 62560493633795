import { MainAnalytic } from '../../utils/analytics';

import {
  CONSISTENTCARTMODES,
  FAST_APPROVE_ACTIONS,
  APPROVE_ANALYTIC_ACTION_BY_CART_STATUS, ConsistentCartModes, QuickApprovalActionTypes,
} from '../../constants/approve';

import { CartsType, ICartWP, ICartWpResult, ISpecificCartTypes } from '../../types/cart';
import { IList } from '../employee/types';

const DEFAULT_STATE_OF_APPROVE = 0;

const sendStatOnOpenApproveDialog = (cart: CartsType) => (
  cart
    ? MainAnalytic.send(
      MainAnalytic.CATEGORY.APPROVEMENT,
      MainAnalytic.ACTIONS.APPROVEMENT.OPENSENDTOAPPROVEDIALOG,
      { label: cart.name },
    )
    : null
);

const sendStatOnSendToApprove = (
  chosenApprovers: string[],
  comment: string,
  approversList: IList[],
  getApproverName: (item: IList) => string,
) => {
  const haveComment = comment ? 1 : 0;

  if (chosenApprovers.length === 1) {
    const approver = approversList.find(approverItem => approverItem.Email === chosenApprovers[0]);

    if (!approver) return null;

    const approverName = getApproverName(approver);

    return MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.SENDTOAPPROVETOONEAPPROVER, {
      label: approverName,
      value: haveComment,
    });
  }

  if (chosenApprovers.length > 1) {
    const approvers: string[] = [];
    chosenApprovers.forEach((chosenApprover) => {
      const approver = approversList.find(approverItem => approverItem.Email === chosenApprover);

      if (approver) {
        const approverName = getApproverName(approver);

        approvers.push(approverName);
      }
    });

    const approversText = approvers.join('; ');

    return MainAnalytic.send(
      MainAnalytic.CATEGORY.APPROVEMENT,
      MainAnalytic.ACTIONS.APPROVEMENT.SENDTOAPPROVETOSEVERALAPPROVERS,
      { label: approversText, value: haveComment },
    );
  }

  return null;
};

const sendStatOnDeleteApproveCart = (mode: string, type: string) => {
  const analyticAction = mode === CONSISTENTCARTMODES.VIEWER
    ? APPROVE_ANALYTIC_ACTION_BY_CART_STATUS[type]
    : MainAnalytic.ACTIONS.APPROVEMENT.DELETEBYAPPROVER;

  MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, analyticAction);
};

const sendStatOnOpenApprovePage = (cartService: any, cart: ICartWpResult, id: string) => { // TODO: убрать сервис, добавить только метод
  cartService.loadWaitingApproveList().then((res: ICartWP[]) => {
    const index = res.findIndex(item => item.Id === Number(id));

    if (index > -1 && cart) {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.OPENAPPROVEPAGE, {
        label: cart.Name,
        value: index + 1,
      });
    }
  });
};

const sendStatOnApprove = (shouldApprove: boolean) => {
  if (shouldApprove) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.APPROVE, {
      value: DEFAULT_STATE_OF_APPROVE,
    });
  }
};

const sendStatOnPurchase = (TYPES: ISpecificCartTypes, type: ConsistentCartModes) => {
  if (type === TYPES.APPROVER) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.BUYTRIP);
  }
};

const sendStatOnDecline = (declineComment: string) => {
  const haveComment = declineComment ? 1 : 0;

  MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.DECLINE, {
    value: haveComment,
  });
};

const sendStatOnDeleteOption = (confirmDelete: boolean) => {
  if (confirmDelete) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.DELETEBYAPPROVEROPTION);
  }
};

const sendStatOnFastApprove = (act: QuickApprovalActionTypes) => {
  switch (act) {
    case FAST_APPROVE_ACTIONS.ACCEPT: {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.FASTAPPROVE);
      break;
    }
    case FAST_APPROVE_ACTIONS.DECLINE: {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.FASTDECLINE);
      break;
    }
  }
};

export {
  sendStatOnOpenApproveDialog,
  sendStatOnSendToApprove,
  sendStatOnDeleteApproveCart,
  sendStatOnOpenApprovePage,
  sendStatOnApprove,
  sendStatOnDecline,
  sendStatOnDeleteOption,
  sendStatOnFastApprove,
  sendStatOnPurchase,
};
