import React from 'react';
import { RadioSwitcher, Icon, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './styles/index.module.css';

export interface IAttachingMice {
  items: {
    value: string,
    id: string,
    info?: boolean,
  }[],
  linkApplication(data: { EventId: string | null, CartId: number }): void,
  cartId: number,
  isOpen: boolean,
  valueSwitcher: string | null,
  setOpenSwitcher(value: boolean): void,
  setValueSwitcher(value: string | null): void,
}

const DEFAULT_LABEL = {
  LABEL: getText('event:attaching.general'),
  SWITCH_LABEL: getText('event:attaching.switch'),
  SELECT_LABEL: getText('event:attaching.select'),
  NO_CONTENT: getText('event:attaching.noContent'),
  TOOLTIP: getText('event:attaching.tooltip'),
};

const AttachingMice = ({
  items,
  linkApplication,
  cartId = 0,
  isOpen,
  valueSwitcher,
  setOpenSwitcher,
  setValueSwitcher,
}: IAttachingMice) => {
  const onChange = (eventId: string | null) => {
    linkApplication({ EventId: eventId, CartId: cartId });

    setValueSwitcher(eventId);

    if (eventId) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.SWITHER_ON);
    } else {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.SWITHER_OFF);
    }
  };

  const renderIcon = () => {
    const tooltip = () => (
      <Text
        color='white'
        className={ styles.content }
      >
        { DEFAULT_LABEL.TOOLTIP }
      </Text>
    );

    return (
      <Tooltip
        show
        className={ styles.tooltip }
        renderContent={ tooltip }
      >
        <Icon type='tickets'/>
      </Tooltip>
    );
  };

  return (
    <div
      className={ styles.wrapper }
    >
      <RadioSwitcher
        isOpen={ isOpen }
        value={ valueSwitcher }
        items={ items }
        iconType='searchGroup'
        label={ DEFAULT_LABEL.LABEL }
        switchLabel={ DEFAULT_LABEL.SWITCH_LABEL }
        selectLabel={ DEFAULT_LABEL.SELECT_LABEL }
        onSetOpen={ (value) => setOpenSwitcher(value) }
        onChange={ (value: string) => onChange(value) }
        childrenInfo={ renderIcon() }
      />
    </div>
  );
};

export { AttachingMice };
