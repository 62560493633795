import React from 'react';
import { Text } from 'new-ui';

import { TRAIN_CAR_TWO_FLOOR } from '../../../../../../bi/constants/train';
import { combineSections, updateLevelSections } from '../../../../../../bi/utils/train';

import AppService from '../../../../../../bi/services/app';

import { LevelSection } from '../LevelSection';
import { InfoItem } from '../InfoItem';

import {
  ICompartSection,
  IFreePlace,
  ILevel,
  IPlace,
  ISection,
  ISelectedPlace,
} from '../../../../../../bi/types/trains';

import styles from './index.module.css';

interface ITrainLevel {
  appService: AppService,
  number: string,
  level: ILevel,
  selectedPlaces: ISelectedPlace[],
  handleSelectedPlace: (place: IPlace) => void,
  onSelectedSection: (section: ISection) => void,
  type: string,
  freePlaces: IFreePlace[],
  choiceInitiaPlace: () => void,
  twoFloors: boolean,
  compartmentSections: ICompartSection[],
  entireCompartment: boolean,
  isSapsanFamily: boolean,
  differentTrains: boolean,
  carImage: string,
}

const TrainLevel: React.FC<ITrainLevel> = ({
  number,
  level: {
    sections,
    scheme,
    info,
    animal,
  },
  selectedPlaces,
  handleSelectedPlace,
  onSelectedSection,
  type,
  freePlaces,
  choiceInitiaPlace,
  twoFloors,
  compartmentSections,
  entireCompartment,
  isSapsanFamily,
  differentTrains,
  carImage,
  appService,
}) => {
  const levelSections: ISection[] = combineSections(
    sections,
    freePlaces,
    compartmentSections,
    differentTrains,
    twoFloors,
    updateLevelSections,
  );

  const isSedentaryTwoFloorsClassOne: boolean = type === TRAIN_CAR_TWO_FLOOR;

  const wrapClasses: string[] = [styles.wrap, styles[scheme]];

  const carNumberClasses: string[] = [styles.number];

  if (isSedentaryTwoFloorsClassOne) {
    carNumberClasses.push(styles.two_floor);
  }

  const sectionsHtml = levelSections.map((
    section: ISection,
    ind: number,
  ) => (
    <LevelSection
      key={ ind }
      section={ section }
      selectedPlaces={ selectedPlaces }
      choiceInitiaPlace={ choiceInitiaPlace }
      handleSelectedPlace={ handleSelectedPlace }
      onSelectedSection={ onSelectedSection }
      isSapsanFamily={ isSapsanFamily }
      entireCompartment={ entireCompartment }
      differentTrains={ differentTrains }
      appService={ appService }
    />
  ));

  const infoHtml: JSX.Element[] | null = !!info && !!info.length ? info.map(item => (
    <InfoItem
      key={ `${item.css.left}${item.css.top}${item.css.width}${item.css.height}` }
      item={ item }
    />
  )) : null;

  const animalHtml: JSX.Element[] | null = !!animal && !!animal.length ? animal.map(item => (
    <div
      key={ `${item.css.left}${item.css.top}${item.css.width}${item.css.height}` }
      className={ styles.animal }
      style={ { ...item.css } }
    />
  )) : null;

  return (
    <div
      className={ wrapClasses.join(' ') }
      style={ { backgroundImage: `url(${carImage})` } }
    >
      <Text
        type='NORMAL_18'
        className={ carNumberClasses.join(' ') }
      >
        { number }
      </Text>
      <div className={ styles.places }>
        { sectionsHtml }
        { infoHtml }
        { animalHtml }
      </div>
    </div>
  );
};

export { TrainLevel };
