import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Dialog } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { CreateStep } from '../CreateStep';
import { MainStep } from '../MainStep';
import { LimitStep } from '../LimitStep';
import { ApproveExpenseReportsStep } from '../ApproveExpenseReportsStep';
import { AdminStep } from '../AdminStep';
import { NotificationStep } from '../NotificationsStep';
import { CopyStep } from '../CopyStep';
import { EditStep } from '../EditStep';
import { CloseStep } from '../CloseStep';

import { getEmployeeFullName } from '../../../../../bi/utils/employees';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { STEPSRIGHTS, THEMEACTION } from '../../../../../bi/constants/rights';

import {
  CommonBlockProps,
  CommonBlockState,
} from '../../../../../bi/services/employee/types';

const getSteps = (type: string): any => ({
  create: {
    active: THEMEACTION.DEFAULT,
    component: CreateStep,
  },
  main: {
    active: THEMEACTION.DEFAULT,
    component: MainStep,
  },
  limit: {
    active: THEMEACTION.MEDIUM,
    component: LimitStep,
  },
  approveExpenseReports: {
    active: THEMEACTION.MEDIUM,
    component: ApproveExpenseReportsStep,
  },
  admin: {
    active: THEMEACTION.MEDIUM,
    component: AdminStep,
  },
  notification: {
    active: THEMEACTION.DONE,
    component: NotificationStep,
  },
  copy: {
    active: THEMEACTION.COPY,
    component: CopyStep,
  },
  edit: {
    active: THEMEACTION.DEFAULT,
    component: EditStep,
  },
  close: {
    active: THEMEACTION.DELETE,
    component: CloseStep,
  },
}[type]);

const HEADERS: { [key: string]: string } = {
  main: getText('settings:employees.employee.steps.rights.commonBlock.titles.main'),
  limit: getText('settings:employees.employee.steps.rights.commonBlock.titles.limit'),
  approveExpenseReports: getText('settings:employees.employee.steps.rights.commonBlock.titles.approveExpenseReports'),
  admin: getText('settings:employees.employee.steps.rights.commonBlock.titles.admin'),
  notification: getText('settings:employees.employee.steps.rights.commonBlock.titles.notification'),
  copy: getText('settings:employees.employee.steps.rights.commonBlock.titles.copy'),
  close: getText('settings:employees.employee.steps.rights.commonBlock.titles.close'),
};

@observer
class CommonBlock extends Component<CommonBlockProps, CommonBlockState> {
  constructor(props: any) {
    super(props);

    const {
      employee,
      rights,
      users,
    } = props;

    const isCreate = users.all.length > 0 ? STEPSRIGHTS.CREATE : STEPSRIGHTS.MAIN;
    const step = rights.edit ? STEPSRIGHTS.EDIT : isCreate;

    const employeeHeader = getEmployeeFullName(employee);
    const header = HEADERS[step] || employeeHeader;

    this.state = {
      step,
      header,
    };
  }

  handleSave = () => {
    this.props.onSave();
    this.props.onClose();
  };

  handleChangeStep = (step: string) => {
    this.setState({
      step,
      header: HEADERS[step],
    });
  };

  getTravelPolicy = (policies: { value: string }[], chosen: string) => (chosen ? policies.find(({ value }) => value === chosen) : policies[0]);

  render() {
    const { step, header } = this.state;
    const {
      employee: { Surname, Name, Patronymic, Email },
      rights,
      accountLng,
      show,
      showReports,
      showExpenseReportApprove,
      travelPolicies,
      approvalSchemes,
      chosenTP,
      chosenApprovalScheme,
      chosenApprovalERScheme,
      users,
      allApproveUsers,
      approveERUsers,
      approvalERSchemes,
      viewExpenseReportApprovers,
      documents,
      featureFlagsService,
      getCopyUserRights,
      onClose,
      onChangeRights,
      onChangeUser,
      onChangeApproveUsers,
      onChangeTravelPolicy,
      onChangeApprovalScheme,
      onChangeApproveERUsers,
      onChangeApprovalERScheme,
      onChangeDocuments,
      onCloseAccess,
      onChangeAccountLng,
      isWhiteLabel,
    } = this.props;

    const ItemComponent: any = getSteps(step).component;
    const shotEmployeeName = `${Surname} ${Name.charAt(0)}.${Patronymic.length ? `${Patronymic.charAt(0)}.` : ''}`;

    const travelPolicy = travelPolicies
      ? this.getTravelPolicy(travelPolicies, chosenTP)
      : null;

    return (
      <Dialog
        show={ show }
        onChange={ onClose }
      >
        <ItemComponent
          employee={ shotEmployeeName }
          email={ Email }
          showReports={ showReports }
          showExpenseReportApprove={ showExpenseReportApprove }
          isSmartAgent={ isSmartAgent }
          rights={ rights }
          accountLng={ accountLng }
          travelPolicies={ travelPolicies }
          approvalSchemes={ approvalSchemes }
          users={ users }
          documents={ documents }
          active={ getSteps(step).active }
          header={ header }
          allApproveUsers={ allApproveUsers }
          travelPolicy={ travelPolicy }
          approvalScheme={ chosenApprovalScheme }
          approvalERSchemes={ approvalERSchemes }
          viewExpenseReportApprovers={ viewExpenseReportApprovers }
          approveERUsers={ approveERUsers }
          approvalERScheme={ chosenApprovalERScheme }
          getCopyUserRights={ getCopyUserRights }
          featureFlagsService={ featureFlagsService }
          onChangeStep={ this.handleChangeStep }
          onSave={ this.handleSave }
          onClose={ onClose }
          onChangeRights={ onChangeRights }
          onChangeUser={ onChangeUser }
          onChangeApproveUsers={ onChangeApproveUsers }
          onChangeTravelPolicy={ onChangeTravelPolicy }
          onChangeApprovalScheme={ onChangeApprovalScheme }
          onChangeDocuments={ onChangeDocuments }
          onCloseAccess={ onCloseAccess }
          onChangeAccountLng={ onChangeAccountLng }
          onChangeApproveERUsers={ onChangeApproveERUsers }
          onChangeApprovalERScheme={ onChangeApprovalERScheme }
          isWhiteLabel={ isWhiteLabel }
        />
      </Dialog>
    );
  }
}

export { CommonBlock };
