import React, { useRef } from 'react';
import { Select } from 'new-ui';
import { getText } from '../../../../../i18n';

import { NumberInput } from '../../../../components/NumberInput';

import MoneyFormat from '../../../../bi/utils/money';

import { TRAVEL_POLICY_CURRENCIES, TRAVEL_POLICY_CURRENCIES_LABELS } from '../../../../bi/services/travelPolicy/consts';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

type OnePriceRuleProps = {
  isUnique?: boolean,
  type: string,
  value: {
    Value: string,
    Currency: number,
    IsVatIncluded?: boolean,
    IsEclcNotIncluded?: boolean,
  },
  onChange: (newValue: any) => void,
  onRender: (ref: HTMLElement | null) => void,
};

type CurrencyKey = keyof typeof TRAVEL_POLICY_CURRENCIES;

const LABELS = {
  NOT_MORE_EXPENSIVE: getText('settings:travelPolicy.rules.onePrice.notMoreExpensive'),
  WITHOUT_VAT: getText('settings:travelPolicy.rules.onePrice.withoutVat'),
  WITH_VAT: getText('settings:travelPolicy.rules.onePrice.withVat'),
  WITH_EARLY_IN_LATE_OUT: getText('settings:travelPolicy.rules.onePrice.withEarlyInLateOut'),
  WITHOUT_EARLY_IN_LATE_OUT: getText('settings:travelPolicy.rules.onePrice.withoutEarlyInLateOut'),
  AIR_TOOLTIP: getText('settings:travelPolicy.rules.onePrice.airTooltip'),
  AIR: (count: string, currency: string) => getText('settings:travelPolicy.rules.onePrice.labels.air', { count, currency }),
  TRAIN: getText('settings:travelPolicy.rules.onePrice.labels.train'),
  HOTEL: getText('settings:travelPolicy.rules.onePrice.labels.hotel'),
};

const getLabel = (type: string, { Value, Currency = TRAVEL_POLICY_CURRENCIES.RUB }: { Value: string, Currency: number }) => {
  switch (type) {
    case SERVICETYPE.AIR:
      return LABELS.AIR(MoneyFormat.money(Number(Value) * 2), TRAVEL_POLICY_CURRENCIES_LABELS[Currency]);
    case SERVICETYPE.TRAIN:
      return LABELS.TRAIN;
    case SERVICETYPE.HOTEL:
      return LABELS.HOTEL;
    default:
      return '';
  }
};

const VAT_SELECT_VALUES = [
  {
    value: 0,
    label: LABELS.WITHOUT_VAT,
  },
  {
    value: 1,
    label: LABELS.WITH_VAT,
  },
];

const EARLY_IN_LATE_OUT_VALUES = [
  {
    value: 0,
    label: LABELS.WITH_EARLY_IN_LATE_OUT,
  },
  {
    value: 1,
    label: LABELS.WITHOUT_EARLY_IN_LATE_OUT,
  },
];

const SELECT_ITEMS = Object.keys(TRAVEL_POLICY_CURRENCIES).map((key) => {
  const currencyKey = key as CurrencyKey;

  return {
    label: TRAVEL_POLICY_CURRENCIES_LABELS[TRAVEL_POLICY_CURRENCIES[currencyKey]],
    value: TRAVEL_POLICY_CURRENCIES[currencyKey],
  };
});

const OnePriceRule = ({
  isUnique,
  type,
  value,
  onChange,
  onRender,
}: OnePriceRuleProps) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);
  const vatIncludedContent = type === SERVICETYPE.HOTEL && !isUnique && (
    <Select
      theme='open-blue'
      value={ Number(value.IsVatIncluded) }
      onChange={ v => onChange({ IsVatIncluded: !!v }) }
      className={ styles.select }
      items={ VAT_SELECT_VALUES }
    />
  );

  const earlyInLateOutContent = type === SERVICETYPE.HOTEL && !isUnique && (
    <Select
      theme='open-blue'
      value={ Number(value.IsEclcNotIncluded) }
      onChange={ v => onChange({ IsEclcNotIncluded: !!v }) }
      className={ styles.select }
      items={ EARLY_IN_LATE_OUT_VALUES }
    />
  );

  const label = getLabel(type, value);

  return (
    <div className={ styles.wrap } data-qa={ QA_ATTRIBUTES.settings.travelPolicy.addRules.rules.onePriceRule }>
      { LABELS.NOT_MORE_EXPENSIVE }
      <NumberInput
        value={ value.Value }
        disabled={ !!isUnique }
        onChange={ v => onChange({ Value: v }) }
        className={ styles.input }
        onRender={ onRender }
        qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.addRules.rules.input }
      />
      <Select
        theme='open-blue'
        items={ SELECT_ITEMS }
        disabled={ !!isUnique }
        onChange={ (currency: string) => {
          onChange({ ...value, Currency: currency });

          if (inputRef.current) {
            inputRef.current.focus();
          }
        } }
        value={ value.Currency || TRAVEL_POLICY_CURRENCIES.RUB }
      />
      { label }
      { vatIncludedContent }
      { earlyInLateOutContent }
    </div>
  );
};

export { OnePriceRule };
