import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';
import { PageLoader } from 'new-ui';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { TripDocuments } from '../../components/TripDocuments';

import { WorkspaceService } from '../../bi/types/workspace';
import { NotificationService } from '../../bi/types/notification';
import { UserSessionService } from '../../bi/types/userSession';
import { IOrderDetailsData } from '../../bi/types/order';
import { DepartmentsService } from '../../bi/services/departments/types';
import CustomAnalyticsService from '../../bi/services/customAnalytics';
import PopupsService from '../../bi/services/popups';
import SettingsService from '../../bi/services/settings';
import Booking from '../../bi/services/booking';
import AppService from '../../bi/services/app';
import EventService from '../../bi/services/events';
import AccountSettings from '../../bi/services/accountSettings';
import OrderService from '../../bi/services/order';
import ReportService from '../../bi/services/report';

interface TripDocumentsPageProps {
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history'],
  workspaceService: WorkspaceService,
  notificationService: NotificationService,
  userSessionService: UserSessionService,
  orderService: OrderService,
  reportService: ReportService,
  departmentsService: DepartmentsService,
  customAnalyticsService: CustomAnalyticsService,
  popupsService: PopupsService,
  accountSettingsService: AccountSettings,
  settingsService: SettingsService,
  eventService: EventService,
  bookingService: Booking,
  appService: AppService,
}

const TripDocumentsPage = observer(({
  location,
  history,
  workspaceService,
  notificationService,
  userSessionService,
  orderService,
  reportService,
  bookingService,
  departmentsService,
  customAnalyticsService,
  accountSettingsService,
  settingsService,
  popupsService,
  eventService: {
    setOpenSwitcher,
    setValueSwitcher,
  },
  appService,
}: TripDocumentsPageProps) => {
  const stores: any = useStores([MOBX_STORES.UI_SETTINGS, MOBX_STORES.SETTINGS_STORE]);
  const [details, setDetails] = useState<IOrderDetailsData[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setOpenSwitcher(false);
    setValueSwitcher('');
  }, []);

  const fetchDetails = async ({ bookId = '' }: queryString.ParsedQuery) => {
    const {
      getState,
      getBookingStatusPaymentMethods,
    } = bookingService;
    setLoading(true);

    await getBookingStatusPaymentMethods(`${bookId}`);

    const { trips } = await getState();

    const tripsIds = trips.map((item: any) => item.TripId);
    const tripsDetails = await orderService.getDetailsByIds(tripsIds);

    setDetails(tripsDetails);
    setLoading(false);
  };

  useEffect(() => {
    fetchDetails(queryString.parse(location.search));
    customAnalyticsService.getAnalytics();
  }, []);

  const renderTripDocuments = () => {
    const { getState } = bookingService;
    const { trips } = getState();

    return details.map((trip, index) => {
      const isLastOrderItem = details.length === index + 1;
      const invoiceNumber = trips.find(({ TripId }: { TripId: number }) => TripId === trip.TripId).InvoiceNumber[0];

      return (
        <TripDocuments
          key={ index }
          trip={ trip }
          isLastOrderItem={ isLastOrderItem }
          invoiceNumber={ invoiceNumber }
          stores={ stores }
          location={ location }
          history={ history }
          workspaceService={ workspaceService }
          notificationService={ notificationService }
          userSessionService={ userSessionService }
          orderService={ orderService }
          reportService={ reportService }
          bookingService={ bookingService }
          departmentsService={ departmentsService }
          customAnalyticsService={ customAnalyticsService }
          accountSettingsService={ accountSettingsService }
          settingsService={ settingsService }
          popupsService={ popupsService }
          appService={ appService }
        />
      );
    },
    );
  };

  if (!details.length || loading || customAnalyticsService.store.loading) {
    return <PageLoader />;
  }

  return renderTripDocuments();
});

export { TripDocumentsPage };
