import React from 'react';
import {
  Button,
  Text,
  Dialog,
} from 'new-ui';

import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  DIALOG_TITLE_UPDATE_DATE: getText('cart:normalCart.changes.dialogTitleUpdateDate'),
  DIALOG_INFO_UPDATE_DATE: getText('cart:normalCart.changes.dialogInfoUpdateDate'),
  DIALOG_BUTTON_SEARCH: getText('cart:normalCart.changes.dialogButtonSearch'),
  DIALOG_BUTTON_CANCEL: getText('cart:normalCart.changes.dialogButtonCancel'),
};

interface IDialogUpdateDatesProps {
  show: boolean,
  onChangeShowUpdateDatesDialog(value: boolean): void,
  onGoToSearch(): void,
}

const DialogUpdateDates = ({
  show,
  onChangeShowUpdateDatesDialog,
  onGoToSearch,
}: IDialogUpdateDatesProps) => (
  <Dialog
    showClosing
    show={ show }
    onChange={ () => onChangeShowUpdateDatesDialog(false) }
  >
    <div className={ styles.dialog_update_dates }>
      <Text type='bold_22'>{ LABELS.DIALOG_TITLE_UPDATE_DATE }</Text>
      <Text type='NORMAL_16_130'>{ LABELS.DIALOG_INFO_UPDATE_DATE }</Text>
      <div className={ styles.dialog_update_dates_buttons }>
        <Button
          onClick={ onGoToSearch }
        >
          { LABELS.DIALOG_BUTTON_SEARCH }
        </Button>
        <Button
          type='textual'
          onClick={ () => onChangeShowUpdateDatesDialog(false) }
        >
          { LABELS.DIALOG_BUTTON_CANCEL }
        </Button>
      </div>
    </div>
  </Dialog>
);

export { DialogUpdateDates };
