import { observer } from 'mobx-react';
import { History, Location } from 'history';
import React, { useEffect } from 'react';
import { Text } from 'new-ui';
import * as queryString from 'query-string';
import clsx from 'clsx';

import { getText } from '../../../i18n';

import { useServices } from '../../bi/context/services';
import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import TrainsService from '../../bi/services/trains';
import TravelApproval from '../../bi/services/travelApproval';
import Smartdesk from '../../bi/services/smartdesk';
import FeatureFlags from '../../bi/services/featureFlags';

import { TrainsSearchMenu } from '../../components/Menu/TrainSearchMenu';
import { WarningMessage } from '../../components/WarningMessage';
import { TrainHistory } from './components/TrainsHistory';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';

import { HISTORY_ACTIONS } from '../../bi/constants/history';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES, SMART_AGENT_SERVICE_TYPE } from '../../bi/constants/serviceType';

import { ITrainHistoryItem } from '../../bi/types/trains';

import styles from './index.module.css';

interface ITrainsSearchPageProp {
  featureFlagsService: FeatureFlags;
  trainsService: TrainsService;
  travelApprovalService: TravelApproval;
  history: History;
  location: Location;
  isShowTitle?: boolean;
  smartdeskService: Smartdesk;
}

const LABELS = {
  TRAIN_SEARCH: getText('search:train.title'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
};

const TrainsSearchPage = observer(({
  featureFlagsService,
  featureFlagsService: {
    getTripRuleCustomMessage,
    getShowElementForSmartagent,
  },
  trainsService,
  travelApprovalService,
  history,
  location,
  smartdeskService: {
    setSearchSmartdesk,
  },
  isShowTitle = true,
}: ITrainsSearchPageProp) => {
  const { trainSearchStore } = useStores([MOBX_STORES.TRAIN_SEARCH]);
  const {
    orderService: {
      tripCache: {
        tripId,
        isRequest,
        tripName,
        tripDate,
        cacheFromQuery,
      },
    },
  } = useServices(['Order']);

  useEffect(() => {
    cacheFromQuery();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_TRAIN_SCREEN_OPENED);
  }, []);

  useEffect(() => {
    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.MAIN_SEARCH_OPENED, { SAservice: SMART_AGENT_SERVICE_TYPE.TRAIN });
    }

    if (history.action === HISTORY_ACTIONS.POP) {
      return () => {};
    }

    if (!isRequest) {
      trainsService.setNewSearch();
    }

    return travelApprovalService.setResetTravelApprovalsForCartStore();
  }, [history.action, location.key, trainsService, travelApprovalService, isRequest]);

  const handleSearch = () => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_FIND_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );

    if (trainSearchStore.isValid) {
      setSearchSmartdesk(true);

      if (getShowElementForSmartagent() && isSmartAgent) {
        MainAnalytic.sendAmplitudeArrayArgs(
          MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINSEARCH_SEARCH_PRESSED('searchTrain'),
        );
      }

      history.push({
        pathname: ROUTES.SEARCH.TRAIN_RESULTS,
        search: queryString.stringify({ ...trainsService.mapStateToSearchObject(), tripId }),
      });

      setSearchSmartdesk(false);
    }
  };

  const handleSearchByHistoryItem = (item: ITrainHistoryItem) => {
    history.push({
      pathname: ROUTES.SEARCH.TRAIN_RESULTS,
      search: queryString.stringify({ ...trainsService.getSearchObjectByHistoryItem(item), tripId }),
    });
  };

  const renderSearchMenu = () => {
    const wrapperMenu = clsx(
      styles.menu,
      isSmartAgent && getShowElementForSmartagent() && !isShowTitle && styles.search_sa,
    );

    return (
      <div className={ wrapperMenu }>
        { /* @ts-ignore */ }
        <TrainsSearchMenu
          trainsService={ trainsService }
          onSearch={ handleSearch }
          featureFlagsService={ featureFlagsService }
        />
      </div>
    );
  };

  const renderHistory = () => {
    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    return (
      <TrainHistory
        trainsService={ trainsService }
        onClick={ handleSearchByHistoryItem }
      />
    );
  };

  const renderWarningMessage = getTripRuleCustomMessage() ? <WarningMessage /> : null;

  const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TRAIN_SEARCH;
  const dateInTheAddingTrip = tripId
    ? <DateInSearch name={ tripName } date={ tripDate } />
    : null;

  const wrapper = isSmartAgent && getShowElementForSmartagent() && !isShowTitle
    ? styles.wrapper
    : `${styles.wrapper} ${styles.wrapper_sw}`;

  const renderTitle = () => {
    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    return (
      <Text
        type='bold_32'
        className={ styles.wrap_header }
        qaAttr={ QA_ATTRIBUTES.search.train.text }
      >
        { title }
        { dateInTheAddingTrip }
      </Text>
    );
  };

  return (
    <div className={ wrapper }>
      <BackToTripLink tripId={ tripId } />
      { renderTitle() }
      { renderSearchMenu() }
      { renderWarningMessage }
      { renderHistory() }
    </div>
  );
});

export { TrainsSearchPage };
