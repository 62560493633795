// @ts-nocheck
import { CREATE_EXPENSE_REPORT } from '../../constants/rights';
import { STATUS_PAGE, LOADINGS_FIELD, ApproveStatus } from '../../constants/expenseReport';

class ExpenseReports {
  constructor(services) {
    Object.keys(services).forEach((s) => {
      this[s] = services[s];
    });
  }

  setExpenseReportStatusPage = (data) => {
    const { UserId } = this.Workspace.rights;
    const Status = data?.Status;
    const Approves = data?.Approves;

    const isApprover = Status === ApproveStatus.WAITING_APPROVE &&
      ((!!UserId && !!Approves.find(aprrove => aprrove.UserId === UserId)) || this.Workspace.isAdmin);

    if (isApprover) {
      return this.ExpenseReports.setExpenseReportStatusPage(STATUS_PAGE.APPROVER);
    }

    if (status !== undefined && status !== null) {
      return this.ExpenseReports.setExpenseReportStatusPage(this.ExpenseReports.getStatusFromBackend(Status));
    }

    return null;
  };

  loadExpenseReport = async (id) => {
    this.ExpenseReports.setLoadingReport(true, LOADINGS_FIELD.LOADING);

    const createExpenseReports = this.Workspace.canCreateExpenseReports;
    const userEmployee = this.Workspace.dataUserEmployee;
    const { employeeId } = userEmployee;

    try {
      const res = await this.ExpenseReports.getExpenseReport(id);
      const { Items, Status, EmployeeId } = res;

      const statusNotApprove = Status === 6;

      if (statusNotApprove && createExpenseReports === CREATE_EXPENSE_REPORT.ApprovalScheme) {
        await this.ExpenseReports.getApproversSteps(EmployeeId, Items);
      }

      if (statusNotApprove && createExpenseReports === CREATE_EXPENSE_REPORT.OnlyAfterApprove) {
        await this.ExpenseReports.getApprovers(employeeId);
      }

      this.setExpenseReportStatusPage(res);
      this.ExpenseReports.openReport(res);

      return this.ExpenseReports.setLoadingReport(false, LOADINGS_FIELD.LOADING);
    } catch (e) {
      this.setExpenseReportStatusPage(null);
      this.ExpenseReports.setLoadingReport(false, LOADINGS_FIELD.LOADING);

      return Promise.reject();
    }
  };
}

export { ExpenseReports };
