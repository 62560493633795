import React, { useState } from 'react';
import { Input, RadioSwitcher, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { INTEGER } from '../../../../../../bi/constants/regExp';
import { DEFAULT_ITEMS_MICE, FOOD_MICE, HALL_MICE, HOTELS_MICE, MICE_SERVICES, TRANSFER_MICE } from '../../../../const';

import { ICalculatorRes } from '../../../../../../bi/services/events/type';

import styles from './styles/index.module.css';
import MiceButtons from '../MiceButtons';
import { MiceCheckbox } from '../MiceCheckbox';

const getIntegerNumber = (value: string) => INTEGER.test(value);

const isIncludes = (arr: string[], value: string) => arr.includes(value);

const LABELS = {
  SUBTITLE: getText('event:calculator.city.subtitle'),
  PEOPLE: getText('event:calculator.city.people'),
  DAY: getText('event:calculator.city.day'),
  SWITCHER: {
    HOTEL: getText('event:calculator.city.switcher.hotel'),
    CONFERENCE_HALL: getText('event:calculator.city.switcher.conferenceHall'),
    TRANSFER: getText('event:calculator.city.switcher.transfer'),
  },
  SERVICES: getText('event:calculator.city.services'),
  EAT: getText('event:calculator.city.eat'),
  EATS: {
    COFEE: getText('event:calculator.city.checkboxEat.coffee'),
    LUNCH: getText('event:calculator.city.checkboxEat.lunch'),
    DINNER: getText('event:calculator.city.checkboxEat.dinner'),
    BUFFET: getText('event:calculator.city.checkboxEat.buffet'),
    BANQUET: getText('event:calculator.city.checkboxEat.banquet'),
  },
  OTHER: getText('event:calculator.city.other'),
  OTHERS: {
    TEAM: getText('event:calculator.city.checkboxOther.team'),
    DJ: getText('event:calculator.city.checkboxOther.dj'),
    PHOTO: getText('event:calculator.city.checkboxOther.photo'),
    LEADING_EVENT: getText('event:calculator.city.checkboxOther.leadingEvent'),
  },
  BUTTON: {
    DONE: getText('event:calculator.button.done'),
    CANCEL: getText('event:calculator.button.cancel'),
  },
  ERROR: getText('event:calculator.errorInput'),
};

interface ILargeCity {
  direction: string,
  calculatorSend(value: ICalculatorRes): void,
  setShowCalculator(): void,
  setIsCompete(): void,
}

const LargeCity = ({
  direction,
  calculatorSend,
  setShowCalculator,
  setIsCompete,
}: ILargeCity) => {
  const [people, setPeople] = useState('');
  const [day, setDay] = useState('');
  const [hotel, setHotel] = useState<string | null>(DEFAULT_ITEMS_MICE.HOTEL);
  const [hall, setHall] = useState<string | null>(DEFAULT_ITEMS_MICE.HALL);
  const [transfer, setTransfer] = useState<string | null>(DEFAULT_ITEMS_MICE.TRAIN);
  const [food, setFood] = useState<string[]>([]);
  const [miceServices, setMiceServices] = useState<string[]>([]);
  const [errorDay, setErrorDay] = useState<string>('');
  const [errorPeople, setErrorPeople] = useState<string>('');

  const toggleValueFood = (value: string) => {
    const newArray = isIncludes(food, value)
      ? food.filter(item => item !== value)
      : [...food, value];

    setFood(newArray);
  };

  const toggleValueMiceServices = (value: string) => {
    const newArray = isIncludes(miceServices, value)
      ? miceServices.filter(item => item !== value)
      : [...miceServices, value];

    setMiceServices(newArray);
  };

  const setValue = (value: string, setValues: (value: string) => void) => {
    if (getIntegerNumber(value) || value === '') {
      setValues(value);
    }
  };

  const setValuesOpen = (value: boolean, setValues: (value: string | null) => void) => {
    if (!value) {
      setValues(null);
    }
  };

  const onSendCalculator = async () => {
    if (!!day && !!people) {
      setErrorDay('');
      setErrorPeople('');

      await calculatorSend({
        Direction: direction,
        Food: food,
        MICEServices: miceServices,
        Transfer: transfer,
        ConferenceHall: hall,
        HotelStars: hotel ? Number(hotel) : null,
        DaysCount: Number(day),
        ParticipantsCount: Number(people),
      });

      setIsCompete();
    } else {
      if (!day) {
        setErrorDay(LABELS.ERROR);
      }

      if (!people) {
        setErrorPeople(LABELS.ERROR);
      }
    }
  };

  const renderHeader = () => (
    <div className={ styles.wrapper_input }>
      <div className={ styles.subwrapper_input }>
        <Text className={ styles.text }>
          { LABELS.PEOPLE }
        </Text>
        <Input
          className={ styles.input }
          value={ people }
          onChange={ (value: string) => setValue(value, setPeople) }
          error={ errorPeople }
        />
      </div>
      <div className={ styles.subwrapper_input }>
        <Text className={ styles.text }>
          { LABELS.DAY }
        </Text>
        <Input
          className={ styles.input }
          value={ day }
          onChange={ (value: string) => setValue(value, setDay) }
          error={ errorDay }
        />
      </div>
    </div>
  );

  const renderSwitchers = () => {
    const renderSwitcher = (
      label: string,
      value: string | null,
      items: { value: string, id: string }[],
      onChange: (value: string | null) => void,
    ) => (
      <div className={ styles.wrapper_switch }>
        <RadioSwitcher
          isOpen
          value={ value }
          items={ items }
          onChange={ onChange }
          onSetOpen={ (val: boolean) => setValuesOpen(val, onChange) }
          label={ label }
          classname={ styles.switcher }
          // @ts-ignore
          theme='defaultSelect'
        />
      </div>
    );

    return (
      <div className={ styles.wrapper_switcher }>
        <Text className={ styles.title } type='bold_20'>
          { LABELS.SERVICES }
        </Text>
        <div className={ styles.subwrapper_switcher }>
          { renderSwitcher(LABELS.SWITCHER.HOTEL, hotel, HOTELS_MICE, setHotel) }
          { renderSwitcher(LABELS.SWITCHER.CONFERENCE_HALL, hall, HALL_MICE, setHall)}
          { renderSwitcher(LABELS.SWITCHER.TRANSFER, transfer, TRANSFER_MICE, setTransfer) }
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { renderHeader() }
      { renderSwitchers() }
      <div className={ styles.wrapper_checkbox }>
        <Text className={ styles.title } type='bold_20'>
          { LABELS.EAT }
        </Text>
        <div className={ styles.subwrapper_checkbox }>
          <MiceCheckbox
            onChange={ toggleValueFood }
            values={ food }
            label={ LABELS.EATS.COFEE }
            item={ FOOD_MICE.COFEE_BREAK }
          />
          <MiceCheckbox
            onChange={ toggleValueFood }
            values={ food }
            label={ LABELS.EATS.LUNCH }
            item={ FOOD_MICE.LUNCH }
          />
          <MiceCheckbox
            onChange={ toggleValueFood }
            values={ food }
            label={ LABELS.EATS.DINNER }
            item={ FOOD_MICE.DINNER }
          />
          <MiceCheckbox
            onChange={ toggleValueFood }
            values={ food }
            label={ LABELS.EATS.BUFFET }
            item={ FOOD_MICE.BUFFET }
          />
          <MiceCheckbox
            onChange={ toggleValueFood }
            values={ food }
            label={ LABELS.EATS.BANQUET }
            item={ FOOD_MICE.BANQUET }
          />
        </div>
        <div className={ styles.wrapper_other }>
          <Text className={ styles.title } type='bold_20'>
            { LABELS.OTHER }
          </Text>
          <div className={ `${styles.subwrapper_checkbox} ${styles.subwrapper_checkbox_two}` }>
            <MiceCheckbox
              onChange={ toggleValueMiceServices }
              values={ miceServices }
              label={ LABELS.OTHERS.TEAM }
              item={ MICE_SERVICES.TEAM_BUILDING }
            />
            <MiceCheckbox
              onChange={ toggleValueMiceServices }
              values={ miceServices }
              label={ LABELS.OTHERS.DJ }
              item={ MICE_SERVICES.DJ }
            />
            <MiceCheckbox
              onChange={ toggleValueMiceServices }
              values={ miceServices }
              label={ LABELS.OTHERS.PHOTO }
              item={ MICE_SERVICES.PHOTOGRAPHER }
            />
            <MiceCheckbox
              onChange={ toggleValueMiceServices }
              values={ miceServices }
              label={ LABELS.OTHERS.LEADING_EVENT }
              item={ MICE_SERVICES.HOST }
            />
          </div>
        </div>
        <MiceButtons
          labelTextual={ LABELS.BUTTON.CANCEL }
          labelSecondary={ LABELS.BUTTON.DONE }
          onClickSecondary={ onSendCalculator }
          onClickTextual={ setShowCalculator }
        />
      </div>
    </div>
  );
};

export { LargeCity };
