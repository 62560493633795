import ACTION from '../intercom/action';
import { IUserChat } from '../../../types/chat';

class CarrotQuest {
  api: any;
  store: any;
  title: string;

  constructor(api: any, store: any) {
    this.api = api;
    this.store = store;

    this.title = document.title;
  }

  getUserAuthorizeData = () => this.api.carrotQuestChat.userAuthorizeData();

  show = () => {
    // @ts-ignore
    if (window.carrotquest) {
      // @ts-ignore
      window.carrotquest.open();
    }
  };

  showMessages = () => {
    // @ts-ignore
    if (window.carrotquest) {
      // @ts-ignore
      window.carrotquest.openMessenger();
    }
  };

  hide = () => {
    // @ts-ignore
    if (window.carrotquest) {
      // @ts-ignore
      window.carrotquest.removeChat();
    }
  };

  openSession = () => { // нет такого метода в Intercom, заглушка для декоратора
    this.show();
  };

  closeSession = () => { // нет такого метода в Intercom, заглушка для декоратора
    // @ts-ignore
    if (window.carrotquest) {
      // @ts-ignore
      window.carrotquest.hide();
    }
  };

  updateSession = () => null;

  sendMessageToAdmin = (user: IUserChat | null, msg: string, note: string = '', supressWidget: boolean = false) => {
    // @ts-ignore
    if (window.carrotquest) {
      const carrotQuestMessageSenderUser = 1;

      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      // @ts-ignore
      return this.api.chat.sendMessageToAdmin({ Message: msg, Email: user.email, note, MessageSender: carrotQuestMessageSenderUser })
        .then(() => {
          if (supressWidget) {
            return;
          }

          this.showMessages();
        }, 1000)
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  };

  requestError = (errorMsg: string) => {
    if (errorMsg) {
      // @ts-ignore
      this.sendMessageToAdmin('', errorMsg); // TODO: первый параметр должен быть объектом или null
    }
  };
}

export default CarrotQuest;
