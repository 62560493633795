import { Moment } from 'moment';
import CONFIG from '../../config';

import { changeLanguage, getText } from '../../i18n';

import getServiceConstructor, { CONSTRUCTORS } from './services';
import { CONSTRUCTORS as PROTOCOLS, getProtocolConstructor, PROTOCOLS_DEPENDENCIES } from './protocols';

import Api, { LOCALSTORAGEKEY } from './api';

import { setUserSentry } from '../../utils/sentry';
import { Amplitude, MixPanel } from './utils/analytics';
import guid from './utils/guid';
import getAccountId from './utils/getAccountId';
import {
  getMoment,
  diffSeconds,
  getTimeOutMinutes,
  getTimeOutMinute,
  formatDate,
} from './utils/formatDate';

import { DATEFORMATS } from './constants/dateFormats';
import STATUS from './constants/status';
import AppService from './services/app';
import { IDataReconnect } from './types/app';
import { ServiceReturnType } from './context/services';

const LOGINERROR = getText('login:loginError');
const QUICKAUTHERROR = getText('login:quickAuthError');
const TIMEOUT_ATTEMPT_ERROR = getText('login:timeOutAttemptError');

const ROUTELOGIN = '/login';

class App {
  startedServices: { [key: string]: any } = {};
  appService: AppService;
  startedProtocols: { [key: string]: any } = {};
  api: null | Api = null;

  constructor() {
    this.api = new Api({
      reconnectCb: this.reconnect,
    });

    this.appService = this.getService('App');

    if (!this.api.currentAccessToken) {
      this.appService.updateAppState(STATUS.NOTAUTH);
    } else {
      this.appService.updateAppState(STATUS.OK);
    }
  }

  get sessionId() {
    const sessionId = window.sessionStorage.getItem('session_id');

    if (!sessionId) {
      window.sessionStorage.setItem('session_id', guid());
    }

    return window.sessionStorage.getItem('session_id');
  }

  loadAppResources = async () => {
    const appService = this.getService('App');
    const workspaceService = this.getService('Workspace');
    const userSessionService = this.getService('UserSession');
    const featureFlagService = this.getService('FeatureFlags');
    const uiSettingsService = this.getService('UiSettings');
    const approvalRequestProtocol = this.getProtocol('ApprovalRequest');
    const uiSettingsProtocol = this.getProtocol('UiSettings');
    const bannersService = this.getService('Banners');
    const popupsService = this.getService('Popups');
    const cloudPaymentService = this.getService('CloudPayment');

    const accountId = getAccountId();

    try {
      const { Companies, userId } = await workspaceService.getAccount();
      appService.loadHeader(Companies);
      cloudPaymentService.setAccountId(userId);
    } catch (err) {
      // @ts-ignore
      if (err.status === 401 || err.status === 403 || err.status === 404) {
        this.logout();
        window.location.reload();
      }
    }

    try {
      const { Rights: { UserId } } = workspaceService.get();

      await uiSettingsService.getLanguageSettings(UserId, accountId);

      const userLng = uiSettingsService.getCurrentLanguage();
      changeLanguage(userLng);
    } catch {}

    try {
      await bannersService.getBannersState();
      await popupsService.getPopupsState();
    } catch {}

    const enumsPromise = userSessionService.getEnums;
    const projectsPromise = userSessionService.getProjects;
    const travelPoliciesPromise = userSessionService.getTravelPolicies;
    const featureFlagsPromise = featureFlagService.loadFeatureFlags;
    const approvalRequest = approvalRequestProtocol.loadApproveRequestExists;
    const uiSettingsPromises = uiSettingsProtocol.changeUiSettings;
    const loadAgentMode = appService.loadAgentMode;

    const { Email } = workspaceService.get();

    setUserSentry({
      email: Email,
      id: String(accountId),
    });

    return new Promise(resolve => Promise.all([
      enumsPromise(),
      projectsPromise(),
      travelPoliciesPromise(),
      featureFlagsPromise(),
      approvalRequest(),
      uiSettingsPromises(),
      loadAgentMode(),
    ]).then(resolve));
  };

  getService = <S extends keyof ServiceReturnType>(serviceName: S): ServiceReturnType[S] => {
    if (!this.startedServices[serviceName as keyof typeof this.startedServices]) {
      const ServiceConstructor = getServiceConstructor(serviceName as keyof typeof CONSTRUCTORS);

      if (ServiceConstructor) {
        // @ts-ignore
        this.startedServices[serviceName as keyof typeof CONSTRUCTORS] = new ServiceConstructor(this.api);
      } else {
        throw new Error('service with this name was not found');
      }
    }

    return this.startedServices[serviceName];
  };

  getProtocol = (protocolName: string) => {
    if (!this.startedProtocols[protocolName as keyof typeof this.startedProtocols]) {
      const ProtocolConstructor = getProtocolConstructor(protocolName);

      if (ProtocolConstructor) {
        this.startedProtocols[protocolName as keyof typeof this.startedProtocols] =
          // @ts-ignore
          new ProtocolConstructor(PROTOCOLS_DEPENDENCIES[protocolName as keyof typeof PROTOCOLS]
          // @ts-ignore
            .reduce((r, service) => ({ ...r, [service]: this.getService(service) }), {}));
      } else {
        throw new Error('protocol with this name was not found');
      }
    }

    return this.startedProtocols[protocolName];
  };

  reconnect = (action: string, data: IDataReconnect) => {
    const appService = this.getService('App');

    if (action === 'reconnect') {
      appService.showReconnectDialog(data);
    } else {
      appService.hideReconnectDialog();
    }
  };

  handleCheckTimeOutLeft = (timeOutAttempt: Moment, timerId: NodeJS.Timeout) => {
    const authService = this.getService('Auth');
    const now = getMoment();
    const diff = Number(diffSeconds(now, timeOutAttempt));

    if (diff < 3) {
      const state = 401;
      authService.loginError('', state);
      authService.setButtonDisabled(false);
      clearInterval(timerId);
    }
  };

  login = (quickAuthUuid?: string): Promise<void> => new Promise((resolve) => {
    const authService = this.getService('Auth');
    authService.setStartLogin();

    const { username, password } = authService.getStore();

    // @ts-ignore
    this.api.login(username, password, quickAuthUuid)
      .then((res) => {
        // @ts-ignore
        const { TimeOutAttempt } = res;

        if (res && TimeOutAttempt) {
          const state = 401;
          const timeoutMinutes = getTimeOutMinutes(TimeOutAttempt, 1);
          const timeout = getTimeOutMinute(TimeOutAttempt, timeoutMinutes);
          const err = `${TIMEOUT_ATTEMPT_ERROR} ${formatDate(timeout, DATEFORMATS.TIME)}`;

          authService.setButtonDisabled(true);
          const timerId = setInterval(() => this.handleCheckTimeOutLeft(timeout, timerId), 2000);

          return authService.loginError(err, state);
        }

        authService.loginSuccess(res);

        Amplitude.pushEvent(Amplitude.TYPE.LOGIN);

        return this.loadAppResources().then(() => {
          // @ts-ignore
          this.appService.updateAppState(status);
          // @ts-ignore
          resolve(status);
        });
      })
      .catch(() => {
        const err = quickAuthUuid ? QUICKAUTHERROR : LOGINERROR;
        const state = 401;

        Amplitude.pushEvent(Amplitude.TYPE.LOGINFAIL);

        authService.loginError(err, state);
      });
  });

  loginToClientSession = () => new Promise((resolve) => {
    try {
      const authService = this.getService('Auth');
      authService.setStartLogin();

      const { username, password } = authService.getStore();
      // @ts-ignore
      this.api.clientSessionLogin(username, password)
        .then((res) => {
          // @ts-ignore
          if (res.error) {
            const state = 401;

            this.logout();
            window.location.href = ROUTELOGIN;

            authService.loginError(LOGINERROR, state);

            return;
          }

          // @ts-ignore
          const { TimeOutAttempt } = res;

          if (res && TimeOutAttempt) {
            const state = 401;
            const timeoutMinutes = getTimeOutMinutes(TimeOutAttempt, 1);
            const timeout = getTimeOutMinute(TimeOutAttempt, timeoutMinutes);
            const err = `${TIMEOUT_ATTEMPT_ERROR} ${formatDate(timeout, DATEFORMATS.TIME)}`;

            authService.setButtonDisabled(true);
            const timerId = setInterval(() => this.handleCheckTimeOutLeft(timeout, timerId), 2000);

            authService.loginError(err, state);
          }

          authService.loginSuccess(res);

          Amplitude.pushEvent(Amplitude.TYPE.LOGIN);

          this.loadAppResources().then(() => {
            // @ts-ignore
            this.appService.updateAppState(status);
            // @ts-ignore
            resolve(status);
          });
        });
    } catch (error) {
      const state = 401;

      Amplitude.pushEvent(Amplitude.TYPE.LOGINFAIL);
      // @ts-ignore
      authService.loginError(LOGINERROR, state);
    }
  });

  logout = () => {
    // @ts-ignore
    this.api.logout();
    const xFrame = document.getElementById('xFrame');
    const request = {
      method: 'remove',
      token: {
        name: LOCALSTORAGEKEY.MAIN,
      },
    };
    // @ts-ignore
    xFrame.contentWindow.postMessage(JSON.stringify(request), CONFIG.PROMO_MAIN);

    MixPanel.reset();
    Amplitude.pushEvent(Amplitude.TYPE.LOGOUT);
    Amplitude.setUnauthUser();
  };
}

export default App;
