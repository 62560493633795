import React, { ReactNode } from 'react';
import {
  Button,
  Icon,
  // PageLoader,
  Text,
} from 'new-ui';

import { getText } from '../../../../../../../i18n';

import ItemLayout from '../../../../../../components/ItemLayout';
import { RoundIconWithTooltip } from '../../../../../../components/RoundIconWithTooltip';
import AirlineBonus from '../../../../../../components/AirlineBonus';
import TaxiDetailsPrice from '../../../../../../components/TaxiDetailsPrice';
import { AbsencePatronymicInForeignPassport } from '../AbsencePatronymicInForeignPassport';

import {
  getPriceWithCertificate,
  getVoidTimerStartsAfterBookingString,
  getVoidUntilEndOfDayString,
} from '../../../../../../bi/utils/airline';
import { isSmartAgent } from '../../../../../../bi/utils/env';
import { getDetailsAmount } from '../../../../../../bi/utils/taxi';
import MoneyFormat from '../../../../../../bi/utils/money';
import { momentObjectUTC } from '../../../../../../bi/utils/formatDate';
// import { getUpdateTextByServiceType, getColorByServiceType } from '../../../../../../bi/utils/cart';

import { ADDITIONAL_SERVICES } from '../../../../../../bi/constants/airline';
import {
  SERVICETYPE,
  // SERVICES_ICON,
} from '../../../../../../bi/constants/serviceType';

import CartService from '../../../../../../bi/services/cart';
import WorkspaceService from '../../../../../../bi/services/workspace';
import AppService from '../../../../../../bi/services/app';
import { ISourcesEmployees, ISourcesItem } from '../../../../../../bi/services/checkout/types';
import { AirSegmentInterface } from '../../../../../../bi/utils/changeAirTrip/types';
import { AdditionaService } from '../../../../../../bi/types/airline';
// import { ICartItemsUpdate, IStatusUpdates, ITypesUpdates } from '../../../../../../bi/types/cart';

import styles from '../../styles/index.module.css';

const LABELS = {
  FINDREPLACEMENT: getText('cart:normalCart.findReplacement'),
  C: 'С',
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
  UPDATE_INFO: getText('components:hotelItem.updateInfo'),
  BOOKING: getText('cart:normalCart.booking_com'),
  RULES_TARIFF: getText('cart:normalCart.rulesTariff'),
  ANNULATION: (voidTimeLimitString: string | null) =>
    getText('cart:normalCart.annulation', { voidTimeLimitString }),
  ADDITIONAL_SERVICES: {
    ADDITIONAL_INFO: getText('cart:normalCart.additionalServices.additionalServiceInfo'),
  },
  CHANGES: {
    TITLE_DATE_CHANGE: getText('cart:normalCart.changes.titleDateChange'),
    BUTTON_CHANGE_DATE: getText('cart:normalCart.changes.buttonDateChange'),
  },
};

interface IBodyNormalCartProps {
  allowedEmployees: ISourcesEmployees[],
  // itemsToUpdate: ICartItemsUpdate[],
  item: ISourcesItem,
  showFindReplacementBtn: boolean,
  readonly: boolean,
  cartService: CartService,
  workspaceService: WorkspaceService,
  appService: AppService,
  isDemoState: boolean,
  isBookCertificate: boolean,
  isHideAdditionalSeatsS7: boolean,
  isHideAdditionalBaggageMixvel: boolean,
  itemData: any,
  currentBonus: number,
  sameAirlineCompany: string | null,
  bookingCom: boolean,
  qaAttrFields: {
    delete: string,
    icon: string,
    note: string,
    price: string,
    selectEmployee: string,
  },
  getQaAttrByField(serviceType: string, qaAttrFields: string): string,
  onChangeRulesAirDialog(openDialog: boolean): void,
  onFindReplacement(): void,
  // onShowUpdateDatesDialog(value: boolean): void,
  renderAdditionalPrice(): ReactNode,
  renderItem(): ReactNode,
  renderBookAirCertificate(): ReactNode,
}

const BodyNormalCart = ({
  allowedEmployees,
  // itemsToUpdate,
  item: {
    Id,
    Price,
    ServiceType,
    AgentFee,
    EmployeeCartItems,
    Data,
  },
  showFindReplacementBtn,
  readonly,
  cartService: {
    setAdditionalFeeSmartagentCart,
    removeAdditionalFeeSmartagentCart,
  },
  workspaceService: {
    isDemo,
  },
  appService,
  isDemoState,
  isBookCertificate,
  isHideAdditionalSeatsS7,
  isHideAdditionalBaggageMixvel,
  // itemData,
  itemData: {
    Routes: routes = [],
    Metadata,
    VoidTimeLimitUTC = null,
    VoidUntilEndOfDay = false,
    VoidTimeLimitUpdated = null,
  },
  currentBonus,
  sameAirlineCompany,
  bookingCom,
  getQaAttrByField,
  qaAttrFields,
  onChangeRulesAirDialog,
  // onShowUpdateDatesDialog,
  onFindReplacement,
  renderAdditionalPrice,
  renderItem,
  renderBookAirCertificate,
}: IBodyNormalCartProps) => {
  // Скрытый релиз обновления корзины
  // const itemToUpdate = itemsToUpdate.find(({ CartItemId }) => CartItemId === Id);
  // const itemHasChanges = itemToUpdate && itemToUpdate.Changes.length;

  // if (itemToUpdate && itemToUpdate.State.Status === IStatusUpdates.InProgress) {
  //   const colorIconByServiceType = getColorByServiceType(ServiceType);
  //   const nameByServiceType = getUpdateTextByServiceType(ServiceType, itemData);

  //   return (
  //     <div className={ styles.update_info_hotel }>
  //       <Icon
  //         type={ SERVICES_ICON[ServiceType] }
  //         alternativeDesign={ isSmartAgent }
  //         color={ colorIconByServiceType }
  //         className={ styles.update_icon }
  //       />
  //       <Text type='bold_18'>{ nameByServiceType }</Text>
  //       <Text type='NORMAL_16'>{ LABELS.UPDATE_INFO }</Text>
  //       <div className={ styles.update_loader }>
  //         <PageLoader />
  //       </div>
  //     </div>
  //   );
  // }

  const renderAirAFlag = () => {
    const { AFlag } = JSON.parse(Data);

    if (!AFlag) {
      return null;
    }

    return (
      <RoundIconWithTooltip
        label={ LABELS.C }
        className={ styles.a_flag }
      />
    );
  };

  const renderBonus = () => {
    if (!currentBonus
      || !sameAirlineCompany
      || !isDemo
      || (isDemo && isSmartAgent)) return null;

    return (
      <AirlineBonus
        bonus={ currentBonus }
        sameAirlineCompany={ sameAirlineCompany }
      />
    );
  };

  const renderDetailsPrice = () => {
    const detailsAmount = getDetailsAmount(Price);

    return (
      <TaxiDetailsPrice
        tariffAmount={ detailsAmount.tariff }
        vatAmount={ detailsAmount.vat }
        showDetails
      />
    );
  };

  const renderVatHtml = () => {
    const { Rate: { Price: { HasVAT, VAT } } } = JSON.parse(Data);

    if (!HasVAT) return null;

    const moneyFormatVat = MoneyFormat.moneyWithDecimal(Number(VAT), true);

    return (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }: { moneyFormatVat }
        </Text>
      </div>
    );
  };

  const renderBookingCom = () => <div className={ styles.hotel_bookingcom }>{ LABELS.BOOKING }</div>;

  const renderPriceOption = () => {
    if (ServiceType === SERVICETYPE.AIR) return renderBonus();

    if (ServiceType === SERVICETYPE.TAXI || ServiceType === SERVICETYPE.TAXI_VOUCHER) return renderDetailsPrice();

    if (ServiceType === SERVICETYPE.HOTEL) return renderVatHtml();

    return bookingCom ? renderBookingCom() : null;
  };

  const renderRulesButtonAirAndTrain = () => {
    if (ServiceType !== SERVICETYPE.AIR && ServiceType !== SERVICETYPE.TRAIN) {
      return null;
    }

    return (
      <Button
        className={ styles.rules_button }
        type='textual'
        onClick={ () => onChangeRulesAirDialog(true) }
      >
        { LABELS.RULES_TARIFF }
      </Button>
    );
  };

  const renderVoidInfo = () => {
    if (ServiceType !== SERVICETYPE.AIR || !VoidTimeLimitUTC) {
      return null;
    }

    const voidTimeLimitUpdatedUTC = momentObjectUTC(VoidTimeLimitUpdated);

    const voidTimeLimitString = VoidUntilEndOfDay
      ? getVoidUntilEndOfDayString(VoidTimeLimitUTC)
      : getVoidTimerStartsAfterBookingString(VoidTimeLimitUTC, voidTimeLimitUpdatedUTC);

    return (
      <Text
        className={ styles.voidtime }
        type='NORMAL_14_130'
        color='green'
      >
        { LABELS.ANNULATION(voidTimeLimitString) }
      </Text>
    );
  };

  const renderAirlineAdditionalServicesInfo = () => {
    /** Скрываем плашку доп. услуг, если нет доступа ни по одной услуге */
    if (isHideAdditionalSeatsS7 && isHideAdditionalBaggageMixvel) return null;

    return (
      <div className={ styles['additional-service-info'] }>
        <div className={ styles['icon-wrapper'] }>
          <Icon type='info' fill='#AFC4E2' color='white'/>
          <Text>{ LABELS.ADDITIONAL_SERVICES.ADDITIONAL_INFO }</Text>
        </div>
      </div>
    );
  };

  const renderPriceBlock = () => {
    if (showFindReplacementBtn) {
      return (
        <Button
          type='primary'
          onClick={ onFindReplacement }
          disabled={ isDemoState && readonly }
        >
          { LABELS.FINDREPLACEMENT }
        </Button>
      );
    }

    // if (itemHasChanges) {
    //   const includesDateUpdateType = itemToUpdate.Changes.find(({ Type }) => Type === ITypesUpdates.HotelExpireDates);

    //   if (!includesDateUpdateType) return null;

    //   return (
    //     <div className={ styles.update_dates_item }>
    //       <Text type='NORMAL_16_130'>{ LABELS.CHANGES.TITLE_DATE_CHANGE }</Text>
    //       <Button
    //         onClick={ () => onShowUpdateDatesDialog(true) }
    //       >
    //         { LABELS.CHANGES.BUTTON_CHANGE_DATE }
    //       </Button>
    //     </div>
    //   );
    // }

    return null;
  };

  const additionalServicesPrice = routes
    .flatMap(({ Segments }: { Segments: AirSegmentInterface[] }) => Segments
      .flatMap(({ AdditionalServices = [] }) => AdditionalServices))
    .reduce((acc: number, service: AdditionaService) => {
      if (service.Type === ADDITIONAL_SERVICES.Seat
        || service.Type === ADDITIONAL_SERVICES.Baggage) {
        return acc + service.Service.Price;
      }

      return acc;
    }, 0);
  const price = showFindReplacementBtn ? null : Price - additionalServicesPrice;
  const travellersCount = Metadata ? Metadata.TravellersCount : 1;
  const priceWithCetificate = isBookCertificate
    ? getPriceWithCertificate(travellersCount, price as number)
    : price;
  const airAFlagHtml = ServiceType === SERVICETYPE.AIR && renderAirAFlag();
  // const actionsStyle = itemHasChanges ? styles.actions_style : '';
  const qaAttrPrice = getQaAttrByField(ServiceType, qaAttrFields.price);
  const qaAttrIcon = getQaAttrByField(ServiceType, qaAttrFields.icon);

  return (
    <ItemLayout
      price={ priceWithCetificate }
      serviceType={ ServiceType }
      renderOption={ renderPriceOption() }
      html={ renderPriceBlock() }
      absoluteHtml={ airAFlagHtml }
      qaAttrPrice={ qaAttrPrice }
      qaAttrIcon={ qaAttrIcon }
      itemId={ Id }
      isAdditionalFee
      setAdditionalFeeSmartagent={ setAdditionalFeeSmartagentCart }
      removeAdditionalFeeSmartagentCart={ removeAdditionalFeeSmartagentCart }
      feeSmartagent={ AgentFee }
      renderAdditionalPriceText={ renderAdditionalPrice() }
      appService={ appService }
      // actionsStyle={ actionsStyle }
    >
      { renderItem() }
      <AbsencePatronymicInForeignPassport
        cartItem={ EmployeeCartItems }
        employees={ allowedEmployees }
        serviceType={ ServiceType }
      />
      { renderRulesButtonAirAndTrain() }
      { renderVoidInfo() }
      { renderBookAirCertificate()}
      { renderAirlineAdditionalServicesInfo() }
    </ItemLayout>
  );
};

export { BodyNormalCart };
