import React from 'react';
import moment, { Moment } from 'moment';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { FavoriteAction } from '../../../../components/FavoriteAction';
import { LocalTime } from '../../../../components/LocalTime';

import { formatDate } from '../../../../bi/utils/formatDate';
import { getTrainNameByNumber } from '../../../../bi/utils/train';

import TIMETYPELIST from '../../../../bi/constants/timeTypeList';
import CLASSNAMESFORTESTS from '../../../../bi/constants/classnamesfortests';
import { PATTERN } from '../../../../bi/constants/dateFormats';

import { IQaAttrsTrain } from '../../types';
import { IInternalTrainDetailsClientData } from '../../../../bi/types/trains';

import styles from './index.module.css';

const DASH = '—';

const LABELS = {
  TRAIN_NUMBER: getText('trains:ticket.train'),
  EREGISTRATION_IS_NOT_AVAILABLE: getText('trains:results.eRegWarning'),
};

interface ITrainPrimaryInfoBlockProps {
  name: string,
  stations: [string, string],
  departureDate: Moment | string,
  arrivalDate: string | Moment,
  departureDateLocal: Moment | string,
  arrivalDateLocal: Moment | string,
  favoriteId?: string | null,
  shouldHideFavorite?: boolean,
  train: IInternalTrainDetailsClientData | null,
  onFavoriteToggle: (item: string) => void,
  hasERegistration?: boolean,
  showERegistrationWarning?: boolean,
  qaAttrs: IQaAttrsTrain,
}

const TrainPrimaryInfoBlock = ({
  name,
  stations,
  favoriteId = null,
  train,
  shouldHideFavorite = false,
  onFavoriteToggle,
  departureDate,
  arrivalDate,
  departureDateLocal,
  arrivalDateLocal,
  hasERegistration = false,
  showERegistrationWarning = false,
  qaAttrs,
}: ITrainPrimaryInfoBlockProps) => {
  const [from, to] = stations.map(station => station.toLowerCase());
  const departure = moment(departureDate);
  const arrival = moment(arrivalDate);
  const departureLocal = moment(departureDateLocal);
  const arrivalLocal = moment(arrivalDateLocal);

  const trainNameIdentifier = getTrainNameByNumber(train);

  let trainName = null;

  if (train?.FirmName) {
    trainName = ` (${train.FirmName})`;
  } else if (!train?.FirmName && trainNameIdentifier) {
    trainName = ` (${trainNameIdentifier})`;
  }

  const stationsHtml = (
    <Text type='bold_16'>
      { from } { DASH } { to }
    </Text>
  );

  const renderEndingStations = (isStartStation: boolean) => {
    const userStation = isStartStation ? train?.StationFrom : train?.StationTo;

    if (!train?.Route?.length || !train.Route[0] || !train.Route[1]) return null;

    const endingStation = isStartStation ? train.Route[0] : train.Route[1];

    if (userStation?.toLowerCase() !== endingStation.toLowerCase()) {
      return isStartStation
        ? <Text color='gray'>{ endingStation.toLowerCase()} { DASH }&nbsp;</Text>
        : <Text color='gray'>&nbsp;{ DASH } { endingStation.toLowerCase() }</Text>;
    }

    return null;
  };

  const renderTimeHTML = (date: Moment, dateLocal: Moment, timeType: string, qaAttr: string) => (
    <div className={ styles.time }>
      <Text qaAttr={ qaAttr }>
        { formatDate(dateLocal, PATTERN.ACTION_IN_TIME_PATTERN) }
      </Text>
      <LocalTime
        className={ styles.tooltip }
        iconClassName={ styles.icon }
        dateMoscow={ date }
        dateLocal={ dateLocal }
        timeType={ timeType }
        dayMoscow={ formatDate(date, PATTERN.LOCAL_DAY_PATTERN) }
        timeMoscow={ formatDate(date, PATTERN.LOCAL_TIME_PATTERN) }
      />
    </div>
  );

  const renderWarningERegistration = () => {
    if (!showERegistrationWarning || hasERegistration) {
      return null;
    }

    return (
      <Text
        className={ styles.warning }
        type='NORMAL_14'
        color='red'
      >
        { LABELS.EREGISTRATION_IS_NOT_AVAILABLE }
      </Text>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <Text
          className={ styles.name }
          type='NORMAL_18'
          qaAttr={ qaAttrs.name }
        >
          {LABELS.TRAIN_NUMBER} { name }{ trainName }
        </Text>
        { !shouldHideFavorite &&
          <FavoriteAction
            id={ favoriteId }
            onClick={ onFavoriteToggle }
            className={ CLASSNAMESFORTESTS.TRAINS.TOFAVORITE }
            qaAttrIcon={ qaAttrs.favorite }
          />
        }
      </div>
      <div className={ styles.bottom }>
        <div className={ styles.directions }>
          <div className={ styles.direction } data-qa={ qaAttrs.direction }>
            { renderEndingStations(true) } { stationsHtml } { renderEndingStations(false) }
          </div>
          { renderWarningERegistration() }
        </div>
        <div className={ styles.dates }>
          <div className={ styles.labels }>
            <Text
              className={ styles.label }
              color='gray'
            >
              { TIMETYPELIST.DEPARTURE }:
            </Text>
            <Text
              className={ styles.label }
              color='gray'
            >
              { TIMETYPELIST.ARRIVAL }:
            </Text>
          </div>
          <div className={ styles.times }>
            { renderTimeHTML(departure, departureLocal, TIMETYPELIST.DEPARTURE, qaAttrs.dates.from) }
            { renderTimeHTML(arrival, arrivalLocal, TIMETYPELIST.ARRIVAL, qaAttrs.dates.to) }
          </div>
        </div>
      </div>
    </div>
  );
};

export { TrainPrimaryInfoBlock };
