// @ts-nocheck
import React from 'react';
import { Text } from 'new-ui';
import PropTypes from 'prop-types';

import { getText } from '../../../../../../i18n';

import { ConsistentCartList } from '../ConsistentCartList';

import { getCartByStatus } from '../../../../../bi/utils/cart';

import { CART_STATUS } from '../../../../../bi/constants/cart';
import ROUTES from '../../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from './style.module.css';

const LABELS = {
  ON_APPROVE: getText('cart:onApprove'),
};

const ConsistentCartLists = ({ carts, cartService, history, handlePurchaseCart, showApproversCKR }) => {
  const preparedGetCartByStatus = getCartByStatus(carts);
  const declinedList = preparedGetCartByStatus(CART_STATUS.REJECTED, true);
  const approvedList = preparedGetCartByStatus(CART_STATUS.AUTHORISED, true);
  const waitingApproveList = preparedGetCartByStatus(CART_STATUS.IN_PROGRESS, true);
  const { alreadySentIds } = cartService.get();

  const handleTimeIsUp = (item) => cartService.updateTimeIsUp(item);

  const handleRemoveCart = ({ id }, airToNote) => cartService.removeCart(id, airToNote);

  const handleConsistentCartClick = ({ id }) => history.push(`${ROUTES.CART.SPECIFIC}${id}`);

  const renderConsistentCart = ({ list, type, onDelete, onPurchase, onClick, checkUnderage = false }) =>
    !!list.length && (
      <ConsistentCartList
        list={ list }
        type={ type }
        onDelete={ onDelete }
        onPurchase={ onPurchase }
        onClick={ onClick }
        onTimeIsUp={ handleTimeIsUp }
        checkUnderage={ checkUnderage }
        qaAttr={ QA_ATTRIBUTES.cart.approval.item }
        showApproversCKR={ showApproversCKR }
        alreadySentIds={ alreadySentIds }
      />
    );

  const declinedListHtml = renderConsistentCart({
    checkUnderage: false,
    list: declinedList,
    type: CART_STATUS.REJECTED,
    onDelete: handleRemoveCart,
    onPurchase: () => {},
    onClick: handleConsistentCartClick,
  });

  const approvedListHtml = renderConsistentCart({
    list: approvedList,
    type: CART_STATUS.AUTHORISED,
    onDelete: handleRemoveCart,
    onPurchase: handlePurchaseCart,
    onClick: handleConsistentCartClick,
    checkUnderage: true,
  });

  const waitingApproveListHtml = renderConsistentCart({
    checkUnderage: false,
    list: waitingApproveList,
    type: CART_STATUS.IN_PROGRESS,
    onDelete: handleRemoveCart,
    onPurchase: () => {},
    onClick: handleConsistentCartClick,
  });

  const condition = !!declinedList.length || !!approvedList.length || !!waitingApproveList.length;

  return condition ? (
    <div className={ styles['approve-carts-list'] }>
      <Text
        type='bold_32'
        className={ styles.header }
        qaAttr={ QA_ATTRIBUTES.cart.approval.header }
      >
        { LABELS.ON_APPROVE }
      </Text>
      { declinedListHtml }
      { approvedListHtml }
      { waitingApproveListHtml }
    </div>
  ) : null;
};

ConsistentCartLists.propTypes = {
  carts: PropTypes.array.isRequired,
  cartService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handlePurchaseCart: PropTypes.func.isRequired,
  showApproversCKR: PropTypes.bool.isRequired,
};

export { ConsistentCartLists };
