import React, { useState } from 'react';
import {
  Text,
  PageLoader,
  Dropdown,
  Button,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { DocumentRow } from './row';

import { FORMATS_DOCUMENTS } from '../../../../bi/constants/report';

import { PackagesItems, ReportService } from '../../../../bi/types/report';

import styles from '../../styles/table.module.css';

const LABELS = {
  EMPTY_LIST: getText('reports:documents.table.emptyList'),
  DATE: getText('reports:documents.table.createDate'),
  NUMBER: getText('reports:documents.table.number'),
  PERIOD: getText('reports:documents.table.period'),
  DOCUMENT: getText('reports:documents.table.document'),
  DOWNLOAD_SELECTED: getText('reports:documents.table.downloadSelected'),
};

interface TableProps {
  items: PackagesItems[],
  loading: boolean,
  currentCompany: number,
  reportService: ReportService,
}

const renderRow = (
  items: PackagesItems[],
  handleDownloadDocument: (packageId: number, format: string, documentId: number) => void,
  onDownloadAllInvoiceByData: (documentId: number) => void,
  handleDocsId: (id: number) => void,
  docsIds: number[],
) => {
  if (!items.length) {
    return <Text className={ styles.empty }>{LABELS.EMPTY_LIST}</Text>;
  }

  return items.map((item: PackagesItems, key) => (
    <DocumentRow
      key={ `${key}__${item.Id}` }
      item={ item }
      onDownloadDocument={ handleDownloadDocument }
      onDownloadAllInvoiceByData={ onDownloadAllInvoiceByData }
      onHandleDocsId={ handleDocsId }
      docsIds={ docsIds }
    />
  ));
};

const renderContent = (
  items: PackagesItems[],
  loading: boolean,
  handleDownloadDocument: (packageId: number, format: string, documentId: number) => void,
  onDownloadAllInvoiceByData: (documentId: number) => void,
  handleDocsId: (id: number) => void,
  docsIds: number[],
) => {
  if (loading) {
    return (
      <div className={ styles.loading }>
        <PageLoader />
      </div>
    );
  }

  return renderRow(items, handleDownloadDocument, onDownloadAllInvoiceByData, handleDocsId, docsIds);
};

const Table = ({
  items,
  loading,
  currentCompany,
  reportService,
}: TableProps) => {
  const [docsIds, setDocsIds] = useState<number[]>([]);

  const isDisabled = !docsIds.length;
  const color = isDisabled ? 'default' : 'accent';

  const handleDownloadDocument = (packageId: number, format: string, documentId: number) => {
    reportService.downloadDocument(currentCompany, packageId, documentId, format);
  };

  const onDownloadAllInvoiceByData = (documentId: number) => reportService.downloadAllInvoiceByData(currentCompany, documentId);

  const handleDownloadSelectedDocuments = (ids: number[], format: string) => {
    reportService.downloadSelectedDocuments(currentCompany, ids, format);
  };

  const handleDocsId = (id: number) => {
    if (docsIds.includes(id)) {
      return setDocsIds(docsIds.filter((docId) => docId !== id));
    }

    return setDocsIds([...docsIds, id]);
  };

  const content = renderContent(items, loading, handleDownloadDocument, onDownloadAllInvoiceByData, handleDocsId, docsIds);

  const renderDownloadButtons = () => (
    FORMATS_DOCUMENTS.map(({ label, format }) => (
      <Button
        onClick={ () => handleDownloadSelectedDocuments(docsIds, format) }
        type='textual'
        className={ styles.btn }
        key={ format }
      >
        { label }
      </Button>
    ))
  );

  const renderLabel = () => <Text color={ color }>{ LABELS.DOWNLOAD_SELECTED }</Text>;

  return (
    <div className={ styles.wrap }>
      <div className={ styles.header }>
        <Text className={ styles.date }>{ LABELS.DATE }</Text>
        <Text className={ styles.number }>{ LABELS.NUMBER }</Text>
        <Text className={ styles.period }>{ LABELS.PERIOD }</Text>
        <Text className={ styles.document }>{ LABELS.DOCUMENT }</Text>
        <Dropdown
          hoverOpenMenu
          disabled={ isDisabled }
          renderLabel={ renderLabel }
        >
          { renderDownloadButtons() }
        </Dropdown>
      </div>
      { content }
    </div>
  );
};

export { Table };
