// @ts-nocheck
import { CloudPaymentsStore } from './store';

import { openCloudPaymentsForm } from '../../utils/cloudPayments';
import { formatDate } from '../../utils/formatDate';

const MAINDATEFORMAT = 'YYYY-MM-DDTHH:mm:ss';

const STATUSWIDGETCLOSEDBYUSER = 'User has cancelled';

class Payment {
  store = CloudPaymentsStore;

  constructor(api) {
    this.api = api.payment;
  }

  startCloudPayments = (
    amount,
    transactionPercent,
    amountOnAccount,
    isChargeTransactionFee,
    email,
    customerInfo,
    customerInn,
    companyId,
  ) => {
    this.store.setCantLeave(true);

    return openCloudPaymentsForm(
      amount,
      transactionPercent,
      amountOnAccount,
      isChargeTransactionFee,
      email,
      customerInfo,
      customerInn,
      companyId,
      this.onSuccessCloudPayments,
      this.onFailCloudPayments,
    );
  };

  onSuccessCloudPayments = () => {
    this.store.setCantLeave(false);
  };

  onFailCloudPayments = (reason, amount, {
    data: {
      companyId,
      cloudPayments: {
        customerReceipt: {
          Items,
        },
      },
    },
  }) => {
    const { price } = Items[1] || { price: 0 };

    if (reason === STATUSWIDGETCLOSEDBYUSER) {
      this.store.setCantLeave(false);

      return;
    }

    const params = {
      Amount: amount,
      TransactionFee: price,
      CreatedDate: formatDate(Date.now(), MAINDATEFORMAT),
      Status: {
        Code: false,
        Message: reason,
      },
    };

    this.api.sendResult(companyId, params).then(() => this.store.setCantLeave(false));
  };
}

export default Payment;
