import React, { useState } from 'react';
import { Button, Datepicker, Select, Text } from 'new-ui';
import { Moment } from 'moment';
import { getText } from '../../../../../../i18n';

import { getMoment, startOfDay } from '../../../../../bi/utils/formatDate';

import TRAVELERS from '../../../../../bi/constants/travelers';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from './style.module.css';

const LABELS = {
  TITLE: getText('favorites:dialogs.train.title'),
  DATE: getText('favorites:dialogs.train.date'),
  START_SEARCH: getText('favorites:dialogs.train.startSearch'),
  CANCEL: getText('common:undo'),
  TRAVELLERS: getText('favorites:dialogs.train.travellers'),
};

interface IQaAttrs {
  wrapper: string;
  header: string;
  date: string;
  passengers: string;
  buttons: {
    startSearch: string;
    cancel: string;
  },
}

interface ITrainSearchStartDialogProps {
  onSearch(item: { date: Moment | string | null, travellers: number }): void;
  onClose():void;
  qaAttrs: IQaAttrs;
}
const TrainSearchStartDialog = ({
  onSearch,
  onClose,
  qaAttrs = {
    wrapper: '',
    header: '',
    date: '',
    passengers: '',
    buttons: {
      startSearch: '',
      cancel: '',
    },
  },
}: ITrainSearchStartDialogProps) => {
  const [dateFrom, setDateFrom] = useState<Moment | string | null>(getMoment());
  const [minDate] = useState(startOfDay());
  const [travellers, setTravellers] = useState(TRAVELERS[0].value);

  const handleChangeDate = (value: Moment | string | null) => setDateFrom(value);

  const handleChangeTravelers = (value: number) => setTravellers(value);

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_20' qaAttr={ qaAttrs.header }>
        { LABELS.TITLE }
      </Text>
      <Datepicker
        placeholder={ LABELS.DATE }
        inputClassName={ styles.input }
        wrapperClassName={ styles.date }
        value={ dateFrom }
        min={ minDate }
        onChange={ handleChangeDate }
        qaAttr={ qaAttrs.date }
        qaAttrNextMonth={ QA_ATTRIBUTES.favorites.chooseDateDialog.checkInNextMonth }
      />
      <Select
        withLabel
        theme='default-border'
        className={ styles.select }
        value={ travellers }
        placeholder={ LABELS.TRAVELLERS }
        items={ TRAVELERS }
        onChange={ handleChangeTravelers }
        qaAttr={ qaAttrs.passengers }
      />
      <div className={ styles.action }>
        <Button
          onClick={ () => onSearch({ date: dateFrom, travellers }) }
          qaAttr={ qaAttrs.buttons.startSearch }
        >
          { LABELS.START_SEARCH }
        </Button>
        <Button
          className={ styles.cancel }
          type='textual-medium'
          onClick={ onClose }
          qaAttr={ qaAttrs.buttons.cancel }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </div>
  );
};

export { TrainSearchStartDialog };
