import { Moment } from 'moment';

import FeatureFlags from '../featureFlags';

import { EmployeesObj, IProjects } from '../../types/employees';
import { ApproversType } from '../expenseReports/types';

export type BuyTripPersonalType = 'Unavailable' | 'OnlyAfterApprove' | 'Limited' | 'TravelPolicy' | 'Unlimited' | 'ApprovalScheme';
export type BuyTripAccountType = 'Unavailable' | 'OnlyAfterApprove' | 'TravelPolicy' | 'Unlimited' | 'ApprovalScheme';
export type EasyRigthsType = 'Unavailable' | 'Available';
export type EasyRigthsByPersonType = 'All' | 'Personal' | 'Undefined';
export type NotificationRightsType = 'Unavailable' | 'PersonalVouchers' | 'PersonalInvoices' | 'PersonalVouchersAndInvoices' | 'AccountVouchers' | 'AccountInvoices' | 'AccountVouchersAndInvoices';
export type CreateExpenseType = 'OnlyAfterApprove' | 'Unlimited' | 'ApprovalScheme';
export type DocumentsType = 'PersonalTrips' | 'AccountTrips';

export interface SimpleSignatureType {
  registrationNumber: number | null,
  email: string,
  phoneNumber: string,
  accountId?: number,
  changedDate?: string,
  createdDate?: string,
  confirmationMethod?: number,
  id?: string,
  status?: number,
}

export interface DataSignatureType {
  email: string,
  phoneNumber: string,
}

export interface ListType {
  Email: string,
  EmployeeId: number,
  FirstName: string,
  LastName: string,
  MiddleName: string,
  Name: string,
  UserId: string,
}

export interface PaginateType {
  currentPage: number,
  itemsPerPage: number,
  total: number,
}

export interface DocumentType {
  accountId: number,
  changedDate: string
  companyId: null | number
  documentId: string
  employeeId: number
  name: string
  signingStatus: number
  sourceType: number
}

export interface IList {
  EmoloyeeId?: number,
  UserId: string,
  LastName: string,
  FirstName: string,
  MiddleName: string,
  Email: string,
  Name: string,
}

export type UsersType = {
  value: string,
  label: string,
  name: string,
};

export type UsersTypeLigth = {
  value: string,
  label: string,
};

export interface IApproveUsersWithId {
  user?: UsersType,
}

export interface IListExpense {
  UserId: string,
  EmployeeId: number,
}

export type AppoversTypeLigth = {
  Email: string,
};

export interface IApproveERUsers {
  value: string;
  label: string;
  name: string;
}

export interface IListRigthsFull {
  AccountId: number,
  Approve: EasyRigthsType,
  ApproveExpenseReports: EasyRigthsType,
  BuyTripAccount: BuyTripAccountType,
  BuyTripPersonal: BuyTripPersonalType,
  CanBook: boolean,
  CanLogin: boolean,
  CanUseIntercom: boolean,
  CreateExpenseReports: CreateExpenseType,
  EditEmployee: EasyRigthsByPersonType,
  EditRights: EasyRigthsType,
  EditTravelPolicies: boolean,
  EmployeeId: number,
  Finance: EasyRigthsType,
  Limits: number,
  Login: string,
  Notifications: NotificationRightsType,
  SendDocuments: boolean,
  UserId: string,
  ViewExpenseReports: EasyRigthsByPersonType,
  ViewMiceEvents: EasyRigthsByPersonType,
  ViewTrips: EasyRigthsByPersonType,
}

export interface IEmployeeFieldDefault {
  [key: string]: string,
}

export interface IErrorListEmployee {
  field: string,
  value: string,
}

export interface IPassportView {
  BirthCertificate: string,
  DomesticPassport: string,
  ForeignPassport: string,
}

export interface IRights {
  AccountId: number,
  Approve: string,
  BuyTripAccount: string,
  BuyTripPersonal: string,
  CanBook: boolean,
  CanLogin: boolean,
  EditEmployee: string,
  EditRights: string,
  EmployeeId: number,
  Finance: string,
  Limits: number,
  Login: string,
  Notifications: string,
  UserId: string,
  ViewTrips: string,
}

export interface IPreparedRigthsBackend {
  buyTripPersonal: boolean,
  buyTripAccount: boolean,
  buyUnlimited: boolean,
  buyAfterApprove: boolean,
  buyApprovalScheme: boolean,
  buyTravelPolicy: boolean,
  approve: boolean,
  finance: boolean,
  approveExpenseReports: boolean,
  editEmployee: boolean,
  editTravelPolicies: boolean,
  viewTrips: boolean,
  viewSelfTrips: boolean,
  editRights: boolean,
  editSelf: boolean,
  canGetVouchers: boolean,
  canGetInvoices: boolean,
  sendDocuments: boolean,
  documents: DocumentsType,
  viewExpenseReports: boolean,
  createERUnlimited: boolean,
  createERAfterApprove: boolean,
  createERApprovalScheme: boolean,
  viewMiceEvents: boolean,
  buyInsurance: boolean,
  isWhiteLabel: boolean,
  isDisplayInsurance: boolean,
  showPersonalPaymentRights: boolean,
  Acquiring: AcquiringLevel,
}

export interface IAdditionalPrepared {
  showReports: boolean,
  isSmartAgent: boolean,
  showExpenseReportApprove: boolean,
}

export interface IAllApproveUsers {
  value: string,
  label: string
  name: string,
}

export interface IPreparedRigthsFrontend {
  BuyTripPersonal: BuyTripPersonalType,
  BuyTripAccount: BuyTripAccountType,
  Approve: EasyRigthsType,
  Finance: EasyRigthsType,
  ApproveExpenseReports: EasyRigthsType,
  EditEmployee: EasyRigthsByPersonType,
  ViewTrips: EasyRigthsByPersonType,
  EditRights: EasyRigthsType,
  EditTravelPolicies: boolean,
  Notifications: NotificationRightsType,
  SendDocuments: boolean,
  ViewExpenseReports: EasyRigthsByPersonType,
  ViewMiceEvents: EasyRigthsByPersonType,
  CreateExpenseReports: CreateExpenseType,
  BuyInsurance: boolean,
  showReports?: boolean,
  Acquiring: AcquiringLevel,
}

export interface IDocumentUser {
  FirstName: string,
  Id?: number,
  LastName: string,
  MiddleName: string,
  Name: string,
  Number: string,
  Patronymic: string,
  Selected?: boolean,
  Surname: string,
  Type: string,
  DueDate?: string,
  index?: number,
  NoPatronymic: boolean,
}
export interface IPassport {
  [key: string]: string,
}

export interface IPassportUser {
  DueDate: string,
  FirstName: string,
  Id: number,
  LastName: string,
  MiddleName: string,
  Name: string,
  Number: string,
  Patronymic: string,
  Surname: string,
  Type: string,
  mayCyrillic: boolean,
  NoPatronymic: boolean,
}

export interface IPassportError {
  Number: string;
  Surname: string;
  Name: string;
  Patronymic: string;
  DueDate: string;
  LastName: string;
  FirstName: string;
  MiddleName: string;
  TravelPolicy: string;
  EmployeeNumber: string;
  EmployeeNumSixDig: string;
}

export type BonusesType = {
  Name?: string,
  Code: string,
  Id: number,
  Number: string,
  Type: string,
  airlineSuggestions: {
    label: string,
    selected: null,
    suggestions: {
      Code: string,
      Name: string,
    }[],
  }
};

export interface ICompanyEasy {
  main: string,
  nested: string[],
}

export interface IEmployee {
  Birthday: Moment | string,
  Bonuses: BonusesType[],
  Code:string,
  Code3: string,
  Companies: ICompanyEasy[]
  Documents: IDocumentUser[],
  Email: string,
  EmailEditable: boolean,
  EmployeeNumSixDig: string,
  EmployeeNumber: string,
  Id: number,
  MobilePhone: string,
  Name: string,
  Patronymic: string,
  ProjectId: number,
  Sex: string,
  Status: string,
  Surname: string,
  TpRights: ITpRightLight,
  Underage: number,
  employeeHasCyrillicDomesticPassport: number,
}

export interface ITpRightLight {
  Id: string,
  PolicyId: string,
  UserId?: string,
}

export interface IEmployeKey {
  [key: string]: string,
}

export type DocumentsTypes = {
  DueDate: string,
  EmployeeNumSixDig: string,
  EmployeeNumber: string,
  FirstName: string,
  LastName: string,
  MiddleName: string,
  Name: string,
  Number: string,
  Patronymic: string,
  Surname: string,
  TravelPolicy: string,
};

export interface IErrorsValidate {
  Birthday: string,
  Code: string,
  Documents: DocumentsTypes[],
  Email: string,
  EmployeeNumSixDig: string,
  EmployeeNumber: string,
  Login: string,
  MobilePhone: string,
  Name: string,
  Patronymic: string,
  Sex: string,
  Surname: string,
  TravelPolicy: string,
}

export interface IDocsNames {
  Surname: string,
  Name: string,
  Patronymic: string,
  LastName: string,
  FirstName: string,
  MiddleName: string,
}

export interface IValidateErrors {
  Documents: DocumentsTypes[],
  Surname: string,
  Name: string,
  MobilePhone: string,
  Email: string,
  Code: string,
  Birthday: string,
  Login: string,
  Patronymic: string,
  TravelPolicy: string,
  EmployeeNumber: string,
}

export enum AcquiringLevel {
  Undefined = 'Undefined',
  Unavailable = 'Unavailable',
  Personal = 'Personal',
  All = 'All',
}

export interface IDefaultRigths {
  loadingButton?: boolean,
  manually?: boolean,
  copy?: boolean,
  edit?: boolean,
  close?: boolean,
  buyTripPersonal?: boolean,
  disableBuyTripPersonal?: boolean,
  buyTripAccount?: boolean,
  approve?: boolean,
  finance?: boolean,
  approveExpenseReports?: boolean,
  showSecondStep?: boolean,
  buyUnlimited?: boolean,
  buyAfterApprove?: boolean,
  buyTravelPolicy?: boolean,
  buyApprovalScheme?: boolean,
  createERUnlimited?: boolean,
  createERAfterApprove?: boolean,
  createERApprovalScheme?: boolean,
  editEmployee?: boolean,
  editSelf?: boolean,
  viewTrips?: boolean,
  viewExpenseReports?: boolean,
  viewSelfTrips?: boolean,
  editRights?: boolean,
  disableEditRights?: boolean,
  editTravelPolicies?: boolean,
  showEditTravelPolicy?: boolean,
  canGetVouchers?: boolean,
  canGetInvoices?: boolean,
  sendDocuments?: boolean,
  viewMiceEvents?: boolean,
  approvers: string[],
  approversForExpenseReports?: string[],
  documents?: IDocumentUser | any,
  showSelectDocuments?: boolean,
  copyUser?: string,
  buyWithPersonalFunds: string,
  buyInsurance: boolean,
  Acquiring: AcquiringLevel,
}

export type TSteps = {
  Approvers: string[],
  ConditionOfApproval: number,
  Id: number,
  Roles: number[]
};

export type TInvalid = {
  type: string,
  errors: {
    someFieldsEmpty: boolean,
    emptyFields: string[],
  },
};

export interface IApprovalShemas {
  AccountId: number;
  ApplyForAll: boolean;
  Id: number;
  IsApplied: boolean;
  Name: string;
  Steps: TSteps[]
}

export interface IApprovalShemasERA {
  ApplyForAll: boolean;
  Id: number;
  IsApplied: boolean;
  MultiCompanyId: number;
  Name: string;
  PreSteps: TSteps[],
  Steps: TSteps[],
  TemplateRequestId: number | null;
}

export interface IEmployees {
  ApprovalExpenseReportSchemeId: number;
  ApprovalSchemeId: number;
  Birthday: string;
  Bonuses: {
    Code: string;
    EmployeeId: number;
    Id: number;
    Number: string;
    Type: string;
  }[],
  Code: string,
  Code3: string,
  Email: string;
  EmailEditable: boolean;
  EmployeeNumber: string;
  Id: number;
  MobilePhone: string;
  Name: string;
  Patronymic: string;
  Position: string;
  ProjectId: number | null;
  Sex: string;
  Status: string;
  Surname: string;
  employeeHasCyrillicDomesticPassport: number;
  Projects: IProjects[],
  Documents: IDocumentUser[],
  Companies: ICompany[] | ICompanyEasy[],
  Underage: number,
  DueDate?: string,
  Rights?: IRights,
  TpRights?: ITpRightLight,
  UserIdentity?: IUserIdentity,
}

export interface IDepartments {
  CompanyId: number,
  HeadOfDepartmentEmployeeId: number,
  HeadOfDepartmentId?: number,
  Id: number,
  Name: string,
}

export interface ICompany {
  CompanyId: number;
  CompanyName: string;
  Departments: IDepartments[],
  ShortCompanyName: string,
}

export interface IUserIdentity {
  CompanyId: number;
  EditRightsLevelIsAvailable: boolean;
  Email: string;
  Id: string;
  IntercomComment: null | string;
  IsPrimaryAccount: boolean;
  MultiCompanyId: number;
  Notification: null | string;
  PhoneNumber: string;
  Region: null | string;
  RegistrationName: string;
  RegistrationStatus: number;
  RegistrationStatusStr: string;
  RegistrationUrl: string;
  SendDoc: boolean;
  TimeZoneId: null | string;
  UserName: string;
}

export interface IPolicyData {
  EmployeeId: number,
  PolicyId: string,
}

export interface IBirthdayE {
  Birthday: Moment;
}

export interface ISchemeData {
  EmployeeId: number,
  SchemeId: number,
}

export interface TAirlines {
  Code: string,
  Name: string
}

export interface TCitizenship {
  Name: string,
  Code: string,
  Code3: string
}

export interface TDocumentsError {
  dueDate: string;
  employeeNumSixDig: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
  number: string;
  patronymic: string;
  surname: string;
  travelPolicy: string;
}

export interface TErrors {
  birthday: string;
  code: string;
  documents: TDocumentsError[];
  email: string;
  employeeNumSixDig: string;
  employeeNumber: string;
  login: string;
  mobilePhone: string;
  name: string;
  patronymic: string;
  sex: string;
  surname: string;
  travelPolicy: string;
}

export interface IErrorEmployee {
  LastName: string,
  FirstName: string,
  MiddleName: string,
  Number: string
  Name: string,
  Surname: string
  Patronymic: string,
  Type: string,
  NoPatronymic: boolean,
}

export interface IFieldRights {
  approve: boolean,
  approveExpenseReports: boolean,
  approvers: ApproversType[],
  approversForExpenseReports: string[],
  buyAfterApprove: boolean;
  buyApprovalScheme: boolean;
  buyTravelPolicy: boolean;
  buyTripAccount: boolean;
  buyTripPersonal: boolean;
  buyUnlimited: boolean;
  buyWithPersonalFunds: string,
  canGetInvoices: boolean;
  canGetVouchers: boolean;
  close: boolean;
  copy: boolean;
  copyUser: string;
  createERAfterApprove: boolean;
  createERApprovalScheme: boolean;
  createERUnlimited: boolean;
  disableBuyTripPersonal: boolean;
  disableEditRights: boolean;
  documents: string;
  edit: boolean;
  editEmployee: boolean;
  editRights: boolean;
  editSelf: boolean;
  editTravelPolicies: boolean;
  finance: boolean;
  loadingButton: boolean;
  manually: boolean;
  sendDocuments: boolean;
  showEditTravelPolicy: boolean;
  showReports: boolean;
  showSecondStep: boolean;
  showSelectDocuments: boolean;
  viewExpenseReports: boolean;
  viewSelfTrips: boolean;
  viewTrips: boolean;
}

export interface IUsers {
  approvalExpenseReportSchemeId: number,
  approvalSchemeId: number,
  label: string,
  name: string,
  value: string,
}

export interface IStringData {
  label: string,
  name: string,
  value: string,
}

export interface IUsersFull {
  all: IUsers[],
  default: IUsers[],
  withRights: IUsers[],
}

export interface CommonBlockProps {
  employee: EmployeesObj,
  show: boolean,
  showReports: boolean,
  showExpenseReportApprove: boolean,
  rights: IRights,
  accountLng: string,
  users: IUsersFull,
  chosenTP: string
  chosenApprovalScheme: number,
  chosenApprovalERScheme: number,
  allApproveUsers: string[],
  documents: string[],
  travelPolicies: {
    value: string;
  }[],
  approvalSchemes: IApprovalShemasERA[],
  viewExpenseReportApprovers: string[],
  approvalERSchemes: string[],
  approveERUsers: string[],
  featureFlagsService: FeatureFlags,
  getCopyUserRights(value: number): void,
  onSave(): void,
  onClose(): void,
  onChangeRights(field: string, value: boolean | string): void,
  onChangeUser(value: string): void,
  onChangeApproveUsers(value: string[]): void,
  onChangeTravelPolicy(value: string[] | string | number): void,
  onChangeApprovalScheme(value: string): void,
  onChangeApprovalERScheme(value: string): void,
  onChangeApproveERUsers(value: string[]): void,
  onChangeDocuments(value: string | number): void,
  onCloseAccess(): void,
  onChangeAccountLng(value: string): void,
  isWhiteLabel: boolean,
}

export interface CommonBlockState {
  step: string,
  header: string,
}

export interface INested {
  label: string;
  value: string;
}

export interface MultiSelectList {
  label: string;
  value: number | string;
  nested: INested[];
}

export interface SuggestItem {
  value: number;
  label: string;
}

export interface ISendEmail {
  SendEmail: boolean,
  Rights: IRights,
}

export interface TAirBonuses {
  Code: string,
  airlineSuggestions: {
    label: string,
    selected: null,
    suggestions: {
      Code: string,
      Name: string,
    }[],
  }
  Number: string,
}

export interface IAutocompleteEmployeeArgs {
  search: string
  companyId: number
  onlyAdult: boolean
}
