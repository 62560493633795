import React from 'react';

import { useServices } from '../../../../bi/context/services';

import HotelsService from '../../../../bi/services/hotels';
import AppService from '../../../../bi/services/app';

import { HotelMapItem } from '../HotelMaplItem';
import { HotelItem } from '../HotelItem';

import { convertToBase64, stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import { applyHotelTravelPolicy } from '../../../../bi/utils/travelPolicy';
import { prepareCustomCheckinCheckoutTime } from '../../../../bi/utils/hotel';
import { prepareQueryMeal } from '../../../../bi/utils/hotels';
import { useGetParams } from '../../../../bi/hooks/useGetParams';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import { VIEW_MODE_TYPES } from '../../../../bi/services/hotels/constants/searchMenuTypes';

import {
  Filters,
  IHotelsFilters,
  PreparedVariant,
} from '../../../../bi/services/hotels/types';

import { ITravelPolicyListItem } from '../../../../bi/types/travelPolicy';

interface HotelResultItemProps {
  ind: number,
  item: PreparedVariant,
  query: any,
  filters: Filters,
  travelPolicyList: ITravelPolicyListItem[],
  selectedTravelPolicy: string,
  onGetLink(item: PreparedVariant, link: string): string,
  diffDays: number,
  aggregationId: number | null,
  hotelsService: HotelsService,
  showPriceDetails: boolean,
  onClickToActionLink(): void,
  onCopyToClipboard(): void,
  onCloseDialogCopyLink(): void,
  onChangeFavorite(hotel: PreparedVariant, action: string): void,
  onShowHotelOnMap(): void,
  onOpenMap(item: PreparedVariant): void,
  qaAttr?: string,
  isFiltersHotelsInMicroservice: boolean,
  hotelsFilters: IHotelsFilters,
  appService: AppService,
}

const HotelResultItem = ({
  ind,
  item,
  query,
  query: {
    checkout,
    checkin,
    adult,
    region,
    customCheckout,
    customCheckin,
    requestItemId,
    travellersCount,
    roomCount,
  },
  filters,
  filters: {
    online,
    breakfast,
    hasCancellation,
  },
  travelPolicyList,
  selectedTravelPolicy,
  onGetLink,
  diffDays,
  aggregationId,
  hotelsService,
  showPriceDetails,
  onClickToActionLink,
  onCopyToClipboard,
  onCloseDialogCopyLink,
  onChangeFavorite,
  onShowHotelOnMap,
  onOpenMap,
  qaAttr = '',
  isFiltersHotelsInMicroservice,
  hotelsFilters,
  hotelsFilters: {
    Rate: {
      // Breakfast,
      Online,
      HasCancellation,
      Recommended,
      Meal,
    },
  },
  appService,
}: HotelResultItemProps) => {
  const { featureFlagsService: { getFiltersHotelsInMicroservice } } = useServices(['FeatureFlags']);
  const { viewMode } = hotelsService.getHotelsState();
  const { guid } = hotelsService.getSearchState();

  const {
    favoriteId,
    isContract,
    static: { HotelId },
    rate,
  } = item;

  const optimalRateQueryParams = isFiltersHotelsInMicroservice && Recommended
    ? {
      Name: rate.RoomInfo.Name,
      Basket: rate.RoomInfo.Basket,
      BedType: rate.RoomInfo.BedType,
      Price: rate.Total,
      MealCategory: rate.RoomInfo.MealCategory,
    }
    : '';

  const queryTripId = useGetParams('tripId');
  const breakfastFilter = isFiltersHotelsInMicroservice ? prepareQueryMeal(Meal) : prepareQueryMeal(breakfast ? [1] : []);
  const onlineFilter = isFiltersHotelsInMicroservice ? Online : online;
  const hasCancellationFilter = isFiltersHotelsInMicroservice ? HasCancellation : hasCancellation;

  const queryParams = {
    CheckinDate: checkin.format(PATTERN.YEARMONTHDAY),
    CheckoutDate: checkout.format(PATTERN.YEARMONTHDAY),
    GuestsCount: adult,
    TravellersCount: travellersCount,
    RoomCount: roomCount,
    RegionName: region.label,
    RegionId: region.selected.Id,
    AddToHistory: false,
    ...prepareCustomCheckinCheckoutTime(customCheckin, customCheckout),
    RequestItemId: requestItemId,
    online: isContract ? false : onlineFilter,
    breakfast: breakfastFilter,
    hasCancellation: hasCancellationFilter,
    RedirectSearchType: 'region',
    tripId: queryTripId,
    OptimalRate: convertToBase64(optimalRateQueryParams),
  };

  const queryParamsByTypeSearch = !getFiltersHotelsInMicroservice() && viewMode === VIEW_MODE_TYPES.LIST_MAP
    ? queryParams
    : { ...queryParams, RedirectSearchId: guid };

  const searchParams = stringifySearchParams(queryParamsByTypeSearch);
  const hotelUri = `/search/hotel/${HotelId}?${searchParams}`;
  const applyTP = applyHotelTravelPolicy(item, selectedTravelPolicy);

  return (
    <>
      {viewMode === VIEW_MODE_TYPES.LIST_MAP ? (
        <HotelMapItem
          ind={ ind }
          item={ item }
          warning={ applyTP }
          favorite={ !!favoriteId }
          contract={ isContract }
          hotelUrl={ hotelUri }
          diffDays={ diffDays }
          aggregationId={ aggregationId }
          query={ query }
          handleSaveStats={ () => {} }
          hotelsService={ hotelsService }
          isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
          hotelsFilters={ hotelsFilters }
          appService={ appService }
        />
      ) : (
        <HotelItem
          ind={ ind }
          item={ item }
          filters={ filters }
          guid={ guid }
          query={ query }
          showPriceDetails={ showPriceDetails }
          travelPolicyList={ travelPolicyList }
          selectedTravelPolicy={ selectedTravelPolicy }
          onChangeFavorite={ onChangeFavorite }
          onGetLink={ onGetLink }
          diffDays={ diffDays }
          aggregationId={ aggregationId }
          onClickToActionLink={ onClickToActionLink }
          onCopyToClipboard={ onCopyToClipboard }
          onCloseDialogCopyLink={ onCloseDialogCopyLink }
          onShowHotelOnMap={ onShowHotelOnMap }
          onOpenMap={ onOpenMap }
          qaAttr={ qaAttr }
          queryTripId={ queryTripId }
          isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
          hotelsFilters={ hotelsFilters }
          optimalRateQueryParams={ optimalRateQueryParams }
          appService={ appService }
        />
      )}
    </>
  );
};

export { HotelResultItem };
