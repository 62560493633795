import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { StyledWrapper, Text, DotLoading } from 'new-ui';
import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { Vouchers } from './components/Vouchers';
import { Voucher } from './components/Voucher/Voucher';
import { ExternalLink } from '../../components/ExternalLink';
import { WarningMessage } from '../../components/WarningMessage';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';
import { WarningBlock } from '../../components/WarningBlock';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import NotificationService from '../../bi/services/notification';
import TaxiService from '../../bi/services/taxi';
import { VoucherStoreType } from '../../bi/services/taxi/types';

import styles from './styles/taxi.module.css';

const LABELS = {
  TITLE: getText('taxi:title'),
  TITLE_INTRO: getText('taxi:titleIntro'),
  SUBMIT_INTRO: getText('taxi:submitIntro'),
  VOUCHER: getText('taxi:voucher.title'),
  PAYMENT_INFO_ONE: getText('taxi:voucher.paymentInfo.one'),
  PAYMENT_INFO_TWO: getText('taxi:voucher.paymentInfo.two'),
  YANDEX: getText('taxi:providersName.yandex'),
  ACTIVE_VOUCHERS: getText('taxi:activeVouchers'),
  CREATED_VOUCHERS: getText('taxi:createdVouchers'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
  ADD_TO_CART_SUCCESS: (amount: number | string, startDate: string, endDate: string) =>
    getText('taxi:notifications.toCart.success', { amount, startDate, endDate }),
  ADD_TO_CART_ERROR: (amount: number | string, startDate: string, endDate: string) =>
    getText('taxi:notifications.toCart.error', { amount, startDate, endDate }),
};

interface TaxiProps {
  taxiService: TaxiService,
  notificationService: NotificationService,
  featureFlagsService: {
    getTripRuleCustomMessage(): boolean,
    getNewTaxiClasses(): boolean,
  },
}

const Taxi = observer(({
  taxiService,
  notificationService,
  featureFlagsService: {
    getTripRuleCustomMessage,
    getNewTaxiClasses,
  },
}: TaxiProps) => {
  const {
    taxiStore: {
      voucher: {
        amount,
        vat,
        date,
        dateTo,
      },
      activeVouchers,
      createdVouchers,
      loadingActiveVouchers,
      loadingCreatedVouchers,
      textTemplateWarning,
    },
  } = useStores([MOBX_STORES.TAXI_STORE]);
  const history = useHistory();

  const searchParams = parseSearchString(history.location.search) as Record<string, string>;

  const [tripId, setTripId] = useState<string>(searchParams?.tripId || '');
  const [tripName, setTripName] = useState<string>(searchParams?.tripName || '');
  const [tripDate, setTripDate] = useState<string>(searchParams?.tripDate || '');

  useEffect(() => {
    taxiService.getVouchers();
    taxiService.setVoucherClasses(getNewTaxiClasses());

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_TAXI_SCREEN_OPENED);

    return () => taxiService.resetVoucher(getNewTaxiClasses());
  }, []);

  useEffect(() => {
    const searchTripId = searchParams?.tripId || '';
    const searchTripDate = searchParams?.tripDate || '';
    const searchTripName = searchParams?.tripName || '';

    if (searchTripId !== tripId || searchTripDate !== tripDate || searchTripName !== tripName) {
      setTripId(searchParams?.tripId || '');
      setTripName(searchParams?.tripDate || '');
      setTripDate(searchParams?.tripName || '');
    }
  }, [searchParams]);

  const handleAddToCart = () => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TAXI),
    );

    const newStartDate = date?.format(DATEFORMATS.DATE) || '';
    const newEndDate = dateTo?.format(DATEFORMATS.DATE) || '';
    const fullPrice = Number(amount) + Number(vat);

    const promise = taxiService.addToCart(tripId);

    return promise.then(() => {
      notificationService.send({
        message: LABELS.ADD_TO_CART_SUCCESS(fullPrice, newStartDate, newEndDate),
        onClick: () => history.push(ROUTES.CART.MAIN),
        qaAttr: QA_ATTRIBUTES.taxi.notification,
      });
    }).catch((error: any) => {
      const message = error.response.body.Message || '';

      notificationService.send({
        message: message.charAt(0).toUpperCase() + message.slice(1),
      });
    });
  };

  const renderPaymentInfo = () => (
    <Text type='NORMAL_18'>
      { LABELS.PAYMENT_INFO_ONE } <strong>{ LABELS.YANDEX }</strong> { LABELS.PAYMENT_INFO_TWO }
    </Text>
  );

  const renderVouchers = (vouchers: VoucherStoreType[], title: string, loading: boolean) => {
    if (loading) {
      return (
        <StyledWrapper className={ styles['vouchers-loading'] }>
          <DotLoading />
        </StyledWrapper>
      );
    }

    if (!vouchers.length) {
      return null;
    }

    return (
      <>
        <hr className={ styles.hr } />
        <Text type='bold_20' className={ styles.headerVouchers }>
          { title }
        </Text>
        <StyledWrapper className={ styles['vouchers-wrapper'] }>
          <Vouchers vouchers={ vouchers } />
        </StyledWrapper>
      </>
    );
  };

  const renderWarningMessages = () => (getTripRuleCustomMessage() ? <WarningMessage /> : null);

  const renderContent = () => {
    const activeVouchersHtml = renderVouchers(activeVouchers, LABELS.ACTIVE_VOUCHERS, loadingActiveVouchers);
    const createdVouchersHtml = renderVouchers(createdVouchers, LABELS.CREATED_VOUCHERS, loadingCreatedVouchers);

    return (
      <>
        <div className={ styles['voucher-wrap'] }>
          <Text type='bold_20' className={ styles['voucher-title'] }>
            { LABELS.VOUCHER }
          </Text>
          { renderPaymentInfo() }
          <Voucher
            taxiService={ taxiService }
            onAddToCart={ handleAddToCart }
            getNewTaxiClasses={ getNewTaxiClasses }
          />
        </div>
        { activeVouchersHtml }
        { createdVouchersHtml }
      </>
    );
  };

  const renderWarningText = () => {
    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TITLE;
  const addingTriipDate = tripId ? <DateInSearch name={ tripName } date={ tripDate }/> : null;
  const wrapperWarningStyle = !textTemplateWarning ? styles.wrapper : `${styles.wrapper} ${styles['wrapper-warning']}`;

  return (
    <div className={ wrapperWarningStyle }>
      <BackToTripLink tripId={ tripId } />
      { renderWarningText() }
      <div className={ styles.header }>
        <div className={ styles.title }>
          <Text type='bold_32' qaAttr={ QA_ATTRIBUTES.taxi.title } >{ title }</Text>
          <div className={ styles.link }>
            <ExternalLink
              link={ '/documents/PresentationTaxi(fin).pdf' }
              text={ LABELS.TITLE_INTRO }
            />
          </div>
        </div>
        { addingTriipDate }
      </div>
      { renderContent() }
      { renderWarningMessages() }
    </div>
  );
});

export { Taxi };
