import React from 'react';
import moment, { Moment } from 'moment';
import { PageLoader, Icon, Text, Button, IconType } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { isSmartAgent } from '../../../../../../bi/utils/env';
import { formatDate } from '../../../../../../bi/utils/formatDate';

import { TripEventData, ITripPlanItem } from '../../../../../../bi/types/order';

import { SERVICES_ICON_FOR_TRIP_PLAN } from '../../../../../../bi/constants/serviceType';
import { DATEFORMATS, PATTERN } from '../../../../../../bi/constants/dateFormats';

import TripPlanEvent from '../TripPlanEvent';

import styles from './index.module.css';

interface TripPlanContentProps {
  trips: ITripPlanItem[],
  firstLoading: boolean,
  isShowEdit: boolean,
  selectEmployee:number | null,
  onEdit(TripEvent: ITripPlanItem, value: boolean): void,
  tripPlanEvent: ITripPlanItem,
  onDeleteEvent(value: any): void,
  onSetCollapseOpen(): void,
  onChangePlanEventData(type: string, value: string): void,
  onEditEvent(event: TripEventData, value: boolean): void,
  onCreateEvent(value: { Text: string, Name:string, EventDate: Moment, EventTime: string }): void,
}

const LABELS = {
  LOADER: getText('trip:tripPlan.uploadPlan'),
  ADDITIONAL_INFORMATION: getText('trip:tripPlan.additionalInformation'),
  EDIT: getText('trip:tripPlan.edit'),
  DELETE: getText('trip:tripPlan.remove'),
};

const EVENT_TYPES = {
  TRANSFER: getText('trip:tripPlan.transfer'),
};

const EVENT = 'Event';

const TripPlanContent = ({
  trips,
  firstLoading,
  onDeleteEvent,
  onEdit,
  isShowEdit,
  onSetCollapseOpen,
  onChangePlanEventData,
  onEditEvent,
  onCreateEvent,
  tripPlanEvent,
  selectEmployee,
}: TripPlanContentProps) => {
  if (firstLoading && !trips.length) {
    return (
      <div className={ styles.loader }>
        <PageLoader text={ LABELS.LOADER }/>
      </div>
    );
  }

  const handleEditEvent = (data: TripEventData, value: boolean) => {
    let updateData = data;

    if (Array.isArray(data.Text)) {
      updateData = {
        ...data,
        Text: data.Text.join(),
      };
    }

    onEditEvent(updateData, value);
    onSetCollapseOpen();
  };

  const allButtonsDisabled = !selectEmployee || firstLoading;

  const renderItem = (item: ITripPlanItem & { index: number }) => {
    const {
      EventType,
      EventDate,
      EventTime,
      ServiceType,
      IsClosing,
      Notification,
      Name,
      AdditionalInfo,
    } = item;

    const icon: IconType = SERVICES_ICON_FOR_TRIP_PLAN[ServiceType] as IconType;
    const iconColor = isSmartAgent ? 'second_green' : 'white';
    const date = EventDate ? formatDate(EventDate, DATEFORMATS.DATE) : null;
    const time = EventTime ? moment(EventTime, PATTERN.LOCAL_TIME_PATTERN).format(PATTERN.LOCAL_TIME_PATTERN) : null;
    const wrapperClasses = [styles.wrapper];
    const contentClasses = [styles.body];
    const isTransfer = EventType === EVENT_TYPES.TRANSFER;

    if (!IsClosing && !isTransfer) {
      contentClasses.push(styles.line);
    }

    if (ServiceType === EVENT) {
      wrapperClasses.push(styles.grey_wrapper);
    }

    const infoHtml = !IsClosing && !isTransfer && (
      <div>
        <Text type='SEMIBOLD_16'>{ LABELS.ADDITIONAL_INFORMATION }</Text>
        {Array.isArray(AdditionalInfo) && AdditionalInfo.map((element: any, index: React.Key | null | undefined) =>
          <div
            key={ index }
            className={ styles.text }
            dangerouslySetInnerHTML={ { __html: element } }
          />,
        )}
      </div>
    );

    const notificationHtml = Notification && (
      <div className={ styles.notification }>
        <Text className={ styles.text } type='NORMAL_14'> { Notification } </Text>
      </div>
    );

    const dateAndTimeHtml = date && time && (
      <div className={ styles.information }>
        <Text type='bold_18'>{ time }</Text>
        <Text type='NORMAL_14' color='gray'>{ date }</Text>
      </div>
    );

    const rightContentHtml = ServiceType === EVENT && (
      <div className={ styles.right_content }>
        <Button type='textual' onClick={ () => onEdit(item, true) }>{ LABELS.EDIT }</Button>
        <Button type='textual' onClick={ () => onDeleteEvent(item?.Id) }>{ LABELS.DELETE }</Button>
      </div>
    );

    const renderItemName = () => {
      const isEvent = ServiceType === EVENT;
      const eventHeader = isEvent ? Name : EventType;
      const eventContent = isEvent ? AdditionalInfo.join(', ') : Name;
      const headerDirectionStyles = isTransfer ? styles.header_horizontal : styles.header_vertical;
      const eventHeaderRules = eventHeader === '' ? styles.header_event : '';

      return (
        <div className={ headerDirectionStyles }>
          <div className={ eventHeaderRules }><Text type='bold_18'>{ eventHeader }</Text></div>
          <div><Text>{ eventContent }</Text></div>
        </div>
      );
    };

    const renderItemIcon = () => {
      if (EventType === EVENT_TYPES.TRANSFER) {
        return null;
      }

      return (
        <div className={ styles.left_content }>
          <Icon
            type={ icon }
            alternativeDesign={ isSmartAgent }
            color={ iconColor }
          />
          { dateAndTimeHtml }
        </div>
      );
    };

    if (isShowEdit && ServiceType === EVENT) {
      return (
        <TripPlanEvent
          onCreateEvent={ onCreateEvent }
          onEditEvent={ handleEditEvent }
          onSetCollapseOpen={ onSetCollapseOpen }
          tripPlanEvent={ tripPlanEvent }
          onChangePlanEventData={ onChangePlanEventData }
          isDisabled={ allButtonsDisabled }
        />
      );
    }

    return (
      <div key={ item.index } className={ wrapperClasses.join(' ') }>
        { renderItemIcon() }
        <div className={ styles.content }>
          { renderItemName() }
          <div className={ contentClasses.join(' ') }>
            { notificationHtml }
          </div>
          <div className={ styles.footer }>
            { infoHtml }
          </div>
        </div>
        { rightContentHtml }
      </div>
    );
  };

  return trips.map((trip, index) => renderItem({ ...trip, index }));
};

export { TripPlanContent };
