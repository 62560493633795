import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Moment } from 'moment';
import { Button, PageLoader } from 'new-ui';

import { getText } from '../../../../../i18n';

import { api } from '../../../../bi/apiV2';

import { CombinedDatepicker } from '../../../../components/CombinedDatepicker';

import { FilterEmployees } from './Employees';
import { FilterServices } from './Services';
import { FilterStatuses } from './Statuses';
import { FilterTags } from './Tags';
import { FilterCities } from './Cities';
import { FilterProjects } from './Projects';
import { FilterUserAnalytics } from './UserAnalytics';
import { FilterMineCheckbox } from './MineCheckbox';
import { FilterDepartments } from './Departments';
import { FilterPaymentMethods } from './PaymentMethods';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { useGetParams } from '../../../../bi/hooks/useGetParams';

import { DEFAULT_FILTERS } from '../../../../bi/constants/trips';

import { AnalyticTripFilter, Department, PaymentMethodTypes, TripsFilters } from '../../../../bi/types/trips';

import styles from './index.module.css';

const LABELS = {
  RESET_ALL_FILTERS: getText('trips:resetFilters'),
  LOADING_FILTERS: getText('trips:loadingFilters'),
  MINE_CHECKBOX: getText('trips:mineCheckbox'),
};

const employeesForMultiSelect = (items: { value: number, label: string }[]) => items.map(item => ({ FullName: item.label, EmployeeId: item.value }));
const tagsForMultiSelect = (items: { value: number, label: string }[]) => items.map(item => ({ Value: item.label, Id: item.value }));
const projectsForMultiSelect = (items: { value: number, label: string }[]) => items.map(item => ({ Name: item.label, Id: item.value }));
const userAnalyticsForMultiSelect = (items: { Id: any; Values: any; }[], checkedItems: any[]) => items.map((item: { Id: any; Values: any; }) => {
  const findedItem = checkedItems.find((checkedItem: { Id: any; }) => checkedItem.Id === item.Id);

  return ({ ...findedItem, Values: [...findedItem.Values, ...item.Values] });
});

const useDepartments = () => useQuery({
  queryKey: ['departmentsFilters'],
  queryFn: () => api.departments.get(),
  staleTime: 60000,
});

const useFilters = () => useQuery({
  queryKey: ['tripsFilters'],
  queryFn: () => api.trips.getFilters(),
  staleTime: 60000,
});

interface FiltersProps {
  filters: TripsFilters;
  setFilters: React.Dispatch<React.SetStateAction<TripsFilters>>;
  canPayWithPersonalFunds: boolean;
}

const Filters: FC<FiltersProps> = ({ filters, setFilters, canPayWithPersonalFunds }) => {
  const [departmentSelected, setDepartmentSelected] = useState<Department[]>([]);
  const { data } = useFilters();
  const { data: departmentsData } = useDepartments();
  const history = useHistory();
  const citiesParams = useGetParams('cities');
  const employeesParams = useGetParams('employees');
  const servicesParams = useGetParams('services');
  const statusParams = useGetParams('statuses');
  const startDateParams = useGetParams('startDate');
  const endDateParams = useGetParams('endDate');
  const mineCheckboxParams = useGetParams('mineCheckbox');
  const tagsParams = useGetParams('tags');
  const departmentsParams = useGetParams('departments');
  const companiesParams = useGetParams('companies');
  const checkedDepartmentsParams = useGetParams('checkedDepartments');
  const userAnalyticsParams = useGetParams('userAnalytics');
  const userAnalticsValuesParams = useGetParams('userAnalyticsValues');
  const projectsParams = useGetParams('projects');
  const paymentMethodParams = useGetParams('paymentMethod');

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);

    setDepartmentSelected([]);

    MainAnalytic.send(
      MainAnalytic.CATEGORY.TRIPS,
      MainAnalytic.ACTIONS.TRIPS.FILTERSCLEAR,
    );

    history.push('/trips');
  };

  const handleFilterUse = (label: string, value: any) =>
    MainAnalytic.send(
      MainAnalytic.CATEGORY.TRIPS,
      MainAnalytic.ACTIONS.TRIPS.FILTERSUSE,
      {
        label,
        value,
      },
    );

  const setParams = (key: string, value: string) => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(key, value);
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  };

  const handleChangeDates = (StartDate: Moment | null, EndDate: Moment | null | boolean) => {
    const endDate = typeof EndDate === 'boolean' ? null : EndDate;
    const newFilters = ({ ...filters, StartDate, EndDate: endDate });

    setParams('startDate', JSON.stringify(StartDate));
    setParams('endDate', JSON.stringify(endDate));

    setFilters(newFilters);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.date));
  };

  const handleChangeCities = (checkedCities: string[]) => {
    const newFilters = ({ ...filters, Cities: checkedCities });
    setFilters(newFilters);

    setParams('cities', JSON.stringify(checkedCities));

    handleFilterUse('cities', checkedCities);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.city));
  };

  const handleChangeEmployees = (checkedEmployees: number[], checkedEmployeesWithLabels: { label: string, value: number }[]) => {
    const newFilters = ({ ...filters, Employees: checkedEmployees });
    setFilters(newFilters);

    setParams('employees', JSON.stringify(checkedEmployeesWithLabels));

    handleFilterUse('employees', checkedEmployees);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.employees));
  };

  const handleChangeServices = (checkedServices: string[]) => {
    const newFilters = ({ ...filters, Services: checkedServices });
    setFilters(newFilters);

    setParams('services', JSON.stringify(checkedServices));

    handleFilterUse('services', checkedServices);
    // eslint-disable-next-line no-trailing-spaces

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.services));
  };

  const handleChangeStatuses = (checkedStatuses: string[]) => {
    const newFilters = ({ ...filters, Statuses: checkedStatuses });
    setFilters(newFilters);

    setParams('statuses', JSON.stringify(checkedStatuses));

    handleFilterUse('statuses', checkedStatuses);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.status));
  };

  const handleChangeDepartments = (departmentsChecked: number[], companiesChecked: number[], checkedDepartments: any) => {
    const newFilters = ({ ...filters, Companies: companiesChecked, Departments: departmentsChecked });
    setFilters(newFilters);

    setParams('companies', JSON.stringify(companiesChecked));
    setParams('departments', JSON.stringify(departmentsChecked));
    setParams('checkedDepartments', JSON.stringify(checkedDepartments));

    handleFilterUse('companies', ({ Companies: companiesChecked, Departments: departmentsChecked }));

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.organization));
  };

  const handleChangeProjects = (checkedProjects: number[], checkedProjectsWithLabels: any) => {
    const newFilters = ({ ...filters, Projects: checkedProjects });
    setFilters(newFilters);

    setParams('projects', JSON.stringify(checkedProjectsWithLabels));

    handleFilterUse('projects', checkedProjects);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.cost_center));
  };

  const handleChangeTags = (checkedTags: number[], checkeTagsWithLabels: { label: string, value: number }[]) => {
    const newFilters = ({ ...filters, Tags: checkedTags });
    setFilters(newFilters);

    setParams('tags', JSON.stringify(checkeTagsWithLabels));

    handleFilterUse('tripTags', checkedTags);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.tags));
  };

  const handleChangeUserAnalytics = (checkedUserAnalytics: number[], checkedUserAnalyticsWithLabels: any) => {
    const newFilters = ({ ...filters, AnalyticValues: checkedUserAnalytics });
    setFilters(newFilters);

    handleFilterUse('analytics', checkedUserAnalytics);

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.TRIPS.TRIPS_FILTER_PRESSED(AnalyticTripFilter.analysts));

    if (!userAnalyticsParams) {
      const userAnalitcsOneModified = data!.UserAnalytics.map(userAnalytic => {
        if (userAnalytic.Id !== checkedUserAnalyticsWithLabels.Id) return ({ ...userAnalytic, Values: [] });

        return ({ ...userAnalytic, Values: checkedUserAnalyticsWithLabels.Values });
      });

      setParams('userAnalytics', JSON.stringify(userAnalitcsOneModified));

      return;
    }

    const userAnalitcsOneModified = data!.UserAnalytics.map(userAnalytic => {
      if (userAnalytic.Id !== checkedUserAnalyticsWithLabels.Id) return userAnalytic;

      return ({ ...userAnalytic, Values: checkedUserAnalyticsWithLabels.Values });
    });

    setParams('userAnalytics', JSON.stringify(userAnalitcsOneModified));
    setParams('userAnalyticsValues', JSON.stringify(checkedUserAnalytics));
  };

  const handleChangeMineCheckbox = (checkedUserIsOwner: boolean) => {
    const newFilters = ({ ...filters, UserIsOwner: checkedUserIsOwner });
    setFilters(newFilters);

    setParams('mineCheckbox', JSON.stringify(checkedUserIsOwner));

    handleFilterUse('userIsOwner', checkedUserIsOwner);
  };

  const handleChangePaymentMethod = (checkedPaymentMethods: PaymentMethodTypes[]) => {
    const newFilters = ({ ...filters, Labels: checkedPaymentMethods });
    setFilters(newFilters);

    setParams('paymentMethods', JSON.stringify(checkedPaymentMethods));

    handleFilterUse('paymentMethods', checkedPaymentMethods);
  };

  useEffect(() => {
    let citiesArray = [];
    let employeesValues = [];
    let servicesArray = [];
    let statusArray = [];
    let startDateParam = null;
    let endDateParam = null;
    let userIsOwner = false;
    let companiesArray = [];
    let departmentsArray = [];
    let analyticValuesArray = [];
    let projectsValuesArray = [];
    let tagsValuesArray = [];
    let paymentMethodParam = [];

    if (citiesParams) {
      citiesArray = JSON.parse(citiesParams);
    }

    if (employeesParams) {
      const employees = JSON.parse(employeesParams);
      employeesValues = employees.map(({ value }: { value: number }) => value);
    }

    if (servicesParams) {
      servicesArray = JSON.parse(servicesParams);
    }

    if (statusParams) {
      statusArray = JSON.parse(statusParams);
    }

    if (startDateParams) {
      startDateParam = JSON.parse(startDateParams);
    }

    if (endDateParams) {
      endDateParam = JSON.parse(endDateParams);
    }

    if (mineCheckboxParams) {
      userIsOwner = JSON.parse(mineCheckboxParams);
    }

    if (companiesParams) {
      companiesArray = JSON.parse(companiesParams);
    }

    if (departmentsParams) {
      departmentsArray = JSON.parse(departmentsParams);
    }

    if (checkedDepartmentsParams) {
      setDepartmentSelected(JSON.parse(checkedDepartmentsParams));
    }

    if (userAnalticsValuesParams) {
      analyticValuesArray = JSON.parse(userAnalticsValuesParams);
    }

    if (projectsParams) {
      const projects = JSON.parse(projectsParams);
      projectsValuesArray = projects.map(({ value }: { value: number }) => value);
    }

    if (tagsParams) {
      const tags = JSON.parse(tagsParams);
      tagsValuesArray = tags.map(({ value }: { value: number }) => value);
    }

    if (paymentMethodParams) {
      paymentMethodParam = JSON.parse(paymentMethodParams);
    }

    setFilters({
      Cities: citiesArray,
      Employees: employeesValues,
      Services: servicesArray,
      Statuses: statusArray,
      StartDate: startDateParam,
      EndDate: endDateParam,
      UserIsOwner: userIsOwner,
      Companies: companiesArray,
      Departments: departmentsArray,
      AnalyticValues: analyticValuesArray,
      Projects: projectsValuesArray,
      Tags: tagsValuesArray,
      Labels: paymentMethodParam,
    });
  }, []);

  if (!data || !departmentsData) return <PageLoader text={ LABELS.LOADING_FILTERS } />;

  const departmentFilters = departmentsData
    .map(department => ({
      ...department,
      Departments: department.Departments.filter(departmentValue => data.Departments.includes(departmentValue.Id)),
    }))
    .filter(department => data.Companies.includes(department.CompanyId));

  const cities = citiesParams ? [...new Set([...data.Cities, ...JSON.parse(citiesParams)])] : data.Cities;
  const employees = employeesParams ? [...data.Employees, ...employeesForMultiSelect(JSON.parse(employeesParams))] : data.Employees;
  const services = servicesParams ? [...new Set([...data.Services, ...JSON.parse(servicesParams)])] : data.Services;
  const statuses = statusParams ? [...new Set([...data.Statuses, ...JSON.parse(statusParams)])] : data.Statuses;
  const tags = tagsParams ? [...data.Tags, ...tagsForMultiSelect(JSON.parse(tagsParams))] : data.Tags;
  const userAnalytics = userAnalyticsParams ?
    [...userAnalyticsForMultiSelect(data.UserAnalytics, JSON.parse(userAnalyticsParams))]
    : data.UserAnalytics;
  const projects = projectsParams ? [...data.Projects, ...projectsForMultiSelect(JSON.parse(projectsParams))] : data.Projects;
  const paymentMethods = paymentMethodParams ? [...new Set([...data.Labels, ...JSON.parse(paymentMethodParams)])] : data.Labels;

  return (
    <div className={ styles.wrapper }>
      <Button
        type='textual'
        className={ styles.reset }
        onClick={ () => resetFilters() }
      >
        { LABELS.RESET_ALL_FILTERS }
      </Button>
      <div className={ styles.filters }>
        <div className={ styles.filter }>
          <FilterCities
            cities={ cities }
            values={ filters.Cities }
            onChange={ handleChangeCities }
          />
        </div>
        <div className={ styles.filter }>
          <FilterEmployees
            employees={ employees }
            values={ filters.Employees }
            onChange={ handleChangeEmployees }
          />
        </div>
        <div className={ styles.filter }>
          <FilterServices
            services={ services }
            values={ filters.Services }
            onChange={ handleChangeServices }
          />
        </div>
        <div className={ styles.filter }>
          <FilterStatuses
            statuses={ statuses }
            values={ filters.Statuses }
            onChange={ handleChangeStatuses }
          />
        </div>
        <div className={ styles.filter }>
          <CombinedDatepicker
            date={ filters.StartDate }
            optionalDate={ filters.EndDate }
            className={ styles['range-datepicker'] }
            onChange={ handleChangeDates }
          />
        </div>
        <FilterDepartments
          selected={ departmentSelected }
          setSelected={ setDepartmentSelected }
          departments={ departmentFilters }
          onChange={ handleChangeDepartments }
        />
        <FilterProjects
          projects={ projects }
          values={ filters.Projects }
          onChange={ handleChangeProjects }
        />
        <FilterTags
          tags={ tags }
          values={ filters.Tags }
          onChange={ handleChangeTags }
        />
        <FilterUserAnalytics
          userAnalytics={ userAnalytics }
          values={ filters.AnalyticValues }
          onChange={ handleChangeUserAnalytics }
        />
        <FilterPaymentMethods
          paymentMethods={ paymentMethods }
          values={ filters.Labels }
          onChange={ handleChangePaymentMethod }
          canPayWithPersonalFunds={ canPayWithPersonalFunds }
        />
        <FilterMineCheckbox
          show={ data.ShowButtonOwner }
          value={ filters.UserIsOwner }
          label={ LABELS.MINE_CHECKBOX }
          onChange={ handleChangeMineCheckbox }
        />
      </div>
    </div>
  );
};

export { Filters };
