import React, { Component } from 'react';
import { PageLoader, LocaleContext } from 'new-ui';
import {
  Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import * as queryString from 'query-string';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { StoreProvider } from './bi/context';
import { ServicesProtocolProvider } from './bi/context/context';

import { getText } from '../i18n';

import STATUS from './bi/constants/status';
import { ICON_LIST, SMARTWAY } from './bi/constants/accounts';
import ROUTES from './bi/constants/routes';

import MainLayout from './layout/Main';
import LoginPage from './page/Login';
import LoginDialog from './components/Login';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytic from './components/GoogleAnalytic';
import YandexMetric from './components/YandexMetric';
import MixPanelMetric from './components/MixPanel';
import { QuickApprovePage } from './page/QuickApprove';
import Search from './components/Search';
import { ReportAct } from './page/ReportAct';
import { ContractHotel } from './page/ContractHotel';

import { detectBrowser } from './bi/utils/detectBrowser';
import { isSmartAgent } from './bi/utils/env';
import { history } from '../utils/history';

import App from './bi';
import CONFIG from '../config';

import './styles/global/index.css';
import styles from './styles/index.module.css';
import { IAppStore } from './bi/types/app';

const SEARCH = getText('smartdesk:openWorkplace');

const LINKSELECTOR = 'link[rel="icon"], link[rel="apple-touch-icon"]';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      staleTime: Infinity,
    },
  },
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

interface AppComponentProps {

}

interface IAppComponentState {
  appStatus: number | null;
  loading: boolean;
  showDevTools: boolean;
}

const initializeCarrotQuest = () => {
  // eslint-disable-next-line func-names
  (function () {
    // eslint-disable-next-line no-shadow,no-unused-vars,@typescript-eslint/no-shadow
    function t(t: any) {
      // eslint-disable-next-line func-names
      return function () {
        // eslint-disable-next-line prefer-rest-params
        window.carrotquestasync.push(t, arguments);
      };
    }

    if (typeof window.carrotquest === 'undefined') {
      const e = document.createElement('script');
      // eslint-disable-next-line no-sequences,@typescript-eslint/no-unused-expressions
      e.type = 'text/javascript';
      e.async = !0;
      e.src = 'https://cdn.carrotquest.app/api.min.js';
      document.getElementsByTagName('head')[0].appendChild(e);
      window.carrotquest = {};
      window.carrotquestasync = [];
      window.carrotquest.settings = {};

      for (let n = ['connect', 'track', 'identify', 'auth', 'onReady', 'addCallback', 'removeCallback', 'trackMessageInteraction'], a = 0; a < n.length; a++) window.carrotquest[n[a]] = t(n[a]);
    }
  }());

  // eslint-disable-next-line prefer-rest-params
  window.carrotquest.connect('64729-4c5de0bd58c3fa6fe3f5b69f8e');
};

class AppComponent extends Component<AppComponentProps, IAppComponentState> {
  app: App;
  unsubscribeFn: () => void;

  constructor(props: AppComponentProps) {
    super(props);

    this.app = new App();
    const appService = this.app.getService('App');

    const { appStatus } = appService.get();

    this.state = {
      loading: appStatus !== STATUS.NOTAUTH,
      appStatus,
      showDevTools: false,
    };

    this.unsubscribeFn = appService.subscribe(this.updateState);

    if (this.state.appStatus === STATUS.OK) {
      this.app.loadAppResources().then(() => {
        this.startSignalr();
        this.setCompanySettings();
        this.setState({
          loading: false,
        });
      });
    }
  }

  componentDidMount() {
    // @ts-ignore
    if (import.meta.env.MODE === 'development') {
      this.setState({ showDevTools: true });
    }

    if (isSmartAgent) {
      initializeCarrotQuest();
    }
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  setCompanySettings = () => {
    document.title = this.app.getService('UiSettings').getHeadTitle();

    const companyName = this.app.getService('UiSettings').getCompanyName();

    if (companyName !== SMARTWAY && ICON_LIST[companyName]) {
      this.setFavicons(companyName);
    }
  };

  setFavicons = (companyName: string) => {
    if (ICON_LIST[companyName]) {
      const iconList = document.querySelectorAll(LINKSELECTOR);
      const icons = ICON_LIST[companyName];

      iconList.forEach((linkIcon) => {
        const size = linkIcon.getAttribute('sizes');

        if (icons[size as keyof typeof icons]) {
          linkIcon.setAttribute('href', icons[size as keyof typeof icons]);
        }
      });
    }
  };

  updateState = ({ appStatus }: IAppStore) => {
    this.setState({
      appStatus,
    });
  };

  startSignalr = () => new Promise<void>((resolve) => {
    const query = queryString.stringify({
      // @ts-ignore
      Authorization: `Bearer ${this.app.api.currentToken}`,
      SessionId: this.app.sessionId,
    });

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${CONFIG.WEB_SOCKET}/signalr/hub?${query}`)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    const isFiltersHotelsInMicroservice = this.app.getService('FeatureFlags').getFiltersHotelsInMicroservice();

    (async () => {
      await connection.start();
      connection.on('refreshStatus', (data) => {
        // eslint-disable-next-line no-console
        console.info('refresh header');

        const { Companies } = this.app.getService('Workspace').get();
        this.app.getService('App').updateHeader(data, Companies);
      });

      connection.on('refreshPage', (guid) => {
        // eslint-disable-next-line no-console
        console.info('refresh hotels page');

        this.app.getService('Hotels').loadLastSearch(guid, isFiltersHotelsInMicroservice);
      });

      connection.on('searchComplited', (guid) => {
        // eslint-disable-next-line no-console
        console.info('hotels search completed');

        this.app.getService('Hotels').loadComplete(guid, isFiltersHotelsInMicroservice);
      });

      connection.on('logoutApp', () => {
        // eslint-disable-next-line no-console
        console.info('logoutApp');

        this.handleLogout();
        this.app.getService('App').logout();
      });

      connection.on('appRefresh', () => {
        // eslint-disable-next-line no-console
        console.info('refresh app');

        window.location.reload();
      });

      connection.on('updateHeaderPartial', (data) => {
        this.app.getService('App').updateHeaderPartial(data);
      });
      resolve();
    })();
  });

  getCurrentLocale = () => {
    const uiSettingsService = this.app.getService('UiSettings');
    const currentLanguage = uiSettingsService.getCurrentLanguage();

    return {
      locale: currentLanguage,
    };
  };

  updateChatSession = () => {
    const chatService = this.app.getService('ChatDecorator');
    const directoryName = this.app.getService('UiSettings').getCompanyName();
    const { Email, CompanyId, ShortCompanyName, CompanyName } = this.app.getService('Workspace').get();

    const user = { email: Email };
    const company = {
      companyId: CompanyId,
      companyName: ShortCompanyName || CompanyName,
    };

    chatService.openSession(user, company, directoryName);
  };

  handleLogout = () => {
    if (this.app.getService('UiSettings').getCompanyName() !== SMARTWAY) {
      this.setFavicons(SMARTWAY);
    }

    this.app.logout();
    window.location = CONFIG.PROMO_ROOT;
  };

  handleRedirectToRoot = () => {
    window.location.reload();
  };

  handleLogin = (quickAuthUuid: string): Promise<void> => this.app.login(quickAuthUuid).then(this.startSignalr);

  handleClientSessionLogin = () => this.app.loginToClientSession().then(this.startSignalr);

  renderAppLoading() {
    return (
      <div className={ styles['app-loading-wrap'] }>
        <PageLoader text={ SEARCH } />
      </div>
    );
  }

  renderLogin() {
    return <Redirect to='/login' />;
  }

  renderLayout = (props: AppComponentProps) => {
    const { appStatus } = this.state;

    if (appStatus === STATUS.NOTAUTH) {
      return this.renderLogin();
    }

    const currentLocale = this.getCurrentLocale();

    return (
      <LocaleContext.Provider value={ currentLocale }>
        <MainLayout
          { ...props }
            // @ts-ignore
          getService={ this.app.getService }
          getProtocol={ this.app.getProtocol }
          onLogout={ this.handleLogout }
          updateChatSession={ this.updateChatSession }
          browser={ detectBrowser() }
        />
      </LocaleContext.Provider>
    );
  };

  render() {
    const { appStatus, loading, showDevTools } = this.state;

    const googleAnaliticsHtml = CONFIG.METRIKS.GOOGLE.ENABLED && appStatus !== STATUS.NOTAUTH ? (
      <GoogleAnalytic workspaceService={ this.app.getService('Workspace') } />
    ) : null;

    const mixpanelMetrikaHtml = CONFIG.METRIKS.MIXPANEL.ENABLED && appStatus !== STATUS.NOTAUTH ? (
      <MixPanelMetric workspaceService={ this.app.getService('Workspace') } />
    ) : null;

    const loginRoutes = ['/login', '/auth'].map(path => (
      <Route
        key={ path }
        path={ path }
        render={ props => (
          <Search withoutMargin>
            <LoginPage
              { ...props }
                // @ts-ignore
              onLogin={ this.handleLogin }
              onClientSession={ this.handleClientSessionLogin }
              authService={ this.app.getService('Auth') }
              appService={ this.app.getService('App') }
            />
          </Search>
        ) }
      />
    ));

    const quickApproveRoutes = [
      ROUTES.QUICK_APPROVES.MAIN,
      ROUTES.QUICK_APPROVES.TRAVEL_APPROVAL,
      ROUTES.QUICK_APPROVES.EXPENSE_REPORT_APPROVAL].map(path => (
        <Route
          key={ path }
          path={ path }
          render={ (props) => (
            // @ts-ignore
            <QuickApprovePage { ...props } />
          ) }
        />
    ));

    const content = loading ? this.renderAppLoading() :
      (
        <QueryClientProvider client={ queryClient }>
          <ServicesProtocolProvider
            getService={ this.app.getService }
            getProtocol={ this.app.getProtocol }
          >
            <StoreProvider>
              <Router history={ history }>
                <YandexMetric
                  workspaceService={ this.app.getService('Workspace') }
                  appService={ this.app.getService('App') }
                >
                  <div className={ styles.app }>
                    <ScrollToTop>
                      <Switch>
                        { loginRoutes }
                        { quickApproveRoutes }
                        <Route
                          path='/reviseact/:guid'
                          render={ props => (
                            <ReportAct
                              { ...props }
                              reportService={ this.app.getService('Report') }
                            />
                          ) }
                        />
                        <Route
                          path='/contract-hotel/:action/:guid'
                          render={ props => (
                            <ContractHotel
                              { ...props }
                              hotelsService={ this.app.getService('Hotels') }
                            />
                          ) }
                        />
                        <Route path='/' render={ this.renderLayout } />
                      </Switch>
                    </ScrollToTop>
                    <LoginDialog
                      authService={ this.app.getService('Auth') }
                      appService={ this.app.getService('App') }
                      uiSettingsProtocol={ this.app.getProtocol('UiSettings') }
                      onLogin={ () => this.app.login().then(this.handleRedirectToRoot) }
                    />
                    { googleAnaliticsHtml }
                    { mixpanelMetrikaHtml }
                  </div>
                </YandexMetric>
              </Router>
            </StoreProvider>
          </ServicesProtocolProvider>
          {showDevTools && (
          <React.Suspense fallback={ null }>
            <ReactQueryDevtoolsProduction/>
          </React.Suspense>
          )}
        </QueryClientProvider>
      );

    return (
      <>
        <iframe id='xFrame' title='xFrame' src={ `${CONFIG.PROMO_MAIN}/xauth.html` } style={ { display: 'none' } } />
        { content }
      </>
    );
  }
}

export default AppComponent;
