import { Moment } from 'moment';

import { UserAnalytic } from './trips';
import { ServiceType } from './order';
import { IStatus } from './booking';

import { ConsistentCartModes } from '../constants/approve';

interface ApproveType {
  ApprovalStepId: number,
  CartId: number,
  CreatedDate: string,
  Email: string,
  Id: string,
  Messages: {
    Comment: string,
    Date: string,
    Email: string,
    Name: string,
  }[],
  Name: string | null,
  Resolution: string,
  TravelApproval: boolean,
  Type: string,
}

export interface CartsType {
  approve: ApproveType[],
  fouls: IItem[],
  id: number,
  isOffline: boolean,
  maxDate: Moment,
  minDate: Moment | null,
  name: string,
  price: number,
  project: boolean,
  serverTime: Moment,
  ServerTime?: Moment,
  sources: IItem[],
  status: string,
  unlock: boolean,
  userAnalytics: UserAnalytic[],
  items: any,
}

// NEW
export interface ICartWpResult extends ICartWP {
  MiceCartLink: IMiceCartLink;
}

export interface ICartWP {
  Id: number;
  BookId: string;
  Name: string;
  ServerTime: string;
  ShowPurchaseButton: boolean;
  Status: ECartStatus;
  Items: IItem[];
  Approve: ApproveType[];
  UserAnalytics: UserAnalytic[];
  MiceCartLink: IMiceCartLink;
  IsOffline: boolean;
}

export interface ISpecificCartTypes {
  APPROVER: ConsistentCartModes.approver,
  VIEWER: ConsistentCartModes.viewer,
}

export interface IPrepareCartWpResult extends ICartWpResult {
  Items: IPrepareItem[];
}

export interface IItem {
  CartId: number;
  Id: number;
  CompanyId?: number;
  Price: number;
  ServiceType: ServiceType;
  Data: string;
  LowestFares: ILowestFare[];
  RequestItem: IRequestItem;
  TripId?: number | null;
  Reason: string | null;
  BookDeadline: string | null;
  AdditionalData: string[] | null;
  Metadata: string;
  IsReserved: boolean;
  EmployeeCartItems: IItemEmployee[];
  Employees: ICartItemEmployeeData[];
  Tags: IEmployeeTag[];
  TravelPolicy: Record<string, string>;
  UserAnalytics: number[];
  AllowedDocuments: Record<string, DocumentType[]>;
  AgentFee: number;
  Error: string | null;
  CancellationInfo?: string;
  HasChanges: boolean,
}

export interface IPrepareItem extends IItem {
  isCanceled?: boolean;
  bookingStatus?: IStatus;
}

export interface IItemEmployee {
  Id: number;
  EmployeeId: number;
  CartItemId: number;
  CompanyId?: number;
  ProjectId?: number;
  DepartmentId?: number;
  DocumentId?: number;
}

export interface ICartItemEmployeeData {
  Employee: IEmployee;
  Documents: ICartItemEmployeeDocument;
  Clone: () => ICartItemEmployeeData;
}

export interface IEmployee {
  Id: number;
  ExternalId: string;
  Surname: string;
  Name: string;
  Patronymic: string;
  MobilePhone: string;
  Email: string;
  Code: string;
  Code3: string;
  Birthday: Date;
  Underage: boolean;
  Sex: ISex;
  Status: IEmployeeStatus;
  EmployeeNumber: string;
  Bonuses: IEmployeeBonus[];
  Documents: IEmployeeDocument[];
  Companies: IEmployeeCompany[];
  Departments: IEmployeesDepartments[];
  Projects: IEmployeeProject[];
  Tags: IEmployeeTag[];
  ApprovalSchemeId?: number;
  ProjectId?: number;
  Position: string;
}

export enum IEmployeeStatus {
  Active = 'Active',
  Archived = 'Archived',
  User = 'User',
}

export enum ISex {
  Woman = 'Woman',
  Man = 'Man',
}

export interface ICartItemEmployeeDocument {
  DefaultDocumentId?: number;
  AllowedDocumentTypes: string[];
}

export interface ICartApprove {
  Id: string;
  Email: string;
  Name: string;
  CartId?: number;
  Messages: IApproveMessage[];
  Resolution: ECartStatus;
  Type: ApproveType;
  ApprovalCondition?: EConditionOfApproval;
  ApprovalStepId?: number;
  TravelApproval: boolean;
  CreatedDate: Date;
}

export enum EConditionOfApproval {
  /** Необходимо подтверждение всех согласующих */
  AllApprovers = 'AllApprovers',

  /** Необходимо подтверждение одного из согласующих, но на согласование отправляем всем */
  OneOfApproversAndSendAll = 'OneOfApproversAndSendAll',

  /** Необходимо подтверждение одного из согласующих, но на согласование только тем кого выбрал пользователь */
  OneOfApproversAndSendSelected = 'OneOfApproversAndSendSelected',
}

export interface IApproveMessage {
  Name: string;
  EmployeeId?: number;
  Email: string;
  Date: Date;
  Comment: string;
}

export interface IEmployeeTag {
  Id: number;
  Value: string;
}

export interface IEmployeeProject {
  Id: number;
  Name: string;
  ResponsibleEmployeeId: number;
}

export interface IEmployeeDocument {
  Id: number;
  Type: string;
  Number: string;
  Surname: string;
  Name: string;
  Patronymic: string;
  LastName: string;
  FirstName: string;
  MiddleName: string;
  DueDate?: Date;
  Selected: boolean;
}

export interface IEmployeesDepartments {
  EmployeeId: number;
  DepartmentId: number;
}

export interface IEmployeeCompany {
  CompanyId: number;
  CompanyName: string;
  ShortCompanyName: string;
  CompanySettings: CompanySettings;
  EmployeeId: number;
  Departments: IEmployeeDepartment[];
}

export interface CompanySettings {
  MandatoryProject: boolean;
}

export interface IEmployeeDepartment {
  Id: number;
  Name: string;
  CompanyId: number;
  HeadOfDepartmentEmployeeId?: number;
}

export interface IEmployeeBonus {
  Id: number;
  // Type: BonusType;
  Type: any;
  Code: string;
  Number: string;
  EmployeeId: number;
}

export interface IRequestItem {
  Id: number;
  RequestId: number;
}

export enum ECartStatus {
  /// Нормальное состояние - обычная корзина
  Normal = 'Normal',
  /// Ожидает согласования (на согласовании)
  WaitingApprove = 'WaitingApprove',
  /// Согласовано
  Approved = 'Approved',
  /// Не согласовано
  Declined = 'Declined',
  /// Согласовано автоматически(Для многоуровневых схем.)
  /// Кейс когда согласовал кто-то один и мы должны согласовать все остальные согласования сами.
  /// В таком случаи надо отличать согласовано ли это пользователем или это сделали мы.
  AutoApproved = 'AutoApproved',
  /// Не согласовано автоматически(Для многоуровневых схем.)
  /// Кейс когда не согласовал кто-то один и мы должны отменить все остальные согласования сами.
  /// В таком случаях надо отличать не согласовано ли это пользователем или это сделали мы.
  AutoDeclined = 'AutoDeclined',
}

export interface IMiceCartLink {
  EventId: string;
  CartId: number;
}

export interface ILowestFare {
  Id: string;
  AccountId: number;
  CartItemId: number;
  ServiceType: number;
  DeparturePlace: IBaseInfo;
  ArrivalPlace: IBaseInfo;
  Organization: IBaseInfo;
  Additional: IAirlineAdditional;
  Price: number;
  DepartureTime: Date;
  ArrivalTime: Date;
}

export interface IBaseInfo {
  Code: string;
  Name: string;
}

export enum EFlightClass {
  Economy,
  Business,
  First,
}

export enum EFareServiceStatus {
  Included,
  NotIncluded,
}

export interface IAirlineAdditional {
  Duration: string;
  RouteNumber: number;
  FlightNumber: string;
  FlightClass: EFlightClass;
  IsBaggageIncluded: EFareServiceStatus;
}

export interface PreselectedRequest1c {
  type: string,
  id?: string,
}

interface ReservationRoute {
  ArrivalCity: string,
  DepartureCity: string,
}

export interface ReservationFailedItems {
  Departure: string,
  Arrival: string,
  Date: string,
  Price: number,
  ReservationRoute: ReservationRoute[],
}

export interface TripsToAddOrder {
  value: number,
  label: string,
}

export interface Request1cList {
  RequestId: number,
  RequestItemIds: number[],
}

export enum IStatusUpdates {
  InProgress = 'InProgress',
  Finished = 'Finished',
}

export enum ITypesUpdates {
  HotelExpireDates = 'HotelExpireDates',
}

export interface ICartItemsUpdate {
  CartItemId: number,
  Changes: { Type: ITypesUpdates }[],
  State: {
    Status: IStatusUpdates,
    UpdateDate: string
  }
}
